import { css } from '@emotion/core';
import { withTheme } from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import openSocket from 'socket.io-client';
// import 'sweetalert2/src/sweetalert2.scss';
import Store from '../../../store';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const select_option = [
    { title: 'Người lạ', value: 4 },
    { title: 'Vip', value: 2 },
    { title: 'Nhân viên', value: 1 },
];


class Checking extends Component {
    constructor (props) {
        super(props);
        this.state = {
            startDate: new Date(),
            isLogin: Store.getState().isLogin.isLogin,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            group: true,
            lst_person: [],
            crrDataInfo: null,
            crr_person: null,
            auto_update: false,
            modalShowMoveImage: false,
            dataMoveImage: null,
            fullScreen: false,
            loading: false,
        }

        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    crrDataInfo: null,
                    crr_person: null,
                    auto_update: true,
                }, () => {
                    this.getdata()
                })
            } else {
                this.setState({
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    crrDataInfo: null,
                    crr_person: null,
                    auto_update: true,
                })
            }
            this.setState({
                rule: Store.getState().rules.rule,
            })
        });
        this.escFunction = this.escFunction.bind(this);
    }

    componentDidMount() {
        if(this.state.box_engine_cf){
            this.getdata();
        }
        document.addEventListener("keydown", this.escFunction, false);
    }
    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.auto_update !== this.state.auto_update) {
    //         if(!this.state.auto_update){
    //             return clearInterval(this.timeOut);
    //         }
    //         this.timeOut = setInterval(() => {
    //             this.setState({
    //                 startDate: new Date(),
    //             })
    //         }, 1000)
    //     }
    // }
    escFunction(event){
        if(event.keyCode === 27) {
            this.setState({ fullScreen: false })
        }
      }
      componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
        if (this.socket !== undefined) {
            if (this.socket.connected === true) {
                this.socket.disconnect();
            }
        }
      }

    getdata = async () => {
        this.setState({loading: true})

        const a = await this.getDataAPI(1, 10);
        const b = await this.getDataAPI(2, 10);
        
        const c = a.concat(b);
        const d = c.sort((a, b) => {
            if(a.timeget > b.timeget){
                return -1;
            }else if(a.timeget < b.timeget){
                return 1;
            }
            return 0;
        });
        this.setState({
            lst_person: d,
            loading: false,
        });
        this.checkSocket();
    }

    checkSocket = () => {
        if (this.state.box_engine_cf === undefined) return;
        let idbox = this.state.box_engine_cf.dataCam.id_box_engine.toString();

        this.socket = openSocket(`${this.state.box_engine_cf.apiSocket}`);
        this.socket.on('connect', () => {
            console.log('socket connected!')
            this.socket.emit('joined', idbox + '');
        })
        this.socket.on('disconnect', () => {
            console.log('socket disconnected!')
        })
        this.socket.on('my response', async (msg) => {
            console.log(msg)
            if (msg.operationType === 'insert') {
                // if (moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss') >= moment(new Date(new Date().setHours(0, 0, 0, 0))).format('YYYY-MM-DD HH:mm:ss') && moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss') <= moment(new Date(new Date().setHours(23, 59, 59, 0))).format('YYYY-MM-DD HH:mm:ss')) {
                var lst_person = [];
                if (msg.type === 2 || msg.type === 1) {
                    lst_person = this.state.lst_person;
                    var ii = lst_person.findIndex((val) => {
                        if (this.state.group) {
                            return (val.person === msg.person)
                        }
                        else {
                            return (val.person === msg.person && val.imgRc === msg.imgRc)
                        }
                    })
                    if (ii >= 0) {
                        lst_person[ii].imgFace.unshift({ 'imgFace': msg.imgFace, '_id': msg._id, 'imgRc': msg.imgRc, 'timeget': msg.timeget, 'timecreated': msg.timecreated });
                        lst_person[ii].imgFace_Original_Size.unshift({ 'imgFace': msg.imgFace_Original_Size, '_id': msg._id, 'imgRc': msg.imgRc, 'timeget': msg.timeget, 'timecreated': msg.timecreated });
                        lst_person[ii].timeget = msg.timeget;
                        lst_person[ii].timecreated = msg.timecreated;
                        lst_person = lst_person.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                    }
                    else {
                        lst_person.unshift({
                            '_id': msg._id,
                            'idCamera': msg['idCamera'],
                            'timeget': msg['timeget'],
                            'timecreated': msg['timecreated'],
                            'imgFace': [{ imgFace: msg.imgFace, _id: msg._id }],
                            'imgFace_Original_Size': [{ imgFace: msg.imgFace_Original_Size, _id: msg._id }],
                            'imgRc': msg['imgRc'],
                            'person': msg['person'],
                            'age': msg['age'],
                            'gender': msg['gender'],
                            'emotion': msg['emotion'],
                            'type': msg['type'],
                            'information': msg['information']
                        })
                    }
                    this.setState({
                        lst_person: lst_person.slice(0, 10),
                    })
                }
            } else if (msg.operationType === 'update') {
                // if (moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss') >= moment(new Date(new Date().setHours(0, 0, 0, 0))).format('YYYY-MM-DD HH:mm:ss') && moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss') <= moment(new Date(new Date().setHours(23, 59, 59, 0))).format('YYYY-MM-DD HH:mm:ss')) {
                    //Update
                    var lst_person = [];
                if (msg.type === 1 || msg.type === 2) {
                    lst_person = this.state.lst_person
                    //Delete
                    for (let index = 0; index < lst_person.length; index++) {
                        if (lst_person[index]._id === msg._id) {
                            lst_person.splice(index, 1)
                        }
                    }
                    var ii = lst_person.findIndex((val) => {
                        if (this.state.group) {
                            return (val.person === msg.person)
                        }
                        else {
                            return (val.person === msg.person && val.imgRc === msg.imgRc)
                        }
                    })
                    if (ii >= 0) {
                        lst_person[ii].imgFace.unshift({ 'imgFace': msg.imgFace, '_id': msg._id, 'imgRc': msg.imgRc, 'timeget': msg.timeget, 'timecreated': msg.timecreated });
                        lst_person[ii].imgFace_Original_Size.unshift({ 'imgFace': msg.imgFace_Original_Size, '_id': msg._id, 'imgRc': msg.imgRc, 'timeget': msg.timeget, 'timecreated': msg.timecreated });
                        lst_person[ii].timeget = msg.timeget;
                        lst_person[ii].timecreated = msg.timecreated;
                        lst_person = lst_person.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                    }
                    else {
                        lst_person.unshift({
                            '_id': msg._id,
                            'idCamera': msg['idCamera'],
                            'timeget': msg['timeget'],
                            'timecreated': msg['timecreated'],
                            'imgFace': [{ imgFace: msg.imgFace, _id: msg._id }],
                            'imgFace_Original_Size': [{ imgFace: msg.imgFace_Original_Size, _id: msg._id }],
                            'imgRc': msg['imgRc'],
                            'person': msg['person'],
                            'age': msg['age'],
                            'gender': msg['gender'],
                            'emotion': msg['emotion'],
                            'type': msg['type'],
                            'information': msg['information']
                        })
                    }
                    this.setState({
                        lst_person: lst_person.slice(0, 10),
                    })
                }
                // }
            } else {
                var lst_person = [...this.state.lst_person].filter(filter => filter._id !== msg._id);

                this.setState({
                    lst_person: lst_person
                })
            }
        })
    }


     getDataAPI = async (type, number) => {
        var { group } = this.state;
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        const result = await Axios({
            method: 'POST',
            url: `${this.state.box_engine_cf.api2_url}/api/person/getFaceByTime`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                type: type,
                idCamera: this.state.box_engine_cf.ids_Cam.join(','),
                fromDate: moment(this.state.startDate.setHours(0, 0, 0, 0)).format('YYYY-MM-DD HH:mm:ss'),
                toDate: moment(this.state.startDate.setHours(23, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss'),
                numberOfItem: number,
            })
        });
        if(result.data.status === 10000){
            try {
                var img = [];
                var data = result.data.data;
                console.log(result);
                for (let i = 0; i < data.length; i++) {
                    data[i].type = data[i].person_type
                    data[i]._id = data[i].id
                    var ii = img.findIndex((val) => {
                        if (group) {
                            return (val.person === data[i].person)
                        }
                        else {
                            return (val.person === data[i].person && val.imgRc === data[i].imgRc)
                        }
                    })
                    if (ii >= 0) {
                        img[ii].imgFace.push({ 'imgFace': data[i].thumbnail_sample_image, '_id': data[i]._id, 'imgRc': data[i].imgRc, 'timeget': data[i].timeget, 'timecreated': data[i].timecreated });
                        img[ii].imgFace_Original_Size.push({ 'imgFace': data[i].origin_size_sample_image, '_id': data[i]._id, 'imgRc': data[i].imgRc, 'timeget': data[i].timeget, 'timecreated': data[i].timecreated });
                    }
                    else {
                        data[i].imgFace = [{ 'imgFace': data[i].thumbnail_sample_image, '_id': data[i]._id, 'imgRc': data[i].imgRc, 'timeget': data[i].timeget, 'timecreated': data[i].timecreated }];
                        data[i].imgFace_Original_Size = [{ 'imgFace': data[i].imgFace_Original_Size, '_id': data[i]._id, 'imgRc': data[i].imgRc, 'timeget': data[i].timeget, 'timecreated': data[i].timecreated }];
                        // data[i].timecreated = moment(data[i].timecreated).format('DD-MM-YYYY HH:mm:ss')
                        img.push(data[i]);
                    }
                }
                return img;
            } catch (error) {
                console.log(error)
            }
        }else{  
            return [];
        }
    }
    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper mb-0" style={{'fontFamily': 'arial,verdana,sans-serif'}}>
                <div className="m-content mt-3-phone pd_phone_0 p-3" style={this.state.fullScreen ? { position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 9999, backgroundColor: '#fff'} : {backgroundColor: '#fff'}}>
                    <div>  
                        <div className="m-portlet__body  m-portlet__body--no-padding">
                            <div className="row m-row--no-padding">
                                <div className="col-md-12 p-4">
                                    {/*begin::Total Profit*/}
                                    <div className="card-box">
                                        <div className='row m-0 p-0'>
                                            {
                                                this.state.lst_person.filter((filter, i) => i < 6).map((map, i) => {
                                                    console.log(map)
                                                    return(
                                                    <div key={i} className='col-xl-4 col-md-6 col-12 row m-0' style={!this.state.fullScreen ? { paddingTop: 50, paddingBottom: 80 } : { paddingBottom: 80, paddingTop: 120 }}>
                                                        <div className='col-12 col-xl-6' style={{ 'position': 'relative', 'margin': 'auto' }}>
                                                            <Image
                                                                style={{width: 270, height: 270, objectFit: 'cover'}}
                                                                src={map == null ? '/img/photo-placeholder.png' :
                                                                        map.imgFace_Original_Size[0].imgFace === undefined ? "/img/photo-placeholder.png" : map.imgFace_Original_Size[0].imgFace.indexOf('http') >= 0 ? map.imgFace_Original_Size[0].imgFace :
                                                                            `${this.state.box_engine_cf.api2_url}/view${map.imgFace_Original_Size[0].imgFace}`}
                                                                className='rounded-circle mr-0 mr-md-5'></Image>
                                                            <Image style={{ 'position': 'absolute', 'bottom': '-20px', 'left': 170, 'border': 'solid 5px white', width: 110, height: 110, objectFit: 'cover' }} 
                                                                src={map == null ? '/img/photo-placeholder.png' :
                                                                    (map.information.accept_face && map.information.accept_face.indexOf('http')) >= 0 ? map.information.accept_face :
                                                                        `${this.state.box_engine_cf.api2_url}/view${map.information.accept_face}`}
                                                                className='rounded-circle'></Image>
                                                        </div>
                                                        <div className='col-12 col-xl-6' style={{ 'margin': 'auto', paddingLeft: 43}}>
                                                            <div className='pl-0'>
                                                                <div className='my-3 p-1' style={{ fontWeight: 'bold', fontSize: 30, lineHeight: 1.1, }}>
                                                                    {`${map == null ? "" : map.information.name} ${(map !== null && map.information.code) ? '(' + map.information.code + ')' : ''}`}
                                                                </div>
                                                                <div className='my-4'>
                                                                    <span style={(map && map.information.department) ? { fontWeight: 'bold', fontSize: 22, color: 'white', backgroundColor: '#43b968', borderRadius: 5, lineHeight: 1, padding: 5 } : {}}>
                                                                        {map && map.information.department}
                                                                    </span>
                                                                </div>
                                                                <div className='my-4' style={{'margin': 'auto'}}>
                                                                    <span style={{ fontWeight: 'bold', fontSize: 22, color: 'white', backgroundColor: '#f59345', borderRadius: 5, padding: 5, lineHeight: 1 }}>{map !== null ? moment(map.timeget).format('DD-MM-YYYY HH:mm:ss') : ''}</span>
                                                                </div>
                                                                <div className='m--font-info' style={{ fontSize: 22 }}>
                                                                    {map === null ? "" : select_option.filter(x => x.value === map.type).length > 0 ? select_option.filter(x => x.value === map.type)[0].title : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    {/*end::Total Profit*/}
                                </div>
                                <div className='my-1 position-absolute' style={{border: 'none'}}>
                                    <button className='btn btn-outline-dark' style={{ border: 'none' }} onClick={() => {
                                        this.setState({ fullScreen: !this.state.fullScreen }, () => {
                                            if (this.state.fullScreen) {
                                                document.documentElement.requestFullscreen();
                                            }
                                            else {
                                                if (document.fullscreenElement) {
                                                    document.exitFullscreen();
                                                }
                                            }
                                        });

                                    }}>
                                        {this.state.fullScreen ?
                                            <i class="fas fa-compress" style={{ fontSize: 20 }}></i>
                                            :
                                            <i class="fas fa-expand" style={{ fontSize: 20 }}></i>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PulseLoader
                        css={override}
                        sizeUnit={"px"}
                        size={12}
                        margin={'2px'}
                        color={'#36D7B7'}
                        loading={this.state.loading}
                    />
                </div>
            </div>
            
        );
    }

}

export default Checking;
