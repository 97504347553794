import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Store from '../../store';
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const styleImg = { 'width': '60px', 'height': '65px' }
class ModalTraffic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            api_countface: '',
            api2_url: '',
            activePage: 1,
            listImageKnown: [],
            listImageUnknown: [],
            crrImages: [],
            show: this.props.show,
            isLogin: Store.getState().isLogin.isLogin,
        }

        this.crr_idCamera = props.idCamera;
        this.crrDate = props.crrDate;
        this.itemsPerPage = 8;
    }

    FilterImg(activePage) {
        const { listImage } = this.state;
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrImages = listImage.slice(offset, offset + this.itemsPerPage);
        this.setState({ crrImages })
    }
    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        })
        this.FilterImg(pageNumber);

    }

    getImg() {
        if (this.crr_idCamera === undefined || this.crrDate === undefined) {
            // alert('Please select camera first!');
        }
        else {
            // console.log('------------ show ----------------');

            if (this.state.show === true) {
                this.update(0, this.crr_idCamera, this.crrDate);
            }
        }
    }

    update = (Top, idCamera, crrDate) => {
        var time = new Date(crrDate - 25200000);
        var dd = time.getDate();
        var MM = time.getMonth() + 1;
        var yyyy = time.getFullYear();
        var hh = time.getHours();

        if (hh < 10) {
            hh = '0' + hh
        }

        if (dd < 10) {
            dd = '0' + dd
        }

        if (MM < 10) {
            MM = '0' + MM
        }
        var date = yyyy + '-' + MM + '-' + dd;
        if (this.state.api_countface === "") return;
        fetch(`${this.state.api_countface}/api/reports/image_perhour`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            dataType: 'json',
            body: JSON.stringify({
                'idCamera': idCamera,
                'date': date,
                'hour': hh
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                data.known.sort(function (a, b) {
                    return new Date(b.timeget) - new Date(a.timeget);
                });
                data.unknown.sort(function (a, b) {
                    return new Date(b.timeget) - new Date(a.timeget);
                });
                this.setState({
                    listImageKnown: data.known,
                    listImageUnknown: data.unknown,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                })
            }
        });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            this.crr_idCamera = nextProps.idcompany;
            this.crrDate = nextProps.dataop;
            this.setState({
                activePage: 1,
                api_countface: Store.getState().boxai.box_engine_cf[0].api,
                api2_url: Store.getState().boxai.box_engine_cf[0].api2_url,
                show: nextProps.show,
                loading: true,
                listImageKnown: [],
                listImageUnknown: [],
                crrImages: []
            },() => {
                this.getImg();
            });
        }
    }

    render() {
        console.log('xxx')
        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        List Traffic
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row" id="modal_traffic_dashboard">
                        <div className="col-xl-4">
                            <div className="m-portlet m-portlet--full-height ">
                                <div className="m-portlet__head">
                                    <div className="m-portlet__head-caption m-auto">
                                        <div className="m-portlet__head-title">
                                            <h3 className="m-portlet__head-text">
                                                List of customer daily
                                        </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-portlet__body">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="m_widget4_tab1_content" style={{ 'height': '400px' }}>
                                            <div className="m-scrollable scroll scroll_staff">
                                                <div className="m-list-timeline m-list-timeline--skin-light">
                                                    <div className="m-list-timeline__items">
                                                        {
                                                            this.state.listImageUnknown.map((value, index) => {
                                                                return (
                                                                    value.type === 4
                                                                        ?
                                                                        <div className="m-list-timeline__item" key={index}>
                                                                            <span className="m-list-timeline__badge m-list-timeline__badge--success"></span>
                                                                            <img src={
                                                                                value.img.indexOf('http') >= 0 ? 
                                                                                value.img :
                                                                                this.state.api2_url + '/view/static/' + value.img
                                                                            } title="" alt="img" style={styleImg} />

                                                                            {/* <div className="m-list-timeline__text" style={{ 'paddingLeft': '65px' }}>
                                                                                <h5 className='truncate mb-0'>{value.name}</h5>
                                                                                <span className='tag tag-success' style={{ 'fontSize': '12px' }}>{this.state.name_department}</span>
                                                                                <ul className="face_details pt-1 mb-2" style={{ 'paddingLeft': '0px' }}>
                                                                                    <li className="date tag tag-warning" style={{ 'listStyle': 'none', 'fontSize': '12px' }}>
                                                                                        {this.state.valueShift.title}
                                                                                    </li>
                                                                                </ul>
                                                                            </div> */}
                                                                            <span className="m-list-timeline__time">{value.timeget}</span>
                                                                        </div>
                                                                        :
                                                                        ""
                                                                )
                                                            })
                                                        }
                                                        <div className="text-center">
                                                            <PulseLoader
                                                                css={override}
                                                                sizeUnit={"px"}
                                                                size={12}
                                                                margin={'2px'}
                                                                color={'#36D7B7'}
                                                                loading={this.state.loading}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">

                            <div className="m-portlet m-portlet--full-height ">
                                <div className="m-portlet__head">
                                    <div className="m-portlet__head-caption m-auto">
                                        <div className="m-portlet__head-title">
                                            <h3 className="m-portlet__head-text">
                                                List of customer VIP
                                        </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-portlet__body">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="m_widget4_tab1_content" style={{ 'height': '400px' }}>
                                            <div className="m-scrollable scroll scroll_staff">
                                                <div className="m-list-timeline m-list-timeline--skin-light">
                                                    <div className="m-list-timeline__items">
                                                        {
                                                            this.state.listImageKnown.map((value, index) => {
                                                                return (
                                                                    value.type === 2
                                                                        ?
                                                                        <div className="m-list-timeline__item" key={index}>
                                                                            <span className="m-list-timeline__badge m-list-timeline__badge--warning"></span>
                                                                            <img src={
                                                                                value.img.indexOf('http') >= 0 ?
                                                                                value.img : 
                                                                                this.state.api_countface + '/view/static/' + value.img
                                                                            } title="" alt="img" style={styleImg} />

                                                                            {/* <div className="m-list-timeline__text" style={{ 'paddingLeft': '65px' }}>
                                                                                <h5 className='truncate mb-0'>{value.name}</h5>
                                                                                <span className='tag tag-success' style={{ 'fontSize': '12px' }}>{this.state.name_department}</span>
                                                                                <ul className="face_details pt-1 mb-2" style={{ 'paddingLeft': '0px' }}>
                                                                                    <li className="date tag tag-warning" style={{ 'listStyle': 'none', 'fontSize': '12px' }}>
                                                                                        {this.state.valueShift.title}
                                                                                    </li>
                                                                                </ul>
                                                                            </div> */}
                                                                            <span className="m-list-timeline__time">{value.timeget}</span>
                                                                        </div>
                                                                        :
                                                                        ""
                                                                )
                                                            })
                                                        }
                                                        <div className="text-center">
                                                            <PulseLoader
                                                                css={override}
                                                                sizeUnit={"px"}
                                                                size={12}
                                                                margin={'2px'}
                                                                color={'#36D7B7'}
                                                                loading={this.state.loading}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">

                            <div className="m-portlet m-portlet--full-height ">
                                <div className="m-portlet__head">
                                    <div className="m-portlet__head-caption m-auto">
                                        <div className="m-portlet__head-title">
                                            <h3 className="m-portlet__head-text">
                                                Black List
                                        </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-portlet__body">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="m_widget4_tab1_content" style={{ 'height': '400px' }}>
                                            <div className="m-scrollable scroll scroll_staff">
                                                <div className="m-list-timeline m-list-timeline--skin-light">
                                                    <div className="m-list-timeline__items">
                                                        {
                                                            this.state.listImageKnown.map((value, index) => {
                                                                return (
                                                                    value.type === 3
                                                                        ?
                                                                        <div className="m-list-timeline__item" key={index}>
                                                                            <span className="m-list-timeline__badge m-list-timeline__badge--danger"></span>
                                                                            <img src={
                                                                                value.img.indexOf('http') >= 0 ? 
                                                                                value.img :
                                                                                this.state.api_countface + '/view/static/' + value.img
                                                                            } title="" alt="img" style={styleImg} />

                                                                            {/* <div className="m-list-timeline__text" style={{ 'paddingLeft': '65px' }}>
                                                                                <h5 className='truncate mb-0'>{value.name}</h5>
                                                                                <span className='tag tag-success' style={{ 'fontSize': '12px' }}>{this.state.name_department}</span>
                                                                                <ul className="face_details pt-1 mb-2" style={{ 'paddingLeft': '0px' }}>
                                                                                    <li className="date tag tag-warning" style={{ 'listStyle': 'none', 'fontSize': '12px' }}>
                                                                                        {this.state.valueShift.title}
                                                                                    </li>
                                                                                </ul>
                                                                            </div> */}
                                                                            <span className="m-list-timeline__time">{value.timeget}</span>
                                                                        </div>
                                                                        :
                                                                        ""
                                                                )
                                                            })
                                                        }
                                                        <div className="text-center">
                                                            <PulseLoader
                                                                css={override}
                                                                sizeUnit={"px"}
                                                                size={12}
                                                                margin={'2px'}
                                                                color={'#36D7B7'}
                                                                loading={this.state.loading}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}


ModalTraffic.propTypes = {
    idcompany: PropTypes.string,
    dataop: PropTypes.number,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default React.memo(ModalTraffic);