import React, { Component } from 'react';
// First way to import
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import { Redirect } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import Store from '../../../store';
import $ from 'jquery';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/core';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Statistical extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            accessToken: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            startDate: new Date(),
            endDate: new Date(),
            loading: true,
            topPerson: [],
            series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            options: {
                chart: {
                    width: 300,
                    type: 'pie',
                },
                colors: ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#00c5dc', '#9a9caf', '#3e70c9', '#34bfa3', '#5867dd'],
                labels: ['Lần đâu tiên đến', 'Đến 2 lần', 'Đến 3 lần', 'Đến 4 lần', 'Đến 5 lần', 'Đến 6 lần', 'Đến 7 lần', 'Đến 8 lần', 'Đến 9 lần', 'Đến 10 lần hoặc nhiều hơn'],
                responsive: [{
                    breakpoint: 1024,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            dataStatis: [
                {
                    'name': 'Lần đâu tiên đến',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 2',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 3',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 4',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 5',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 6',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 7',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 8',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 9',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 10 hoặc nhiều hơn',
                    'total': 0,
                }
            ],
            totalPerson: 0,
            numberPerson: 10,
        };

        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    loading: true,
                    isLogin: Store.getState().isLogin.isLogin,
                    accessToken: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    dataStatis: [
                        {
                            'name': 'Lần đâu tiên đến',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 2',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 3',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 4',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 5',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 6',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 7',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 8',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 9',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 10 hoặc nhiều hơn',
                            'total': 0,
                        }
                    ],
                    totalPerson: 0
                }, () => {
                    this.getStatis();
                });
            } else {
                this.setState({
                    loading: false,
                    isLogin: Store.getState().isLogin.isLogin,
                    accessToken: Store.getState().isLogin.access_token,
                    box_engine_cf: undefined,
                    series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    dataStatis: [
                        {
                            'name': 'Lần đâu tiên đến',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 2',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 3',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 4',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 5',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 6',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 7',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 8',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 9',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 10 hoặc nhiều hơn',
                            'total': 0,
                        }
                    ],
                    totalPerson: 0
                });
            }
        });
    }

    componentDidMount() {
        this.getStatis();
        $('text.highcharts-credits').html('');
    }

    getStatis = async () => {
        if (this.state.box_engine_cf === undefined) return;
        await fetch(`${this.state.box_engine_cf.api2_url}/api/reports/person_comeback`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            dataType: 'json',
            body: JSON.stringify({
                'idCamera': this.state.box_engine_cf.ids_Cam.join(','),
                'start_date': moment(this.state.startDate).format('YYYY-MM-DD'),
                'end_date': moment(this.state.endDate).format('YYYY-MM-DD')

            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                if (data.status === 10000) {
                    let response = [...data.data];

                    response.forEach((value, i) => {
                        let count = 0;
                        if(value.most.length > 0){
                            value.most.forEach(value_1 => {
                                count += value_1.count;
                            });
                        }
                        response[i].count_most = count;
                    })
                    let sort_count = response.sort((a, b) => b.count_most - a.count_most).filter(filter => filter.name );

                    var dataStatis = this.state.dataStatis
                    for (let i = 0; i < data.data.length; i++) {
                        if (data.data[i].times.length === 1) {
                            dataStatis[0]['total'] += 1;
                        } else if (data.data[i].times.length === 2) {
                            dataStatis[1]['total'] += 1;
                        } else if (data.data[i].times.length === 3) {
                            dataStatis[2]['total'] += 1;
                        } else if (data.data[i].times.length === 4) {
                            dataStatis[3]['total'] += 1;
                        } else if (data.data[i].times.length === 5) {
                            dataStatis[4]['total'] += 1;
                        } else if (data.data[i].times.length === 6) {
                            dataStatis[5]['total'] += 1;
                        } else if (data.data[i].times.length === 7) {
                            dataStatis[6]['total'] += 1;
                        } else if (data.data[i].times.length === 8) {
                            dataStatis[7]['total'] += 1;
                        } else if (data.data[i].times.length === 9) {
                            dataStatis[8]['total'] += 1;
                        } else if (data.data[i].times.length >= 10) {
                            dataStatis[9]['total'] += 1;
                        }
                    }
                    
                    this.setState({
                        loading: false,
                        topPerson: sort_count,
                        dataStatis: dataStatis,
                        totalPerson: data.data.length,
                        series: [dataStatis[0]['total'], dataStatis[1]['total'], dataStatis[2]['total'], dataStatis[3]['total'], dataStatis[4]['total'], dataStatis[5]['total'], dataStatis[6]['total'], dataStatis[7]['total'], dataStatis[8]['total'], dataStatis[9]['total']],
                    });
                } else {
                    this.setState({
                        loading: false,
                        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        dataStatis: [
                            {
                                'name': 'Lần đâu tiên đến',
                                'total': 0,
                            }, {
                                'name': 'Lần thứ 2',
                                'total': 0,
                            }, {
                                'name': 'Lần thứ 3',
                                'total': 0,
                            }, {
                                'name': 'Lần thứ 4',
                                'total': 0,
                            }, {
                                'name': 'Lần thứ 5',
                                'total': 0,
                            }, {
                                'name': 'Lần thứ 6',
                                'total': 0,
                            }, {
                                'name': 'Lần thứ 7',
                                'total': 0,
                            }, {
                                'name': 'Lần thứ 8',
                                'total': 0,
                            }, {
                                'name': 'Lần thứ 9',
                                'total': 0,
                            }, {
                                'name': 'Lần thứ 10 hoặc nhiều hơn',
                                'total': 0,
                            }
                        ],
                    });
                }
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    loading: false,
                    series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    dataStatis: [
                        {
                            'name': 'Lần đâu tiên đến',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 2',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 3',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 4',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 5',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 6',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 7',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 8',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 9',
                            'total': 0,
                        }, {
                            'name': 'Lần thứ 10 hoặc nhiều hơn',
                            'total': 0,
                        }
                    ],
                });
            }
        });
    }

    handleChangeStartDate = startDate => {
        this.setState({
            startDate: startDate
        });
    }

    handleChangeEndDate = endDate => {
        this.setState({
            endDate: endDate
        })
    }

    handleClickSearch = () => {
        this.setState({
            loading: true,
            totalPerson: 0,
            series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            dataStatis: [
                {
                    'name': 'Lần đâu tiên đến',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 2',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 3',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 4',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 5',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 6',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 7',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 8',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 9',
                    'total': 0,
                }, {
                    'name': 'Lần thứ 10 hoặc nhiều hơn',
                    'total': 0,
                }
            ],
        }, () => {
            this.getStatis()
        });
    }


    render() {

        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedStatis = this.state.dataStatis.map((e, i) => {
            return (
                <tr key={i}>
                    <td>{e.name}</td>
                    <td>{e.total}</td>
                    <td>{parseFloat((e.total / this.state.totalPerson) * 100).toFixed(1) === "NaN" ? 0 : parseFloat((e.total / this.state.totalPerson) * 100).toFixed(1)}%</td>
                </tr>
            );
        });
        let bulletedStatisTop = this.state.topPerson.filter((e, i) => i < this.state.numberPerson).map((e, i) => {
            return (
                <tr key={i}>
                    <td>{e.name}</td>
                    <td>{e.type === '1' ? 'Nhân viên' : e.type === '2' ? 'VIP' : e.type === '4' ? 'Người lạ' : ''}</td>
                    <td>{e.department}</td>
                    <td>{e.count_most}</td>
                </tr>
            );
        });

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content mt-3-phone pd_phone_0 pt-3 pl-0 pr-0">
                    <div className="row m-0 m_phone_0">
                        <div className="col-xl-12 pd_phone_0">
                            <LoadingOverlay
                                active={this.state.loading}
                                spinner={<FadeLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={150}
                                    color={'rgb(54, 215, 183)'}
                                    loading={true}
                                />}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(17, 17, 17, 0.24)'
                                    })
                                }}
                                className="col-xl-12 p-0"
                            >
                                <div className="m-portlet m-portlet--full-height ">
                                    <div className="m-portlet__head">
                                        <div className="m-portlet__head-caption">
                                            <div className="m-portlet__head-title">
                                                <h3 className="m-portlet__head-text">
                                                    Thống kê
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="m-portlet__head-tools">
                                            <div className="m-form__group row">
                                                <div className="mt-1 pr-0 d-none d-md-block">
                                                    <div className="input-daterange input-group dateRanger">
                                                        <DatePicker
                                                            className="form-control m-input m_datetimepicker_6"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChangeStartDate}
                                                            maxDate={this.state.endDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            withPortal
                                                            placeholderText="Start Date" />
                                                        <div className="input-group-append" id="m_daterangepicker_6">
                                                            <span className="input-group-text">
                                                                <i className="la la-calendar glyphicon-th" />
                                                            </span>
                                                        </div>
                                                        <DatePicker
                                                            className="form-control m-input m_datetimepicker_6"
                                                            selected={this.state.endDate}
                                                            onChange={this.handleChangeEndDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            withPortal
                                                            minDate={this.state.startDate}
                                                            maxDate={addDays(new Date(), 0)}
                                                            placeholderText="End Date" />
                                                    </div>
                                                </div>
                                                <div className="pl-0 d-none d-md-block">
                                                    <button onClick={this.handleClickSearch} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air" id="searchAge">Lọc</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-portlet__body pl_phone_0 pr_phone_0">
                                        <div className="text-center">
                                            <div className="col-md-10 mt-1 pr-0 d-block d-md-none">
                                                <div className="input-daterange input-group dateRanger">
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.startDate}
                                                        onChange={this.handleChangeStartDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        disabledKeyboardNavigation
                                                        placeholderText="Start Date" />
                                                    <div className="input-group-append" id="m_daterangepicker_6">
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar glyphicon-th" />
                                                        </span>
                                                    </div>
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.endDate}
                                                        onChange={this.handleChangeEndDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        disabledKeyboardNavigation
                                                        minDate={this.state.startDate}
                                                        maxDate={this.state.startDate}
                                                        placeholderText="End Date" />
                                                </div>
                                            </div>
                                            <div className="col-md-2 pl-0 d-block btn_pd_mobile d-md-none">
                                                <button onClick={this.handleClickSearch} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air" id="searchAge">Search</button>
                                            </div>
                                        </div>
                                        <div className="row m_phone_0" id="contai_showAge">
                                            <div className="col-md-6 pl_phone_0 pr_phone_0">
                                                <div id="chart" className='mb-5'>
                                                    <ReactApexChart options={this.state.options} series={this.state.series} type="pie" height={350} />
                                                </div>
                                                <table className="table table-bordered">
                                                    <thead className="thead-light">
                                                        <tr className="text-center">
                                                            <th scope="col" colSpan='3'>Tổng số người: {this.state.totalPerson}</th>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col" colSpan='1'>Số lượng người đến</th>
                                                            <th scope="col" colSpan='1'>Số người</th>
                                                            <th scope="col" colSpan='1'>Phần trăm</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="show_data_age">
                                                        {bulletedStatis}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-6 pt-3">
                                                <div className='d-flex justify-content-end align-items-center mb-2'>
                                                    <label style={{color: 'white', marginRight: 10}}>
                                                        Số người
                                                    </label>
                                                    <select value={this.state.numberPerson} onChange={(e) => this.setState({ numberPerson: e.target.value })}>
                                                        <option value={10}>10</option>
                                                        <option value={15}>15</option>
                                                        <option value={20}>20</option>
                                                    </select>
                                                </div>
                                                <table className="table table-bordered">
                                                    <thead className="thead-light">
                                                        <tr className="text-center">
                                                            <th scope="col" colSpan='4'>Thống kê TOP người vào ra nhiều nhất</th>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col" colSpan='1'>Họ và Tên</th>
                                                            <th scope="col" colSpan='1'>Nhóm</th>
                                                            <th scope="col" colSpan='1'>Phòng ban</th>
                                                            <th scope="col" colSpan='1'>Số lần</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="show_data_age">
                                                        {bulletedStatisTop}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </LoadingOverlay>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Statistical;