import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Chart from 'react-apexcharts';

class ViewEmotion extends Component {
    render() {
        return (
            <Tabs defaultActiveKey="chart" transition={false} id="uncontrolled-tab-example">
                <Tab eventKey="chart" title="Biểu đồ">
                    <div className="col-md-12">
                        <div className="donut mb-4" id="chart_emotion">
                            <Chart options={this.props.optionsEmotion} series={this.props.seriesEmotion} type="donut" width="380" />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="table" title="Bảng">
                    <div className="col-md-12">
                        <table className="table table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Biểu cảm</th>
                                    <th scope="col">Lưu lượng qua lại</th>
                                </tr>
                            </thead>
                            {
                                this.props.dataEmotion.map((e, i) => {
                                    var happy = 0;
                                    var neutral = 0;
                                    var sad = 0;
                                    var unknown = 0;
                                    for (let i = 0; i < e.data.length; i++) {
                                        happy += e.data[i].emotion.happy;
                                        neutral += e.data[i].emotion.neutral;
                                        sad += e.data[i].emotion.sad;
                                        unknown += e.data[i].emotion.unknown;
                                    }
                                    return (
                                        <tbody id="show_data_emotion" key={i}>
                                            <tr>
                                                <td>Bình thường</td>
                                                <td id="neutral_data">{neutral}</td>
                                            </tr>
                                            <tr>
                                                <td>Vui vẻ</td>
                                                <td id="happy_data">{happy}</td>
                                            </tr>
                                            <tr>
                                                <td>Buồn</td>
                                                <td id="sad_data">{sad}</td>
                                            </tr>
                                            <tr>
                                                <td>Không rõ</td>
                                                <td id="unknown_data_emotion">{unknown}</td>
                                            </tr>
                                        </tbody>
                                    );
                                })
                            }
                        </table>
                    </div>
                </Tab>
            </Tabs>
        );
    }
}

export default ViewEmotion;