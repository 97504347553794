import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Chart from 'react-apexcharts';
import { convertDate } from '../utils/Date'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment'
import { Redirect } from 'react-router-dom';
import Store from '../../store';
import $ from 'jquery';
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/core';
import { chartGender, chartGenderLight, chartGenderDark } from './Dashboard/ConfigChart/chartGender';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Gender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            accessToken: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            dataGender: [],
            optionsTrafic: null,
            //chartdonut
            startDate: new Date(),
            endDate: new Date(),
            //chartColumn
            dataEmotionDays: [],
            startDateDays: new Date(moment().subtract(6, "days").format("YYYY-MM-DD")),
            endDateDays: new Date(),
            loading: true,
            loadingDay: true,
            options: chartGender,
            series: [0],
            labels: ['0'],
            optionsDays: chartGenderDark,
        }

        Store.subscribe(() => {
            //CharGender
            if (Store.getState().theme.setTheme === 'Dark') {
                this.setState({
                    optionsDays: chartGenderDark
                })
            } else {
                this.setState({
                    optionsDays: chartGenderLight
                })
            }
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    loading: true,
                    loadingDay: true,
                    isLogin: Store.getState().isLogin.isLogin,
                    accessToken: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                }, () => {
                    this.getData();
                });
            } else {
                this.setState({
                    loading: false,
                    loadingDay: false,
                    isLogin: Store.getState().isLogin.isLogin,
                    accessToken: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                });
            }

        });
    }

    componentDidMount() {
        this.getData()
        $('text.highcharts-credits').html('');
    }

    getData = () => {
        this.getGender();
        this.getGenderbyDays();
    }

    getGender = async () => {
        if (this.state.box_engine_cf === undefined) return;
        await fetch(`${this.state.box_engine_cf.api2_url}/api/reports/gender_perday`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            dataType: 'json',
            body: JSON.stringify({
                'idCamera': this.state.box_engine_cf.ids_Cam.join(','),
                'start_date': convertDate(this.state.startDate),
                'end_date': convertDate(this.state.endDate)
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var numMale = 0
                var numFemale = 0
                var numUnknown = 0
                for (let i = 0; i < data.data.length; i++) {
                    numMale += data.data[i].male;
                    numFemale += data.data[i].female;
                    numUnknown += data.data[i].unknown;
                }
                this.setState({
                    dataGender: [data],
                    loading: false,
                    series: [numMale, numFemale, numUnknown],
                    labels: ['Male', 'Female', 'Unknown'],
                })
            } else {
                this.setState({
                    dataGender: [],
                    loading: false,
                    series: [0, 0, 0],
                    labels: ['Male', 'Female', 'Unknown'],
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    dataGender: [],
                    loading: false,
                    series: [0, 0, 0],
                    labels: ['Male', 'Female', 'Unknown'],
                })
            }
        });

    }

    getGenderbyDays = async () => {
        if (this.state.box_engine_cf === undefined) return;
        await fetch(`${this.state.box_engine_cf.api2_url}/api/reports/gender_perday`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            dataType: 'json',
            body: JSON.stringify({
                'idCamera': this.state.box_engine_cf.ids_Cam.join(','),
                'start_date': convertDate(this.state.startDateDays),
                'end_date': convertDate(this.state.endDateDays)
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {

            if (data.status === 10000) {
                var categories;
                var male;
                var female;
                var TrafficGenderbyDay = [];
                var dataMale = [];
                var dataFemale = [];
                var dataUK = [];
                var totalMale = 0;
                var totalFemale = 0;
                var totalUk = 0;
                for (let i = 0; i < data.data.length; i++) {
                    totalMale += data.data[i].male;
                    totalFemale += data.data[i].female;
                    totalUk += data.data[i].unknown;
                }
                var totalAll = totalMale + totalFemale + totalUk;
                for (let index = 0; index < data.data.length; index++) {
                    var dataday = data.data[index].day;
                    var today = new Date(dataday);
                    var dd = today.getDate();
                    var MM = today.getMonth() + 1;
                    male = data.data[index].male;
                    female = data.data[index].female;
                    var uk = data.data[index].unknown;
                    if (dd < 10) {
                        dd = '0' + dd
                    }
                    if (MM < 10) {
                        MM = '0' + MM
                    }

                    categories = (dd + '/' + MM);

                    TrafficGenderbyDay.push(categories);
                    dataMale.push(male);
                    dataFemale.push(female);
                    dataUK.push(uk);
                }
                this.setState({
                    loadingDay: false,
                    optionsDays: {
                        title: {
                            text: 'Total: ' + totalAll,
                            style: {
                                fontSize: '15px'
                            }
                        },

                        xAxis: {
                            categories: TrafficGenderbyDay,
                            labels: {
                                skew3d: true,
                            }
                        },
                        series: [{
                            name: 'Male',
                            data: dataMale,
                        }, {
                            name: 'Femlae',
                            data: dataFemale,
                        }, {
                            name: 'Unknown',
                            data: dataUK,
                        }]
                    }
                });
            } else {
                this.setState({
                    loadingDay: false,
                    optionsDays: {
                        title: {
                            text: 'Total: 0',
                            style: {
                                fontSize: '15px'
                            }
                        },

                        xAxis: {
                            categories: [],
                            labels: {
                                skew3d: true,
                            }
                        },
                        series: [{
                            name: 'Male',
                            data: [],
                        }, {
                            name: 'Femlae',
                            data: [],
                        }, {
                            name: 'Unknown',
                            data: [],
                        }]
                    }
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    loadingDay: false,
                    optionsDays: {
                        title: {
                            text: 'Total: 0',
                            style: {
                                fontSize: '15px'
                            }
                        },

                        xAxis: {
                            categories: [],
                            labels: {
                                skew3d: true,
                            }
                        },
                        series: [{
                            name: 'Male',
                            data: [],
                        }, {
                            name: 'Femlae',
                            data: [],
                        }, {
                            name: 'Unknown',
                            data: [],
                        }]
                    }
                });
            }
        });
    }

    handleChangeStartDate = startDate => {
        this.setState({
            startDate: startDate
        })
    }

    handleChangeEndDate = endDate => {
        this.setState({
            endDate: endDate
        })
    }

    handleClickSearch = () => {
        this.setState({
            loading: true
        }, () => {
            this.getGender()
        });
    }

    //chartColumn
    handleChangeStartDateDays = startDateDays => {
        this.setState({
            startDateDays: startDateDays
        })
    }
    handleChangeEndDateDays = endDateDays => {
        this.setState({
            endDateDays: endDateDays
        })
    }

    handleClickSearchDays = () => {
        this.setState({
            loadingDay: true
        }, () => {
            this.getGenderbyDays()
        });
    }

    render() {

        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        const { optionsDays } = this.state;
        let bulletedGender = this.state.dataGender.map((e, i) => {
            var numMale = 0
            var numFemale = 0
            var numUnknow = 0
            for (let i = 0; i < e.data.length; i++) {
                numMale += e.data[i].male;
                numFemale += e.data[i].female;
                numUnknow += e.data[i].unknown
            }
            return (
                <tbody id="show_data_gender" key={i}>
                    <tr>
                        <td>Male</td>
                        <td>{numMale}</td>
                    </tr>
                    <tr>
                        <td>Female</td>
                        <td>{numFemale}</td>
                    </tr>
                    <tr>
                        <td>Unknown</td>
                        <td>{numUnknow}</td>
                    </tr>
                </tbody>
            );
        });
        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content mt-3-phone pd_phone_0 pt-3 pl-0 pr-0">
                    <div className="row m-0 m_phone_0">
                        <div className="col-md-12 pd_phone_0">
                            <LoadingOverlay
                                active={this.state.loading}
                                spinner={<FadeLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={150}
                                    color={'rgb(54, 215, 183)'}
                                    loading={true}
                                />}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(17, 17, 17, 0.24)'
                                    })
                                }}
                            >
                                <div className="m-portlet mb-3 ">
                                    <div className="m-portlet__head">
                                        <div className="m-portlet__head-caption">
                                            <div className="m-portlet__head-title">
                                                <h3 className="m-portlet__head-text">
                                                    Gender
                                            </h3>
                                            </div>
                                        </div>
                                        <div className="m-portlet__head-tools">
                                            <div className="m-form__group row">
                                                <div className="col-md-10 pr-0 mt-1 d-none d-md-block">
                                                    <div className="input-daterange input-group dateRanger">
                                                        <DatePicker
                                                            className="form-control m-input m_datetimepicker_6"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChangeStartDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            withPortal
                                                            placeholderText="Start Date" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="la la-calendar glyphicon-th" />
                                                            </span>
                                                        </div>
                                                        <DatePicker
                                                            className="form-control m-input m_datetimepicker_6"
                                                            selected={this.state.endDate}
                                                            onChange={this.handleChangeEndDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            withPortal
                                                            minDate={this.state.startDate}
                                                            placeholderText="End Date" />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 pl-0 d-none d-md-block">
                                                    <button className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air"
                                                        id="searchGender" onClick={this.handleClickSearch}>Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-portlet__body">
                                        <div className="m-form__group text-center row">
                                            <div className="col-md-10 pr-0 mt-1 search_date_mobile d-block d-md-none">
                                                <div className="input-daterange input-group dateRanger">
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.startDate}
                                                        onChange={this.handleChangeStartDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        disabledKeyboardNavigation
                                                        placeholderText="Start Date" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar glyphicon-th" />
                                                        </span>
                                                    </div>
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.endDate}
                                                        onChange={this.handleChangeEndDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        disabledKeyboardNavigation
                                                        minDate={this.state.startDate}
                                                        placeholderText="End Date" />
                                                </div>
                                            </div>
                                            <div className="col-md-2 pl-0 d-block btn_pd_mobile d-md-none">
                                                <button className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air"
                                                    id="searchGender" onClick={this.handleClickSearch}>Search</button>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="donut" id="chartGender">
                                                        <Chart options={this.state.options} series={this.state.series} type="donut" width="380" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <table className="table table-bordered">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Gender</th>
                                                                <th scope="col">Traffic</th>
                                                            </tr>
                                                        </thead>
                                                        {bulletedGender}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </div>
                    <div className="row m-0 m_phone_0">
                        <div className="col-xl-12 pd_phone_0">
                            <div className="m-portlet  m-portlet--full-height ">
                            <LoadingOverlay
                                    active={this.state.loadingDay}
                                    spinner={<FadeLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={150}
                                        color={'rgb(54, 215, 183)'}
                                        loading={true}
                                    />}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(17, 17, 17, 0.24)'
                                        })
                                    }}
                                >
                                <div className="m-portlet__head">
                                    <div className="m-portlet__head-caption">
                                        <div className="m-portlet__head-title">
                                            <h3 className="m-portlet__head-text">
                                                Gender by days
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="m-portlet__head-tools">
                                        <div className="m-form__group row">
                                            <div className="col-md-10 mt-1 pr-0 dateRanger d-none d-md-block">
                                                <div className="input-daterange input-group">
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.startDateDays}
                                                        onChange={this.handleChangeStartDateDays}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        placeholderText="Start Date" />
                                                    <div className="input-group-append" id="m_daterangepicker_6">
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar glyphicon-th" />
                                                        </span>
                                                    </div>
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.endDateDays}
                                                        onChange={this.handleChangeEndDateDays}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        minDate={this.state.startDateDays}
                                                        placeholderText="End Date" />
                                                </div>
                                            </div>
                                            <div className="col-md-2 pl-0 d-none d-md-block">
                                                <button onClick={this.handleClickSearchDays} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air" id="searchGenderDays">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div className="m-portlet__body pl_phone_0 pr_phone_0">
                                        <div className="text-center">
                                            <div className="col-md-10 mt-1 pr-0 dateRanger d-block d-md-none">
                                                <div className="input-daterange input-group">
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.startDateDays}
                                                        onChange={this.handleChangeStartDateDays}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        disabledKeyboardNavigation
                                                        placeholderText="Start Date" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar glyphicon-th" />
                                                        </span>
                                                    </div>
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.endDateDays}
                                                        onChange={this.handleChangeEndDateDays}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        disabledKeyboardNavigation
                                                        minDate={this.state.startDateDays}
                                                        placeholderText="End Date" />
                                                </div>
                                            </div>
                                            <div className="col-md-2 pl-0 d-block btn_pd_mobile d-md-none">
                                                <button onClick={this.handleClickSearchDays} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air" id="searchGenderDays">Search</button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 pl_phone_0 pr_phone_0">
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={optionsDays}
                                            />
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gender;