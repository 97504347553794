import React, { Component } from 'react';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import Store from '../../../store';
import $ from 'jquery';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { Row, Col, Image, ProgressBar } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from 'react-tooltip'
import ReactFileReader from 'react-file-reader';
import Parser from 'html-react-parser';
import ModalViewHistory from '../../modal/ModalViewHistory';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { SEARCH_MONTHS } from '../../../config';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
var optionData = [
    { value: "1 2 3 4 5", label: "Tất cả" },
    { value: "1 2 3 5", label: "Có dữ liệu" },
    { value: "4", label: "Người lạ" },
]

class SearchImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            loading: false,
            listHistory: [],
            crrHistory: [],
            dataVerification: null,
            filesImagePerson: "",
            page_num: 1,
            page_size: 12,
            total_count: 0,
            startDate: new Date(moment().format("YYYY-MM-DD") + ' 00:00:00'),
            endDate: new Date(moment().format("YYYY-MM-DD") + ' 23:59:59'),
            valueIsDisplay: 100,
            threshold: 50,
            similarity: 70,
            value: 0,
            selectedEncoding: [],
            boxs: [],
            slides: [],
            encodings: [],
            plugins: [],
            nimages: [],
            startDate: new Date(),
            endDate: new Date(),
            showModal: false,
            crrdatainfo: null,
            selected_type: optionData[0],
            isUploaded: false,
            isSearching: false,
            maxEndDate: new Date(moment().add(SEARCH_MONTHS, 'months').format('YYYY-MM-DD')),
            fullDataSearch: []
        };
        this.onchange = this.onchange.bind(this);
        this.closeModal = this.closeModal.bind(this);

        var itemsPerPage = $(window).width() < 768 ? 5 : 12;
        this.itemsPerPage = itemsPerPage;
        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    ...this.state,
                    loading: false,
                    valueIsDisplay: 100,
                    listHistory: [],
                    crrHistory: [],
                    isLogin: Store.getState().isLogin.isLogin,
                    access_token: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    dataImageMoveChange: null,
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    valueIsDisplay: 100,
                    listHistory: [],
                    crrHistory: [],
                    isLogin: Store.getState().isLogin.isLogin,
                    access_token: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    dataImageMoveChange: null,
                });
            }
        });

    }
    componentDidMount() {
        window.$('.carousel').carousel({
            interval: false,
            wrap: false
        }).on('slid.bs.carousel', function () {
            var curSlide = window.$('.active');
            if (curSlide.is(':first-child')) {
                window.$('.carousel-control-prev').hide();
                window.$('.carousel-control-next').show();
            } else if (curSlide.is(':last-child')) {
                window.$('.carousel-control-prev').show();
                window.$('.carousel-control-next').hide();
            } else {
                window.$('.carousel-control-prev').show();
                window.$('.carousel-control-next').show();
            }
        });
    }
    onchange(value) {
        this.setState({ value });
    }
    closeModal() {
        this.setState({
            showModal: false,
        })
    }
    handlePrevChange() {
        var index = this.state.value;
        index = index - 1
        if (index > 0)
            this.setState({
                pageNumber: 1,
                value: index,
                selectedEncoding: this.state.encodings[index],

            }, () => {
                this.getPerson()
            })


    }
    handleNextChange() {
        var index = this.state.value;
        if (index < (this.state.encodings.length - 1))
            index = index + 1
        this.setState({
            pageNumber: 1,
            value: index,
            selectedEncoding: this.state.encodings[index],

        }, () => {
            this.getPerson()
        })


    }
    changeFace(index) {

        this.setState({
            pageNumber: 1,
            value: index,
            selectedEncoding: this.state.encodings[index],

        }, () => {
            this.getPerson()
        })

    }


    getPerson() {
        if (this.state.box_engine_cf === undefined || !this.state.filesImagePerson) return;
        if (!this.state.valueIsDisplay || !this.state.threshold) {
            return swal('Cảnh báo!', 'Vui lòng điền đầy đủ các trường', 'warning');
        }

        this.setState({
            loading: true,
        });
        var temp = this.state.selected_type.value.split(" ");
        var type_search = temp.length == 5 ? 1 : temp.length == 4 ? 2 : 3;
        fetch(`${this.state.box_engine_cf.api}/api/person/searchpeople`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                idCamera: this.state.box_engine_cf.ids_Cam.join(','),
                encoding: this.state.selectedEncoding,
                value_people: parseInt(this.state.valueIsDisplay),
                threshold: parseInt(this.state.threshold),
                type_search: type_search,
                page_num: this.state.page_num,
                page_size: this.state.page_size,
                start_time: moment(this.state.startDate).format('YYYY-MM-DD 00:00:00'),
                end_time: moment(this.state.endDate).format('YYYY-MM-DD 23:59:59')
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                var data = [];
                var filterData = [];
                var count = 1;
                filterData = rs.data.filter(item => {
                    return this.state.selected_type.value.indexOf(`${item.person_type}`) !== -1
                })
                data = filterData.filter(item => {
                    var index = count;
                    count++;
                    return (index >= 1 && index <= this.state.page_size) ? true : false;
                })
                this.setState({
                    listHistory: data,
                    loading: false,
                    isSearching: false,
                    total_count: filterData.length,
                    fullDataSearch: rs.data
                });
            }
            else {
                swal("Lỗi!", "Ảnh không hợp lệ!", "error");
                this.setState({
                    listHistory: [],
                    loading: false,
                    isSearching: false,
                    total_count: 0,
                });
            }
        }).catch((error) => {
            if (error) {
                console.log(error);
                swal("Lỗi!", "Ảnh không hợp lệ!", "error");
                this.setState({
                    listHistory: [],
                    loading: false,
                    isSearching: false,
                    total_count: 0,
                });
            }
        })
    }

    pageChange() {
        var data = [];
        var filterData = [];
        var count = 1;
        filterData = this.state.fullDataSearch.filter(item => {
            return this.state.selected_type.value.indexOf(`${item.person_type}`) !== -1
        })
        data = filterData.filter(item => {
            var fromItem = (this.state.page_num - 1) * this.state.page_size + 1;
            var toItem = fromItem + this.state.page_size - 1;
            toItem = toItem >= this.state.total_count ? this.state.total_count : toItem;
            var index = count;
            count++;
            return (index >= fromItem && index <= toItem) ? true : false;
        })
        this.setState({
            listHistory: data,
            loading: false
        });
    }

    getFaces() {
        if (this.state.box_engine_cf === undefined || this.state.filesImagePerson == "") return;
        var image_base64;
        image_base64 = this.state.filesImagePerson.replace('data:image/png;base64,', '');
        image_base64 = image_base64.replace('data:image/jpg;base64,', '');
        image_base64 = image_base64.replace('data:image/jpeg;base64,', '');

        fetch(`${this.state.box_engine_cf.api}/api/person/getfaces`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                image: image_base64

            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                var boxs = []
                var slides = []
                var item = ""
                var encodings = []
                rs.nimgs.map((value, index) => {
                    var item_active = "<div class='carousel-item active'>"
                        + "<img style='margin-left: 5px;width: 75px; opacity: 1;cursor: pointer;' src=" + `data:image/png;base64,` + " dangerouslySetInnerHTML={{__html:this.state.userText}} onClick={this.toggleTranspose()} />"
                        + "</div>"
                    // var item_inactive = "<div class='carousel-item'>"
                    // + "<img src="+`data:image/png;base64,${value} `+ "style={{ marginLeft: '5px', width: '75px', opacity: 1, cursor: 'pointer' }}  alt='...' />"
                    // + "</div>"
                    if (index == 0) {
                        item += "<div class='carousel-item active'>"
                        item += "<img style='margin-left: 5px;width: 75px; opacity: 1;cursor: pointer;'  alt='...' src=" + `data:image/png;base64,${value} ` + " />"

                    }
                    else if (index % 2 == 0) {
                        item += "<div class='carousel-item'>"
                    }
                    console.log("index % 2", index % 2)
                    if (index != 0 && (index % 2 == 0)) {
                        item += "<img style='margin-left: 5px;width: 75px; opacity: 1;cursor: pointer;' src=" + `data:image/png;base64,${value} ` + " />"

                    }
                    else if (index != 0) {
                        item += "<img style='margin-left: 5px;width: 75px; opacity: 1;cursor: pointer;' src=" + `data:image/png;base64,${value} ` + " />"
                        item += "</div>"
                    }

                    item = item_active
                    var id_image = "img_" + index
                    boxs.push(rs.boxs[index])
                    encodings.push(rs.encodings[index])
                });
                this.setState({
                    isUploaded: true,
                    encodings: encodings,
                    slides: Parser(item),
                    boxs: boxs,
                    value: 0,
                    selectedEncoding: encodings[0],
                    listHistory: [],
                    nimages: rs.nimgs,
                    total_count: 0,

                    numbershow: (rs.nimgs.length > 3 ? 3 : rs.nimgs.length),
                    total_count: 0,
                }, () => {
                    window.$("#search-image-filter").parent().attr("style", "padding: 0 5px");

                    if (this.state.encodings.length == 1) {
                        window.$('.carousel-control-prev').hide();
                        window.$('.carousel-control-next').hide();
                    }
                    else {
                        window.$('.carousel-control-prev').show();
                        window.$('.carousel-control-next').show();
                    }

                    if (this.state.encodings.length >= 1)
                        this.changeFace(0)
                })

                //console.log("numbershow", this.state.numbershow)
            }
            else {
                swal("Lỗi!", "Ảnh không hợp lệ!", "error");
                this.setState({
                    listHistory: [],
                    loading: false,
                    total_count: 0,
                });
            }
        }).catch((error) => {
            if (error) {
                swal("Lỗi!", "Ảnh không hợp lệ!", "error");
                this.setState({
                    listHistory: [],
                    loading: false,
                    total_count: 0,
                });
            }
        })
    }

    handleChangeOption = (index, option) => {
        this.setState({
            selected_type: option,
        });
    }

    onFilesChangeImagePerson = async (files) => {
        document.getElementById('previewImagePerson').src = files.base64;
        this.setState({
            filesImagePerson: files.base64,
            loading: false,
            plugins: []
        });
        this.getFaces();
    }

    isChangeDisplayDetail = (event) => {
        var targetValue = event.target.value;
        if (event.target.value < 0)
            targetValue = 1;
        if (event.target.value >= 1000)
            targetValue = 1000;
        $("#input-display-detail").val(targetValue);
        this.setState({
            // listHistory: [],
            // loading: true,
            pageNumber: 1,
            valueIsDisplay: targetValue,
        }, () => {
            //this.getPerson()
        })
    }

    isChangeThreshold = (event) => {
        var targetValue = event.target.value;
        if (event.target.value < 0)
            targetValue = 1;
        if (event.target.value >= 100)
            targetValue = 100;
        $("#input-threshold").val(targetValue);
        this.setState({
            // listHistory: [],
            // loading: true,
            pageNumber: 1,
            threshold: targetValue
        }, () => {
            //this.getPerson()
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            loading: true,
            page_num: pageNumber,
            listHistory: [],
        }, () => {
            this.pageChange();
        })
    }

    searchFilterForm = () => {
        return (
            <div>
                <div style={{ marginTop: '10px', textAlign: 'left' }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <span>Số kết quả:</span>
                                </td>
                                <td>
                                    <input id="input-display-detail" className="form-control form-control-sm" type="number" step="1" min="1" max="1000" name="m_table_1_length" value={this.state.valueIsDisplay} onChange={(event) => this.isChangeDisplayDetail(event)} aria-controls="m_table_1" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Tỷ lệ giống:</span>
                                </td>
                                <td>
                                    <div className="input-group">
                                        <input id="input-threshold" className="form-control form-control-sm" type="number" min="1" max="100" step="1" name="m_table_1_length" value={this.state.threshold} onChange={(event) => this.isChangeThreshold(event)} aria-controls="m_table_1" />
                                        <div className="input-group-addon" style={{ border: "1px solid #ebedf2" }}>%</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Đối tượng:</span>
                                </td>
                                <td>
                                    <Autocomplete
                                        //className="form-control form-control-sm"
                                        id="search-image-filter"
                                        options={optionData}
                                        getOptionLabel={option => option.label}
                                        value={this.state.selected_type}
                                        onChange={this.handleChangeOption}
                                        disableClearable
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Chọn nhóm"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div style={{ marginTop: '10px' }} className="ml-2 mb-mobile-10">
                    <button
                        onClick={() => {
                            this.setState({
                                page_num: 1,
                                isSearching: true,
                                listHistory: []
                            }, () => {
                                this.getPerson();
                            })
                        }}
                        className="btn btn-accent m-btn m-btn--icon">
                        <span>
                            <i className="la la-search" />
                            <span>Tìm kiếm</span>
                        </span>
                    </button>
                </div>
            </div>
        );
    };

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        let rsImages = this.state.nimages.map((value, index) => {
            var item_active = ""
            console.log("index __", index)
            if (index == 0) {

                console.log("active __", index)
                item_active =
                    (
                        <div key={value} class={"carousel-item active"}>
                            <img src={`data:image/png;base64,${value} `} style={{ marginLeft: '5px', width: '75px', opacity: 1, cursor: 'pointer' }} onClick={() => this.changeFace(index)} class={"img-responsive"} />
                        </div>
                    )

            }
            else if (index > 0) {
                console.log("inactive __", index)
                item_active =
                    (
                        <div key={value} class={"carousel-item"}>
                            <img src={`data:image/png;base64,${value} `} style={{ marginLeft: '5px', width: '75px', opacity: 1, cursor: 'pointer' }} onClick={() => this.changeFace(index)} class={"img-responsive"} />
                        </div>
                    )
            }

            return item_active

        });

        const aliasCamera = (id) => {
            if (this.state.box_engine_cf) {
                const listCamera = this.state.box_engine_cf.listCamera;

                const dataCamera = listCamera.find(e => e.id_box_engine == id);

                if (dataCamera) {
                    if (dataCamera.alias_box_engine) {
                        return dataCamera.alias_box_engine;
                    } else {
                        return id;
                    }
                } else {
                    return id;
                }
            }
        }

        const setColorType = (type) => {
            if (type === 1) {
                return "btn btn-info btn-xs" //"blue"
            } else if (type === 2) {
                return "btn btn-danger btn-xs" //"red"
            } else if (type === 3) {
                return "btn btn-warning btn-xs" //"green"
            } else if (type === 4) {
                return "btn btn-success btn-xs" //"black"
            } else {
                return "btn btn-primary btn-xs" //"#909704"
            }
        }


        let bulletedImg = this.state.listHistory.map((value, index) => {
            var gender = ""
            if (value.gender == 1) {
                gender = "Nam"
            } else if (value.gender == 2) {
                gender = "Nữ"
            }

            var glasses = ""
            if (value.glasses === 0) {
                glasses = "Không đeo kính"
            } else if (value.glasses === 1) {
                glasses = "Đeo kính"
            }
            var person_type = ""
            if (value.person_type === 1) {
                person_type = "Nhân viên"
            } else if (value.person_type === 2) {
                person_type = "VIP"
            } else if (value.person_type === 3) {
                person_type = "Danh sách đen"
            } else if (value.person_type === 4) {
                person_type = "Người lạ"
            } else {
                person_type = "Khách hàng"
            }
            return (
                <Col xs={12} md={6} lg={4} xl={3} key={index} className="p-1 col_xxl-4">
                    <div className={'box box-block form_info_history mb-0 '} style={{ 'borderRadius': '5px' }} onClick={() => { this.setState({ showModal: true, crrdatainfo: value }) }}>
                        <Row className='row-sm'>
                            <Col xs={4} md={4} style={{ 'textAlign': 'center' }} className="imgHover">
                                <Image width='100%' style={{ height: '135px' }} src={`${this.state.box_engine_cf.api2_url}/view${value.imgFace}`} className='img-fluid imgHistory border-3' />
                            </Col>
                            <Col xs={8} md={8} style={{ 'fontSize': '13px' }}>
                                <Row className="ml-3">
                                    <Col xs={12} md={12} className="pr-0 pl-0">
                                        <Row>
                                            <Col xs={8} md={8} className="pr-2 pl-1" style={{ 'display': 'inline-block' }}>
                                                <div className="col-md-12 p-0 table-responsive">
                                                    <div id="formUpdate">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        {/* <div style={{ border: `2px solid ${setColorType(value.person_type)}`, padding: 5, borderRadius: 10, marginBottom: 10 }}>
                                                                            <div className='center' style={{ fontSize: 15, fontWeight: 'bold', color: setColorType(value.person_type) }}>{person_type}</div>
                                                                        </div> */}
                                                                        <button className={setColorType(value.person_type)} style={{ width: "100px", padding: 0 }}>{person_type}</button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-credit-card'></i> </td>
                                                                    <td>
                                                                        <b>
                                                                            {
                                                                                value.code
                                                                            }
                                                                        </b>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-user'></i> </td>
                                                                    <td>
                                                                        <b>
                                                                            {
                                                                                value.name
                                                                            }
                                                                        </b>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td><i className='fa fa-birthday-cake'></i> </td>
                                                                    <td>
                                                                        {
                                                                            value.age
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-venus-mars'></i> </td>
                                                                    <td>
                                                                        {
                                                                            gender
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-camera'></i> </td>
                                                                    <td>
                                                                        {
                                                                            aliasCamera(value.camera_id)
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-eye'></i> </td>
                                                                    <td>
                                                                        {
                                                                            glasses
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-building'></i> </td>
                                                                    <td>
                                                                        {
                                                                            value.department
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <td><i className='fa fa-child'></i> </td>
                                                                    <td>
                                                                        {
                                                                            person_type
                                                                        }
                                                                    </td>
                                                                </tr> */}
                                                                {/* <tr>
                                                                    <td><i className='fa fa-clock'></i></td>
                                                                    <td>{value.created_time}</td>
                                                                </tr> */}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} className="pl-0 pr-0 d-flex flex-column justify-content-between align-items-center">
                                                {
                                                    value.idStaff == 'unknown'
                                                        ?
                                                        ''
                                                        :
                                                        <Image width='100%' src={`${this.state.box_engine_cf.api2_url}/view${value.thumbnail_sample_image}`} style={{ 'maxHeight': '100px' }} className={'img-fluid ' + (value.thumbnail_sample_image === "" ? "d-none" : "") + ' border-3'} />
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="mb-2 pt-1" >
                            <ProgressBar className="animation" style={{ height: '20px', fontSize: '11pt' }} striped variant={value.similarity <= 50 ? 'warning' : 'success'} label={value.similarity + '%'} min={0} max={100} now={value.similarity} />

                        </div>
                    </div>

                </Col>
            )
        });

        var pageRangeDisplayed = $(window).width() < 768 ? 3 : 5;
        var fromItem = (this.state.page_num - 1) * this.state.page_size + 1;
        var toItem = fromItem + this.state.page_size - 1;
        toItem = toItem >= this.state.total_count ? this.state.total_count : toItem;
        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper" style={{ backgroundColor: 'white' }}>
                <div className="m-content mt-3-phone pd_phone_0 p-3">
                    <div className="m-form m-form--fit m-form--label-align-right">
                        <div className='d-flex mb-3'>
                            <DatePicker
                                className="form-control m-input m_datetimepicker_6 w_140"
                                showTimeSelect={true}
                                selected={this.state.startDate}
                                onChange={(date) => {
                                    var maxEndDate = new Date(moment(date).add(SEARCH_MONTHS, 'months'));
                                    var endDate = this.state.endDate;
                                    if (this.state.endDate > maxEndDate)
                                        endDate = maxEndDate
                                    this.setState({
                                        startDate: date,
                                        endDate: endDate,
                                        maxEndDate: maxEndDate
                                    })
                                }}
                                dateFormat="yyyy-MM-dd"
                                withPortal
                                // minDate={new Date(moment().subtract(SEARCH_MONTHS, 'months').format('YYYY-MM-DD'))}
                                placeholderText="Start Date" />
                            <div className="input-group-append" id="m_daterangepicker_6">
                                <span className="input-group-text">
                                    <i className="la la-calendar glyphicon-th" />
                                </span>
                            </div>
                            <DatePicker
                                className="form-control m-input m_datetimepicker_6 w_140"
                                showTimeSelect={true}
                                selected={this.state.endDate}
                                onChange={date => this.setState({ endDate: date })}
                                dateFormat="yyyy-MM-dd"
                                withPortal
                                minDate={this.state.startDate}
                                maxDate={this.state.maxEndDate}
                                placeholderText="End Date" />
                        </div>
                        <div className="row m-0">
                            <div className="col-md-2 px-0">
                                <img src="photo-placeholder.png" id="previewImagePerson" className="text-center" height="350px" width='100%' alt="" />
                                {/* <div style={this.state.outline}></div> */}
                                <div className="text-center p-8">
                                    <ReactFileReader fileTypes={['image/png', 'image/jpg', 'image/jpeg']} base64={true} multipleFiles={false} handleFiles={this.onFilesChangeImagePerson}  >
                                        <button style={{ marginTop: '10px' }} className={'btn m-btn--icon m-btn btn-default m-loader--success m-loader--right'}>
                                            <span >
                                                <i className="la la-cloud-upload"></i>
                                                <span>Tải lên ảnh chân dung</span>
                                            </span>
                                        </button>
                                    </ReactFileReader>
                                    {
                                        this.state.isUploaded ?
                                            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                                <div className="carousel-inner" data-interval="false">
                                                    {rsImages}
                                                </div>
                                                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" onClick={() => this.handlePrevChange()}>
                                                    <span className="carousel-control-prev-icon fa fa-chevron-left" aria-hidden="true"></span>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" onClick={() => this.handleNextChange()}>
                                                    <span className="carousel-control-next-icon fa fa-chevron-right" aria-hidden="true"></span>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                            </div>
                                            : ""
                                    }
                                    {
                                        this.state.isUploaded ?
                                            this.searchFilterForm()
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className='row-sm row pl-3 pr-3 pb-3'>
                                    {bulletedImg}
                                    <PulseLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={12}
                                        margin={'2px'}
                                        color={'#36D7B7'}
                                        loading={this.state.loading}
                                    />
                                </div>
                                {
                                    this.state.isUploaded && !this.state.isSearching && this.state.total_count > 0 ?
                                        <div className="pl-2 pr-3 row" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="col-md-6" style={{ paddingRight: 10, fontSize: 13 }}>
                                                Hiển thị <span style={{ fontWeight: 600 }}>{fromItem}-{toItem}</span> trong tổng số <span style={{ fontWeight: 600 }}>{this.state.total_count}</span> kết quả
                                            </div>
                                            <div className="pl-2" style={{ 'paddingBottom': '50px' }}>
                                                <Pagination
                                                    prevPageText={<i className='la la-angle-left'></i>}
                                                    nextPageText={<i className='la la-angle-right'></i>}
                                                    firstPageText={<i className='la la-angle-double-left'></i>}
                                                    lastPageText={<i className='la la-angle-double-right'></i>}
                                                    activePage={this.state.page_num}
                                                    itemsCountPerPage={this.state.page_size}
                                                    totalItemsCount={this.state.total_count}
                                                    pageRangeDisplayed={pageRangeDisplayed}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                        </div>
                                        :
                                        this.state.isUploaded && !this.state.loading ?
                                            <div className="alert alert-danger">
                                                <i className="fa fa-info-circle"></i> Không tìm thấy kết quả nào
                                            </div>
                                            : ""
                                }
                                <ModalViewHistory show={this.state.showModal} onHide={this.closeModal} box_engine_cf={this.state.box_engine_cf} crrdatainfo={this.state.crrdatainfo} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchImage;