import React, { Component } from 'react';
import { css } from '@emotion/core';
import { Image, Modal } from 'react-bootstrap';
import Store from '../../store';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PulseLoader } from 'react-spinners';




const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class ModalViewHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataHistory: [],
            hasMoreHistory: true,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            loadingHistory: false,
            box_engine_cf: '',
            activePageHistory: 1,
            crrDataInfo: null,
        }
        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                }, () => {
                });
            } else {
                this.setState({
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                });
            }
        });

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            let crr_person = { ...nextProps.crrdatainfo };
            let box_engine_cf = nextProps.box_engine_cf;
            if (crr_person != null && box_engine_cf != null) {
                this.setState({
                    show: nextProps.show,
                    activePageHistory: 1,
                    box_engine_cf: box_engine_cf,
                    crrDataInfo: crr_person,
                }, () => {
                    this.getHistory(box_engine_cf, crr_person);
                })
            }
        } else {
            this.setState({
                crrDataInfo: null,
                dataHistory: [],
                hasMoreHistory: true,
                loadingHistory: false,
            })
        }
    }
    getHistory = (box_engine_cf, crr_person) => {
        if (!crr_person) return;
        this.setState({
            loadingHistory: true,
        })
        fetch(`${box_engine_cf.api2_url}/api/person/history`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                id_person: crr_person.person,
                type: crr_person.person_type,
                index: this.state.activePageHistory,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 10000) {
                    var { dataHistory } = this.state;
                    dataHistory = dataHistory.concat(data.data)
                    var totalPageHistory = parseInt(dataHistory.length / 10);
                    if (totalPageHistory % 10 != 0)
                        totalPageHistory++;
                    this.setState({
                        dataHistory: dataHistory,
                        loadingHistory: this.state.activePageHistory > totalPageHistory ? false : true,
                    });
                } else if (data.status === 10001) {
                    this.setState({
                        hasMoreHistory: false,
                        loadingHistory: false,
                    });
                } else {
                    this.setState({
                        dataHistory: [],
                        loadingHistory: false,
                    });
                }
            })
            .catch((error) => {
                if (error) {
                    this.setState({
                        dataHistory: [],
                        loadingHistory: false,
                    });
                }
            });
    };
    fetchMoreDataHistory = () => {
        this.setState(
            {
                activePageHistory: this.state.activePageHistory + 1,
                loadingHistory: true,
            },
            () => {
                if (this.state.crrDataInfo != null) {
                    setTimeout(() => {
                        this.getHistory(this.state.box_engine_cf, this.state.crrDataInfo,);
                    }, 1000);
                } else {
                    this.setState({
                        loadingHistory: false,
                    });
                }
            });
    };

    handleReloadHistory = () => {
        this.setState({
            dataHistory: [],
            activePageHistory: 1,
            loadingHistory: true
        }, () => {
            this.getHistory(this.state.box_engine_cf, this.state.crrDataInfo);
        })
    }
    
    render() {
        const aliasCamera = (id) => {
            if (this.state.box_engine_cf) {
                const listCamera = this.state.box_engine_cf.listCamera;

                const dataCamera = listCamera.find(e => e.id_box_engine == id);

                if (dataCamera) {
                    if (dataCamera.alias_box_engine) {
                        return `${dataCamera.alias_box_engine}(${id})`;
                    } else {
                        return `${dataCamera.name_engine}_${id}`;
                    }
                } else {
                    return id;
                }
            }
        }
        var bullet_data_history = this.state.dataHistory.map((e, i) => {
            var urlImg = ""
            if (e.images.length > 0) {
                const date = e.images[0].file_name.split("_");
                urlImg = e.mount_folder + "/" + date[1].slice(0, 6) + "/" + e.camera_id;
            }
            return (
                <tr key={i} style={{ fontSize: 16 }}>
                    <td style={{ width: 50 }}>{i + 1}</td>
                    <td style={{ width: 150 }}>
                        <Image
                            width='70'
                            height='70'
                            src={`${`${this.state.box_engine_cf.api2_url}/view/image/${urlImg}/${e.images[0].file_name}`}`}
                        ></Image>

                    </td>
                    <td>
                        <span style={{ display: 'inline-block', padding: '5px 10px', backgroundColor: '#f59345', color: 'white', borderRadius: 5 }}>{e.created_time}</span>
                    </td>
                    <td style={{ width: 180 }}>
                        {aliasCamera(e.camera_id)}
                    </td>
                </tr>
            );
        });

        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header style={{ padding: "15px 25px" }}>
                    <Modal.Title>
                        <i className='fa fa-list-alt'></i> Lịch sử
                        <i
                            style={{ position: " absolute", top: "17px", left: "760px", cursor: "pointer" }}
                            className='la la-close'
                            onClick={this.props.onHide}
                        ></i>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table id='tbViolation' className="table text-center table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed" style={{ "maxHeight": "800px", "overflow": "hidden scroll" }}>
                        <thead>
                            <tr>
                                <th style={{ width: 50 }}>STT</th>
                                <th style={{ width: 150 }}>Ảnh</th>
                                <th>Thời gian</th>
                                <th style={{ width: 180 }}>Camera</th>
                            </tr>
                        </thead>
                        <tbody className='scroll' id="scrollableHistory">
                            {/* {
                                                                        bullet_data_history
                                                                    } */}
                            <InfiniteScroll
                                dataLength={this.state.dataHistory.length}
                                next={this.fetchMoreDataHistory}
                                hasMore={this.state.hasMoreHistory}
                                loader={
                                    <div className="pt-3 text-center m-auto">
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loadingHistory}
                                        />
                                    </div>
                                }
                                scrollableTarget="scrollableHistory"
                            >
                                {bullet_data_history}
                            </InfiniteScroll>
                        </tbody>
                    </table>
                    <div className='pull-right'>
                        <button
                            onClick={() => {
                                this.handleReloadHistory();
                            }}
                            className="btn btn-secondary m-btn m-btn--icon">
                            <span>
                                <i className="la la-rotate-left" />
                                <span>Tải lại</span>
                            </span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

        );
    }
}

ModalViewHistory.propTypes = {

};

export default ModalViewHistory;