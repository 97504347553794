import { css } from '@emotion/core';
import $ from 'jquery';
import moment from 'moment';
import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { Redirect } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { Dropdown } from 'semantic-ui-react';
import { LIST_PERSON } from '../../../config'
import swal from 'sweetalert';
import swals from '@sweetalert/with-react';
import Store from '../../../store';
import ModalViewPersonNoImage from '../../modal/ModalViewPersonNoImage';
import {
    ExcelExport,
    ExcelExportColumn,
    ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import ReactFileReader from 'react-file-reader';
import { onFilesChange } from '../UploadFaceImport/UploadFaceImport';
import Files from "react-files";
import ReactTooltip from 'react-tooltip';
import Moment from "moment";
import ModalUpload from '../UploadFaceImport/ModalUpload';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

let select_option = [];

if (LIST_PERSON.indexOf('1') !== -1) {
    select_option.push({ title: 'Nhân viên', value: 1 })
}
if (LIST_PERSON.indexOf('2') !== -1) {
    select_option.push({ title: 'Vip', value: 2 })
}
if (LIST_PERSON.indexOf('3') !== -1) {
    select_option.push({ title: 'Danh sách đen', value: 3 })
}
// if(LIST_PERSON.indexOf('4') !== -1){
//     select_option.push({ title: 'Người lạ', value: 4, key: 4 })
// }
if (LIST_PERSON.indexOf('5') !== -1) {
    select_option.push({ title: 'Khách hàng', value: 5 })
}
console.log(select_option)
class Trainning extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            toStaff: null,
            crrIdx: 0,
            crr_comp: select_option[0],
            optionPerson: [],
            listImage: [],
            crrImages: [],
            listChecked: {
                valueImg: [],
                _id: [],
                dataPost: [],
            },
            modalUploadShow: false,
            ExportContent: '',
            crrDataInfo: {
                '_id': "",
                'id': '',
                'code': '',
                'name': '',
                'phone': '',
                'age': '',
                'gender': '',
                'department': '',
                'type': '',
                'last_type': '',
                'email': '',
                'notify': '',
            },
            typePerson: {
                key: 4,
                text: 'Người lạ',
                value: 4,
            },
            valuePerson: null,
            checkDeleteMulti: false,
            loading: false,
            checkedMale: false,
            checkedFemale: false,
            checkedSend: false,
            checkedNoSend: false,
            number_person_not_image: 0,
            total_person: 0,
            modalViewExcel: false,
            listPersonNotTrain: null,
            loading_api_excel: false,
            activeCapture: false,
            UploadFaces: false,
            max_upload_images: 0,
            files_length: 0,
            imgError: [],
            number_of_for: 0,
            images: []
        };

        var itemsPerPage = 1;
        if ($(window).width() < 768) {
            itemsPerPage = 8
        } else {
            itemsPerPage = 18
        }
        this.itemsPerPage = itemsPerPage;

        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                $('#count_img').html(0)
                this.setState({
                    ...this.state,
                    isActive: true,
                    isLogin: Store.getState().isLogin.isLogin,
                    access_token: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    toStaff: null,
                    valuePerson: null,
                    crr_comp: select_option[0],
                    optionPerson: [],
                    crrImages: [],
                    listImage: [],
                    listChecked: {
                        valueImg: [],
                        _id: [],
                        dataPost: [],
                    },
                    crrDataInfo: {
                        '_id': "",
                        'id': '',
                        'code': '',
                        'name': '',
                        'phone': '',
                        'age': '',
                        'gender': '',
                        'department': '',
                        'type': '',
                        'last_type': '',
                        'email': '',
                        'notify': ''
                    },
                    crrIdx: 0,
                }, () => {
                    this.getPerson(this.state.crr_comp.value);
                    this.getListPersonNotTrain();
                });
            } else {
                this.setState({
                    ...this.state,
                    isActive: false,
                    isLogin: Store.getState().isLogin.isLogin,
                    access_token: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    toStaff: null,
                    valuePerson: null,
                    crr_comp: select_option[0],
                    optionPerson: [],
                    crrImages: [],
                    listImage: [],
                    listChecked: {
                        valueImg: [],
                        _id: [],
                        dataPost: [],
                    },
                    crrIdx: 0,
                });
            }

        });
    }
    _exporter;
    export = () => {
        this._exporter.save();
    }

    componentDidMount() {
        this.getPerson(this.state.crr_comp.value);
        this.getListPersonNotTrain();
    }

    getListPersonNotTrain = () => {
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        this.setState({
            loading_api_excel: true,
        })
        console.log("idcompany", this.state.box_engine_cf.idCompany);
        fetch(`${this.state.box_engine_cf.api}/api/person/export/${this.state.box_engine_cf.idCompany}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            const sortBy = [
                {
                    prop: 'type',
                    direction: -1
                },
                {
                    prop: 'name',
                    direction: 2
                }
            ];
            const processSort = (a, b) => {
                let i = 0, result = 0;
                while (i < sortBy.length && result === 0) {
                    result = sortBy[i].direction * (a[sortBy[i].prop] < b[sortBy[i].prop] ? -1 : (a[sortBy[i].prop] > b[sortBy[i].prop] ? 1 : 0));
                    i++;
                }
                return result;
            }
            const data_type_1_0 = LIST_PERSON.indexOf('1') !== -1 ? rs.data.filter(filter => (filter.type === 1 && filter.trainning_set === 0)).sort((a, b) => processSort(a, b)) : [];
            const data_type_1_1 = LIST_PERSON.indexOf('1') !== -1 ? rs.data.filter(filter => (filter.type === 1 && filter.trainning_set !== 0)).sort((a, b) => processSort(a, b)) : [];
            const data_type_2_0 = LIST_PERSON.indexOf('2') !== -1 ? rs.data.filter(filter => (filter.type === 2 && filter.trainning_set === 0)).sort((a, b) => processSort(a, b)) : [];
            const data_type_2_1 = LIST_PERSON.indexOf('2') !== -1 ? rs.data.filter(filter => (filter.type === 2 && filter.trainning_set !== 0)).sort((a, b) => processSort(a, b)) : [];
            const data_type_3_0 = LIST_PERSON.indexOf('3') !== -1 ? rs.data.filter(filter => (filter.type === 3 && filter.trainning_set === 0)).sort((a, b) => processSort(a, b)) : [];
            const data_type_3_1 = LIST_PERSON.indexOf('3') !== -1 ? rs.data.filter(filter => (filter.type === 3 && filter.trainning_set !== 0)).sort((a, b) => processSort(a, b)) : [];
            const data_type_5_0 = LIST_PERSON.indexOf('5') !== -1 ? rs.data.filter(filter => (filter.type === 5 && filter.trainning_set === 0)).sort((a, b) => processSort(a, b)) : [];
            const data_type_5_1 = LIST_PERSON.indexOf('5') !== -1 ? rs.data.filter(filter => (filter.type === 5 && filter.trainning_set !== 0)).sort((a, b) => processSort(a, b)) : [];
            const data_person_not_training = data_type_1_0.concat(data_type_2_0).concat(data_type_3_0).concat(data_type_5_0).map((map, i) => ({
                ...map,
                index: (i + 1).toString(),
                gender: map.gender === '1' ? 'Nam' : 'Nữ',
                type: map.type === 1 ? 'Nhân viên' : map.type === 2 ? 'VIP' : map.type === 3 ? 'Danh sách đen' : map.type === 5 ? 'Khách hàng' : '',
                trainning_set: map.trainning_set.toString(),
            }));
            const data_person = data_type_1_0.concat(data_type_1_1, data_type_2_0, data_type_2_1, data_type_3_0, data_type_3_1, data_type_5_0, data_type_5_1).map((map, i) => ({
                ...map,
                index: (i + 1).toString(),
                gender: map.gender === '1' ? 'Nam' : 'Nữ',
                type: map.type === 1 ? 'Nhân viên' : map.type === 2 ? 'VIP' : map.type === 3 ? 'Danh sách đen' : map.type === 5 ? 'Khách hàng' : '',
                trainning_set: map.trainning_set.toString(),
            }))
            this.setState({
                listPersonNotTrain: data_person_not_training,
                number_person_not_image: rs.person_not_train,
                total_person: rs.total_person,
                loading_api_excel: false,
                listPerson: data_person,
            })
        }).catch(error => {
            console.log(error)
        })
    }

    getPerson = (value) => {
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        fetch(`${this.state.box_engine_cf.api}/api/person/getByType`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: value,
                idCamera: this.state.box_engine_cf.ids_Cam.join(','),
                day: moment(new Date()).format('YYYY-MM-DD'),
                id_company: `${this.state.box_engine_cf.idCompany}`,
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                var optionPerson = [];
                for (let index = 0; index < rs.data.length; index++) {
                    var name = "No Name"
                    const id = rs.data[index].code ? ` - ${rs.data[index].code}` : '';
                    if (rs.data[index].name !== "") {
                        name = rs.data[index].name
                    }
                    optionPerson.push(
                        {
                            ...rs.data[index],
                            title: `${name}${id} (${rs.data[index].trainning_set})`,
                        }
                    )
                }
                var valuePerson = null;
                if (rs.data.length > 0) {
                    valuePerson = {
                        ...rs.data[0],
                        title: `${rs.data[0].name ? rs.data[0].name : 'No Name'}${rs.data[0].code ? ` - ${rs.data[0].code}` : ''} (${rs.data[0].trainning_set})`
                    }
                }
                this.setState({
                    valuePerson: valuePerson,
                    optionPerson: optionPerson
                }, () => {
                    console.log(valuePerson)
                    if (this.state.valuePerson !== null) {
                        this.getImageTrainning(this.state.valuePerson)
                        this.getinfo(this.state.valuePerson)
                    }
                });
            }
        })
    }

    deleteFaceMulti = async (dataImg) => {
        if (this.state.box_engine_cf === undefined) return;
        $('#btn_deleteMulti').addClass('m-loader');
        $('#btn_deleteMulti').attr('disabled', true);
        let data = await fetch(`${this.state.box_engine_cf.api}/api/person/ignoreFace`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                person: this.state.listImage[0].person,
                images: dataImg.dataPost,
                type: this.state.listImage[0].person_type
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            let listImage = this.state.listImage;
            for (let index = 0; index < dataImg.valueImg.length; index++) {
                for (let i = 0; i < listImage.length; i++) {
                    if (dataImg.valueImg[index] == listImage[i].images[0].file_name) {
                        listImage.splice(i, 1)
                    }
                }
            }

            this.setState({
                ...this.state,
                listChecked: {
                    valueImg: [],
                    _id: [],
                    dataPost: [],
                },
                listImage: listImage,
            });
            swal("Thành công!", "Xóa ảnh thành công", "success", {
                buttons: false,
                timer: 1500,
            });
            $('#btn_deleteMulti').removeClass('m-loader');
            $('#btn_deleteMulti').removeAttr('disabled', true);
            this.FilterImg(this.state.activePage);
        } else {
            swal("Lỗi!", "Xóa ảnh lỗi", "error", {
                buttons: false,
                timer: 1500,
            });
            $('#btn_deleteMulti').removeClass('m-loader');
            $('#btn_deleteMulti').removeAttr('disabled', true);
        }

    }

    deletePerson = () => {

        if (this.state.box_engine_cf === undefined) return;
        $('#btn_deletePerson').addClass('m-loader');
        $('#btn_deletePerson').attr('disabled', true);
        fetch(`${this.state.box_engine_cf.api}/api/person/inactive`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: this.state.crrDataInfo._id,
                type: this.state.crrDataInfo.type,
                id: this.state.crrDataInfo.id,
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                let { optionPerson } = this.state;
                optionPerson = optionPerson.filter(x => x._id !== this.state.crrDataInfo._id)
                this.setState({
                    optionPerson,
                    valuePerson: null,
                    crrDataInfo: {
                        '_id': "",
                        'id': '',
                        'code': '',
                        'name': '',
                        'phone': '',
                        'age': '',
                        'gender': '',
                        'department': '',
                        'type': '',
                        'last_type': '',
                        'email': '',
                        'notify': ''
                    },
                    crrImages: [],
                    listImage: [],
                    listChecked: {
                        valueImg: [],
                        _id: [],
                        dataPost: [],
                    },
                })
                swal("Thành công!", "Xóa ảnh thành công", "success", {
                    buttons: false,
                    timer: 1500,
                });
                $('#btn_deletePerson').removeClass('m-loader');
                $('#btn_deletePerson').removeAttr('disabled', true);
            }
            else {
                swal("Lỗi!", "Xóa ảnh lỗi", "error", {
                    buttons: false,
                    timer: 1500,
                });
                $('#btn_deletePerson').removeClass('m-loader');
                $('#btn_deletePerson').removeAttr('disabled', true);
            }
        })

    }


    handleCheckedImg = (event, value) => {
        let listChecked = this.state.listChecked;
        let isChecked = event.target.checked;
        if (listChecked.valueImg.indexOf(event.target.value) === -1) {
            listChecked.valueImg.push(event.target.value)
            listChecked._id.push(value.id)
            listChecked.dataPost.push({ track_id: value.id, file_name: event.target.value })
        } else {
            var i = listChecked.valueImg.indexOf(event.target.value);
            if (i != -1) {
                listChecked.valueImg.splice(i, 1);
                listChecked._id.splice(i, 1);
            }
        }
        this.setState({
            listChecked: listChecked
        })
    }


    handleChangeOption = (index, value) => {
        this.setState({
            crr_comp: value,
            listImage: [],
            activePage: 1,
            crrImages: [],
            crrDataInfo: {
                '_id': "",
                'id': '',
                'code': '',
                'name': '',
                'phone': '',
                'age': '',
                'gender': '',
                'department': '',
                'type': '',
                'last_type': '',
                'email': '',
                'notify': ''
            },
        }, () => {
            this.getPerson(this.state.crr_comp.value)
        });
    }

    handleChangePerson = (event, value) => {
        this.setState({
            valuePerson: value,
            personchange: {
                'imgFace_Original_Size': [
                    {
                        'imgFace': value.accept_face
                    }
                ],
                'person': value._id
            },
            max_upload_images: 0,
            crrImages: [],
            listImage: [],
            crrIdx: 0,
            loading: true,
            crrDataInfo: {
                '_id': "",
                'id': '',
                'code': '',
                'name': '',
                'phone': '',
                'age': '',
                'gender': '',
                'department': '',
                'type': '',
                'last_type': '',
                'email': '',
                'notify': ''
            },
        }, () => {
            this.getImageTrainning(this.state.valuePerson)
            this.getinfo(this.state.valuePerson)
        });
    }

    getinfo = (e) => {
        if (this.state.box_engine_cf === undefined) return;
        fetch(`${this.state.box_engine_cf.api}/api/person/getById/${e.id}`)
            .then((response) => {
                return response.json()
            }).then((rs) => {
                if (rs.status === 10000) {
                    var crrDataInfo = this.state.crrDataInfo;
                    crrDataInfo['_id'] = rs.data._id;
                    crrDataInfo['id'] = rs.data.id;
                    crrDataInfo['code'] = rs.data.code;
                    crrDataInfo['age'] = rs.data.age;
                    crrDataInfo['gender'] = rs.data.gender;
                    crrDataInfo['name'] = rs.data.name;
                    crrDataInfo['phone'] = rs.data.phone;
                    crrDataInfo['department'] = rs.data.department;
                    crrDataInfo['type'] = rs.data.type;
                    crrDataInfo['last_type'] = rs.data.type;
                    crrDataInfo['email'] = rs.data.email;
                    crrDataInfo['notify'] = rs.data.notify;
                    crrDataInfo['id_company'] = this.state.box_engine_cf.idCompany;

                    const typePerson = rs.data.type === 1 ? { title: 'Nhân viên', value: 1 } : rs.data.type === 2 ? { title: 'Vip', value: 2 } :
                        rs.data.type === 3 ? { title: 'Danh sách đen', value: 3 } : rs.data.type === 5 ? { title: 'Khách hàng', value: 5 } : null;
                    this.setState({
                        crrDataInfo: crrDataInfo,
                        typePerson: typePerson
                    })
                }
            })
    }

    UpdateInfo = () => {
        if (this.state.box_engine_cf === undefined) return;
        $('#button_update_info').addClass('m-loader');
        $('#button_update_info').attr('disabled', true);
        fetch(`${this.state.box_engine_cf.api}/api/person/update`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.crrDataInfo)
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                swal("Thành công!", "Cập nhật thành công!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                $('#button_update_info').removeClass('m-loader');
                $('#button_update_info').removeAttr('disabled', true);
            } else {
                swal("Lỗi!", "Cập nhật thất bại!", "error");
                $('#button_update_info').removeClass('m-loader');
                $('#button_update_info').removeAttr('disabled', true);
            }
        }).catch((error) => {
            if (error) {
                swal("Lỗi!", "Cập nhật thất bại!", "error");
                $('#button_update_info').removeClass('m-loader');
                $('#button_update_info').removeAttr('disabled', true);
            }
        })
    }

    modalUploadClose = () => {
        this.setState({
            modalUploadShow: false,
        });
    }

    getImageTrainning = (valuePerson) => {
        if (this.state.box_engine_cf === undefined) return;
        fetch(`${this.state.box_engine_cf.api}/api/imgFace/getById/${valuePerson.id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                this.setState({
                    listImage: rs.data,
                    activePage: 1,
                    loading: false
                }, () => {
                    this.FilterImg(this.state.activePage);
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    loading: false
                });
            }
        })
    }

    handlePageChange = (activePage) => {
        this.setState({
            ...this.state,
            activePage: activePage,
            crrImages: [],
        }, () => {
            this.FilterImg(activePage);
        })
    }

    uploadFaceAll = async (base64, id) => {
        let error = 10000;
        if (this.state.box_engine_cf.api == undefined) return error = 10003;
        this.setState({
            activeCapture: true,
            UploadFaces: true
        })
        await fetch(`${this.state.box_engine_cf.api}/api/upload_face_import`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                idCamera: this.state.box_engine_cf.value === -1 ? this.state.box_engine_cf.ids_Cam.toString() : this.state.box_engine_cf.id_box_engine,
                id_staff: id,
                image: base64.split(',').slice(1).toString()
            })
        }).then((responseData) => {
            return responseData.json()
        }).then((data) => {
            if (data.status == 10000) {
                if (data.face_images.length > 0) {
                    var images = this.state.images;
                    for (let index = 0; index < data.face_images.length; index++) {
                        images.unshift(data.face_images[index]);
                        var count_img = $('#count_img').html();
                        $('#count_img').html(parseInt(count_img) + 1)
                    }
                    this.setState({
                        images: images,
                    })
                } else {
                    error = 10002;
                }
            } else if (data.status === 10007) {
                error = 10007;
            } else {
                error = 10002;
            }
        }).catch((err) => {
            error = 10002;
        })
        this.setState({
            activeCapture: true,
            UploadFaces: true
        })
        return error;
    }



    actionUploadAll = async (numberOfFiles, base64, id) => {
        let error = 10000;
        if (numberOfFiles > 0) {
            error = await this.uploadFaceAll(base64, id)

            this.setState({
                files_length: numberOfFiles,
                number_of_for: 0,
                imgError: [],
            })
        }
        return error;
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    handleFileReader = async (files) => {
        this.modalUploadClose();
        console.log('[onFilesChange] files:', files, files.length)
        // var readerxx = new FileReader();
        // readerxx.onload = function (e) {
        //     document.getElementById('preview').src = e.target.result;
        // };
        // readerxx.readAsDataURL(files.fileList[0]);

        var _URL = window.URL;
        var fileSet, imgSet;
        var size = [];
        var sizeId = [];
        var arrError = [];
        var arrErrorId = [];
        for (let index = 0; index < files.length; index++) {
            var error = true
            var errorID = true
            var status = 10002;
            if ((fileSet = files[index])) {
                var file = files[index];
                var dataurl = await this.convertBase64(file);
                console.log('lovvv', dataurl);
                var id;
                imgSet = new Image();
                imgSet.onload = async function () {
                    var heightSet = this.height;
                    var widthSet = this.width;

                    // if (widthSet >= 1281 || heightSet >= 1281) {
                    //     if (window.File && window.FileReader && window.FileList && window.Blob) {
                    //         var file = files.fileList[index];
                    //         if (file) {
                    //             var reader = new FileReader();
                    //             // Set the image once loaded into file reader
                    //             reader.onload = async function (e) {
                    //                 var img = document.createElement("img");
                    //                 img.src = e.target.result;
                    //                 var canvas = document.createElement("canvas");
                    //                 var ctx = canvas.getContext("2d");
                    //                 ctx.drawImage(img, 0, 0);

                    //                 var MAX_WIDTH = 1280;
                    //                 var MAX_HEIGHT = 1280;
                    //                 var width = widthSet;
                    //                 var height = heightSet;
                    //                 if (width > height) {
                    //                     if (width > MAX_WIDTH) {
                    //                         height *= MAX_WIDTH / width;
                    //                         width = MAX_WIDTH;
                    //                     }
                    //                 } else {
                    //                     if (height > MAX_HEIGHT) {
                    //                         width *= MAX_HEIGHT / height;
                    //                         height = MAX_HEIGHT;
                    //                     }
                    //                 }
                    //                 canvas.width = width;
                    //                 canvas.height = height;
                    //                 var ctx = canvas.getContext("2d");
                    //                 ctx.drawImage(img, 0, 0, width, height);
                    //                 dataurl = canvas.toDataURL(file.type);
                    //             }
                    //             await reader.readAsDataURL(file);
                    //         }
                    //     }
                    // }
                };
                // if (files[index].name.indexOf('.png') > -1) {
                //     id = files[index].name.replace('.png', '')
                // } else if (files[index].name.indexOf('.jpg') > -1) {
                //     id = files[index].name.replace('.jpg', '')
                // } else if (files[index].name.indexOf('.jpeg') > -1) {
                //     id = files[index].name.replace('.jpeg', '')
                // }
                id = files[index].name.substring(0, files[index].name.lastIndexOf("."));
                // files.fileList[index].name
                var i = id.lastIndexOf("_");
                if (i > -1) {
                    id = id.slice(0, i)
                }
                status = await this.actionUploadAll(files.length, dataurl, id);
                if (status === 10002) {
                    error = true
                    errorID = false
                } else if (status === 10007) {
                    errorID = true
                    error = false
                } else {
                    error = false
                    errorID = false
                }
                imgSet.src = await _URL.createObjectURL(fileSet);
            }
            size.push(error);
            sizeId.push(errorID)
            if (error) {
                arrError.push(files[index].name);
            }
            if (errorID) {
                arrErrorId.push(files[index].name)
            }
        }
        this.validateImgAll(arrError, arrErrorId, size, files)
    }

    validateImgAll = (arrError, arrErrorId, size, files) => {
        if (size.length === files.length) {
            this.getPerson(this.state.crr_comp.value);
            this.getListPersonNotTrain();
            this.setState({
                activeCapture: false,
                UploadFaces: false,
                images: []
            })
            if (size.findIndex(e => e === true) === -1) {
                swal({
                    title: "Upload ảnh thành công",
                    text: this.state.files_length + '/' + this.state.files_length + '!',
                    icon: "success",
                    button: "Oke!",
                });
            } else {
                if (arrError.length === files.length) {
                    swals({
                        title: "Upload ảnh thất bại",
                        content: <div>
                            {arrError.length > 0 && (
                                <div>
                                    <div>
                                        Thất bại
                                    </div>
                                    <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height='auto' style={{ 'overflow': 'hidden' }}>
                                        {
                                            arrError.map((value) => {
                                                return (
                                                    <div style={{ color: 'red' }}>
                                                        {value}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )}
                            {arrErrorId.length > 0 && (
                                <div>
                                    <div>
                                        Ảnh có mã nhân viên không có trong hệ thống: 
                                    </div>
                                    <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height='auto' style={{ 'overflow': 'hidden' }}>
                                        {
                                            arrErrorId.map((value) => {
                                                return (
                                                    <div style={{ color: 'red' }}>
                                                        {value}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )}
                        </div>,
                        icon: "error",
                        button: "Oke!",
                    });
                } else {
                    swals({
                        title: "Upload ảnh thành công",
                        text: `${files.length - arrError.length}` + '/' + this.state.files_length + '!',
                        content: <div>
                            {arrError.length > 0 && (
                                <div>
                                    <div>
                                        Hình ảnh không phát hiện khuôn mặt
                                    </div>
                                    <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height='auto' style={{ 'overflow': 'hidden' }}>
                                        {
                                            arrError.map((value) => {
                                                return (
                                                    <div style={{ color: 'red' }}>
                                                        {value}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )}
                            {arrErrorId.length > 0 && (
                                <div>
                                    <div>
                                        Ảnh có mã nhân viên không có trong hệ thống: 
                                    </div>
                                    <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height='auto' style={{ 'overflow': 'hidden' }}>
                                        {
                                            arrErrorId.map((value) => {
                                                return (
                                                    <div style={{ color: 'red' }}>
                                                        {value}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )}
                        </div>,
                        icon: "success",
                        button: "Oke!",
                    });
                }
            }

        }
    }


    handleEnter = (event) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    handleChange(e) {
        var crrDataInfo = this.state.crrDataInfo;
        crrDataInfo[e.target.name] = e.target.value
        this.setState({
            crrDataInfo: crrDataInfo
        });
    }
    handleChangeType(e, value) {
        var crrDataInfo = this.state.crrDataInfo;
        crrDataInfo['type'] = value.value;
        this.setState({
            typePerson: value,
            crrDataInfo: crrDataInfo,
        })
    }

    FilterImg = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrImages = this.state.listImage.slice(offset, offset + this.itemsPerPage);
        this.setState({ crrImages })
    }
    handleCloseModalViewExcel = () => {
        this.setState({
            modalViewExcel: false,
        })
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedImg = this.state.crrImages.map((value, index) => {
            var urlImg = ""
            if (value.images.length > 0) {
                const date = value.images[0].file_name.split("_");
                urlImg = value.mount_folder + "/" + date[1].slice(0, 6) + "/" + value.camera_id;
            }
            return (
                <Col sm={2} md={2} xs={4} className="pl-0 mb-3" key={index + 1}>
                    <div style={{ 'height': 80 }}>
                        {
                            this.state.checkDeleteMulti == false ?
                                <img src={`${this.state.box_engine_cf.api2_url}/view/image/${urlImg}/${value.images[0].file_name}`} onClick={() => {
                                    this.Image = value;
                                    this.setState({ crrIdx: index });
                                }} className={"opacity_img_click img_check " + (this.state.crrIdx === index ? 'active' : '')} />
                                :
                                <label className="m-checkbox img-checkbox m-checkbox-day pl-0 mb-0" style={{ 'width': '100%' }}>
                                    <input type="checkbox" defaultValue={value.images[0].file_name} name="img_checked" checked={this.state.listChecked.valueImg.indexOf(value.images[0].file_name) == -1 ? false : true} onClick={e => this.handleCheckedImg(e, value)} readOnly />
                                    <img src={`${this.state.box_engine_cf.api2_url}/view/image/${urlImg}/${value.images[0].file_name}`} className="opacity_img img_check" />
                                    <span />
                                </label>
                        }

                    </div>
                </Col>
            )
        })

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 2
        } else {
            pageRangeDisplayed = 5
        }

        var checkedMale = this.state.checkedMale;
        var checkedFemale = this.state.checkedFemale;
        if (this.state.crrDataInfo.gender === '1') {
            checkedMale = true
            checkedFemale = false
        } else if (this.state.crrDataInfo.gender === '2') {
            checkedMale = false
            checkedFemale = true
        } else {
            checkedMale = false
            checkedFemale = false
        }

        var checkedSend = this.state.checkedSend;
        var checkedNoSend = this.state.checkedNoSend;
        if (this.state.crrDataInfo.notify === '1') {
            checkedSend = true
            checkedNoSend = false
        } else if (this.state.crrDataInfo.notify === '2') {
            checkedSend = false
            checkedNoSend = true
        } else {
            checkedSend = false
            checkedNoSend = false
        }


        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2" id="capture_form">
                <div className="m-content p-2">
                    <div className="m-portlet" id="form_reva">
                        <div className="m-portlet__head capture_form_select d-flex align-items-center justify-content-between">
                            <div className="row m-0 p-2 form_select">
                                <div className="select_option_type">
                                    <Autocomplete
                                        options={select_option}
                                        getOptionLabel={option => option.title}
                                        value={this.state.crr_comp}
                                        onChange={this.handleChangeOption}
                                        disableClearable
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder='Chọn...'
                                                fullWidth
                                            />
                                        )}
                                    />
                                </div>

                                <div className="select_option_person">
                                    <Autocomplete
                                        options={this.state.optionPerson}
                                        getOptionLabel={option => option.title}
                                        value={this.state.valuePerson}
                                        onChange={this.handleChangePerson}
                                        disableClearable
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder='Chọn...'
                                                fullWidth
                                            />
                                        )}
                                        renderOption={(option, { inputValue }) => {
                                            return (
                                                <div style={{
                                                    color: option.trainning_set < 1 ? '#f44236 ' : '',
                                                }}>
                                                    <span style={{ fontWeight: 400 }}>
                                                        {option.title}
                                                    </span>
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <span style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: 17 }}>Số người có mẫu/tổng: ( {this.state.total_person - this.state.number_person_not_image}/{this.state.total_person} )</span>
                                <button type="button" id="button_update_info" className="btn btn-success m-loader--light m-loader--right mx-3" style={{ fontWeight: 500, fontSize: 14 }}
                                    onClick={() => this.setState({ modalViewExcel: true })}
                                >
                                    {
                                        this.state.loading_api_excel ?
                                            <PulseLoader
                                                css={override}
                                                sizeUnit={"px"}
                                                size={12}
                                                margin={'2px'}
                                                color={'#36D7B7'}
                                                loading={this.state.loading_api_excel}
                                            />
                                            :
                                            'Nhân viên không có mẫu'
                                    }
                                </button>
                                <button type="button" id="button_update_info" className="btn btn-primary m-loader--light m-loader--right" style={{ fontWeight: 500, fontSize: 14 }}
                                    onClick={this.export}
                                >
                                    {
                                        this.state.loading_api_excel ?
                                            <PulseLoader
                                                css={override}
                                                sizeUnit={"px"}
                                                size={12}
                                                margin={'2px'}
                                                color={'#36D7B7'}
                                                loading={this.state.loading_api_excel}
                                            />
                                            :
                                            'Xuất Excel'
                                    }
                                </button>
                                
                                <>
                                    <div style={{ 'display': 'inline-block' }} className="ml-2">
                                        <button
                                            onClick={(e) => {
                                                this.setState({
                                                    modalUploadShow: true,
                                                })
                                            }}
                                            className="btn btn-success m-btn--icon m-btn">
                                            <span>
                                                <i className="la la-cloud-upload"></i>
                                                <span>Upload thư mục</span>
                                            </span>
                                        </button>
                                    </div>
                                    {/* <div style={{ 'display': 'inline-block' }} className="ml-2">
                                        <ReactFileReader maxFileSize={1000} minFileSize={0} fileTypes={['image/png', 'image/jpg', 'image/jpeg']} base64={true} multipleFiles={true} handleFiles={this.handleFileReader}>
                                            <button className='btn btn-success m-btn--icon m-btn ' >
                                                <span>
                                                    <i className="la la-cloud-upload"></i>
                                                    <span>Tải lên thư mục</span>
                                                </span>
                                            </button>
                                        </ReactFileReader>
                                        <img src="" id="preview" style={{ 'display': 'none' }}></img>
                                    </div> */}
                                </>
                            </div>
                        </div>
                        <div className="m-portlet__body  m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-3">
                                    <div className="m-portlet--tab mb-0">
                                        {/*begin::Form*/}
                                        <div className="m-form m-form--fit m-form--label-align-right" id="formCapture">

                                            <div className="m-portlet__foot--fit">
                                                <div className="m-portlet__body pl-3 pt-0-mobie pr-0 m-auto col-12 col-md-8 col-lg-6 col-xl-12">
                                                    <div className="form-group m-form__group m-0 row text-center">
                                                        <div className="col-12 col-md-12">
                                                            <h4 className="m-form__section">Thông tin</h4>
                                                        </div>
                                                    </div>
                                                    <div className="form-group m-form__group m-0 pl-4 pb-1 pt-3 pr-3 row">
                                                        <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">Mã nhân viên</label>
                                                        <div className="col-9 col-md-9">
                                                            <input className="form-control m-input" type="text" name="id" value={this.state.crrDataInfo !== null ? this.state.crrDataInfo.code : ''} disabled onKeyDown={(event) => this.handleEnter(event)} />

                                                        </div>
                                                    </div>
                                                    <div className="form-group m-form__group m-0 pl-4 pb-1 pt-3 pr-3 row">
                                                        <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">Họ và Tên</label>
                                                        <div className="col-9 col-md-9">
                                                            {/* <input className="form-control m-input" type="text" name="name" value={this.state.crrDataInfo !== null ? this.state.crrDataInfo.name : ''} onChange={this.handleChange.bind(this)} onKeyDown={(event) => this.handleEnter(event)} /> */}
                                                            <input className="form-control m-input" type="text" name="name" value={this.state.crrDataInfo !== null ? this.state.crrDataInfo.name : ''} onChange={this.handleChange.bind(this)} disabled onKeyDown={(event) => this.handleEnter(event)} />

                                                        </div>
                                                    </div>
                                                    <div className="form-group m-form__group m-0 pl-4 pb-1 pt-3 pr-3 row">
                                                        <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">Điện thoại</label>
                                                        <div className="col-9 col-md-9">
                                                            {/* <input className="form-control m-input" type="text" name="phone" value={this.state.crrDataInfo !== null ? this.state.crrDataInfo.phone : ''} onChange={this.handleChange.bind(this)} onKeyDown={(event) => this.handleEnter(event)} /> */}
                                                            <input className="form-control m-input" type="text" name="phone" value={this.state.crrDataInfo !== null ? this.state.crrDataInfo.phone : ''} onChange={this.handleChange.bind(this)} disabled onKeyDown={(event) => this.handleEnter(event)} />

                                                        </div>
                                                    </div>
                                                    <div className="form-group m-form__group m-0 pl-4 pb-1 pt-3 pr-3 row">
                                                        <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">Tuổi</label>
                                                        <div className="col-9 col-md-9">
                                                            {/* <input className="form-control m-input" type="text" name="age" value={this.state.crrDataInfo !== null ? this.state.crrDataInfo.age : ''} onChange={this.handleChange.bind(this)} onKeyDown={(event) => this.handleEnter(event)} /> */}
                                                            <input className="form-control m-input" type="text" name="age" value={this.state.crrDataInfo !== null ? this.state.crrDataInfo.age : ''} onChange={this.handleChange.bind(this)} disabled onKeyDown={(event) => this.handleEnter(event)} />

                                                        </div>
                                                    </div>
                                                    <div className="form-group m-form__group m-0 pl-4 pb-1 pt-3 pr-3 row">
                                                        <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">Giới tính</label>
                                                        <div className="col-9 col-md-9">
                                                            <div className="m-radio-inline">
                                                                <label className="m-radio mb-0">
                                                                    <input type="radio" name="gender" checked={checkedMale} defaultValue={1} disabled /> Nam
                                                                    <span />
                                                                </label>
                                                                <label className="m-radio mb-0">
                                                                    <input type="radio" name="gender" checked={checkedFemale} defaultValue={2} disabled /> Nữ
                                                                    <span />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group m-form__group m-0 pl-4 pb-1 pt-3 pr-3 row">
                                                        <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">Phòng ban</label>
                                                        <div className="col-9 col-md-9">
                                                            {/* <input className="form-control m-input" type="text" name="department" value={this.state.crrDataInfo !== null ? this.state.crrDataInfo.department : ''} onChange={this.handleChange.bind(this)} onKeyDown={(event) => this.handleEnter(event)} /> */}
                                                            <input className="form-control m-input" type="text" name="department" value={this.state.crrDataInfo !== null ? this.state.crrDataInfo.department : ''} onChange={this.handleChange.bind(this)} disabled onKeyDown={(event) => this.handleEnter(event)} />

                                                        </div>
                                                    </div>
                                                    {/* <div className="form-group m-form__group m-0 pl-4 pb-1 pt-3 pr-3 row">
                                                        <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">Email</label>
                                                        <div className="col-9 col-md-9">
                                                            <input className="form-control m-input" type="text" name="email" value={this.state.crrDataInfo !== null ? this.state.crrDataInfo.email : ''} onChange={this.handleChange.bind(this)} onKeyDown={(event) => this.handleEnter(event)} />

                                                        </div>
                                                    </div>
                                                    <div className="form-group m-form__group m-0 pl-4 pb-1 pt-3 pr-3 row">
                                                        <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">Nhận thông báo</label>
                                                        <div className="col-9 col-md-9">
                                                            <div className="m-radio-inline">
                                                                <label className="m-radio mb-0">
                                                                    <input type="radio" name="notify" checked={checkedSend} defaultValue={1} onChange={this.handleChange.bind(this)} /> Có
                                                                    <span />
                                                                </label>
                                                                <label className="m-radio mb-0">
                                                                    <input type="radio" name="notify" checked={checkedNoSend} defaultValue={2} onChange={this.handleChange.bind(this)} /> Không
                                                                    <span />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="form-group m-form__group m-0 pl-4 pb-1 pt-3 pr-3 row">
                                                        <label htmlFor="example-text-input" className="col-3 col-md-3 pl-0 col-form-label text-left">Nhóm</label>
                                                        <div className="col-9 col-md-9 select_option_type">
                                                            <Autocomplete
                                                                options={select_option}
                                                                getOptionLabel={option => option.title}
                                                                value={this.state.typePerson}
                                                                // onChange={this.handleChangeType.bind(this)}
                                                                disabled
                                                                disableClearable
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        placeholder='Chọn...'
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="m-form__actions pt-3 text-center">
                                                    {/* <button type="button" id="button_update_info" className="btn btn-accent m-loader--light m-loader--right" 
                                                        onClick={(event) => this.UpdateInfo(event)}>
                                                        Lưu lại
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!window.confirm("Thao tác này sẽ không thể khôi phục được, Bạn chắc chắn muốn xóa?")) {
                                                                return;
                                                            }
                                                            this.deletePerson();
                                                        }}
                                                        className="btn btn-danger width-mobie--100 m-loader--light m-loader--right ml-2" id="btn_deletePerson">
                                                        Xóa đối tượng
                                                    </button> */}
                                                    <div style={{ 'display': 'inline-block' }} className="ml-2">
                                                        <ReactFileReader maxFileSize={1000} minFileSize={0} fileTypes={['image/png', 'image/jpg', 'image/jpeg']} disabled={this.state.crrDataInfo === null ? true : false} base64={true} multipleFiles={true} handleFiles={async (files) => {
                                                            this.setState({
                                                                loading: true
                                                            })
                                                            await onFilesChange(files, this.state.crrDataInfo, this.state.box_engine_cf)
                                                            this.getImageTrainning(this.state.valuePerson)
                                                            this.setState({
                                                                loading: false
                                                            })
                                                        }}>
                                                            <button className='btn m-btn--icon m-btn button_uploadfaces btn-default m-loader--success m-loader--right' disabled={this.state.crrDataInfo === null ? true : false}>
                                                                <span>
                                                                    <i className="la la-cloud-upload"></i>
                                                                    <span>Upload Ảnh</span>
                                                                </span>
                                                            </button>
                                                        </ReactFileReader>
                                                        <img src="" id="preview" style={{ 'display': 'none' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end::Form*/}
                                    </div>
                                </div>
                                <div className="col-xl-9" id="iframe_hide_mobie">
                                    <div className="m-portlet--tab mb-0" style={{ 'height': '100%' }}>
                                        <div className="m-form m-form--fit m-form--label-align-right">
                                            <div className="m-portlet__body pl-5 pr-5">
                                                <div className="row m-0">
                                                    <div className="col-xl-3 col-md-4 p-0">
                                                        <label className="m-checkbox m-checkbox--success hide_mobile mt-3" style={{ 'visibility': 'hidden' }}>
                                                            <input type="checkbox" onClick={(e) => {
                                                                this.setState({
                                                                    checkDeleteMulti: !this.state.checkDeleteMulti,
                                                                    listChecked: {
                                                                        valueImg: [],
                                                                        _id: [],
                                                                        dataPost: [],
                                                                    }
                                                                })
                                                            }} checked={this.state.checkDeleteMulti} /> <div style={{ 'paddingTop': '2px' }}>Chọn</div>
                                                            <span />
                                                        </label>
                                                        {
                                                            this.state.crrImages.length > 0 ? (
                                                                <div className="col-xl-12 pt-3 pb-3 pl-0 pr-0">
                                                                    <div className="col-md-12 p-0  mx-auto">
                                                                        {
                                                                            this.state.crrImages[this.state.crrIdx] && this.state.crrImages[this.state.crrIdx].images.length > 0 &&
                                                                            <img
                                                                                src={`${this.state.box_engine_cf.api2_url}/view/image/${this.state.crrImages[this.state.crrIdx].mount_folder}/${this.state.crrImages[this.state.crrIdx].images[0].file_name.split("_")[1].slice(0, 6)}/${this.state.crrImages[this.state.crrIdx].camera_id}/${this.state.crrImages[this.state.crrIdx].images[0].file_name}`}
                                                                                alt='Lựa chọn ảnh' className="image_capture_big col-md-12 p-0" />

                                                                        }

                                                                    </div>

                                                                </div>


                                                            ) : ''
                                                        }

                                                    </div>
                                                    <div className="col-xl-9 col-md-8 list_col_image">
                                                        {
                                                            this.state.listImage.length > 0
                                                                ?
                                                                <div className="mt-2 mb-2">
                                                                    <label className="m-checkbox m-checkbox--success mt-3 ml-1">
                                                                        <input type="checkbox" onClick={(e) => {
                                                                            this.setState({
                                                                                checkDeleteMulti: !this.state.checkDeleteMulti,
                                                                                listChecked: {
                                                                                    valueImg: [],
                                                                                    _id: [],
                                                                                    dataPost: [],
                                                                                }
                                                                            })
                                                                        }} checked={this.state.checkDeleteMulti} /> <div style={{ 'paddingTop': '2px' }}>Chọn</div>
                                                                        <span />
                                                                    </label>
                                                                    {
                                                                        this.state.checkDeleteMulti === true
                                                                            ?
                                                                            <div style={{ 'display': 'inline-flex' }} className="pull-right">
                                                                                <button
                                                                                    onClick={(e) => {
                                                                                        if (this.state.listChecked.valueImg.length > 0) {
                                                                                            e.preventDefault();
                                                                                            if (!window.confirm("Bạn chắc chắn muốn xóa ảnh này?")) {
                                                                                                return;
                                                                                            }
                                                                                            this.deleteFaceMulti(
                                                                                                this.state.listChecked
                                                                                            );
                                                                                        } else {
                                                                                            swal("Cảnh báo!", "Bạn chưa chọn ảnh để xóa", "warning");
                                                                                        }
                                                                                    }}
                                                                                    className="btn btn-danger width-mobie--100 m-loader--light m-loader--right" id="btn_deleteMulti">
                                                                                    Xóa ảnh đã chọn
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            ""
                                                                    }

                                                                </div>
                                                                :
                                                                ""
                                                        }

                                                        <div className="row col-md-12 pr-0 image_capture_small pull-left">
                                                            {
                                                                bulletedImg
                                                            }
                                                        </div>
                                                        {
                                                            this.state.listImage.length > 0
                                                                ?
                                                                <div className="col-xl-12  pl-0 pr-4 pull-left">
                                                                    <div className="pull-left">
                                                                        <Pagination
                                                                            prevPageText='Trang trước'
                                                                            nextPageText='Trang sau'
                                                                            firstPageText='Trang đầu'
                                                                            lastPageText='Trang cuối'
                                                                            activePage={this.state.activePage}
                                                                            itemsCountPerPage={this.itemsPerPage}
                                                                            totalItemsCount={this.state.listImage.length}
                                                                            pageRangeDisplayed={pageRangeDisplayed}
                                                                            onChange={this.handlePageChange}
                                                                        />
                                                                    </div>
                                                                    {/* <span className="pull-right p-2">
                                                                        {language[this.state.indexlanguage].Capture.showing} {this.state.showFirst} {language[this.state.indexlanguage].Capture.to} {this.state.showLast} {language[this.state.indexlanguage].Capture.of} {this.state.totalLength}
                                                                    </span> */}
                                                                </div>
                                                                :
                                                                ""
                                                        }

                                                        <div className="text-center">
                                                            <PulseLoader
                                                                css={override}
                                                                sizeUnit={"px"}
                                                                size={12}
                                                                margin={'2px'}
                                                                color={'#36D7B7'}
                                                                loading={this.state.loading}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/*end::Form*/}
                                        <ModalViewPersonNoImage data={this.state.listPersonNotTrain} show={this.state.modalViewExcel} onHide={this.handleCloseModalViewExcel} />
                                        <ExcelExport
                                            data={this.state.listPerson}
                                            ref={(exporter) => { this._exporter = exporter; }}
                                            fileName={`Danh-Sach-Nhan-Vien-${moment(new Date()).format('YYYY-MM-DD-HH-mm-ss')}.xlsx`}
                                        >
                                            <ExcelExportColumn field="index" title="STT" width={50} />
                                            <ExcelExportColumn field="code" title="Mã nhân viên" width={150} />
                                            <ExcelExportColumn field="name" title="Họ và Tên" width={180} />
                                            <ExcelExportColumn field="phone" title="Điện thoại" width={150} />
                                            <ExcelExportColumn field="gender" title="Giới tính" width={80} />
                                            <ExcelExportColumn field="department" title="Phòng" width={150} />
                                            <ExcelExportColumn field="type" title="Nhóm" width={100} />
                                            <ExcelExportColumn field="trainning_set" title="Số lượng" width={80} />
                                        </ExcelExport>
                                    </div>
                                    
                                    <ModalUpload
                                        show={this.state.modalUploadShow}
                                        onHide={this.modalUploadClose}
                                        onUpload={this.handleFileReader}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Trainning;
