import React, { Component } from 'react';
import { Route } from "react-router-dom";
import Store from '../store';
import { withRouter } from 'react-router';
import Dashboard from '../components/action/Dashboard/Dashboard';
import Statistical from '../components/action/reva/Statistical';
import Age from '../components/action/Age';
import Gender from '../components/action/Gender';
import Emotion from '../components/action/Emotion';
import MenuBar from '../components/layouts/MenuBar';
import RevaRC from '../components/action/reva/RevaRC';
import History from '../components/action/reva/History';
import Trainning from '../components/action/reva/Trainning';
import ListUser from '../components/action/reva/ListUser';
import SettingDoor from '../components/action/reva/SettingDoor';
import ListTrash from '../components/action/reva/ListTrash';
import SearchImage from '../components/action/reva/Search';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import Checking from '../components/action/reva/Checking';
import { EMOTION } from '../config';

class ReactRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            setTheme: Store.getState().theme.setTheme
        }
        Store.subscribe(() => {
            this.setState({
                setTheme: Store.getState().theme.setTheme,
                isLogin: Store.getState().isLogin.isLogin,
                rule: Store.getState().rules.rule,
            })
        });
    }
    render() {
        var classBody = "";
        let classRule = ' m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body';
        if(this.state.rule === '5'){
            classRule= ' rule_5'
        }


        if (this.state.setTheme === 'Dark') {
            classBody = 'themeBodyDark';
        } else {
            classBody = '';
        }
        return (
            <div className="m-grid m-grid--hor m-grid--root m-page">
                <Header />
                <div className={classBody + classRule}>
                    <button className="m-aside-left-close  m-aside-left-close--skin-dark " id="m_aside_left_close_btn">
                        <i className="la la-close" />
                    </button>
                    {
                        this.state.rule !== '5' &&
                        <>
                            <MenuBar />
                            <Route path="/" exact component={RevaRC} />
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/statistical" component={Statistical} />
                            <Route path="/history" component={History} />
                            <Route path="/trainning" component={Trainning} />
                            <Route path="/list-user" component={ListUser} />
                            <Route path="/checking" component={Checking} />
                            <Route path="/trash" component={ListTrash} />
                            <Route path="/searchimage" component={SearchImage} />
                            <Route path="/setting-door" component={SettingDoor} />
                            {
                                EMOTION == 1 &&
                                <>
                                    <Route path="/age" component={Age} />
                                    <Route path="/gender" component={Gender} />
                                    <Route path="/emotion" component={Emotion} />
                                </>
                            }
                        </>
                    }
                    {
                        <Route path="/reva" component={RevaRC} />

                    }
                </div>
                <Footer />
            </div>
        );
    }
}

export default withRouter(ReactRouter);