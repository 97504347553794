import React from 'react';
import './../css/App.css';
import Login from '../Login';
import ReactRouter from '../router/ReactRouter';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login" render={() => <Login />}  />
        <ReactRouter />
      </Switch>
    </Router>
  );
}

export default App;
