export const chartEmotion = {
    labels: ['Bình thường', 'Vui vẻ', 'Buồn', 'Không rõ'],
    colors: ['#008ffb', '#00e396','#feb019','#c4c5d6'],
    plotOptions: {
        pie: {
            size: 120,
            expandOnClick: false,
            donut: {
                size: '70%',
                background: 'transparent',
                labels: {
                    show: true,
                    name: {
                        show: true,
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        color: undefined,
                        offsetY: -10
                    },
                    value: {
                        show: true,
                        fontSize: '22px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        color: '#b1b1b5',
                        offsetY: 16,
                        formatter: function (val) {
                            return val
                        }
                    },
                    total: {
                        show: true,
                        label: 'Tổng',
                        color: '#b1b1b5',
                        formatter: function (w) {
                            return w.globals.seriesTotals.reduce((a, b) => {
                                return a + b
                            }, 0)
                        }
                    }
                }
            }
        },
    },
    responsive: [{
        breakpoint: 2000,
        options: {
            chart: {
                width: 250,
                height: 360,
            },
            legend: {
                position: 'bottom'
            }
        }
    }]
}

export const chartEmotionLight = {
    chart: {
        type: 'column',
        options3d: {
            enabled: true,
            alpha: 0,
            beta: 0,
            depth: 0,
            viewDistance: 0
        },
        height: 440,
        backgroundColor: {
            linearGradient: { x1: 1, y1: 1, x2: 1, y2: 1 },
            stops: [
                [0, '#ffff']
            ]
        },
        style: {
            fontFamily: '\'Unica One\', sans-serif'
        },
        plotBorderColor: '#e6e6e6'
    },

    colors: ['#008ffb', '#00e396','#feb019','#c4c5d6'],

    title: {
        text: 'Tổng: ',
        style: {
            fontSize: '15px',
            color: '#666666',
            textTransform: 'uppercase',
        }
    },

    xAxis: {
        categories: [],
        labels: {
            skew3d: true,
            style: {
                color: '#666666'
            }
        },
        gridLineColor: '#e6e6e6',
        lineColor: '#e6e6e6',
        minorGridLineColor: '#505053',
        tickColor: '#e6e6e6',
        title: {
            style: {
                color: '#666666'
            }
        }
    },

    yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
            text: '',
            skew3d: true,
            style: {
                color: '#666666'
            },
        },
        gridLineColor: '#e6e6e6',
        labels: {
            style: {
                color: '#666666'
            }
        },
        lineColor: '#e6e6e6',
        minorGridLineColor: '#505053',
        tickColor: '#e6e6e6',
        tickWidth: 1,
    },

    tooltip: {
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
            color: '#b1b1b5'
        }
    },
    legend: {
        itemStyle: {
            color: '#666666'
        },
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            depth: 40,
            pointPadding: 0.35,
        },

    },

    series: [{
        name: 'Bình thường',
        data: [],
    }, {
        name: 'Vui vẻ',
        data: [],
    }, {
        name: 'Buồn',
        data: [],
    }, {
        name: 'Không rõ',
        data: [],
    }],
    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                chart: {
                    height: 300
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.15,
                        groupPadding: 0.25,
                        borderWidth: 0.1,
                    }
                },
            }
        }]
    },

}

export const chartEmotionDark = {
    chart: {
        type: 'column',
        options3d: {
            enabled: true,
            alpha: 0,
            beta: 0,
            depth: 0,
            viewDistance: 0
        },
        height: 440,
        backgroundColor: {
            linearGradient: { x1: 1, y1: 1, x2: 1, y2: 1 },
            stops: [
                [0, '#222437']
            ]
        },
        style: {
            fontFamily: '\'Unica One\', sans-serif'
        },
        plotBorderColor: '#606063'
    },

    colors: ['#008ffb', '#00e396','#feb019','#c4c5d6'],

    title: {
        text: 'Tổng: ',
        style: {
            fontSize: '15px',
            color: '#b1b1b5',
            textTransform: 'uppercase',
        }
    },

    xAxis: {
        categories: [],
        labels: {
            skew3d: true,
            style: {
                color: '#b1b1b5'
            }
        },
        gridLineColor: '#707073',
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        title: {
            style: {
                color: '#b1b1b5'
            }
        }
    },

    yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
            text: '',
            skew3d: true,
            style: {
                color: '#b1b1b5'
            },
        },
        gridLineColor: '#707073',
        labels: {
            style: {
                color: '#b1b1b5'
            }
        },
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        tickWidth: 1,
    },

    tooltip: {
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
            color: '#b1b1b5'
        }
    },
    legend: {
        itemStyle: {
            color: '#b1b1b5'
        },
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            depth: 40,
            pointPadding: 0.35,
        },

    },

    series: [{
        name: 'Bình thường',
        data: [],
    }, {
        name: 'Vui vẻ',
        data: [],
    }, {
        name: 'Buồn',
        data: [],
    }, {
        name: 'Không rõ',
        data: [],
    }],
    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                chart: {
                    height: 300
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.15,
                        groupPadding: 0.25,
                        borderWidth: 0.1,
                    }
                },
            }
        }]
    },
}