import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import ReactTooltip from 'react-tooltip';
import { Dropdown } from 'semantic-ui-react';
import swal from 'sweetalert';
import Store from '../../store';
import { LIST_PERSON} from '../../config/index';

let select_option = [];

if(LIST_PERSON.indexOf('1') !== -1){
    select_option.push({ title: 'Nhân viên', value: 1 })
}
if(LIST_PERSON.indexOf('2') !== -1){
    select_option.push({ title: 'Vip', value: 2 })
}
if(LIST_PERSON.indexOf('3') !== -1){
    select_option.push({ title: 'Danh sách đen', value: 3 })
}
if(LIST_PERSON.indexOf('5') !== -1){
    select_option.push({ title: 'Khách hàng', value: 5 })
}

class ModalMoveImg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            listChecked: [],
            datamove: null,
            listImage: [],
            crrImages: [],
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            crr_comp: select_option[1],
            valuePerson: null,
            optionPerson: [],
            checkAll: false,
        }
        Store.subscribe(() => {
            this.setState({
                ...this.state,
                activePage: 1,
                loading: true,
                isLogin: Store.getState().isLogin.isLogin,
                box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            });
        });
        this.itemsPerPage = 8;
    }

    MoveImage = () => {
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        if(this.state.listChecked.length === 0){
            swal("Cảnh báo!", "Bạn chưa chọn ảnh!", "warning");
        }else{
            fetch(`${this.state.box_engine_cf.api}/api/person/acceptFace`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify({
                    person: this.state.datamove.person_type === 4 ? "" : this.state.datamove.person,
                    images: this.state.listChecked,
                    type: this.state.datamove.person_type,
                    new_person: this.state.valuePerson.value,
                    new_type: this.state.crr_comp.value,
                    track: this.state.datamove.id,
                })
            }).then((response) => {
                return response.json()
            }).then((rs) => {
                if (rs.status === 10000) {
                    swal("Thành công!", "Chuyển ảnh thành công!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    var { listImage } = this.state;
                    for (let index = 0; index < listImage.length; index++) {
                        for (let i = 0; i < this.state.listChecked.length; i++) {
                            if (listImage[index]['_id'] === this.state.listChecked[i]) {
                                listImage[index]['status'] = "imgFace"
                            }
                        }
                    }
                    this.setState({ 
                        listImage: listImage,
                        listChecked: [],
                        checkAll: false,
                        activePage: 1,
                    }, () => {
                        this.FilterImg(this.state.activePage);
                        this.getImageTrack(this.state.activePage);
                    })
                } else {
                    swal("Lỗi!", "Chuyển ảnh thất bại!", "error");
                }
            }).catch((error) => {
                if (error) {
                    swal("Lỗi!", "Chuyển ảnh thất bại!", "error");
                }
            })
        }
    }

    getImageTrack = () => {
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        fetch(`${this.state.box_engine_cf.api2_url}/api/person/ImagePerDay`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                'idCamera': this.state.datamove.camera_id,
                'id_person': this.state.datamove.person,
                'type': this.state.datamove.person_type,
                'day': this.state.datamove.created_time.split(' ')[0],
                'track': ""
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                console.log(data);
                this.setState({
                    listImage: data.data,
                    loadingImage: false
                }, () => {
                    this.FilterImg(this.state.activePage);
                })
            }
        })
    }

    FilterImg = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        const crrImages = this.state.listImage.slice(offset, offset + this.itemsPerPage);
        this.setState({ crrImages })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber,
            crrImages: [],
        }, () => {
            this.FilterImg(pageNumber);
        })
    }
    handleChangeOption = (index, value) => {
        console.log('chúng ta của hiện tại',value);
        this.setState({
            crr_comp: value,
            valuePerson: null,
        }, () => {
            this.getPerson(this.state.crr_comp.value)
        });
    }
    handleChangePerson = (event, value) => {
        this.setState({
            valuePerson: value,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            this.setState({
                crrImages: [nextProps.datamove],
                crr_comp: nextProps.datamove.person_type === 1 ? select_option[0] : nextProps.datamove.person_type === 2 ? select_option[1] : nextProps.datamove.person_type === 3 ? select_option[2] : nextProps.datamove.person_type === 5 ? select_option[3] : {value: null},
                valuePerson: {
                    value: nextProps.datamove.person,
                    title: `${nextProps.datamove.name === undefined ? nextProps.datamove.information.name : nextProps.datamove.name}${nextProps.datamove.code ? ` - ${nextProps.datamove.code}` : ''}`,
                },
            })
            this.setState({
                datamove: nextProps.datamove,
                show: nextProps.show,
                activePage: 1,
                listChecked: [],
                crrImages: [],
            }, () => {
                this.getImageTrack(this.state.activePage);
                if(nextProps.datamove.type !== 4){
                    this.getPerson(nextProps.datamove.type);
                }
                this.setState({
                    crr_comp: nextProps.datamove.type === 1 ? select_option[0] : nextProps.datamove.type === 2 ? select_option[1] : nextProps.datamove.type === 3 ? select_option[2] : nextProps.datamove.type === 5 ? select_option[3] : null,
                    valuePerson: {
                        value: nextProps.datamove.person,
                        title: `${nextProps.datamove.name === undefined ? nextProps.datamove.information.name : nextProps.datamove.name}${nextProps.datamove.id ? ` - ${nextProps.datamove.id}` : ''}`,
                    },
                })
            });
        }else{
            this.setState({
                listImage: [],
                listChecked: [],
                checkAll: false,
                optionPerson: [],
            })
        }
    }
    getPerson = (value) => {
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        fetch(`${this.state.box_engine_cf.api}/api/person/getByType`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                type: value,
                idCamera: this.state.box_engine_cf.ids_Cam.join(','),
                day: moment(new Date()).format('YYYY-MM-DD'),
                id_company: `${this.state.box_engine_cf.idCompany}`,
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                var optionPerson = [];
                for (let index = 0; index < rs.data.length; index++) {
                    var name = "No Name"
                    const id = rs.data[index].id ? ` - ${rs.data[index].id}` : '';
                    if (rs.data[index].name !== "") {
                        name = rs.data[index].name
                    }
                    optionPerson.push(
                        {
                            value: rs.data[index].id,
                            title: `${name} - ${rs.data[index].code}`,
                        }
                    )
                }
                this.setState({
                    optionPerson: optionPerson
                }, () => {
                    if (this.state.valuePerson !== null) {
                    }
                });
            }
        })
    }


    handleCheckedImg = (event, track) => {
        let listChecked = this.state.listChecked;
        if (listChecked.findIndex(x => x.file_name === event.target.value) === -1) {
            listChecked.push({track_id: track, file_name: event.target.value})
        } else {
            var i = listChecked.findIndex(x => x.file_name === event.target.value);
            if (i !== -1) {
                listChecked.splice(i, 1);
            }
        }
        this.setState({
            listChecked: listChecked
        },() => {
            console.log('list checked',this.state.listChecked);
        })
    }
    handleCheckAll = () => {
        this.setState({
            checkAll: !this.state.checkAll,
        }, () => {
            let data = [];
            if(this.state.checkAll){
                this.state.listImage.forEach(value => {
                    data.push({track_id: value.track, file_name: value.file_name});
                });
            }
            this.setState({
                listChecked: data,
            })
        })
    }

    render() {
        console.log('datamove',this.state.datamove);
        console.log('crrImage',this.state.crrImages);
        let bulletedDatamol = this.state.crrImages.map((e, i) => {
            var img
            return (
                <div className="col-md-3 col-sm-6 mb-4 pr-2 pl-2" key={i}>
                    {
                        e.status === "imgFace"
                            ?
                            <div className="card border-0 shadow ">
                                <label className="m-checkbox img-checkbox-move m-checkbox-day pl-0 mb-0">
                                    <input type="checkbox" defaultValue={e.file_name} name="img_checked" checked={this.state.listChecked.findIndex(x => x.file_name === e.file_name) === -1 ? false : true} onClick={event => this.handleCheckedImg(event, e.track)} readOnly />
                                    <img src={
                                        e.imgFace_Original_Size.indexOf('http') >= 0 ?
                                        e.imgFace_Original_Size :
                                        `${this.state.box_engine_cf.api2_url}/view${e.imgFace_Original_Size}`
                                    } className="card-img-top" alt="xx" height={150} />
                                    <span />
                                </label>
                                <i className="fa fa-id-card-alt iconMoveHisstory" data-tip data-for='ImageTrain'></i>
                                <ReactTooltip id='ImageTrain' type='dark' effect='solid'>
                                    <span>Đây là hình ảnh mẫu</span>
                                </ReactTooltip>
                            </div>
                            :
                            <div className="card border-0 shadow ">
                                <label className="m-checkbox img-checkbox-move m-checkbox-day pl-0 mb-0">
                                    <input type="checkbox" defaultValue={e.file_name} name="img_checked" checked={this.state.listChecked.findIndex(x => x.file_name === e.file_name) === -1 ? false : true} onClick={event => this.handleCheckedImg(event, e.track)} readOnly />
                                    <img src={
                                        e.imgFace_Original_Size.indexOf('http') >= 0 ?
                                        e.imgFace_Original_Size :
                                        `${this.state.box_engine_cf.api2_url}/view${e.imgFace_Original_Size}`
                                    } className="card-img-top" alt="xx" height={150} />
                                    <span />
                                </label>
                            </div>
                    }
                </div>
            )
        });
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Ảnh
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center mb-3'>
                        <input id='checkall' type="checkbox" checked={this.state.checkAll} onClick={() => this.handleCheckAll()} />
                        <label htmlFor='checkall' className="ml-2 my-0">{!this.state.checkAll ? 'Chọn tất cả' : 'Bỏ chọn tất cả'}</label>
                    </div>
                    <div className="row">
                        {bulletedDatamol}
                    </div>
                    <div className="row mt-2">
                        <Pagination
                            prevPageText='Trang sau'
                            nextPageText='Trang trước'
                            firstPageText='Trang đầu'
                            lastPageText='Trang cuối'
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.itemsPerPage}
                            totalItemsCount={this.state.listImage.length}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                        />
                    </div>
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className="select_option_type">
                                <Autocomplete
                                    options={select_option}
                                    getOptionLabel={option => option.title}
                                    value={this.state.crr_comp}
                                    onChange={this.handleChangeOption}
                                    disableClearable
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder='Chọn nhóm...'
                                            fullWidth
                                        />
                                    )}
                                />
                            </div>
                            <div className="select_option_person">
                            <Autocomplete
                                    options={this.state.optionPerson}
                                    getOptionLabel={option => option.title}
                                    value={this.state.valuePerson}
                                    onChange={this.handleChangePerson}
                                    disableClearable
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder='Chọn tên...'
                                            fullWidth
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="">
                            <button className="btn btn-accent m-loader--light m-loader--right" id="button_move" onClick={() => this.MoveImage()} disabled={(this.state.crr_comp && this.state.valuePerson) ? false : true} >Chuyển Ảnh Thành Mẫu</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}


ModalMoveImg.propTypes = {
    datamove: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default React.memo(ModalMoveImg);