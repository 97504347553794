import React, { Component } from 'react';
import Store from '../../store';
import { withRouter } from 'react-router';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            setTheme: Store.getState().theme.setTheme
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin,
                setTheme: Store.getState().theme.setTheme
            })
        });
    }
    render() {
        var dislable_footer = "";
        if (this.props.location.pathname === "/reva") {
            dislable_footer = 'd-none';
        }

        var classFooter;
        if (this.state.setTheme === 'Dark') {
            classFooter = 'themeFooterDark';
        } else {
            classFooter = '';
        }

        return (
            <footer className={classFooter + " m-grid__ite m-footer " + dislable_footer}>
                <div className="m-container m-container--fluid m-container--full-height m-page__container">
                    <div className="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
                        <div className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
                            <span className="m-footer__copyright">
                            2019 © BeetInnovators
                            </span>
                        </div>
                        <div className="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
                            <ul className="m-footer__nav m-nav m-nav--inline m--pull-right">
                                <li className="m-nav__item">
                                    <a href="http://reva.com.vn" target="blank" className="m-nav__link">
                                        <span className="m-nav__link-text">Giới thiệu</span>
                                    </a>
                                </li>
                                <li className="m-nav__item">
                                    <a href="http://reva.com.vn#contact" target="blank" className="m-nav__link">
                                        <span className="m-nav__link-text">Trung tâm hỗ trợ</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default withRouter(Footer);