import { css } from '@emotion/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import $ from 'jquery';
import moment from 'moment';
import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import { Redirect } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import swal from 'sweetalert';
import { LIST_PERSON } from '../../../config';
import Store from '../../../store';
import ModalUser from '../../modal/ModalUser';
import ModalShift from '../../modal/ModalShift';
import Files from 'react-files';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { make_cols } from '../../utils/MakeColumms';
import XLSX from 'xlsx';
import swals from '@sweetalert/with-react';
import FadeLoader from 'react-spinners/FadeLoader';
import LoadingOverlay from 'react-loading-overlay';
import ReactTooltip from 'react-tooltip';
import ModalUpdatePerson from '../../modal/ModalUpdatePerson';
import Select from 'react-select'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
let select_option = [
    { title: 'Tất cả', value: -1 }
];

if (LIST_PERSON.indexOf('1') !== -1) {
    select_option.push({ title: 'Nhân viên', value: 1 })
}
if (LIST_PERSON.indexOf('2') !== -1) {
    select_option.push({ title: 'Vip', value: 2 })
}
if (LIST_PERSON.indexOf('3') !== -1) {
    select_option.push({ title: 'Danh sách đen', value: 3 })
}
// if (LIST_PERSON.indexOf('4') !== -1) {
//     select_option.push({ title: 'Người lạ', value: 4 })
// }
if (LIST_PERSON.indexOf('5') !== -1) {
    select_option.push({ title: 'Khách hàng', value: 5 })
}

class History extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            loading: true,
            listUser: [],
            crr_comp: select_option[0],
            modalShow: false,
            deparment: '',
            search_data: '',
            page_num: 1,
            page_size: 10,
            total_count: 0,
            showUpdatePerson: false,
            showUpdateShift: false,
            crrDataInfo: {
                '_id': "",
                'id': '',
                'code': '',
                'name': '',
                'phone': '',
                'age': '',
                'gender': '',
                'department': '',
                'type': '',
                'type': '',
                'email': '',
                'notify': '',
                'company_id': '',
            },
            offset: 0,
            data: [],
            activeUploadExcel: false,
            shifts: [],
            dataShiftEdit: [],
            loading_api_excel: false,
            listUserExport: [],
        };
        this.typeModal = 2;
        var itemsPerPage = 10;
        this.itemsPerPage = itemsPerPage;
        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    ...this.state,
                    loading: true,
                    listUser: [],
                    isLogin: Store.getState().isLogin.isLogin,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    modalShow: false,
                    activeUploadExcel: false,
                }, () => {
                    this.getPerson();
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    listUser: [],
                    isLogin: Store.getState().isLogin.isLogin,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    modalShow: false
                });
            }
        });
        this.getPerson();
    }

    getPerson() {
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        fetch(`${this.state.box_engine_cf.api2_url}/api/person/search`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: this.state.crr_comp.value,
                id_company: `${this.state.box_engine_cf.idCompany}`,
                index: this.state.page_num,
                item_per_page: this.state.page_size,
                department: this.state.deparment,
                search_data: this.state.search_data
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                var data = rs.data;
                const offset = (this.state.page_num - 1) * this.state.page_size;
                this.setState({
                    offset: offset,
                    listUser: data,
                    loading: false,
                    total_count: rs.count,
                });
            }
            else {
                this.setState({
                    listUser: [],
                    loading: false,
                    total_count: 0,
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    listUser: [],
                    loading: false,
                    total_count: 0,
                });
            }
        })
    }

    _exporter;
    export = () => {
        this._exporter.save();
    }

    getPersonExport() {
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        this.setState({
            loading_api_excel: true
        })
        console.log({
            type: this.state.crr_comp.value,
            id_company: `${this.state.box_engine_cf.idCompany}`,
            index: -1,
            item_per_page: 10000,
            department: this.state.deparment,
            search_data: this.state.search_data
        });
        fetch(`${this.state.box_engine_cf.api2_url}/api/person/search`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: this.state.crr_comp.value,
                id_company: `${this.state.box_engine_cf.idCompany}`,
                index: -1,
                item_per_page: 10000,
                department: this.state.deparment,
                search_data: this.state.search_data
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                var data = rs.data;
                for (var i = 0; i < data.length; i++) {
                    data[i].gender == 1 ? data[i].gender = "Nam" : data[i].gender = "Nữ";
                    switch (data[i].type) {
                        case 1: data[i].type = "Nhân viên"
                            break;
                        case 2: data[i].type = "VIP"
                            break;
                        case 3: data[i].type = "Danh sách đen"
                            break;
                        case 4: data[i].type = "Người lạ"
                            break;
                        case 5: data[i].type = "Khách hàng"
                            break;
                        default:
                            break;
                    }
                }
                this.setState({
                    listUserExport: data,
                    loading_api_excel: false
                }, () => {
                    this._exporter.save();
                });
            }
            else {
                this.setState({
                    listUserExport: [],
                    loading_api_excel: false
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    listUserExport: [],
                    loading_api_excel: false
                });
            }
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            page_num: pageNumber,
        }, () => {
            this.getPerson();
        })
    }

    handlePageSizeChange = (pageSize) => {
        this.setState({
            ...this.state,
            page_size: pageSize.value,
        }, () => {
            this.getPerson();
        })
    }


    closeModal = (crrDataInfo, old_id, old_type) => {
        this.setState({
            showUpdatePerson: false,
            showUpdateShift: false,
            crrDataInfo: null
        }, () => {
            this.getPerson();
        })
    }

    onFilesError = (error, file) => {
        alert('error code ' + error.code + ': ' + error.message)
    }

    splitDate = (date) => {
        if (date.length > 0) {
            var splitDate = date.split("-");
            var a = splitDate[2];
            splitDate[2] = splitDate[0];
            splitDate[0] = a;
            return splitDate.join("-")
        }
    }

    importExcel = async (data) => {
        if (this.state.box_engine_cf === undefined) return;
        let dataImport = await fetch(`${this.state.box_engine_cf.api2_url}/api/person/setShifts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token,
            },
            body: JSON.stringify({
                "shiftsOfPersonList": data
            })
        }).then((response) => {
            return response.json()
        });

        if (dataImport.status === 10000) {
            this.setState({
                activeUploadExcel: false,
            })

            if (data.length === 0) {
                this.setState({
                    activeUploadExcel: false,
                })
                swal("Cảnh báo", "Vui lòng kiểm tra tập tin", "warning");
            } else {
                swal("Thành công", "Nhập ca thành công", "success", {
                    button: "Đóng",
                });
            }
            this.getPerson();
        } else if (dataImport.status === 10002) {
            swals({
                title: "Cảnh báo",
                content: <div>
                    {(dataImport.person_errors.length > 0 &&
                        <div>
                            Lỗi mã nhân viên: {dataImport.person_errors.map((err, i) => {
                                let id = err.personId !== "" ? err.personId : "(trống)";
                                return <span>{(i ? ', ' : '') + id}</span>
                            })}
                        </div>
                    )}
                    {(dataImport.door_errors.length > 0 &&
                        <div>
                            Lỗi mã cửa : {dataImport.door_errors.map((err, i) => {
                                let id = err.personId !== "" ? err.personId : "(trống)";
                                return <span>{(i ? ', ' : '') + id}</span>
                            })}
                        </div>
                    )}
                    {(dataImport.datetime_errors.length > 0 &&
                        <div>
                            Lỗi ca : {dataImport.datetime_errors.map((err, i) => {
                                let id = err.personId !== "" ? err.personId : "(trống)";
                                return <span>{(i ? ', ' : '') + id}</span>
                            })}
                        </div>
                    )}
                </div>,
                icon: "warning",
            });
            this.setState({
                activeUploadExcel: false,
            })
        } else {
            this.setState({
                activeUploadExcel: false,
            })
            swal("Lỗi", "Nhập ca thất bại", "error");
        }
    }

    onFilesChange = (files) => {
        // const files = e.target.files;
        // if (files && files[0]) this.setState({ file: files[0] });
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname_sheet_1 = wb.SheetNames[1];
            const ws_sheet_1 = wb.Sheets[wsname_sheet_1];
            /* Convert array of arrays */
            const data_sheet_1 = XLSX.utils.sheet_to_json(ws_sheet_1, {
                raw: false,
            });

            const wsname_sheet_2 = wb.SheetNames[0];
            const ws_sheet_2 = wb.Sheets[wsname_sheet_2];
            /* Convert array of arrays */
            const data_sheet_2 = XLSX.utils.sheet_to_json(ws_sheet_2, {
                raw: false,
            });



            var shift_dict = {}
            for (let i = 0; i < data_sheet_1.length; i++) {
                const element = data_sheet_1[i];
                shift_dict[element['Tên ca']] = element
            }
            var arr_shift_err1 = []
            var arr_shift_err2 = []

            for (let i = 0; i < data_sheet_2.length; i++) {
                const element = data_sheet_2[i];
                var shifts_temp = []
                var shifts = element['Ca 1']
                var shifts2 = element['Ca 2']
                var start_date_shift1 = element['Ngày bắt đầu 1']
                var start_date_shift2 = element['Ngày bắt đầu 2']
                var end_date_shift1 = element['Ngày kết thúc 1']
                var end_date_shift2 = element['Ngày kết thúc 2']
                var location_shift1 = element['Vị trí 1']
                var location_shift2 = element['Vị trí 2']
                if (shifts !== undefined) {
                    let shift_list = shifts.split(",")
                    for (let j = 0; j < shift_list.length; j++) {
                        const e = shift_list[j];
                        let shift = e.trim()
                        if (shift_dict[shift] === undefined) {
                            arr_shift_err1.push(shift)
                        } else {
                            let shiftPush = {}
                            shiftPush.name = shift_dict[shift]['Tên ca']
                            shiftPush.startTime = shift_dict[shift]['Giờ vào']
                            shiftPush.endTime = shift_dict[shift]['Giờ ra']
                            shiftPush.isThroughDayShift = shift_dict[shift]['Loại']
                            shiftPush.dayOfWeek = shift_dict[shift]['Thứ trong tuần']
                            shiftPush.doorId = location_shift1
                            shiftPush.startDate = start_date_shift1
                            shiftPush.endDate = end_date_shift1
                            shifts_temp.push(shiftPush)
                        }
                    }
                }
                if (shifts2 !== undefined) {
                    let shift_list = shifts2.split(",")
                    for (let j = 0; j < shift_list.length; j++) {
                        const e = shift_list[j];
                        let shift = e.trim()
                        if (shift_dict[shift] === undefined) {
                            arr_shift_err2.push(shift)
                        } else {
                            let shiftPush = {}
                            shiftPush.name = shift_dict[shift]['Tên ca']
                            shiftPush.startTime = shift_dict[shift]['Giờ vào']
                            shiftPush.endTime = shift_dict[shift]['Giờ ra']
                            shiftPush.isThroughDayShift = shift_dict[shift]['Loại']
                            shiftPush.dayOfWeek = shift_dict[shift]['Thứ trong tuần']
                            shiftPush.doorId = location_shift2
                            shiftPush.startDate = start_date_shift2
                            shiftPush.endDate = end_date_shift2

                            shifts_temp.push(shiftPush)
                        }
                    }
                }
                element['shifts'] = shifts_temp
            }
            var totalShiftErr = arr_shift_err1.concat(arr_shift_err2)

            if (totalShiftErr.length > 0) {
                swal("Lỗi", "Ca " + totalShiftErr + " không tồn tại", "error");
                return
            }

            this.setState({
                data: data_sheet_2,
                // cols: make_cols(ws['!ref'])
            }, () => {
                var dataExport = this.state.data;
                var dataExportConvert = [];
                let indexErrId = [];
                let indexErrNameShift = [];
                let indexErrNameShiftShift = [];
                let validStarDate = [];
                let validEndDate = [];
                let validStartTime = [];
                let validEndTime = [];
                let validCamID = [];
                let validStartTimeEmpty = [];
                let validEndTimeEmpty = [];
                let validEndDateEmpty = [];
                let validStartDateEmpty = [];
                let validDayWeekEmpty = [];
                let validEndDateLess = [];
                let validStartDateGreater = [];
                let validEmptyDoor = [];

                for (let index = 0; index < dataExport.length; index++) {
                    const e = dataExport[index]
                    //id
                    var personId = ""
                    if (e["ID"] !== undefined) {
                        personId = e["ID"].toString()
                    } else {
                        indexErrId.push(index + 2)
                    }

                    var shifts = []

                    if (e["shifts"].length > 0 || e["shifts"] !== undefined) {
                        for (let i = 0; i < e["shifts"].length; i++) {
                            const element = e["shifts"][i];
                            //name shift
                            var name = ""
                            if (element !== undefined && element["name"] !== undefined) {
                                name = element["name"].toString()
                            } else {
                                return swal("Cảnh báo", "Vui lòng không để trống tên ca trong thông tin ca", "warning");
                                // indexErrNameShiftShift.push(index + 2)
                            }

                            //start date 
                            var startDate = null
                            if (element["startDate"] !== undefined) {
                                var checkDate1 = moment(element["startDate"], 'DD-MM-YYYY', true).isValid();
                                if (!checkDate1) {
                                    validStarDate.push(index + 2)
                                }
                                startDate = this.splitDate(element["startDate"]);
                            } else {
                                validStartDateEmpty.push(index + 2)
                            }

                            //end date 
                            var endDate = null
                            if (element["endDate"] !== undefined) {
                                var checkDate1 = moment(element["endDate"], 'DD-MM-YYYY', true).isValid();
                                if (!checkDate1) {
                                    validEndDate.push(index + 2)
                                }
                                endDate = this.splitDate(element["endDate"]);
                            } else {
                                validEndDateEmpty.push(index + 2)
                            }

                            //start time 
                            var startTime = null
                            if (element["startTime"] !== undefined) {
                                var checkDate1 = moment(element["startTime"], 'HH:mm', true).isValid();
                                if (!checkDate1) {
                                    validStartTime.push(index + 2)
                                }
                                startTime = element["startTime"] + ":00";
                            } else {
                                validStartTimeEmpty.push(index + 2)
                            }

                            //end time 
                            var endTime = null
                            if (element["endTime"] !== undefined) {
                                var checkDate1 = moment(element["endTime"], 'HH:mm', true).isValid();
                                if (!checkDate1) {
                                    validEndTime.push(index + 2)
                                }
                                endTime = element["endTime"] + ":00";
                            } else {
                                validEndTimeEmpty.push(index + 2)
                            }


                            //idCam
                            var doorId = null
                            if (element["doorId"] !== undefined) {
                                doorId = element["doorId"];
                            } else {
                                validEmptyDoor.push(index + 2)
                                doorId = null
                            }

                            //idCam
                            var isThroughDayShift = null
                            if (element["isThroughDayShift"] !== undefined) {
                                if (element["isThroughDayShift"] === "Ngày") {
                                    isThroughDayShift = 0
                                } else if (element["isThroughDayShift"] === "Xuyên ngày") {
                                    isThroughDayShift = 1
                                } else {
                                    isThroughDayShift = element["isThroughDayShift"];
                                }
                            } else {
                                doorId = null
                            }

                            //dayOfWeek
                            var dayOfWeek = []
                            if (element["dayOfWeek"] !== undefined) {
                                dayOfWeek = element["dayOfWeek"].split(',').map(function (item) {
                                    return parseInt(item, 10);
                                });
                            } else {
                                validDayWeekEmpty.push(index + 2)
                            }

                            shifts.push({
                                name: name,
                                startDate: startDate,
                                endDate: endDate,
                                startTime: startTime,
                                endTime: endTime,
                                doorId: doorId,
                                dayOfWeek: dayOfWeek,
                                isThroughDayShift: isThroughDayShift
                            })
                        }

                    }

                    dataExportConvert.push({
                        "personId": personId,
                        "shifts": shifts,
                    })

                }
                if (indexErrNameShiftShift.length > 0 || indexErrId.length > 0 || indexErrNameShift.length > 0 || validEndTime.length > 0 || validStartTime.length > 0 || validEndDate.length > 0
                    || validStarDate.length > 0 || validEndTimeEmpty.length > 0 || validStartTimeEmpty.length > 0 || validEndDateEmpty.length > 0 || validEmptyDoor.length > 0
                    || validStartDateEmpty.length > 0 || validDayWeekEmpty.length > 0 || validCamID.length > 0 || validStartDateGreater.length > 0 || validEndDateLess.length > 0) {
                    swals({
                        title: "Cảnh báo",
                        content: <div>
                            <div>Vui lòng kiểm tra tệp nhập</div>
                            {(indexErrNameShiftShift.length > 0 &&
                                <div>
                                    Tên ca trống (Dòng {indexErrNameShiftShift.map((err, i) => {
                                        return <span>{(i ? ', ' : '') + err}</span>
                                    })})
                                </div>
                            )}
                            {(indexErrId.length > 0 &&
                                <div>
                                    ID Trống (Dòng {indexErrId.map((err, i) => {
                                        return <span>{(i ? ', ' : '') + err}</span>
                                    })})
                                </div>
                            )}
                            {(indexErrNameShift.length > 0 &&
                                <div>Tên ca trống (Dòng {indexErrNameShift.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validEmptyDoor.length > 0 &&
                                <div>Vị trí trống (Dòng {validEmptyDoor.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validEndTime.length > 0 &&
                                <div>Giờ ra ca sai định dạng (hh:mm) (Dòng {validEndTime.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validEndDateLess.length > 0 &&
                                <div>Ngày kết thúc lớn hơn ngày bắt đầu (Dòng {validEndDateLess.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validStartDateGreater.length > 0 &&
                                <div>Ngày bắt đầu phải nhỏ hơn ngày kết thúc (Dòng {validStartDateGreater.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validEndTimeEmpty.length > 0 &&
                                <div>Giờ ra ca đang trống (Dòng {validEndTimeEmpty.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validStartTime.length > 0 &&
                                <div>Giờ vào ca sai định dạng (hh:mm) (Dòng {validStartTime.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validStartTimeEmpty.length > 0 &&
                                <div>Giờ vào ca đang trống (Dòng {validStartTimeEmpty.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validEndDate.length > 0 &&
                                <div>Ngày kết thúc sai định dạng (DD-MM-YYYY) (Dòng {validEndDate.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validEndDateEmpty.length > 0 &&
                                <div>Ngày kết thúc ca đang trống (Dòng {validEndDateEmpty.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validStarDate.length > 0 &&
                                <div>Ngày bắt đầu sai định dạng (DD-MM-YYYY) (Dòng {validStarDate.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validStartDateEmpty.length > 0 &&
                                <div>Ngày bắt đầu ca đang trống (Dòng {validStartDateEmpty.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}
                            {(validDayWeekEmpty.length > 0 &&
                                <div>Thứ trong tuần của ca đang trống (Dòng {validDayWeekEmpty.map((err, i) => {
                                    return <span>{(i ? ', ' : '') + err}</span>
                                })})
                                </div>
                            )}

                        </div>,
                        icon: "warning",
                    });
                    return;
                }


                this.setState({
                    activeUploadExcel: true,
                }, () => {
                    this.importExcel(dataExportConvert)
                })
            });
        };

        if (rABS) {
            reader.readAsBinaryString(files[0]);
        } else {
            reader.readAsArrayBuffer(files[0]);
        };
    }


    modalClose = () => {
        this.setState({
            modalShow: false,
        }, () => {
            this.getPerson();
        });
    }


    handleChangeOption = (index, option) => {
        this.setState({
            crr_comp: option,
            loading: true,
            listUser: [],
            page_num: 1,
        }, () => {
            this.getPerson()
        });
    }

    handleEdit = (value, shifts) => {
        var crrDataInfo = {
            age: value.age,
            department: value.department,
            gender: value.gender,
            id: value.id,
            code: value.code,
            id_company: value.id_company,
            type: value.type,
            name: value.name,
            phone: value.phone,
            type: value.type,
            _id: value._id,
            company_id: value.company_id
        }
        this.setState({
            crrDataInfo: crrDataInfo,
            shifts: shifts,
            showUpdatePerson: true,
        });
    }

    handleShift = (value, shifts, dataShift) => {
        var crrDataInfo = {
            id: value.id,
        }
        this.setState({
            crrDataInfo: crrDataInfo,
            shifts: shifts,
            showUpdateShift: true,
            dataShiftEdit: dataShift
        });
    }

    downloadFormImport = (type) => {
        var url = window.location.href;
        var urlImport = url.replace(this.props.location.pathname, '/')
        console.log(urlImport)
        window.location.href = urlImport + "Template/Import_shifts.xlsx"
    }


    handleDelete = (infoUser) => {
        swal({
            text: "Bạn có chắc chắn muốn xoá " + infoUser.name,
            icon: "warning",
            button: {
                text: "Xoá",
                closeModal: false,
            },
        }).then((oke => {
            if (!oke) throw null;
            if (this.state.box_engine_cf === undefined) return;
            fetch(`${this.state.box_engine_cf.api}/api/person/inactive`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    _id: infoUser._id,
                    type: infoUser.type,
                    id: infoUser.id,
                })
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    swal("Thành công!", "Xóa thành công", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    this.getPerson();
                }
                else {
                    swal("Lỗi!", "Xóa thất bại", "error", {
                        buttons: false,
                        timer: 1500,
                    });
                }
            })

        }))


    }

    render() {
        // console.log('crrDataInfo', this.state.crrDataInfo);
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        let bulletedUser = this.state.listUser.map((value, index) => {
            var gender = ""
            if (value.gender == 1) {
                gender = "Nam"
            } else if (value.gender == 2) {
                gender = "Nữ"
            } else if (value.gender == 2) {
                gender = "Giới tính khác"
            } else {
                gender = ""
            }

            var group = ""
            if (value.type == 1) {
                group = "Nhân viên"
            } else if (value.type == 2) {
                group = "Vip"
            } else if (value.type == 3) {
                group = "Danh sách đen"
            } else if (value.type == 4) {
                group = "Người lạ"
            } else {
                group = "Khách hàng"
            }


            var shift = []
            var dataEditShift = []
            if (value.shifts && value.shifts.length > 0) {
                value.shifts.map((valueShift, indexShift) => {
                    shift.push(
                        <div key={indexShift}>
                            <div>{valueShift.name} ({valueShift.start_time.slice(0, -3)} - {valueShift.end_time.slice(0, -3)}), {moment(valueShift.start_date).format("DD/MM/YYYY")}-{moment(valueShift.end_date).format("DD/MM/YYYY")}{valueShift.door_id ? ", " : ""}{valueShift.door_id}</div>
                        </div>
                    )
                    dataEditShift.push({
                        dayOfWeek: valueShift.day_of_week,
                        doorId: valueShift.door_id,
                        endDate: valueShift.end_date,
                        endTime: valueShift.end_time,
                        isThroughDayShift: valueShift,
                        name: valueShift.name,
                        startDate: valueShift.start_date,
                        startTime: valueShift.start_time,
                        isThroughDayShift: valueShift.is_through_day_shift,

                    })
                })
            }



            return (
                <tr key={index}>
                    <td>{(index + this.state.offset + 1)}</td>
                    <td>{value.code}</td>
                    <td>{value.name}</td>
                    <td>{value.age}</td>
                    <td>{gender}</td>
                    <td>{value.phone}</td>
                    <td>{value.department}</td>
                    {/* <td>{shift}</td> */}
                    <td>{group}</td>
                    <td>
                        <button
                            className="m-portlet__nav-link btn m-btn m-btn--hover-info m-btn--icon m-btn--icon-only m-btn--pill"
                            onClick={() => this.handleEdit(value, value.shifts)}
                            data-tip data-for='edit-staff'
                        >
                            <ReactTooltip id='edit-staff' type='dark' effect='solid'>Xem thông tin nhân viên</ReactTooltip>
                            <i className="la la-eye" />
                        </button>
                        {/* <button
                            className="m-portlet__nav-link btn m-btn m-btn--hover-info m-btn--icon m-btn--icon-only m-btn--pill"
                            onClick={() => this.handleShift(value, value.shifts, dataEditShift)}
                        >
                            <i className="flaticon-list-3" />
                        </button> */}
                        <button
                            className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                            onClick={() => this.handleDelete(value)}
                            data-tip data-for='delete-staff'
                        >
                            <ReactTooltip id='delete-staff' type='dark' effect='solid'>
                                Xóa nhân viên
                            </ReactTooltip>
                            <i className="la la-trash" />
                        </button>
                    </td>
                </tr>
            )


        });

        var pageRangeDisplayed = $(window).width() < 768 ? 3 : 5;
        var fromItem = (this.state.page_num - 1) * this.state.page_size + 1;
        var toItem = fromItem + this.state.page_size - 1;
        toItem = toItem >= this.state.total_count ? this.state.total_count : toItem;
        return (
            <LoadingOverlay
                active={this.state.activeUploadExcel}
                spinner={<FadeLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={'rgb(54, 215, 183)'}
                    loading={true}
                />}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(0, 0, 0, 0.58)'
                    })
                }}
                className="col-xl-12 p-0"
            >
                <div className="m-grid__item m-grid__item--fluid m-wrapper">
                    <div className="m-content mt-3-phone pd_phone_0 p-3">
                        <div className="m-portlet m-portlet--tab mb-0" id="form_reva">
                            <div className="m-portlet__head p-3">
                                <div style={{ justifyContent: 'space-between' }} className="m-portlet__head-caption col-xs-12 col-md-12 col-lg-12 col-xl-12 ">
                                    <div style={{ display: 'flex' }}>
                                        <div className="col-md-3 col-xs-6 pr-0 pl-0 select_camera">
                                            <Autocomplete
                                                options={select_option}
                                                getOptionLabel={option => option.title}
                                                value={this.state.crr_comp}
                                                onChange={this.handleChangeOption}
                                                disableClearable
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select Type"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="col-md-3">
                                            <input min="0" className="form-control m-input" placeholder="Nhập tên bộ phận" name="deparment" value={this.state.deparment} onKeyDown={this.onEnterPress} onChange={(event) => this.setState({ deparment: event.target.value })} />
                                        </div>
                                        <div className="col-md-4 pl-0">
                                            <input min="0" className="form-control m-input" placeholder="Nhập Mã/Tên nhân viên" name="search_data" value={this.state.search_data} onKeyDown={this.onEnterPress} onChange={(event) => this.setState({ search_data: event.target.value })} />
                                        </div>

                                        <div className="mb-mobile-10">
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        page_num: 1,
                                                        loading: true,
                                                        listUser: [],
                                                    }, () => {
                                                        this.getPerson();
                                                    })
                                                }}
                                                className="btn btn-accent m-btn m-btn--icon">
                                                <span>
                                                    <i className="la la-search" />
                                                    <span>Tìm kiếm</span>
                                                </span>
                                            </button>
                                        </div>
                                        <div className="ml-2 mb-mobile-10">
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        crr_comp: { title: "Tất cả", value: -1 },
                                                        deparment: "",
                                                        search_data: "",
                                                        page_num: 1,
                                                        loading: true,
                                                        listUser: [],
                                                    }, () => {
                                                        this.getPerson()
                                                    })
                                                }}
                                                className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                                <span>
                                                    <i className="la la-rotate-left" />
                                                    <span>Xóa bộ lọc</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="ml-2 mb-mobile-10">
                                        <button className="btn btn-primary m-btn m-btn--icon"
                                            onClick={() => this.getPersonExport()}
                                        >
                                            {
                                                this.state.loading_api_excel ?
                                                    <PulseLoader
                                                        css={override}
                                                        sizeUnit={"px"}
                                                        size={12}
                                                        margin={'2px'}
                                                        color={'#36D7B7'}
                                                        loading={this.state.loading_api_excel}
                                                    />
                                                    :
                                                    <span>
                                                        <i className="la la-download" />
                                                        <span>Xuất Excel</span>
                                                    </span>
                                            }
                                        </button>
                                    </div>
                                    {/* <div>
                                        <div className="m-portlet__head-tools text-right">
                                            <ul className="m-portlet__nav">
                                                <li className="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover">
                                                    <a style={{color: '#000'}} className="m-portlet__nav-link m-dropdown__toggle dropdown-toggle btn btn--sm m-btn--pill btn-secondary m-btn m-btn--label-brand">
                                                        Nhập ca
                                                    </a>
                                                    <div className="m-dropdown__wrapper">
                                                        <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" />
                                                        <div className="m-dropdown__inner">
                                                            <div className="m-dropdown__body">
                                                                <div className="m-dropdown__content">
                                                                    <ul className="m-nav">
                                                                        <li className="m-nav__section m-nav__section--first">
                                                                            <span className="m-nav__section-text pb-2">* Vui lòng tải về và sử dụng tập tin mẫu để tải tập tin lên.</span>
                                                                        </li>
                                                                        <Files
                                                                            className='files-dropzone'
                                                                            style={{cursor: 'pointer'}}
                                                                            onChange={this.onFilesChange}
                                                                            onError={this.onFilesError}
                                                                            accepts={[".xlsx"]}
                                                                            multiple={false}
                                                                            // maxFiles={3}
                                                                            minFileSize={0}
                                                                            clickable
                                                                        >
                                                                            <li className="m-nav__item">
                                                                                <a className="m-nav__link">
                                                                                    <i className="m-nav__link-icon la la-cloud-upload" />
                                                                                    <span className="m-nav__link-text">
                                                                                        Nhập ca
                                                                                    </span>
                                                                                </a>
                                                                            </li>
                                                                        </Files>
                                                                        <li className="m-nav__item">
                                                                            <a className="m-nav__link" style={{cursor: 'pointer'}}
                                                                            onClick={this.downloadFormImport}
                                                                            >
                                                                                <i className="m-nav__link-icon la la la-cloud-download" />
                                                                                <span className="m-nav__link-text">
                                                                                    Tải tệp mẫu
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}




                                </div>


                            </div>
                            {/*begin::Form*/}
                            <div className="m-form m-form--fit m-form--label-align-right">
                                <div className="m-portlet__body pt-3">
                                    <div className="col-md-12">
                                        <div className='row-sm row pl-3 pr-3 pb-3'>
                                            <table className="table table-bordered table-hover dataTable mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '50px' }}>STT</th>
                                                        <th >ID</th>
                                                        <th >Tên</th>
                                                        <th >Tuổi</th>
                                                        <th >Giới tính</th>
                                                        <th >Số điện thoại</th>
                                                        <th >Phòng ban</th>
                                                        {/* <th >Ca</th> */}
                                                        <th >Nhóm</th>
                                                        <th >Thao tác</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bulletedUser}
                                                </tbody>
                                            </table>
                                            <PulseLoader
                                                css={override}
                                                sizeUnit={"px"}
                                                size={12}
                                                margin={'2px'}
                                                color={'#36D7B7'}
                                                loading={this.state.loading}
                                            />
                                        </div>

                                        <div className="pl-2 pr-3 row" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="col-md-6" style={{ paddingRight: 10, fontSize: 13 }}>
                                                Hiển thị <span style={{ fontWeight: 600 }}>{fromItem}-{toItem}</span> trong tổng số <span style={{ fontWeight: 600 }}>{this.state.total_count}</span> kết quả
                                            </div>
                                            <div className="col-md-5">
                                                <div className="pull-right" style={{ marginTop: 5 }}>
                                                    <Pagination
                                                        prevPageText={<i className='la la-angle-left'></i>}
                                                        nextPageText={<i className='la la-angle-right'></i>}
                                                        firstPageText={<i className='la la-angle-double-left'></i>}
                                                        lastPageText={<i className='la la-angle-double-right'></i>}
                                                        activePage={this.state.page_num}
                                                        itemsCountPerPage={this.state.page_size}
                                                        totalItemsCount={this.state.total_count}
                                                        pageRangeDisplayed={pageRangeDisplayed}
                                                        onChange={this.handlePageChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <Select
                                                    options={[
                                                        { value: 10, label: '10/trang' },
                                                        { value: 20, label: '20/trang' },
                                                        { value: 50, label: '50/trang' },
                                                        { value: 100, label: '100/trang' }
                                                    ]}
                                                    defaultValue={{ value: 10, label: "10/trang" }}
                                                    menuPlacement="auto"
                                                    components={{ DropdownIndicator: () => null }}
                                                    onChange={this.handlePageSizeChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*end::Form*/}
                        </div>

                    </div>
                    {/* <ModalUser
                        show={this.state.showUpdatePerson}
                        onHide={this.closeModal}
                        data={this.state.crrDataInfo}
                        box_engine_cf={this.state.box_engine_cf}
                        shifts={this.state.shifts}
                    /> */}
                    <ModalUpdatePerson
                        show={this.state.showUpdatePerson}
                        onHide={this.closeModal}
                        onSplit={this.modalClose}
                        onTrack={this.modalCloseChangeTrack}
                        crrdatainfo={this.state.crrDataInfo}
                        box_engine_cf={this.state.box_engine_cf}
                        onReload={this.reloadPerson}
                        typeModal={this.typeModal}
                    />
                    <ModalShift
                        show={this.state.showUpdateShift}
                        onHide={this.closeModal}
                        data={this.state.crrDataInfo}
                        box_engine_cf={this.state.box_engine_cf}
                        dataShiftEdit={this.state.dataShiftEdit}
                    />
                    <ExcelExport
                        data={this.state.listUserExport}
                        ref={(exporter) => { this._exporter = exporter; }}
                        fileName={`Danh-Sach-Nhan-Vien.xlsx`}
                    >
                        <ExcelExportColumn field="code" title="ID" width={80} />
                        <ExcelExportColumn field="name" title="Họ và Tên" width={180} />
                        <ExcelExportColumn field="age" title="Tuổi" width={150} />
                        <ExcelExportColumn field="gender" title="Giới tính" width={130} />
                        <ExcelExportColumn field="phone" title="Số điện thoại" width={160} />
                        <ExcelExportColumn field="department" title="Phòng ban" width={130} />
                        <ExcelExportColumn field="type" title="Nhóm" width={130} />
                    </ExcelExport>
                </div>
            </LoadingOverlay>
        );
    }
}

export default History;