import React, { Component } from 'react';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import Store from '../../../store';
import $ from 'jquery';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { Row, Col, Image } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoComplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

let optionType = [
    {value: '1', label: 'Ảnh Nghiêng'},
    {value: '2', label: 'Ảnh khẩu trang'},
    {value: '3', label: 'Ảnh kích thước nhỏ'},
]
class ListTrash extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            loading: true,
            pageNumber: 1,
            total: 0,
            listMinSize: [],
            crrHistory: [],
            dataImageMoveChange: null,
            crrFrom_Date: new Date(),
            modalShow: false,
            valueIsDisplay: 48,
            trashType: optionType[0],
        };
        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    ...this.state,
                    pageNumber: 1,
                    valueIsDisplay: 24,
                    total: 0,
                    loading: true,
                    listMinSize: [],
                    crrHistory: [],
                    isLogin: Store.getState().isLogin.isLogin,
                    access_token: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    dataImageMoveChange: null,
                    modalShow: false
                }, () => {
                    this.getListMinSize();
                });
            } else {
                this.setState({
                    ...this.state,
                    pageNumber: 1,
                    valueIsDisplay: 24,
                    total: 0,
                    loading: false,
                    listMinSize: [],
                    crrHistory: [],
                    isLogin: Store.getState().isLogin.isLogin,
                    access_token: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    dataImageMoveChange: null,
                    modalShow: false
                });
            }
        });
    }

    componentDidMount() {
        this.getListMinSize();
    }


    getListMinSize() {
        if (this.state.box_engine_cf === undefined) return;
        fetch(`${this.state.box_engine_cf.api}/api/trash/${this.state.trashType.value}/inday?camera_ids=${this.state.box_engine_cf.value === -1 ? this.state.box_engine_cf.ids_Cam.toString() : this.state.box_engine_cf.dataCam.id_box_engine}&day=${moment(this.state.crrFrom_Date).format('YYYY-MM-DD')}&index=${this.state.pageNumber}&item_per_page=${this.state.valueIsDisplay}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            console.log(rs)
            if (rs.status === 10000) {
                var data = rs.data;
                this.setState({
                    listMinSize: data,
                    total: rs.total,
                    loading: false,
                }, () => {
                    this.FilterImg(this.state.pageNumber);
                });
            }
            else {
                this.setState({
                    listMinSize: [],
                    loading: false
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    listMinSize: [],
                    loading: false
                });
            }
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            pageNumber: pageNumber,
        }, () => {
            this.getListMinSize();
        })
    }

    FilterImg = (pageNumber) => {
        const offset = (pageNumber - 1) * this.itemsPerPage;
        this.setState({ offset })
    }

    handleChangeStartDate = startDate => {
        this.setState({
            crrFrom_Date: startDate,
            loading: true,
            listMinSize: [],
            pageNumber: 1,
        }, () => {
            this.getListMinSize()
        });
    }

    handleChangeType = (index,option) => {
        this.setState({
            trashType: option
        }, () => {
            this.getListMinSize()
        })
    }

    isChangeDisplayDetail = (event) => {
        this.setState({
            listMinSize: [],
            loading: true,
            pageNumber: 1,
            valueIsDisplay: event.target.value,
        }, () => {
            this.getListMinSize()
        })
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        let bulletedMinSize = this.state.listMinSize.map((value, index) => {
            console.log('datacam', this.state.box_engine_cf.dataCam);
            
            return (
                <Col xs={2} md={1} lg={1} xl={1} key={index} className="p-1">
                    <div className={'box box-block form_info_history mb-0 '} style={{ 'height': 'auto', 'borderRadius': '5px' }}>
                        <Row className='row-sm'>
                            <Col xs={12} md={12} style={{ 'textAlign': 'center' }} className="imgHover p-0">
                                <Image width='100%' style={{ 'height': '100px' }} 
                                    src={`${value.file_name.indexOf('http') >= 0 ? value.file_name : `${this.state.box_engine_cf.api2_url}/view/trash/${value.mount_folder}/${value.file_name.slice(4, 10)}/${value.camera_id}/${value.file_name}`}`} 
                                    className='img-fluid imgHistory   border-3' />
                            </Col>
                            {/* <div className="text-center col-md-12 mt-2">
                                <h5 className="mb-0">{value.type}</h5>
                            </div> */}
                            <div className="text-center col-md-12">
                                {value.timeget.split(' ')[1]}
                            </div>
                            
                        </Row>
                    </div>
                </Col>
            )
        });

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content mt-3-phone pd_phone_0 p-3">
                    <div className="m-portlet m-portlet--tab mb-0" id="form_reva">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption col-xs-12 col-md-12 col-lg-10 col-xl-8 ">
                                <div className="col-md-2 col-xs-12 pl-0">
                                    <DatePicker
                                        className="form-control m-input m_datetimepicker_6"
                                        selected={this.state.crrFrom_Date}
                                        onChange={this.handleChangeStartDate}
                                        dateFormat="yyyy-MM-dd"
                                        withPortal
                                        placeholderText="Start date"
                                    />
                                </div>
                                <div className="col-md-2 col-xs-12 pl-0">
                                    <AutoComplete
                                        className="form-control m-input m_datetimepicker_6"
                                        options={optionType}
                                        onChange={this.handleChangeType}
                                        getOptionLabel={option => option.label}
                                        value={this.state.trashType}
                                        disableClearable
                                        style={{padding: 5}}
                                        renderInput={params => (
                                            <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Select Type"
                                            fullWidth
                                            />
                                        )}
                                    />
                                </div>
                                <div className="ml-2 mb-mobile-10">
                                    <button
                                        onClick={() => {
                                            this.setState({
                                                pageNumber: 1,
                                                loading: true,
                                                listMinSize: [],
                                                crrHistory: []
                                            }, () => {
                                                this.getListMinSize();
                                            })
                                        }}
                                        className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                        <span>
                                            <i className="la la-rotate-left" />
                                            <span>Reload</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/*begin::Form*/}
                        <div className="m-form m-form--fit m-form--label-align-right">
                            <div className="m-portlet__body pt-3">
                                <div className="col-md-12">
                                    {/* <span>
                                        Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                    </span> */}
                                    <div className="row m-0">
                                        <div className="pl-0 col-md-6">
                                            <Pagination
                                                prevPageText='Previous'
                                                nextPageText='Next'
                                                firstPageText='First'
                                                lastPageText='Last'
                                                activePage={this.state.pageNumber}
                                                itemsCountPerPage={this.state.valueIsDisplay}
                                                totalItemsCount={this.state.total}
                                                pageRangeDisplayed={pageRangeDisplayed}
                                                onChange={this.handlePageChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="col-xl-3 pr-0 pull-right hide_mobie" style={{ 'paddingTop': '10px' }}>
                                                <div className="dataTables_length pull-right">
                                                    <span>Số ảnh / trang </span>
                                                    <label>
                                                        <select name="m_table_1_length" value={this.state.valueIsDisplay} onChange={(event) => this.isChangeDisplayDetail(event)} aria-controls="m_table_1" className="form-control form-control-sm">
                                                            <option value={24}>24</option>
                                                            <option value={48}>48</option>
                                                            <option value={96}>96</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row-sm row pl-3 pr-3 pb-3'>
                                        {bulletedMinSize}
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end::Form*/}
                    </div>

                </div>
            </div>
        );
    }
}

export default ListTrash;