import React, { Component } from 'react';
import CountUp from 'react-countup';
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/core';
import { EMOTION } from '../../../config';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class ViewHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        var lalaCout = '';
        if (this.props.totalCount >= 0) {
            lalaCout = 'la-arrow-up text-success';
        } else {
            lalaCout = 'la-arrow-down text-danger';
        }

        return (
            <div className="row m-row--no-padding m-row--col-separator-xl form-header-dashboard">
                <LoadingOverlay
                    active={this.props.loading}
                    spinner={<FadeLoader
                        css={override}
                        sizeUnit={"px"}
                        size={150}
                        color={'rgb(54, 215, 183)'}
                        loading={true}
                    />}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(17, 17, 17, 0.24)'
                        })
                    }}
                    className={`${EMOTION == 0 ? 'col-md-12 col-xl-6' : 'col-md-6 col-xl-3'}`}
                >
                    <div className="card-box p-4">
                        <i className="fa fa-info-circle  float-right" data-toggle="tooltip" data-placement="bottom" data-original-title="More Info" />
                        <h4 className="mt-0">Lưu lượng qua lại</h4>
                        <h2 className="my-3 text-center" id="total_traffic"><CountUp duration={2} end={this.props.totalTraffic} /></h2>
                        <p className=" mb-0">So sánh 7 ngày qua <span className="float-right"><i className={'la ' + lalaCout + ' mr-1'} /><span id="total_count_traffic"><CountUp duration={2} end={this.props.totalCount} /></span>%</span></p>
                    </div>
                </LoadingOverlay>
                {
                    parseInt(EMOTION) == 1 &&
                    <>
                        <LoadingOverlay
                            active={this.props.loading}
                            spinner={<FadeLoader
                                css={override}
                                sizeUnit={"px"}
                                size={150}
                                color={'rgb(54, 215, 183)'}
                                loading={true}
                            />}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: 'rgba(17, 17, 17, 0.24)'
                                })
                            }}
                            className="col-md-6 col-xl-3"
                        >
                            <div className="card-box p-4">
                                <i className="fa fa-info-circle  float-right" data-toggle="tooltip" data-placement="bottom" data-original-title="More Info" />
                                <h4 className="mt-0">Tuổi</h4>
                                <div className="col-xl-12 text-center">
                                    <h2 id="show_top_age" className="my-3">{this.props.totalAge}</h2>
                                    <span className="m-section__sub mt-0 pl-2">
                                        Truy cập nhiều nhất theo nhóm tuổi
                            </span>
                                </div>
                            </div>
                        </LoadingOverlay>
                        <LoadingOverlay
                            active={this.props.loading}
                            spinner={<FadeLoader
                                css={override}
                                sizeUnit={"px"}
                                size={150}
                                color={'rgb(54, 215, 183)'}
                                loading={true}
                            />}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: 'rgba(17, 17, 17, 0.24)'
                                })
                            }}
                            className="col-md-6 col-xl-3"
                        >
                            <div className="card-box p-4">
                                <i className="fa fa-info-circle  float-right" data-toggle="tooltip" data-placement="bottom" data-original-title="More Info" />
                                <h4 className="mt-0">Giới tính</h4>
                                <div className="row mt-1 text-center" style={{ paddingLeft: '80px', paddingRight: '80px' }}>
                                    <div className="col-md-6 col-sm-6 col-xl-6">
                                        {/* <i className="la la-male" style={{ fontSize: '45px', color: 'rgb(0, 143, 251)' }} /> */}
                                        <img src="/img/man.svg" alt="imgman" className="width_height_icon pb-2 pt-2" />
                                        <h4 id="show_gender_male" className="width_height_number">
                                            <CountUp duration={2} end={this.props.totalGenderMale} />
                                        </h4>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xl-6">
                                        {/* <i className="la la-female" style={{ fontSize: '45px', color: 'rgb(0, 227, 150)' }} /> */}
                                        <img src="/img/woman.svg" alt="imgwoman" className="width_height_icon pb-2 pt-2" />
                                        <h4 id="show_gender_female" className="width_height_number">
                                            <CountUp duration={2} end={this.props.totalGenderFemale} />
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </LoadingOverlay>
                        <LoadingOverlay
                            active={this.props.loading}
                            spinner={<FadeLoader
                                css={override}
                                sizeUnit={"px"}
                                size={150}
                                color={'rgb(54, 215, 183)'}
                                loading={true}
                            />}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: 'rgba(17, 17, 17, 0.24)'
                                })
                            }}
                            className="col-md-6 col-xl-3"
                        >
                            <div className="card-box p-4">
                                <i className="fa fa-info-circle  float-right" data-toggle="tooltip" data-placement="bottom" data-original-title="More Info" />
                                <h4 className="mt-0">Biểu cảm</h4>
                                <div className="row mt-1 text-center" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                                    <div className="col-md-4 col-sm-4 col-xl-4">
                                        <img src="/img/smile.svg" alt="imgmansmile" className="width_height_icon pb-2 pt-2" />
                                        <h4 id="show_emotion_neutral" className="width_height_number">
                                            <CountUp duration={2} end={this.props.totalEmotionNeutral} />
                                        </h4>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xl-4">
                                        <img src="/img/happy.svg" alt="imgmanhappy" className="width_height_icon pb-2 pt-2" />
                                        <h4 id="show_emotion_happy" className="width_height_number">
                                            <CountUp duration={2} end={this.props.totalEmotionHappy} />
                                        </h4>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xl-4">
                                        <img src="/img/sad.svg" alt="imgmansad" className="width_height_icon pb-2 pt-2" />
                                        <h4 id="show_emotion_sad" className="width_height_number">
                                            <CountUp duration={2} end={this.props.totalEmotionSad} />
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </LoadingOverlay>
                    </>
                }
            </div>
        );
    }
}

export default ViewHeader;