import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

ModalViewPersonNoImage.propTypes = {
    data: PropTypes.array,
    show: PropTypes.bool,
    onHide: PropTypes.func,
};
ModalViewPersonNoImage.defaultProps = {
    data: null,
    show: false,
    onHide: null,
}

function ModalViewPersonNoImage(props) {
    const { data, show, onHide } = props;

    const _export = React.createRef();;
    const exportFile = () => {
        _export.current.save();
    };

    if (!data) return <></>;
    return (
        <Modal
            show={show}
            className='modal_training'
            onHide={onHide}
        >
            <Modal.Body>
                <ExcelExport
                    data={[...data]}
                    ref={_export}
                    fileName={`Danh-Sach-Nhan-Vien-Khong-Co-Mau-${moment(new Date()).format('YYYY-MM-DD-HH-mm-ss')}.xlsx`}
                >
                    <Grid
                        // style={{ height: '800px' }}
                        data={[...data]}
                        className='table_training'
                    >
                        <GridToolbar>
                            <button
                                title='Export Excel'
                                onClick={exportFile}
                                className='btn btn-primary'
                            >
                                Xuất Excel
                        </button>
                        </GridToolbar>
                        <Column field="index" title="STT" width={50}/>
                        <Column field="id" title="Mã nhân viên" width={150}/>
                        <Column field="name" title="Họ và Tên" width={180}/>
                        <Column field="phone" title="Điện thoại" width={150}/>
                        <Column field="gender" title="Giới tính" width={120}/>
                        <Column field="department" title="Phòng" width={150}/>
                        <Column field="type" title="Nhóm" width={150}/>
                    </Grid>
                </ExcelExport>
            </Modal.Body>
        </Modal>
    );
}

export default ModalViewPersonNoImage;