export const convertDate = (date) => {
    let dd = date.getDate();
    let MM = date.getMonth()+1; 
    let yyyy = date.getFullYear();
    if(dd<10) {
        dd = '0'+dd
    } 
    if(MM<10) {
        MM = '0'+MM
    }
    return yyyy + '-' + MM + '-' + dd;
}

export const present = (today) => {
    let dd = today.getDate();
    let MM = today.getMonth()+1; 
    let yyyy = today.getFullYear();
    let hh = today.getHours();
    let mm = today.getMinutes();
    let ss = today.getSeconds();
    if(dd<10) {
        dd = '0'+dd
    }
    if(mm<10) {
        mm = '0'+mm
    }

    if(hh<10) {
        hh = '0'+hh
    }
    if(ss<10) {
        ss = '0'+ss
    }

    if(MM<10) {
        MM = '0'+MM
    }
 
    return yyyy + '-' + MM + '-' + dd +' '+ hh + ':' + mm + ':' + ss;
}

export const today = (today) => {
    let dd = today.getDate();
    let MM = today.getMonth()+1; 
    let yyyy = today.getFullYear();
    if(dd<10) {
        dd = '0'+dd
    } 

    if(MM<10) {
        MM = '0'+MM
    }

    return yyyy + '-' + MM + '-' + dd + ' 00:00:00'; 
}

export const todayTracfic = () => {
    let today = new Date();
    let dd = today.getDate();
    let MM = today.getMonth()+1; 
    let yyyy = today.getFullYear();
    if(dd<10) {
        dd = '0'+dd
    } 

    if(MM<10) {
        MM = '0'+MM
    }
    return yyyy + '-' + MM + '-' + dd; 
}

