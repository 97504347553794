import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { convertDate } from '../../utils/Date';
import Store from '../../../store';
import { present } from '../../utils/Date'
import ModalTraffic from '../../modal/ModalTraffic';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment'
import $ from 'jquery';
import { PulseLoader } from 'react-spinners';
import openSocket from 'socket.io-client';
//import Form
import ViewHeader from './ViewHeader';
import ViewGender from './ViewGender';
import ViewEmotion from './ViewEmotion';
import ViewAge from './ViewAge';
//import config Chart
import { chartDayLight, chartDayDark } from './ConfigChart/ChartDay';
import { chartHourLight, chartHourDark } from './ConfigChart/ChartHour';
import { chartAgeLight, chartAgeDark } from './ConfigChart/ChartAge';
import { chartEmotion } from './ConfigChart/ChartEmotion';
import { chartGender } from './ConfigChart/chartGender';
//css
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/core';
import { EMOTION } from '../../../config';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            insert_person: [],
            datamodal: 0,
            api_heatmap: '',
            totalTraffic: 0,
            totalTrafficLast: 0,
            totalAge: '',
            totalHour: 0,
            totalCount: 0,
            totalEmotion_neutral: 0,
            totalEmotion_happy: 0,
            totalEmotion_sad: 0,
            totalGender_male: 0,
            totalGender_female: 0,
            isLogin: Store.getState().isLogin.isLogin,
            accessToken: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            box_engine_hm: Store.getState().boxai.box_engine_hm,
            modalShow: false,
            loading: true,
            loadingInfoPerDay: false,
            loadingTrafficbyHour: false,
            //time chartHour
            startDate: new Date(),
            //time chart days
            startDateDays: new Date(moment().subtract(6, "days").format("YYYY-MM-DD")),
            endDate: new Date(),
            //chartDays
            chartOptionsLine: chartDayDark,
            //chartHour
            chartOptions: chartHourDark,
            //emotion
            options: chartEmotion,
            dataEmotion: [],
            seriesEmotion: [0],
            labelsEmotion: ['0'],
            //gender
            dataGender: [],
            optionsGender: chartGender,
            seriesGender: [0],
            labelsGender: ['0'],
            //Age
            totalAgeRealAll: 0,
            data_age: [],
            dataAge: [],
            chartOptionsAge: chartAgeDark,
            //heatmap
            selectedOption: null,
            listHeatMap: [],
            loadingHM: true,
            dataNull: false,
            displayHM: false,
            dataImgMerge: '',
            dataImg: '',
            colHMCH: '',
            startDateheatmap: new Date(new Date().setHours(0, 0, 0, 0)),
            endDateheatmap: new Date(),
        };
        Store.subscribe(() => {
            //charOPLine
            if (Store.getState().theme.setTheme === 'Dark') {
                this.setState({
                    chartOptionsLine: chartDayDark,
                    chartOptions: chartHourDark,
                    chartOptionsAge: chartAgeDark,
                })
            } else {
                this.setState({
                    chartOptionsLine: chartDayLight,
                    chartOptions: chartHourLight,
                    chartOptionsAge: chartAgeLight,
                })
            }
            //box_engine_cf
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    loading: true,
                    loadingTrafficbyHour: false,
                    loadingInfoPerDay: false,
                    isLogin: Store.getState().isLogin.isLogin,
                    accessToken: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    //Header
                    totalTraffic: 0,
                    totalTrafficLast: 0,
                    totalAge: '',
                    totalHour: 0,
                    totalCount: 0,
                    //Traffic
                    chartOptionsLine: {
                        title: {
                            text: 'Tổng: ' + 0,
                            style: {
                                fontSize: '15px'
                            }
                        },
                        xAxis: {
                            type: 'datetime',
                            labels: {
                                format: '{value:%d/%m}',
                            }
                        },
                        series: [{
                            type: 'area',
                            name: 'Lưu lượng qua lại',
                            data: []
                        }],
                    },
                    chartOptions: {
                        title: {
                            text: 'Tổng: ' + 0,
                            style: {
                                fontSize: '15px'
                            }
                        },
                        plotOptions: {
                            series: {
                                cursor: 'pointer',
                                point: {
                                    events: {
                                        click: (e) => { e.preventDefault(); this.categoryClicked(e) }
                                    },
                                }
                            }
                        },
                        series: [{
                            name: 'Lưu lượng qua lại',
                            data: []
                        }],
                    },
                    //Emotion
                    totalEmotion_neutral: 0,
                    totalEmotion_happy: 0,
                    totalEmotion_sad: 0,
                    dataEmotion: [],
                    seriesEmotion: [0, 0, 0, 0],
                    //Gender
                    totalGender_male: 0,
                    totalGender_female: 0,
                    dataGender: [],
                    seriesGender: [0, 0, 0],
                }, () => {
                    this.getData();
                });
            } else {
                this.setState({
                    loading: false,
                    loadingInfoPerDay: false,
                    loadingTrafficbyHour: false,
                    isLogin: Store.getState().isLogin.isLogin,
                    accessToken: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    //Header
                    totalTraffic: 0,
                    totalTrafficLast: 0,
                    totalAge: '',
                    totalHour: 0,
                    totalCount: 0,
                    //Traffic
                    chartOptionsLine: {
                        title: {
                            text: 'Tổng: ' + 0,
                            style: {
                                fontSize: '15px'
                            }
                        },
                        xAxis: {
                            type: 'datetime',
                            labels: {
                                format: '{value:%d/%m}',
                            }
                        },
                        series: [{
                            type: 'area',
                            name: 'Lưu lượng qua lại',
                            data: []
                        }],
                    },
                    chartOptions: {
                        title: {
                            text: 'Tổng: ' + 0,
                            style: {
                                fontSize: '15px'
                            }
                        },
                        plotOptions: {
                            series: {
                                cursor: 'pointer',
                                point: {
                                    events: {
                                        click: (e) => { e.preventDefault(); this.categoryClicked(e) }
                                    },
                                }
                            }
                        },
                        series: [{
                            name: 'Lưu lượng qua lại',
                            data: []
                        }],
                    },
                    //Emotion
                    totalEmotion_neutral: 0,
                    totalEmotion_happy: 0,
                    totalEmotion_sad: 0,
                    dataEmotion: [],
                    seriesEmotion: [0, 0, 0, 0],
                    //Gender
                    totalGender_male: 0,
                    totalGender_female: 0,
                    dataGender: [],
                    seriesGender: [0, 0, 0],
                });
            }
        });
    }

    componentDidMount() {
        this.getData()
        $('text.highcharts-credits').html('');
    }

    checkSocket = (idbox) => {
        
        if (this.state.box_engine_cf === undefined) return;
        this.socket = openSocket(`${this.state.box_engine_cf.apiSocket}`);
        this.socket.on('connect', () => {
            console.log('socket connected!')
            this.socket.emit('joined', idbox + '');
        })
        this.socket.on('disconnect', () => {
            console.log('socket disconnected!')
        })

        this.socket.on('my response', async (msg) => {
            console.log(msg)
            let insert_person = this.state.insert_person;
            let obj = { 'person': msg.person, 'imgRc': msg.imgRc };
            if (insert_person.find(x => { return (x.person === msg.person && x.imgRc === msg.imgRc) }) === undefined) {
                if (msg.type !== 1) {
                    if (msg.operationType === "insert") {
                        insert_person.push(obj);
                        this.setState({ insert_person: insert_person });
                        let todayReal = new Date(msg.timeget);
                        let hhReal = todayReal.getHours();
                        //ChartHour
                        let dataYHour = this.state.chartOptions.series[0].data[hhReal];
                        if (dataYHour !== undefined) {
                            dataYHour[1] = parseInt(dataYHour[1] + 1);
                        }
                        let totaldata = this.state.chartOptions.series[0].data;
                        let total = 0;
                        for (let i = 0; i < totaldata.length; i++) {
                            total += totaldata[i][1];
                        }
                        //ChartDay
                        let totaldataWeek = this.state.chartOptionsLine.series[0].data;
                        let totalWeek = 0;
                        for (let i = 0; i < totaldataWeek.length; i++) {
                            totalWeek += totaldataWeek[i][1];
                        }
                        let totalDayReal = parseInt(totalWeek) + 1;
                        let dataYDay = this.state.chartOptionsLine.series[0].data[6];
                        if (dataYDay !== undefined) {
                            dataYDay[1] = parseInt(dataYDay[1] + 1);
                        }
                        let totaldataDay = this.state.chartOptionsLine.series[0].data;
                        $('#total_traffic span').html(totalDayReal)

                        //Gender
                        let totalfemale = $('#female_data').html();
                        let totalmale = $('#male_data').html();
                        let totalUnknownReal = $('#unknown_data').html();

                        let totalGenderReal = [parseInt(totalmale), parseInt(totalfemale), parseInt(totalUnknownReal)];
                        let totalMaleReal = parseInt(totalmale);
                        let totalFemaleReal = parseInt(totalfemale);
                        let totalUnk = parseInt(totalUnknownReal);
                        if (msg.gender === "male") {
                            totalGenderReal = [parseInt(totalmale) + 1, parseInt(totalfemale), parseInt(totalUnknownReal)];
                            totalMaleReal = parseInt(totalmale) + 1;
                            $('#male_data').html(totalMaleReal);
                            $('#show_gender_male span').html(totalMaleReal);
                        } else if (msg.gender === "female") {
                            totalGenderReal = [parseInt(totalmale), parseInt(totalfemale) + 1, parseInt(totalUnknownReal)];
                            totalFemaleReal = parseInt(totalfemale) + 1;
                            $('#female_data').html(totalFemaleReal);
                            $('#show_gender_female span').html(totalFemaleReal);
                        } else {
                            totalGenderReal = [parseInt(totalmale), parseInt(totalfemale), parseInt(totalUnknownReal) + 1];
                            totalUnk = parseInt(totalUnknownReal) + 1;
                            $('#unknown_data').html(parseInt(totalUnk) + 1);
                        }


                        // total neutral
                        let totalneutral = $('#neutral_data').html();
                        let totalneutralReal = parseInt(totalneutral);
                        // total happy
                        let totalhappy = $('#happy_data').html();
                        let totalhappyReal = parseInt(totalhappy);
                        // total sad
                        let totalsad = $('#sad_data').html();
                        let totalsadReal = parseInt(totalsad);
                        // total unknown
                        let totalUnknown = $('#unknown_data_emotion').html();
                        let totalUknReal = parseInt(totalUnknown);

                        let totalEmotionReal;
                        if (msg.emotion === "neutral") {
                            totalEmotionReal = [parseInt(totalneutral) + 1, parseInt(totalhappy), parseInt(totalsad), parseInt(totalUnknown)];
                            totalneutralReal = parseInt(totalneutral) + 1;
                            $('#neutral_data').html(totalneutralReal);
                            $('#show_emotion_neutral span').html(totalneutralReal);
                        } else if (msg.emotion === "happy") {
                            totalEmotionReal = [parseInt(totalneutral), parseInt(totalhappy) + 1, parseInt(totalsad), parseInt(totalUnknown)];
                            totalhappyReal = parseInt(totalhappy) + 1;
                            $('#happy_data').html(totalhappyReal);
                            $('#show_emotion_happy span').html(totalhappyReal);
                        } else if (msg.emotion === "sad") {
                            totalEmotionReal = [parseInt(totalneutral), parseInt(totalhappy), parseInt(totalsad) + 1, parseInt(totalUnknown)];
                            totalsadReal = parseInt(totalsad) + 1;
                            $('#sad_data').html(totalsadReal);
                            $('#show_emotion_sad span').html(totalsadReal);
                        } else {
                            totalEmotionReal = [parseInt(totalneutral), parseInt(totalhappy), parseInt(totalsad), parseInt(totalUnknown) + 1];
                            totalUknReal = parseInt(totalUnknown) + 1;
                            $('#unknown_data_emotion').html(parseInt(totalUknReal) + 1);
                        }
                        //Age
                        let numberAge = parseInt(msg.age);
                        let dataAgeReal = this.state.data_age;
                        //update chart
                        if (numberAge <= 15) {
                            dataAgeReal[0] = dataAgeReal[0] + 1;
                            $('#age_10_15_data').html(dataAgeReal[0]);
                        }
                        if (numberAge > 15 && numberAge <= 25) {
                            dataAgeReal[1] = dataAgeReal[1] + 1;
                            $('#age_16_25_data').html(dataAgeReal[1]);
                        }
                        if (numberAge > 25 && numberAge <= 30) {
                            dataAgeReal[2] = dataAgeReal[2] + 1;
                            $('#age_26_30_data').html(dataAgeReal[2]);
                        }
                        if (numberAge > 30 && numberAge <= 40) {
                            dataAgeReal[3] = dataAgeReal[3] + 1;
                            $('#age_31_40_data').html(dataAgeReal[3]);
                        }
                        if (numberAge > 40 && numberAge <= 50) {
                            dataAgeReal[4] = dataAgeReal[4] + 1;
                            $('#age_41_50_data').html(dataAgeReal[4]);
                        }
                        if (numberAge > 50 && numberAge <= 60) {
                            dataAgeReal[5] = dataAgeReal[5] + 1;
                            $('#age_51_60_data').html(dataAgeReal[5]);
                        }
                        if (numberAge > 60) {
                            dataAgeReal[6] = dataAgeReal[6] + 1;
                            $('#age_61_data').html(dataAgeReal[6]);
                        }
                        if (numberAge === "unknown") {
                            $('#age_unknown_data').html(dataAgeReal[7]);
                        }
                        let totalAgeall = 0;
                        for (let index = 0; index < this.state.data_age.length; index++) {
                            totalAgeall += parseInt(this.state.data_age[index]);
                        }

                        this.setState({
                            //chartHour
                            chartOptions: {
                                title: {
                                    text: 'Tổng: ' + total,
                                    style: {
                                        fontSize: '15px'
                                    }
                                },
                                series: [{
                                    data: totaldata
                                }],
                            },
                            //chartDay
                            chartOptionsLine: {
                                title: {
                                    text: 'Tổng: ' + totalDayReal,
                                    style: {
                                        fontSize: '15px'
                                    }
                                },
                                series: [{
                                    type: 'area',
                                    name: 'Lưu lượng qua lại',
                                    data: totaldataDay
                                }],
                            },
                            //gender
                            seriesGender: totalGenderReal,
                            //emotion
                            seriesEmotion: totalEmotionReal,
                            //age
                            chartOptionsAge: {
                                title: {
                                    text: 'Tổng: ' + totalAgeall,
                                    style: {
                                        fontSize: '15px'
                                    }
                                },
                                series: [{
                                    name: 'Tuổi',
                                    data: dataAgeReal,
                                    color: '#ffc241'
                                }],
                            }
                        });
                    }
                }
            }
        })
    }

    getData = () => {
        this.getInfomationPerday();
        this.getTrafficHour();
        this.getTrafficbyDaysTwoweek()
    }


    getImgMerge = async (idCompany) => {
        // if (this.state.api_heatmap === "" && this.state.api_countface === undefined) return;
        // await fetch(`${this.state.api_heatmap}/api/get_merge_heatmap`, {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': Store.getState().isLogin.access_token
        //     },
        //     body: JSON.stringify({
        //         'idCompany': `${idCompany}`,
        //         'start_time': present(this.state.startDateheatmap),
        //         'end_time': present(this.state.endDateheatmap),
        //     })
        // }).then((response) => {
        //     return response.json()
        // }).then((data) => {
        //     if (data.status === 10000) {
        //         this.setState({
        //             loadingHM: false,
        //             dataNull: false,
        //             dataImgMerge: data.heat_map,
        //         });
        //     } else {
        //         this.setState({
        //             dataImgMerge: '',
        //             dataNull: true,
        //             loadingHM: false
        //         });
        //     }
        // }).catch((error) => {
        //     if (error) {
        //         this.setState({
        //             dataImgMerge: '',
        //             dataNull: true,
        //             loadingHM: false
        //         });
        //     }
        // });
    }

    getInfomationPerday = () => {
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        this.setState({
            loadingInfoPerDay: true
        }, () => {
            fetch(`${this.state.box_engine_cf.api2_url}/api/reports/information_perday`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                dataType: 'json',
                body: JSON.stringify({
                    'idCamera': this.state.box_engine_cf === undefined ? '' : this.state.box_engine_cf.ids_Cam.join(','),
                    'start_date': convertDate(this.state.startDateDays),
                    'end_date': convertDate(this.state.endDate)

                })
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    this.setState({
                        loadingInfoPerDay: false
                    }, () => {
                        this.getAge(data, true)
                        this.getGender(data, true)
                        this.getEmotion(data, true)
                        this.getTrafficbyDays(data, true)
                        this.checkSocket(this.state.box_engine_cf === undefined ? '' : this.state.box_engine_cf.dataCam.id_box_engine + '');
                    });
                } else {
                    if (this.socket !== undefined) {
                        if (this.socket.connected === true) {
                            this.socket.disconnect();
                            console.log('Socket CountFace Disconnect')
                        }
                    }
                    this.setState({
                        loadingInfoPerDay: false
                    }, () => {
                        this.getAge(data, false)
                        this.getGender(data, false)
                        this.getEmotion(data, false)
                        this.getTrafficbyDays(data, false)
                    });
                }
            }).catch((error) => {
                if (error) {
                    if (this.socket !== undefined) {
                        if (this.socket.connected === true) {
                            this.socket.disconnect();
                            console.log('Socket CountFace Disconnect')
                        }
                    }
                    this.setState({
                        loadingInfoPerDay: false
                    }, () => {
                        this.getAge(error, false)
                        this.getGender(error, false)
                        this.getEmotion(error, false)
                        this.getTrafficbyDays(error, false)
                    });
                }
            });
        })
    }

    getAge = (data, status) => {
        if (status === true) {
            let age_10_15 = 0;
            let age_16_25 = 0;
            let age_26_30 = 0;
            let age_31_40 = 0;
            let age_41_50 = 0;
            let age_51_60 = 0;
            let age_61 = 0;
            let age_unknown = 0;
            if (data.data.length > 0) {
                for (let i = 0; i < data.data.length; i++) {
                    age_10_15 += data.data[i].age.age_10_15;
                    age_16_25 += data.data[i].age.age_16_25;
                    age_26_30 += data.data[i].age.age_26_30;
                    age_31_40 += data.data[i].age.age_31_40;
                    age_41_50 += data.data[i].age.age_41_50;
                    age_51_60 += data.data[i].age.age_51_60;
                    age_61 += data.data[i].age.age_61;
                    age_unknown += data.data[i].age.age_unknown;
                }
            }

            let data_age =
                [
                    age_10_15,
                    age_16_25,
                    age_26_30,
                    age_31_40,
                    age_41_50,
                    age_51_60,
                    age_61,
                    age_unknown
                ];
            let totalAll = age_10_15 + age_16_25 + age_26_30 + age_31_40 + age_41_50 + age_51_60 + age_61 + age_unknown;
            let age_max = age_10_15;
            let totalAge = '10-15';
            if (age_16_25 > age_max) {
                totalAge = '16-25';
                age_max = age_16_25;
            }
            if (age_26_30 > age_max) {
                totalAge = '26-30';
                age_max = age_26_30;
            }
            if (age_31_40 > age_max) {
                totalAge = '31-40';
                age_max = age_31_40;
            }
            if (age_41_50 > age_max) {
                totalAge = '41-50';
                age_max = age_41_50;
            }
            if (age_51_60 > age_max) {
                totalAge = '51-60';
                age_max = age_51_60;
            }
            if (age_61 > age_max) {
                totalAge = '61';
                age_max = age_61;
            }

            this.setState({
                data_age: data_age,
                totalAgeRealAll: totalAll,
                totalAge: totalAge,
                dataAge: [data],
                chartOptionsAge: {
                    title: {
                        text: 'Tổng: ' + totalAll,
                        style: {
                            fontSize: '15px'
                        }
                    },
                    series: [{
                        name: 'Tuổi',
                        data: data_age,
                        color: '#ffc241'
                    }],
                }
            });
        } else {
            this.setState({
                data_age: [0, 0, 0, 0, 0, 0, 0, 0],
                totalAgeRealAll: 0,
                totalAge: '',
                dataAge: [],
                chartOptionsAge: {
                    title: {
                        text: 'Tổng: ' + 0,
                        style: {
                            fontSize: '15px'
                        }
                    },
                    series: [{
                        name: 'Tuổi',
                        data: [0, 0, 0, 0, 0, 0, 0, 0],
                        color: '#ffc241'
                    }],
                }
            });
        }
    }

    getGender = (data, status) => {
        if (status === true) {
            let numMale = 0
            let numFemale = 0
            let numUnknown = 0
            for (let i = 0; i < data.data.length; i++) {
                numMale += data.data[i].gender.male;
                numFemale += data.data[i].gender.female;
                numUnknown += data.data[i].gender.unknown;
            }
            this.setState({
                totalGender_male: numMale,
                totalGender_female: numFemale,
                dataGender: [data],
                seriesGender: [numMale, numFemale, numUnknown],
                labelsGender: ['Male', 'Female', 'Unknown'],
            })
        } else {
            this.setState({
                totalGender_male: 0,
                totalGender_female: 0,
                dataGender: [],
                seriesGender: [0, 0, 0],
                labelsGender: ['Male', 'Female', 'Unknown'],
            })
        }
    }

    getEmotion = (data, status) => {
        if (status === true) {
            let happy = 0;
            let neutral = 0;
            let sad = 0;
            let unknown = 0;
            for (let i = 0; i < data.data.length; i++) {
                happy += data.data[i].emotion.happy;
                neutral += data.data[i].emotion.neutral;
                sad += data.data[i].emotion.sad;
                unknown += data.data[i].emotion.unknown;
            }

            this.setState({
                totalEmotion_neutral: neutral,
                totalEmotion_happy: happy,
                totalEmotion_sad: sad,
                dataEmotion: [data],
                seriesEmotion: [neutral, happy, sad, unknown],
                labelsEmotion: ['Neutral', 'Happy', 'Sad', 'Unknown'],
            });
        } else {
            this.setState({
                totalEmotion_neutral: 0,
                totalEmotion_happy: 0,
                totalEmotion_sad: 0,
                dataEmotion: [],
                seriesEmotion: [0, 0, 0, 0],
                labelsEmotion: ['Neutral', 'Happy', 'Sad', 'Unknown'],
            });
        }
    }

    getTrafficbyDays = (data, status) => {
        if (status === true) {
            let companyOption = [];
            for (let index = 0; index < data.data.length; index++) {
                let dataDays = data.data[index].day;
                let today = new Date(dataDays);
                let dd = today.getDate();
                let MM = today.getMonth() + 1;
                let yyyy = today.getFullYear();

                if (dd < 10) {
                    dd = '0' + dd
                }

                if (MM < 10) {
                    MM = '0' + MM
                }

                let data_container_days = [Date.UTC(yyyy, MM - 1, dd), data.data[index].traffic];

                companyOption.push(data_container_days);
            }
            let total = 0;
            for (let i = 0; i < data.data.length; i++) {
                total += data.data[i].traffic;
            }
            this.setState({
                totalTraffic: total,
                chartOptionsLine: {
                    title: {
                        text: 'Tổng: ' + total,
                        style: {
                            fontSize: '15px'
                        }
                    },
                    xAxis: {
                        type: 'datetime',
                        labels: {
                            format: '{value:%d/%m}',
                        }
                    },
                    series: [{
                        type: 'area',
                        name: 'Lưu lượng qua lại',
                        data: companyOption
                    }],
                }
            });
        } else {
            this.setState({
                totalTraffic: 0,
                chartOptionsLine: {
                    title: {
                        text: 'Tổng: ' + 0,
                        style: {
                            fontSize: '15px'
                        }
                    },
                    xAxis: {
                        type: 'datetime',
                        labels: {
                            format: '{value:%d/%m}',
                        }
                    },
                    series: [{
                        type: 'area',
                        name: 'Lưu lượng qua lại',
                        data: []
                    }],
                }
            })
        }
    }

    getTrafficbyDaysTwoweek = () => {
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        fetch(`${this.state.box_engine_cf.api2_url}/api/reports/information_perday`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            dataType: 'json',
            body: JSON.stringify({
                'idCamera': this.state.box_engine_cf === undefined ? '' : this.state.box_engine_cf.ids_Cam.join(','),
                'start_date': convertDate(new Date(moment().subtract(13, "days").format("YYYY-MM-DD"))),
                'end_date': convertDate(new Date(moment().subtract(7, "days").format("YYYY-MM-DD")))
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var totalastweek = 0;
                for (let i = 0; i < data.data.length; i++) {
                    totalastweek += data.data[i].traffic;
                }

                this.setState({
                    totalTrafficLast: totalastweek,
                });
            } else {
                this.setState({
                    totalTrafficLast: 0,
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    totalTrafficLast: 0,
                });
            }
        })
    }

    getTrafficHour = () => {
        // console.log('get dat in ' + convertDate(this.state.startDate));
        // console.log('server: ' + HOST_REVA);
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        this.setState({
            loadingTrafficbyHour: true
        }, () => {
            fetch(`${this.state.box_engine_cf.api2_url}/api/reports/person_perhour`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                dataType: 'json',
                body: JSON.stringify({
                    'idCamera': this.state.box_engine_cf === undefined ? '' : this.state.box_engine_cf.ids_Cam.join(','),
                    'date': convertDate(this.state.startDate)
                })
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    let companyOption = [];
                    for (let index = 0; index < data.data.length; index++) {
                        let datahour = data.data[index].hour;
                        let today = new Date(datahour);
                        let dd = today.getDate();
                        let MM = today.getMonth() + 1;
                        let yyyy = today.getFullYear();
                        let hh = today.getHours();
                        let mm = today.getMinutes();

                        if (mm < 10) {
                            mm = '0' + mm
                        }

                        if (hh < 10) {
                            hh = '0' + hh
                        }

                        if (dd < 10) {
                            dd = '0' + dd
                        }

                        if (MM < 10) {
                            MM = '0' + MM
                        }

                        let data_container_hours = [Date.UTC(yyyy, MM - 1, dd, hh, mm), data.data[index].persons];

                        companyOption.push(data_container_hours);
                    }
                    let total = 0;
                    for (let i = 0; i < data.data.length; i++) {
                        total += data.data[i].persons;
                    }
                    // console.log('-------------- success ---------------');
                    this.setState({
                        loadingTrafficbyHour: false,
                        totalHour: total,
                        chartOptions: {
                            title: {
                                text: 'Tổng: ' + total,
                                style: {
                                    fontSize: '15px'
                                }
                            },
                            plotOptions: {
                                series: {
                                    cursor: 'pointer',
                                    point: {
                                        events: {
                                            click: (e) => { e.preventDefault(); this.categoryClicked(e) }
                                        },
                                    }
                                }
                            },
                            series: [{
                                name: 'Lưu lượng qua lại',
                                data: companyOption
                            }],
                        }
                    });
                } else {
                    this.setState({
                        loadingTrafficbyHour: false,
                        totalHour: 0,
                        chartOptions: {
                            title: {
                                text: 'Tổng: ' + 0,
                                style: {
                                    fontSize: '15px'
                                }
                            },
                            plotOptions: {
                                series: {
                                    cursor: 'pointer',
                                    point: {
                                        events: {
                                            click: (e) => { e.preventDefault(); this.categoryClicked(e) }
                                        },
                                    }
                                }
                            },
                            series: [{
                                name: 'Lưu lượng qua lại',
                                data: []
                            }],
                        }
                    });
                }
            }).catch((error) => {
                if (error) {
                    this.setState({
                        loadingTrafficbyHour: false,
                        totalHour: 0,
                        chartOptions: {
                            title: {
                                text: 'Tổng: ' + 0,
                                style: {
                                    fontSize: '15px'
                                }
                            },
                            plotOptions: {
                                series: {
                                    cursor: 'pointer',
                                    point: {
                                        events: {
                                            click: (e) => { e.preventDefault(); this.categoryClicked(e) }
                                        },
                                    }
                                }
                            },
                            series: [{
                                name: 'Lưu lượng qua lại',
                                data: []
                            }],
                        }
                    });
                }
            });
        })
    }

    categoryClicked(e) {
        this.setState({
            datamodal: e.point.options.x,
            modalShow: true
        });
    }

    modalClose = () => {
        this.setState({
            modalShow: false,
        });
        // this.getData();
    }


    componentWillUnmount() {
        if (this.socket !== undefined) {
            if (this.socket.connected === true) {
                this.socket.disconnect();
            }
        }
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        const { chartOptionsLine, chartOptions, chartOptionsAge } = this.state;

        var totalCount = 0
        if (this.state.totalTrafficLast > 0) {
            totalCount = ((this.state.totalTraffic - this.state.totalTrafficLast) / this.state.totalTrafficLast * 100);
        }

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content mt-3-phone pd_phone_0 pt-3 pl-0 pr-0">
                    <div className="row mr-0 ml-0">
                        <div className="col-md-12 pd_phone_0">
                            <div className={"m-portlet mb-3 bg-Dark "}>
                                <div className="m-portlet__body  m-portlet__body--no-padding" id="info_toltal_traffic">
                                    <ViewHeader
                                        loading={this.state.loadingInfoPerDay}
                                        totalTraffic={this.state.totalTraffic}
                                        totalCount={totalCount}
                                        totalAge={this.state.totalAge}
                                        totalGenderMale={this.state.totalGender_male}
                                        totalGenderFemale={this.state.totalGender_female}
                                        totalEmotionNeutral={this.state.totalEmotion_neutral}
                                        totalEmotionHappy={this.state.totalEmotion_happy}
                                        totalEmotionSad={this.state.totalEmotion_sad}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row m_phone_0 mr-0 ml-0 "}>

                        <div className="col-md-12 col-xl-6 pr-2 pd_phone_0">
                            <LoadingOverlay
                                active={this.state.loadingInfoPerDay}
                                spinner={<FadeLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={150}
                                    color={'rgb(54, 215, 183)'}
                                    loading={true}
                                />}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(17, 17, 17, 0.24)'
                                    })
                                }}
                            >
                                <div className="m-portlet mb-3 " id="traffic_by_day">
                                    <div className="m-portlet__head">
                                        <div className="m-portlet__head-caption">
                                            <div className="m-portlet__head-title">
                                                <h3 className="m-portlet__head-text">
                                                    Dũ liệu theo ngày
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="m-portlet__head-tools">
                                            <h5 className="m-portlet__head-text">
                                                7 ngày qua
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="m-portlet__body pl_phone_0 pr_phone_0">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chartOptionsLine}
                                        />
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                        <div className="col-md-12 col-xl-6 pl-2 pd_phone_0">
                            <LoadingOverlay
                                active={this.state.loadingTrafficbyHour}
                                spinner={<FadeLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={150}
                                    color={'rgb(54, 215, 183)'}
                                    loading={true}
                                />}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(17, 17, 17, 0.24)'
                                    })
                                }}
                            >
                                <div className="m-portlet mb-3 " id="traffic_by_hour">
                                    <div className="m-portlet__head">
                                        <div className="m-portlet__head-caption">
                                            <div className="m-portlet__head-title">
                                                <h3 className="m-portlet__head-text">
                                                    Giữ liệu theo giờ
                                            </h3>
                                            </div>
                                        </div>
                                        <div className="m-portlet__head-tools">
                                        </div>
                                    </div>
                                    <div className="m-portlet__body pl_phone_0 pr_phone_0 p-30">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chartOptions}
                                        />
                                        <ModalTraffic
                                            idcompany={this.state.box_engine_cf === undefined ? '' : this.state.box_engine_cf.dataCam.id_box_engine + ''}
                                            dataop={this.state.datamodal}
                                            show={this.state.modalShow}
                                            onHide={this.modalClose}
                                        />
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </div>
                    {
                        EMOTION == 1 &&
                        <div className={"row mr-0 ml-0 m_phone_0 "}>
                            <div className="col-xl-4 pr-2 pd_phone_0">
                                <LoadingOverlay
                                    active={this.state.loadingInfoPerDay}
                                    spinner={<FadeLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={150}
                                        color={'rgb(54, 215, 183)'}
                                        loading={true}
                                    />}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(17, 17, 17, 0.24)'
                                        })
                                    }}
                                >
                                    <div className="m-portlet mb-3 " id="traffic_by_gender">
                                        <div className="m-portlet__head">
                                            <div className="m-portlet__head-caption">
                                                <div className="m-portlet__head-title">
                                                    <h3 className="m-portlet__head-text">
                                                        Giới tính
                                            </h3>
                                                </div>
                                            </div>
                                            <div className="m-portlet__head-tools">
                                            </div>
                                        </div>
                                        <div className="m-portlet__body pl_phone_0 pr_phone_0">
                                            <ViewGender
                                                optionsGender={this.state.optionsGender}
                                                seriesGender={this.state.seriesGender}
                                                dataGender={this.state.dataGender}
                                            />
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                            <div className="col-xl-4 pl-2 pr-2 pd_phone_0">
                                <LoadingOverlay
                                    active={this.state.loadingInfoPerDay}
                                    spinner={<FadeLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={150}
                                        color={'rgb(54, 215, 183)'}
                                        loading={true}
                                    />}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(17, 17, 17, 0.24)'
                                        })
                                    }}
                                >
                                    <div className="m-portlet mb-3 " id="traffic_by_emotion">
                                        <div className="m-portlet__head">
                                            <div className="m-portlet__head-caption">
                                                <div className="m-portlet__head-title">
                                                    <h3 className="m-portlet__head-text">
                                                        Biểu cảm
                                            </h3>
                                                </div>
                                            </div>
                                            <div className="m-portlet__head-tools">
                                            </div>
                                        </div>
                                        <div className="m-portlet__body pl_phone_0 pr_phone_0">
                                            <ViewEmotion
                                                optionsEmotion={this.state.options}
                                                seriesEmotion={this.state.seriesEmotion}
                                                dataEmotion={this.state.dataEmotion}
                                            />
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                            <div className="col-xl-4 pl-2 pd_phone_0">
                                <LoadingOverlay
                                    active={this.state.loadingInfoPerDay}
                                    spinner={<FadeLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={150}
                                        color={'rgb(54, 215, 183)'}
                                        loading={true}
                                    />}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(17, 17, 17, 0.24)'
                                        })
                                    }}
                                >
                                    <div className="m-portlet mb-3 " id="traffic_by_age">
                                        <div className="m-portlet__head">
                                            <div className="m-portlet__head-caption">
                                                <div className="m-portlet__head-title">
                                                    <h3 className="m-portlet__head-text">
                                                        Tuổi
                                            </h3>
                                                </div>
                                            </div>
                                            <div className="m-portlet__head-tools">
                                            </div>
                                        </div>
                                        <div className="m-portlet__body pl_phone_0 pr_phone_0">
                                            <ViewAge
                                                chartOptionsAge={chartOptionsAge}
                                                highCharts={Highcharts}
                                                dataAge={this.state.dataAge}
                                            />
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default Dashboard;