import {combineReducers} from 'redux';
import isLogin from './isLogin';
import boxai from './boxai';
import theme from './setTheme';
import rules from './rules';
export default combineReducers({
    isLogin,
    boxai,
    theme,
    rules,
});