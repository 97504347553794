import { css } from '@emotion/core';
import moment from 'moment';
import React, { Component } from 'react';
import { Image, Row } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import ReactTooltip from 'react-tooltip';
import openSocket from 'socket.io-client';
import swal from 'sweetalert';
// import 'sweetalert2/src/sweetalert2.scss';
import { AUDIO_TIME, LIST_PERSON } from '../../../config';
import Store from '../../../store';
import ModalMoveImg from '../../modal/ModalMoveImg';
import ModalUpdatePerson from '../../modal/ModalUpdatePerson';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

let select_option = [];

if (LIST_PERSON.indexOf('1') !== -1) {
    select_option.push({ title: 'Nhân viên', value: 1 })
}
if (LIST_PERSON.indexOf('2') !== -1) {
    select_option.push({ title: 'Vip', value: 2 })
}
if (LIST_PERSON.indexOf('3') !== -1) {
    select_option.push({ title: 'Danh sách đen', value: 3 })
}
if (LIST_PERSON.indexOf('4') !== -1) {
    select_option.push({ title: 'Người lạ', value: 4 })
}
if (LIST_PERSON.indexOf('5') !== -1) {
    select_option.push({ title: 'Khách hàng', value: 5 })
}

const length_staff = 24;
const length_vip = 9;
const length_guest = 8;
const length_blacklist = 9;
const length_customer = 24;

var queue = [];
const queue_length = 20;

const myAudio = new Audio();

class RevaRC extends Component {
    constructor(props) {
        super(props);
        // this.audioRef = React.createRef();
        this.timerVip = null;
        this.timerBlack = null;
        this.state = {
            startDate: new Date(),
            isLogin: Store.getState().isLogin.isLogin,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            group: true,

            lst_person_guest: [],
            lst_person_customer: [],
            lst_person_staff: [],
            lst_person_vip: [],
            lst_person_blacklist: [],
            crrDataInfo: null,
            crr_person: null,
            loading_customer: false,
            loading_staff: false,
            loading_vip: false,
            loading_guest: false,
            loading_blacklist: false,
            showUpdatePerson: false,
            fullScreen: false,
            auto_update: false,
            modalShowMoveImage: false,
            dataMoveImage: null,
            arrAudio: [],
            muteAudio: true,
            timeSocketVip: moment(),
            timeSocketBlack: moment(),
            numberSocket: -1,
            audioPlayAudio: 2,
        }
        this.typeModal = 1;
        this.vipAudio = new Audio("/assets/audio/vip.mp3")
        this.vipAudio.loop = true;
        this.blackAudio = new Audio("/assets/audio/black.mp3")
        this.blackAudio.loop = true;
        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    lst_person_guest: [],
                    lst_person_customer: [],
                    lst_person_staff: [],
                    lst_person_vip: [],
                    lst_person_blacklist: [],
                    lst_person_change: {},
                    crrDataInfo: null,
                    crr_person: null,
                    loading_customer: false,
                    loading_staff: false,
                    loading_vip: false,
                    loading_guest: false,
                    loading_blacklist: false,
                    showUpdatePerson: false,
                    auto_update: true,
                }, () => {
                    this.getdata()
                })
            } else {
                this.setState({
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    lst_person_guest: [],
                    lst_person_customer: [],
                    lst_person_staff: [],
                    lst_person_vip: [],
                    lst_person_blacklist: [],
                    lst_person_change: {},
                    crrDataInfo: null,
                    crr_person: null,
                    loading_customer: false,
                    loading_staff: false,
                    loading_vip: false,
                    loading_guest: false,
                    loading_blacklist: false,
                    showUpdatePerson: false,
                    auto_update: true,
                })
            }
            this.setState({
                rule: Store.getState().rules.rule,
            })
        });
        this.escFunction = this.escFunction.bind(this);
        this.playAudio = this.playAudio.bind(this);

    }

    componentDidMount() {
        this.getdata();
        this.vipAudio.pause()
        this.vipAudio.load()
        this.blackAudio.pause()
        this.blackAudio.load()
        document.addEventListener("keydown", this.escFunction, false);
        this.setState({
            timeSocketBlack: moment(),
            timeSocketVip: moment()
        }, () => {
            this.classToNomalAfterMinuteBlack();
            this.classToNomalAfterMinuteVip();
        })
    }


    escFunction(event) {
        if (event.keyCode === 27) {
            this.setState({ fullScreen: false })
        }
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
        if (this.socket !== undefined) {
            if (this.socket.connected === true) {
                this.socket.disconnect();
            }
        }
        this.vipAudio.pause()
        this.vipAudio.load()
        this.blackAudio.pause()
        this.blackAudio.load()
    }

    getdata = () => {
        // this.getDataGuest();
        // this.getDataCustomer();
        this.getDataByType(1, 'lst_person_staff', 'loading_staff', length_staff);
        this.getDataByType(2, 'lst_person_vip', 'loading_vip', length_vip);
        this.getDataByType(3, 'lst_person_blacklist', 'loading_blacklist', length_blacklist);
        this.getDataByType(4, 'lst_person_guest', 'loading_guest', length_guest);
        this.getDataByType(5, 'lst_person_customer', 'loading_customer', length_customer);
        this.checkSocket();
        // this.getDataVip();
        // this.getDataBlackList();

    }

    reloadPerson = (data) => {
        let lst_person = [];
        if (data.type === 5) {
            lst_person = this.state.lst_person_customer
        } else if (data.type === 4) {
            lst_person = this.state.lst_person_guest
        } else if (data.type === 3) {
            lst_person = this.state.lst_person_blacklist
        } else if (data.type === 2) {
            lst_person = this.state.lst_person_vip
        } else if (data.type === 1) {
            lst_person = this.state.lst_person_staff
        }

        var ii = lst_person.findIndex((val) => {
            if (this.state.group) {
                return (val.person === data.person)
            }
            else {
                return (val.person === data.person && val.imgRc === data.imgRc)
            }
        })
        if (ii >= 0) {
            lst_person[ii].information.id = data.information.id;
            lst_person[ii].information.name = data.information.name;
            lst_person[ii].information.phone = data.information.phone;
            lst_person[ii].information.age = data.information.age;
            lst_person[ii].information.department = data.information.department;
        }
        if (data.type === 5) {
            this.setState({
                lst_person_customer: lst_person,
            })
        } else if (data.type === 4) {
            this.setState({
                lst_person_guest: lst_person,
            })
        } else if (data.type === 3) {
            this.setState({
                lst_person_blacklist: lst_person,
            })
        } else if (data.type === 2) {
            this.setState({
                lst_person_vip: lst_person,
            })
        } else if (data.type === 1) {
            this.setState({
                lst_person_staff: lst_person,
            })
        }
    }

    playAudio = (src) => {
        myAudio.src = src;
        let audioPlay = myAudio.play();
        if (audioPlay !== undefined) {
            audioPlay.then(_ => {
                console.log('startAudio ', moment(new Date()).format('HH:mm:ss'))
                myAudio.onended = function () {
                    console.log('endAudio ', moment(new Date()).format('HH:mm:ss'))
                    setTimeout(() => {
                        if (this.state.arrAudio.length !== 0) {
                            const arr = this.state.arrAudio;
                            arr.shift();
                            this.setState({
                                arrAudio: arr,
                            }, () => {
                                if (arr.length !== 0) {
                                    this.playAudio(arr[0])
                                }
                            })
                        }
                    }, 1000);
                }.bind(this);
            })
                .catch(error => {
                    console.log(error, src);
                    setTimeout(() => {
                        if (this.state.arrAudio.length !== 0) {
                            const arr = this.state.arrAudio;
                            arr.shift();
                            this.setState({
                                arrAudio: arr,
                            }, () => {
                                if (arr.length !== 0) {
                                    this.playAudio(arr[0])
                                }
                            })
                        }
                    }, 1000);
                });
        }
    }

    checkSocket = () => {
        if (this.state.box_engine_cf === undefined) return;
        if (this.socket !== undefined) {
            if (this.socket.connected === true) {
                this.socket.disconnect();
            }
        }

        const ids_Cam = this.state.box_engine_cf.ids_Cam;
        // let idbox = this.state.box_engine_cf.dataCam.id_box_engine.toString();
        this.socket = openSocket(`${this.state.box_engine_cf.apiSocket}`);
        this.socket.on('connect', () => {
            console.log('socket connection')
            ids_Cam.forEach(id => {
                this.socket.emit('joined', id + '');
            })
        })
        this.socket.on('disconnect', () => {
            console.log('socket disconnected!')
        })
        this.socket.on('my response', async (msg) => {
            console.log(msg);
            if (msg.operationType === 'insert') {
                // console.log(`socket receive time: ${moment(new Date()).format('HH:mm:ss')}, timeget: ${moment(msg.timeget).format('HH:mm:ss')}, delta time: ${new Date().getTime() - new Date(msg.timeget).getTime()} ms, audio: ${msg.audio_url}`)
                // if (moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss') >= moment(new Date(new Date().setHours(0, 0, 0, 0))).format('YYYY-MM-DD HH:mm:ss') && moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss') <= moment(new Date(new Date().setHours(23, 59, 59, 0))).format('YYYY-MM-DD HH:mm:ss')) {
                var lst_person = [];
                if (msg.type === 5) {
                    lst_person = this.state.lst_person_customer
                } else if (msg.type === 4) {
                    lst_person = this.state.lst_person_guest
                } else if (msg.type === 3) {
                    lst_person = this.state.lst_person_blacklist
                } else if (msg.type === 2) {
                    lst_person = this.state.lst_person_vip
                } else if (msg.type === 1) {
                    lst_person = this.state.lst_person_staff
                }



                var ii = lst_person.findIndex((val) => {
                    if (this.state.group) {
                        return (val.person === msg.person)
                    }
                    else {
                        return (val.person === msg.person && val.imgRc === msg.imgRc)
                    }
                })

                if (!this.state.muteAudio) {
                    if (msg.audio_url) {
                        const time_now = new Date();
                        const time_get = new Date(msg.timeget);
                        if (time_now - time_get < 30000) {
                            if (ii >= 0) {
                                const time_after = new Date(lst_person[ii].timeget);
                                const time_before = new Date(msg.timeget);
                                if ((time_before - time_after) / 3600000 >= parseFloat(AUDIO_TIME)) {
                                    if (this.state.arrAudio.length === 0) {
                                        this.playAudio(msg.audio_url);
                                    }
                                    this.setState({
                                        arrAudio: this.state.arrAudio.concat(msg.audio_url)
                                    })
                                }
                            } else {
                                if (this.state.arrAudio.length === 0) {
                                    this.playAudio(msg.audio_url);
                                }
                                this.setState({
                                    arrAudio: this.state.arrAudio.concat(msg.audio_url)
                                })
                            }
                        }
                    }
                }
                if (ii >= 0) {
                    lst_person[ii].imgFace.unshift({ 'imgFace': msg.imgFace, '_id': msg._id, 'imgRc': msg.imgRc, 'timeget': msg.timeget, 'timecreated': msg.timecreated });
                    lst_person[ii].imgFace_Original_Size.unshift({ 'imgFace': msg.imgFace_Original_Size, '_id': msg._id, 'imgRc': msg.imgRc, 'timeget': msg.timeget, 'timecreated': msg.timecreated });
                    lst_person[ii].timeget = msg.timeget;
                    lst_person[ii].id = msg._id;
                    lst_person[ii]._id = msg._id;
                    lst_person[ii].imgRc = msg.imgRc;
                    lst_person[ii].camera_id = msg.idCamera;
                    lst_person[ii].timecreated = msg.timecreated;
                    lst_person[ii].created_time = msg.timecreated;
                    lst_person[ii].timemomentcheckin = moment() - moment(msg['timeget']) < 60000 ? moment() : 0;
                    lst_person = lst_person.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                }
                else {
                    lst_person.unshift({
                        '_id': msg._id,
                        'id': msg._id,
                        'camera_id': msg['idCamera'],
                        'timeget': msg['timeget'],
                        'created_time': msg['timecreated'],
                        'timecreated': msg['timecreated'],
                        'imgFace': [{ imgFace: msg.imgFace, _id: msg._id }],
                        'imgFace_Original_Size': [{ imgFace: msg.imgFace_Original_Size, _id: msg._id }],
                        //'imgFull': msg['imgFull'],
                        'imgRc': msg['imgRc'],
                        //'status': msg['status'],
                        //'confidence': msg['confidence'],
                        'person': msg['person'],
                        'age': msg['age'],
                        'gender': msg['gender'],
                        'emotion': msg['emotion'],
                        'type': msg['type'],
                        'information': msg['information'],
                        'timemomentcheckin': moment() - moment(msg['timeget']) < 60000 ? moment() : 0,
                        'person_type': msg['type'],
                        'images': [{ file_name: msg.images[0].file_name }]
                    })
                }
                if (msg.type === 5) {
                    this.setState({
                        lst_person_customer: lst_person,
                    })
                } else if (msg.type === 4) {
                    this.setState({
                        lst_person_guest: lst_person,
                    })
                } else if (msg.type === 3) {
                    this.setState({
                        lst_person_blacklist: lst_person,
                        timeSocketBlack: moment(),
                    }, () => {
                        if (Math.abs(moment() - moment(msg.timeget)) < 60000) {
                            clearTimeout(this.timerBlack);
                            this.setState({
                                audioPlayAudio: this.state.audioPlayAudio !== 1 ? 3 : 1
                            })
                            this.blackAudio.load();
                            this.state.audioPlayAudio === 1 ? this.blackAudio.muted = true : this.blackAudio.muted = false;
                            this.blackAudio.play();
                            this.vipAudio.pause();
                            this.classToNomalAfterMinuteBlack();
                        }
                    })
                } else if (msg.type === 2) {
                    console.log(msg);
                    this.setState({
                        lst_person_vip: lst_person,
                        timeSocketVip: moment(),
                    }, () => {
                        if (Math.abs(moment() - moment(msg.timeget)) < 60000) {
                            this.setState({
                                audioPlayAudio: this.state.audioPlayAudio !== 1 ? 3 : 1
                            })
                            clearTimeout(this.timerVip);
                            this.state.audioPlayAudio === 1 ? this.vipAudio.muted = true : this.vipAudio.muted = false;
                            this.blackAudio.paused === false ? this.vipAudio.load() : this.vipAudio.play()
                            this.classToNomalAfterMinuteVip();
                        }
                    })
                } else if (msg.type === 1) {
                    this.setState({
                        lst_person_staff: lst_person,
                    })
                }

                // }
            } else if (msg.operationType === 'update') {
                // if (moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss') >= moment(new Date(new Date().setHours(0, 0, 0, 0))).format('YYYY-MM-DD HH:mm:ss') && moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss') <= moment(new Date(new Date().setHours(23, 59, 59, 0))).format('YYYY-MM-DD HH:mm:ss')) {
                //Update
                var lst_person = [];
                var { lst_person_customer, lst_person_blacklist, lst_person_vip, lst_person_staff, lst_person_guest } = this.state;
                if (msg.type === 5) {
                    lst_person = lst_person_customer
                    //Delete
                    for (let index = 0; index < lst_person_staff.length; index++) {
                        if (lst_person_staff[index]._id === msg._id) {
                            lst_person_staff.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_vip.length; index++) {
                        if (lst_person_vip[index]._id === msg._id) {
                            lst_person_vip.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_blacklist.length; index++) {
                        if (lst_person_blacklist[index]._id === msg._id) {
                            lst_person_blacklist.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_guest.length; index++) {
                        if (lst_person_guest[index]._id === msg._id) {
                            lst_person_guest.splice(index, 1)
                        }
                    }
                } else if (msg.type === 4) {
                    lst_person = lst_person_guest
                    //Delete
                    for (let index = 0; index < lst_person_customer.length; index++) {
                        if (lst_person_customer[index]._id === msg._id) {
                            lst_person_customer.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_staff.length; index++) {
                        if (lst_person_staff[index]._id === msg._id) {
                            lst_person_staff.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_blacklist.length; index++) {
                        if (lst_person_blacklist[index]._id === msg._id) {
                            lst_person_blacklist.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_vip.length; index++) {
                        if (lst_person_vip[index]._id === msg._id) {
                            lst_person_vip.splice(index, 1)
                        }
                    }
                } else if (msg.type === 3) {
                    lst_person = lst_person_blacklist
                    //Delete
                    for (let index = 0; index < lst_person_customer.length; index++) {
                        if (lst_person_customer[index]._id === msg._id) {
                            lst_person_customer.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_staff.length; index++) {
                        if (lst_person_staff[index]._id === msg._id) {
                            lst_person_staff.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_vip.length; index++) {
                        if (lst_person_vip[index]._id === msg._id) {
                            lst_person_vip.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_guest.length; index++) {
                        if (lst_person_guest[index]._id === msg._id) {
                            lst_person_guest.splice(index, 1)
                        }
                    }
                } else if (msg.type === 2) {
                    lst_person = lst_person_vip
                    //Delete
                    for (let index = 0; index < lst_person_customer.length; index++) {
                        if (lst_person_customer[index]._id === msg._id) {
                            lst_person_customer.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_staff.length; index++) {
                        if (lst_person_staff[index]._id === msg._id) {
                            lst_person_staff.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_blacklist.length; index++) {
                        if (lst_person_blacklist[index]._id === msg._id) {
                            lst_person_blacklist.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_guest.length; index++) {
                        if (lst_person_guest[index]._id === msg._id) {
                            lst_person_guest.splice(index, 1)
                        }
                    }
                } else if (msg.type === 1) {
                    lst_person = lst_person_staff
                    //Delete
                    for (let index = 0; index < lst_person_customer.length; index++) {
                        if (lst_person_customer[index]._id === msg._id) {
                            lst_person_customer.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_vip.length; index++) {
                        if (lst_person_vip[index]._id === msg._id) {
                            lst_person_vip.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_blacklist.length; index++) {
                        if (lst_person_blacklist[index]._id === msg._id) {
                            lst_person_blacklist.splice(index, 1)
                        }
                    }
                    for (let index = 0; index < lst_person_guest.length; index++) {
                        if (lst_person_guest[index]._id === msg._id) {
                            lst_person_guest.splice(index, 1)
                        }
                    }
                }

                var ii = lst_person.findIndex((val) => {
                    if (this.state.group) {
                        return (val.person === msg.person)
                    }
                    else {
                        return (val.person === msg.person && val.imgRc === msg.imgRc)
                    }
                })
                if (ii >= 0) {
                    lst_person[ii].imgFace.unshift({ 'imgFace': msg.imgFace, '_id': msg._id, 'imgRc': msg.imgRc, 'timeget': msg.timeget, 'timecreated': msg.timecreated });
                    lst_person[ii].imgFace_Original_Size.unshift({ 'imgFace': msg.imgFace_Original_Size, '_id': msg._id, 'imgRc': msg.imgRc, 'timeget': msg.timeget, 'timecreated': msg.timecreated });
                    lst_person[ii].timeget = msg.timeget;
                    lst_person[ii].id = msg._id;
                    lst_person[ii]._id = msg._id;
                    lst_person[ii].imgRc = msg.imgRc;
                    lst_person[ii].camera_id = msg.idCamera;
                    lst_person[ii].timecreated = msg.timecreated;
                    lst_person[ii].created_time = msg.timecreated;
                    lst_person[ii].timemomentcheckin = moment() - moment(msg['timeget']) < 60000 ? moment() : 0;
                    lst_person = lst_person.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1);
                }
                else {
                    lst_person.unshift({
                        '_id': msg._id,
                        'id': msg._id,
                        'camera_id': msg['idCamera'],
                        'timeget': msg['timeget'],
                        'created_time': msg['timecreated'],
                        'timecreated': msg['timecreated'],
                        'imgFace': [{ imgFace: msg.imgFace, _id: msg._id }],
                        'imgFace_Original_Size': [{ imgFace: msg.imgFace_Original_Size, _id: msg._id }],
                        //'imgFull': msg['imgFull'],
                        'imgRc': msg['imgRc'],
                        //'status': msg['status'],
                        //'confidence': msg['confidence'],
                        'person': msg['person'],
                        'age': msg['age'],
                        'gender': msg['gender'],
                        'emotion': msg['emotion'],
                        'type': msg['type'],
                        'information': msg['information'],
                        'timemomentcheckin': moment() - moment(msg['timeget']) < 60000 ? moment() : 0,
                        'person_type': msg['type'],
                        'images': [{ images: msg.images[0].file_name }]
                    })
                }


                if (msg.type === 5) {
                    this.setState({
                        lst_person_customer: lst_person.slice(0, 10),
                        lst_person_blacklist,
                        lst_person_vip,
                        lst_person_staff,
                    })
                } else if (msg.type === 3) {
                    this.setState({
                        lst_person_customer,
                        lst_person_blacklist: lst_person,
                        lst_person_vip,
                        lst_person_staff,
                        timeSocketBlack: moment(),
                    }, () => {
                        if (Math.abs(moment() - moment(msg.timeget)) < 60000) {
                            clearTimeout(this.timerBlack)
                            this.setState({
                                audioPlayAudio: this.state.audioPlayAudio !== 1 ? 3 : 1
                            })
                            this.blackAudio.load()
                            this.state.audioPlayAudio === 1 ? this.blackAudio.muted = true : this.blackAudio.muted = false
                            this.blackAudio.play();
                            this.vipAudio.pause();
                            this.classToNomalAfterMinuteBlack();
                        }
                    })
                } else if (msg.type === 2) {
                    console.log(msg);
                    this.setState({
                        lst_person_customer,
                        lst_person_blacklist,
                        lst_person_vip: lst_person.slice(0, length_vip),
                        lst_person_staff,
                        timeSocketVip: moment(),
                    }, () => {
                        if (Math.abs(moment() - moment(msg.timeget)) < 60000) {
                            this.setState({
                                audioPlayAudio: this.state.audioPlayAudio !== 1 ? 3 : 1
                            })
                            clearTimeout(this.timerVip);
                            this.state.audioPlayAudio == 1 ? this.vipAudio.muted = true : this.vipAudio.muted = false
                            this.blackAudio.paused === false ? this.vipAudio.load() : this.vipAudio.play()
                            this.classToNomalAfterMinuteVip();
                        }
                        console.log(lst_person);
                    })
                } else if (msg.type === 1) {
                    this.setState({
                        lst_person_customer,
                        lst_person_blacklist,
                        lst_person_vip,
                        lst_person_staff: lst_person.slice(0, length_staff),
                    })
                }
                // }
            } else {
                var { lst_person_guest, lst_person_customer, lst_person_staff, lst_person_vip, lst_person_blacklist } = this.state;
                for (let index = 0; index < lst_person_guest.length; index++) {
                    if (lst_person_guest[index]._id === msg._id) {
                        lst_person_guest.splice(index, 1)
                    }
                    this.setState({
                        lst_person_guest: lst_person_guest
                    }, () => {
                        this.getLastPerson();
                    })
                }
                for (let index = 0; index < lst_person_customer.length; index++) {
                    if (lst_person_customer[index]._id === msg._id) {
                        lst_person_customer.splice(index, 1)
                    }
                    this.setState({
                        lst_person_customer: lst_person_customer
                    }, () => {
                        this.getLastPerson();
                    })
                }
                for (let index = 0; index < lst_person_staff.length; index++) {
                    if (lst_person_staff[index]._id === msg._id) {
                        lst_person_staff.splice(index, 1)
                    }
                    this.setState({
                        lst_person_staff: lst_person_staff
                    }, () => {
                        this.getLastPerson();
                    })
                }
                for (let index = 0; index < lst_person_vip.length; index++) {
                    if (lst_person_vip[index]._id === msg._id) {
                        lst_person_vip.splice(index, 1)
                    }
                    this.setState({
                        lst_person_vip: lst_person_vip
                    }, () => {
                        this.getLastPerson();
                    })
                }
                for (let index = 0; index < lst_person_blacklist.length; index++) {
                    if (lst_person_blacklist[index]._id === msg._id) {
                        lst_person_blacklist.splice(index, 1)
                    }
                    this.setState({
                        lst_person_blacklist: lst_person_blacklist
                    },() => {
                        this.getLastPerson();
                    })
                }
            }
        })
    }


    getDataByType = (type, lst, load, index) => {
        var { group } = this.state;
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        let b = {}
        b[load] = true;
        this.setState(b, () => {
            fetch(`${this.state.box_engine_cf.api2_url}/api/person/getFaceByTime`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify({
                    type: type,
                    idCamera: this.state.box_engine_cf.ids_Cam.join(','),
                    fromDate: moment(this.state.startDate.setHours(0, 0, 0, 0)).format('YYYY-MM-DD HH:mm:ss'),
                    toDate: moment(this.state.startDate.setHours(23, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss'),
                    numberOfItem: index,
                })
            }).then((response) => {
                return response.json()
            }).then((rs) => {
                if (rs.status === 10000) {
                    var img = [];
                    console.log('rs', type, rs.data);
                    var data = rs.data;
                    for (let i = 0; i < data.length; i++) {
                        data[i].type = data[i].person_type;
                        data[i]._id = data[i].id
                        var ii = img.findIndex((val) => {
                            if (group) {
                                return (val.person === data[i].person)
                            }
                            else {
                                return (val.person === data[i].person && val.imgRc === data[i].imgRc)
                            }
                        })
                        if (ii >= 0) {
                            img[ii].imgFace.push({ 'imgFace': data[i].imgFace, '_id': data[i]._id, 'imgRc': data[i].imgRc, 'timeget': data[i].timeget, 'timecreated': data[i].timecreated });
                            img[ii].imgFace_Original_Size.push({ 'imgFace': data[i].imgFace_Original_Size, '_id': data[i]._id, 'imgRc': data[i].imgRc, 'timeget': data[i].timeget, 'timecreated': data[i].timecreated });
                        }
                        else {
                            data[i].imgFace = [{ 'imgFace': data[i].imgFace, '_id': data[i]._id, 'imgRc': data[i].imgRc, 'timeget': data[i].timeget, 'timecreated': data[i].timecreated }];
                            data[i].imgFace_Original_Size = [{ 'imgFace': data[i].imgFace_Original_Size, '_id': data[i]._id, 'imgRc': data[i].imgRc, 'timeget': data[i].timeget, 'timecreated': data[i].timecreated }];
                            data[i].timecreated = moment(data[i].timecreated).format('DD-MM-YYYY HH:mm:ss')
                            img.push(data[i]);
                        }
                    }
                    b[load] = false
                    b[lst] = img
                    console.log(b);
                    this.setState(b, () => {
                        this.getLastPerson();
                    });

                } else {
                    b[load] = false
                    this.setState(b);
                }
            }).catch(err => {
                if (err) {
                    b[load] = false
                    this.setState(b);
                }
            });
        })
    }

    getLastPerson = () => {
        let crr_person = null;
        let lst = ['lst_person_staff', 'lst_person_vip', 'lst_person_blacklist', 'lst_person_guest', 'lst_person_customer']
        for (let i = 0; i < lst.length; i++) {
            if (this.state[lst[i]].length > 0) {
                if (crr_person == null) {
                    crr_person = this.state[lst[i]][0];
                }
                else {
                    // Check time
                    let f = new moment(crr_person.timeget).format('YYYY-MM-DD HH:mm:ss');
                    let l = new moment(this.state[lst[i]][0].timeget).format('YYYY-MM-DD HH:mm:ss')
                    if (l > f) {
                        crr_person = this.state[lst[i]][0];
                    }
                }
            }
        }
        if (crr_person != null) {
            var isNewFace = true;
            for (var i = 0; i < queue.length; i++) {
                if (crr_person._id == queue[i]._id && crr_person.idCamera == queue[i].idCamera && crr_person.type == queue[i].type) {
                    var f = new Date(crr_person.timeget).getTime() / 1000;
                    var l = new Date(queue[i].timeget).getTime() / 1000;
                    if (f - l < 30)
                        isNewFace = false;
                    queue[i].timeget = crr_person.timeget;
                }
            }
            if (isNewFace) {
                if (queue.length >= queue_length)
                    queue.shift();
                queue.push(crr_person);
                fetch(`${this.state.box_engine_cf.api2_url}/api/person/getById/${crr_person.person}`)
                    .then((response) => {
                        return response.json()
                    }).then((rs) => {
                        if (rs.status === 10000) {
                            crr_person.information = rs.data;
                            this.setState({ crr_person })
                        }
                    })
            }
            console.log(queue);

        }
    }

    modalCloseMoveImage = (old_type_person) => {
        this.setState({
            modalShowMoveImage: false,
        }, () => {
            // console.log(old_type_person);
            // if (old_type_person === 1) {
            //     this.getDataByType(1, 'lst_person_staff', 'loading_staff', length_staff);
            // } else if (old_type_person  === 2) {
            //     this.getDataByType(2, 'lst_person_vip', 'loading_vip', length_vip);
            //     // this.vipAudio.pause();
            // } else if (old_type_person === 3) {
            //     this.getDataByType(3, 'lst_person_blacklist', 'loading_blacklist', length_blacklist);
            //     this.blackAudio.pause();
            // } else if (old_type_person === 4) {
            //     this.getDataByType(4, 'lst_person_guest', 'loading_guest', length_guest);
            // } else if (old_type_person === 5) {
            //     this.getDataByType(5, 'lst_person_customer', 'loading_customer', length_customer);
            // }else if (old_type_person === null) {

            // }
        });
    }

    closeModal = (crrDataInfo, old_id, old_type) => {
        this.setState({ showUpdatePerson: false })
        if (crrDataInfo == undefined) return;

        var { lst_person_guest, lst_person_customer, lst_person_staff, lst_person_vip, lst_person_blacklist } = this.state;
        console.log(crrDataInfo);
        //so sánh nếu type cũ khác type mới thì sẽ không thay đổi
        if (old_type !== crrDataInfo.type) {

            //push obj vào type vừa chuyển đến
            if (crrDataInfo.type === 1) {
                lst_person_staff.unshift(crrDataInfo)
            } else if (crrDataInfo.type === 2) {
                lst_person_vip.unshift(crrDataInfo)
            } else if (crrDataInfo.type === 3) {
                lst_person_blacklist.unshift(crrDataInfo)
            } else if (crrDataInfo.type === 4) {
                lst_person_guest.unshift(crrDataInfo)
            } else {
                lst_person_customer.unshift(crrDataInfo)
            }

            //remove obj khỏi type bị chuyển
            if (old_type === 1) {
                lst_person_staff = lst_person_staff.filter((item, index) => {
                    return item['person'] !== old_id
                });
            } else if (old_type === 2) {
                lst_person_vip = lst_person_vip.filter((item, index) => {
                    return item['person'] !== old_id
                });
            } else if (old_type === 3) {
                lst_person_blacklist = lst_person_blacklist.filter((item, index) => {
                    return item['person'] !== old_id
                });
            } else if (old_type === 4) {
                lst_person_guest = lst_person_guest.filter((item, index) => {
                    return item['person'] !== old_id
                });
            } else {
                lst_person_customer = lst_person_customer.filter((item, index) => {
                    return item['person'] !== old_id
                });
            }
        }
        else {
            // Update data
            if (old_type === 1) {
                lst_person_staff = lst_person_staff.filter((item, index) => {
                    return item['person'] !== old_id
                });
                lst_person_staff.push(crrDataInfo)
            } else if (old_type === 2) {
                lst_person_vip = lst_person_vip.filter((item, index) => {
                    return item['person'] !== old_id
                });
                lst_person_vip.push(crrDataInfo)
            } else if (old_type === 3) {
                lst_person_blacklist = lst_person_blacklist.filter((item, index) => {
                    return item['person'] !== old_id
                });
                lst_person_blacklist.push(crrDataInfo)
            } else if (old_type === 4) {
                lst_person_guest = lst_person_guest.filter((item, index) => {
                    return item['person'] !== old_id
                });
                lst_person_guest.push(crrDataInfo)
            } else {
                lst_person_customer = lst_person_customer.filter((item, index) => {
                    return item['person'] !== old_id
                });
                lst_person_customer.push(crrDataInfo)
            }
        }

        lst_person_guest = lst_person_guest.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
        lst_person_customer = lst_person_customer.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
        lst_person_staff = lst_person_staff.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
        lst_person_vip = lst_person_vip.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
        lst_person_blacklist = lst_person_blacklist.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
        this.setState({
            lst_person_guest,
            lst_person_customer,
            lst_person_staff,
            lst_person_vip,
            lst_person_blacklist,
        })
    }

    modalClose = (old_track, new_track, type) => {
        console.log("split success");
        var lst_person;
        var lst_person_guest = this.state.lst_person_guest;
        var crr_person = this.state.crr_person;

        if (crr_person.type === 4) {
            lst_person = this.state.items
        } else if (crr_person.type === 3) {
            lst_person = this.state.lst_person_blacklist
        } else if (crr_person.type === 2) {
            lst_person = this.state.lst_person_vip
        } else if (crr_person.type === 1) {
            lst_person = this.state.lst_person_staff
        }

        if (type === 'split') {
            if (crr_person.type === 4) {
                for (let ii = 0; ii < lst_person.length; ii++) {
                    if (lst_person[ii].person === crr_person.person) {
                        lst_person[ii]._id = old_track._id
                        lst_person[ii].imgRc = old_track.imgRc
                        lst_person[ii].timeget = old_track.timeget
                        lst_person[ii].timecreated = old_track.timecreated
                        //imgFace
                        lst_person[ii].imgFace[0].imgFace = old_track.imgFace
                        lst_person[ii].imgFace[0]._id = old_track._id
                        lst_person[ii].imgFace[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace[0].timeget = old_track.timeget
                        //imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0]._id = old_track._id
                        lst_person[ii].imgFace_Original_Size[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace_Original_Size[0].timeget = old_track.timeget
                    }
                }
                lst_person.unshift(new_track)
                lst_person_guest.unshift(new_track)
                lst_person = lst_person.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                lst_person_guest = lst_person_guest.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                this.setState({
                    items: lst_person,
                    lst_person_guest: lst_person_guest,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 3) {
                for (let ii = 0; ii < lst_person.length; ii++) {
                    if (lst_person[ii].person === crr_person.person) {
                        lst_person[ii]._id = old_track._id
                        lst_person[ii].imgRc = old_track.imgRc
                        lst_person[ii].timeget = old_track.timeget
                        lst_person[ii].timecreated = old_track.timecreated
                        //imgFace
                        lst_person[ii].imgFace[0].imgFace = old_track.imgFace
                        lst_person[ii].imgFace[0]._id = old_track._id
                        lst_person[ii].imgFace[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace[0].timeget = old_track.timeget
                        //imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0]._id = old_track._id
                        lst_person[ii].imgFace_Original_Size[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace_Original_Size[0].timeget = old_track.timeget
                    }
                }
                lst_person.unshift(new_track)
                lst_person = lst_person.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                this.setState({
                    lst_person_blacklist: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 2) {
                for (let ii = 0; ii < lst_person.length; ii++) {
                    if (lst_person[ii].person === crr_person.person) {
                        lst_person[ii]._id = old_track._id
                        lst_person[ii].imgRc = old_track.imgRc
                        lst_person[ii].timeget = old_track.timeget
                        lst_person[ii].timecreated = old_track.timecreated
                        //imgFace
                        lst_person[ii].imgFace[0].imgFace = old_track.imgFace
                        lst_person[ii].imgFace[0]._id = old_track._id
                        lst_person[ii].imgFace[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace[0].timeget = old_track.timeget
                        //imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0]._id = old_track._id
                        lst_person[ii].imgFace_Original_Size[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace_Original_Size[0].timeget = old_track.timeget
                    }
                }
                lst_person.unshift(new_track)
                lst_person = lst_person.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                this.setState({
                    lst_person_vip: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 1) {
                for (let ii = 0; ii < lst_person.length; ii++) {
                    if (lst_person[ii].person === crr_person.person) {
                        lst_person[ii]._id = old_track._id
                        lst_person[ii].imgRc = old_track.imgRc
                        lst_person[ii].timeget = old_track.timeget
                        lst_person[ii].timecreated = old_track.timecreated
                        //imgFace
                        lst_person[ii].imgFace[0].imgFace = old_track.imgFace
                        lst_person[ii].imgFace[0]._id = old_track._id
                        lst_person[ii].imgFace[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace[0].timeget = old_track.timeget
                        //imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0]._id = old_track._id
                        lst_person[ii].imgFace_Original_Size[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace_Original_Size[0].timeget = old_track.timeget
                    }
                }
                lst_person.unshift(new_track)
                lst_person = lst_person.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                this.setState({
                    lst_person_staff: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            }
        } else if (type === 'delete') {
            if (crr_person.type === 4) {
                for (let i = 0; i < lst_person.length; i++) {
                    if (lst_person[i].person === crr_person.person) {
                        lst_person[i].imgFace[0].imgFace = old_track.imgFace;
                        lst_person[i].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size;
                    }
                }
                this.setState({
                    items: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 3) {
                for (let i = 0; i < lst_person.length; i++) {
                    if (lst_person[i].person === crr_person.person) {
                        lst_person[i].imgFace[0].imgFace = old_track.imgFace;
                        lst_person[i].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size;
                    }
                }
                this.setState({
                    lst_person_blacklist: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 2) {
                for (let i = 0; i < lst_person.length; i++) {
                    if (lst_person[i].person === crr_person.person) {
                        lst_person[i].imgFace[0].imgFace = old_track.imgFace;
                        lst_person[i].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size;
                    }
                }
                this.setState({
                    lst_person_vip: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 1) {
                for (let i = 0; i < lst_person.length; i++) {
                    if (lst_person[i].person === crr_person.person) {
                        lst_person[i].imgFace[0].imgFace = old_track.imgFace;
                        lst_person[i].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size;
                    }
                }
                this.setState({
                    lst_person_staff: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            }
        } else {
            this.setState({
                modalShow: false,
            });
        }
    }

    modalCloseChangeTrack = (old_id, new_id, old_id_track, typeFormChange) => {
        console.log("Change track success");
        var items = this.state.items;
        var lst_person_guest = this.state.lst_person_guest;
        var crr_person = this.state.crr_person;
        if (typeFormChange !== 4) {
            if (old_id !== undefined) {
                // items = items.filter((item, index) => {
                //     return item['person'] !== old_id
                // });
                lst_person_guest = lst_person_guest.filter((item, index) => {
                    return item['person'] !== old_id
                });
            }

            this.setState({
                modalChangeTrackShow: false,
                lst_person_guest: lst_person_guest,
                // items: items
            });
        } else {
            // for (let index = 0; index < items.length; index++) {
            //     for (let i = 0; i < items[index].imgFace.length; i++) {
            //         if (items[index].imgFace[i]._id == old_id_track) {
            //             items[index].imgFace.splice(i, 1)
            //             items[index].imgFace_Original_Size.splice(i, 1)
            //             i--
            //         }
            //     }
            // }
            // for (let index = 0; index < items.length; index++) {
            //     if (items[index].imgFace.length > 0) {
            //         items[index]['_id'] = items[index].imgFace[0]._id
            //         items[index]['imgRc'] = items[index].imgFace[0].imgRc
            //         items[index]['timeget'] = items[index].imgFace[0].timeget
            //     } else {
            //         items = items.filter((item, index) => {
            //             return item['person'] !== old_id
            //         });
            //     }
            // }

            // items = items.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)

            if (crr_person !== null) {
                if (crr_person.imgFace.length > 0) {
                    this.getinfo(crr_person);
                    this.setState({
                        // items: items,
                        modalChangeTrackShow: false,
                    });
                } else {
                    // items = items.filter((item, index) => {
                    //     return item['person'] !== old_id
                    // });

                    this.setState({
                        modalChangeTrackShow: false,
                        // items: items,
                        crr_person: null,
                        info: null,
                        dataHistory: [],
                        crrDataInfo: {
                            '_id': "",
                            'name': '',
                            'phone': '',
                            'age': '',
                            'gender': '',
                            'department': '',
                            'type': '',
                            'last_type': ''
                        },
                    });
                }
            } else {
                this.setState({
                    // items: items,
                    modalChangeTrackShow: false,
                });
            }
        }
    }
    moveImgToTrain = (event, value) => {
        this.setState({
            dataMoveImage: value,
        }, () => {
            this.setState({
                modalShowMoveImage: true
            });
        });
    }

    classToNomalAfterMinuteVip = () => {
        this.timerVip = setTimeout(() => {
            this.setState({
                audioPlayAudio: this.state.audioPlayAudio === 1 ? 1 : this.blackAudio.duration > 0 && !this.blackAudio.paused ? 3 : 2,
                timeSocketVip: moment()
            })
            this.vipAudio.pause()
        }, 63000);
    }

    classToNomalAfterMinuteBlack = () => {
        this.timerBlack = setTimeout(() => {
            this.setState({
                audioPlayAudio: this.state.audioPlayAudio === 1 ? 1 : 2,
                timeSocketBlack: moment()
            })
            this.blackAudio.pause()
        }, 63000);
    }

    clickToAudioPlaying = () => {
        this.setState({
            audioPlayAudio: this.state.audioPlayAudio === 1 ? 2 : this.state.audioPlayAudio === 3 ? 2 : 1,
        })
        if (this.state.audioPlayAudio === 3) {
            this.vipAudio.pause()
            this.vipAudio.load()
            this.blackAudio.pause()
            this.blackAudio.load();
        }
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        const aliasCamera = (id) => {
            if (this.state.box_engine_cf) {
                const listCamera = this.state.box_engine_cf.listCamera;

                const dataCamera = listCamera.find(e => e.id_box_engine == id);

                if (dataCamera) {
                    if (dataCamera.alias_box_engine) {
                        return `${dataCamera.alias_box_engine}(${id})`;
                    } else {
                        return `${dataCamera.name_engine}_${id}`;
                    }
                } else {
                    return id;
                }
            }
        }
        let bullet_img_staffs = this.state.lst_person_staff.slice(0, this.state.lst_person_customer.length === 0 ?
            length_staff : this.state.lst_person_customer < 5 ?
                length_staff - 4 : this.state.lst_person_customer.length < 9 ?
                    length_staff - 8 : this.state.fullScreen ?
                        length_staff - 8 : length_staff - 12
        ).map((e, i) => {
            var urlImg = ""
            // if (e.images.length > 0) {
            //     const date = e.images[0].file_name.split("_");
            //     urlImg = e.mount_folder + "/" + date[1].slice(0, 6) + "/" + e.camera_id;
            // }
            return (
                <div className="item p-1 pb-2 col-xl-3 col-lg-4 col-md-6 col_xxl-4" key={i}>

                    <div className="row m-0 p-0" onClick={() => { this.setState({ showUpdatePerson: true, crrDataInfo: e }) }}>
                        <div className='col-4 p-0 img-cover' style={{ 'height': '80px', 'backgroundImage': `url(${e.imgFace_Original_Size[0].imgFace === undefined ? "/img/photo-placeholder.png" : e.imgFace_Original_Size[0].imgFace.indexOf('http') >= 0 ? e.imgFace_Original_Size[0].imgFace : `${this.state.box_engine_cf.api2_url}/view${e.imgFace_Original_Size[0].imgFace}`})` }}>
                            {/* <img width="100%" src={`${`${this.state.box_engine_cf.api2_url}/view/image/${urlImg}/${e.images[0].file_name}`}`} style={{'maxHeight': '100px'}} /> */}
                        </div>
                        <div className="col-8 pl-3" id="staff_img_5facc2804a92151b011880ad">
                            <div>{e.information.name}</div>
                            <div>{e.information.department}</div>
                            <div>{e.timeget ? moment(e.timeget).format('HH:mm:ss') : ''}</div>
                            <div>{e.timeget ? moment(e.timeget).format('DD-MM-YYYY') : ''}</div>
                            <div><b>{aliasCamera(e.camera_id)}</b></div>
                        </div>
                    </div>
                    {
                        this.state.rule !== '5' &&
                        <>
                            <button
                                onClick={(event) => {
                                    event.preventDefault();
                                    swal({
                                        title: "Bạn có chắc?",
                                        text: "Bạn có chắc muốn xóa hay không?",
                                        icon: "warning",
                                        buttons: {
                                            confirm: 'Xóa',
                                            cancel: 'Hủy',
                                        },
                                    })
                                        .then(name => {
                                            if (!name) throw null;
                                            return fetch(`${this.state.box_engine_cf.api}/api/person/deleteTrack`, {
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json',
                                                    'Content-Type': 'application/json',
                                                    'Authorization': Store.getState().isLogin.access_token
                                                },
                                                body: JSON.stringify({
                                                    'idCamera': e.camera_id,
                                                    'person': e.person,
                                                    'track': e.id,
                                                    'type': e.type
                                                })
                                            })
                                        })
                                        .then(response => {
                                            return response.json()
                                        }).then(data => {
                                            if (data.status === 10000) {
                                                var lst_person_staff = this.state.lst_person_staff;
                                                var crr_person = this.state.crr_person;

                                                // for (let index = 0; index < lst_person_staff.length; index++) {
                                                //     for (let i = 0; i < lst_person_staff[index].imgFace.length; i++) {
                                                //         if (lst_person_staff[index].imgFace[i].imgRc == e.imgRc) {
                                                //             lst_person_staff[index].imgFace.splice(i, 1)
                                                //             lst_person_staff[index].imgFace_Original_Size.splice(i, 1)
                                                //             crr_person.imgFace.splice(i, 1)
                                                //             crr_person.imgFace_Original_Size.splice(i, 1)
                                                //             i--
                                                //         }
                                                //     }
                                                // }

                                                if (crr_person.imgFace.length > 0) {
                                                    for (let index = 0; index < lst_person_staff.length; index++) {
                                                        lst_person_staff[index]['_id'] = lst_person_staff[index].imgFace[0]._id
                                                        lst_person_staff[index]['imgRc'] = lst_person_staff[index].imgFace[0].imgRc
                                                        lst_person_staff[index]['timeget'] = lst_person_staff[index].imgFace[0].timeget
                                                    }

                                                    crr_person._id = crr_person.imgFace[0]._id
                                                    crr_person.imgRc = crr_person.imgFace[0].imgRc
                                                    crr_person.timeget = crr_person.imgFace[0].timeget

                                                    swal("Thành công!", "Xóa thành công!", "success", {
                                                        buttons: false,
                                                        timer: 1500,
                                                    });

                                                    lst_person_staff = lst_person_staff.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)

                                                    this.setState({
                                                        lst_person_staff: lst_person_staff,
                                                        crr_person: crr_person,
                                                    });
                                                } else {

                                                    lst_person_staff = lst_person_staff.filter((item, index) => {
                                                        return item['person'] !== e.person
                                                    });

                                                    this.setState({
                                                        lst_person_staff: lst_person_staff,
                                                        crr_person: null,
                                                        info: null,
                                                        dataHistory: [],
                                                        crrDataInfo: {
                                                            '_id': "",
                                                            'name': '',
                                                            'phone': '',
                                                            'age': '',
                                                            'gender': '',
                                                            'type': '',
                                                            'last_type': ''
                                                        },
                                                    });
                                                    swal("Thành công!", "Xóa thành công!", "success", {
                                                        buttons: false,
                                                        timer: 1500,
                                                    });
                                                }
                                            }
                                            else {
                                                swal("Thất bại!", "Xóa thất bại!", "error");
                                            }
                                            // this.getDataByType(1, 'lst_person_staff', 'loading_staff', length_staff);
                                        }).catch(err => {
                                            if (err) {
                                                swal("Thất bại!", "Không thể kết nối đến sever!", "error");
                                            }
                                        });
                                }}
                                className="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only  m-btn--pill m-btn--air btn-remove-customer" style={{ zIndex: 9, left: -5, top: -5 }}>
                                <i className="la la-close"></i>
                            </button>
                            <button
                                onClick={(event) => this.moveImgToTrain(event, e)}
                                className={"btn btn-success m-btn m-btn--icon btn-sm m-btn--icon-only m-btn--pill m-btn--air btn-remove-customer" + (e.idStaff === 'unknown' ? 'd-none' : '')} data-tip data-for='MoveToTrain'
                                style={{ zIndex: 9, left: 15, top: -5 }}
                            >
                                <ReactTooltip id='MoveToTrain' type='dark' effect='solid'>
                                    <span>Chuyển thành ảnh mẫu</span>
                                </ReactTooltip>
                                <i className="la la-share"></i>
                            </button>
                        </>
                    }
                </div>
            )
        })
        console.log('okeoke', this.state.lst_person_guest);
        let bullet_img_guest = this.state.lst_person_guest.slice(0, (this.state.fullScreen && this.state.lst_person_staff.length + this.state.lst_person_customer.length <= 24) ?
            length_guest : this.state.fullScreen ? 12 : this.state.rule === '5' ? length_guest : length_guest).map((e, i) => {
                return (
                    <div className="item p-1 col-xl-3 col-lg-4 col-md-6 col_xxl-4" key={i}>
                        <div className="row m-0 p-0" onClick={() => { this.setState({ showUpdatePerson: true, crrDataInfo: e }) }}>
                            <div className='col-4 p-0 img-cover' style={{ 'height': '80px', 'backgroundImage': `url(${e.imgFace_Original_Size[0].imgFace === undefined ? "/img/photo-placeholder.png" : e.imgFace_Original_Size[0].imgFace.indexOf('http') >= 0 ? e.imgFace_Original_Size[0].imgFace : `${this.state.box_engine_cf.api2_url}/view${e.imgFace_Original_Size[0].imgFace}`})` }}>
                            </div>
                            <div className="col-8 pl-3" id="staff_img_5facc2804a92151b011880ad">
                                <div>{e.timeget ? moment(e.timeget).format('HH:mm:ss') : ''}</div>
                                <div>{e.timeget ? moment(e.timeget).format('DD-MM-YYYY') : ''}</div>
                                <div><b>{aliasCamera(e.camera_id)}</b></div>
                            </div>
                        </div>
                        {
                            this.state.rule !== '5' &&
                            <>
                                <button
                                    onClick={(event) => {
                                        event.preventDefault();
                                        swal({
                                            title: "Bạn có chắc?",
                                            text: "Bạn có chắc muốn xóa hay không?",
                                            icon: "warning",
                                            buttons: {
                                                confirm: 'Xóa',
                                                cancel: 'Hủy',
                                            },
                                        })
                                            .then(name => {
                                                if (!name) throw null;
                                                return fetch(`${this.state.box_engine_cf.api}/api/person/deleteTrack`, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Accept': 'application/json',
                                                        'Content-Type': 'application/json',
                                                        'Authorization': Store.getState().isLogin.access_token
                                                    },
                                                    body: JSON.stringify({
                                                        'idCamera': e.camera_id,
                                                        'person': e.person,
                                                        'track': e.id,
                                                        'type': e.type
                                                    })
                                                })
                                            })
                                            .then(response => {
                                                return response.json()
                                            }).then(data => {
                                                if (data.status === 10000) {
                                                    var lst_person_guest = this.state.lst_person_guest;
                                                    var crr_person = this.state.crr_person;

                                                    // for (let index = 0; index < lst_person_guest.length; index++) {
                                                    //     for (let i = 0; i < lst_person_guest[index].imgFace.length; i++) {
                                                    //         if (lst_person_guest[index].imgFace[i].imgRc == e.imgRc) {
                                                    //             lst_person_guest[index].imgFace.splice(i, 1)
                                                    //             lst_person_guest[index].imgFace_Original_Size.splice(i, 1)
                                                    //             crr_person.imgFace.splice(i, 1)
                                                    //             crr_person.imgFace_Original_Size.splice(i, 1)
                                                    //             i--
                                                    //         }
                                                    //     }
                                                    // }

                                                    if (crr_person.imgFace.length > 0) {
                                                        for (let index = 0; index < lst_person_guest.length; index++) {
                                                            lst_person_guest[index]['_id'] = lst_person_guest[index].imgFace[0]._id
                                                            lst_person_guest[index]['id'] = lst_person_guest[index].imgFace[0]._id
                                                            lst_person_guest[index]['imgRc'] = lst_person_guest[index].imgRc
                                                            lst_person_guest[index]['timeget'] = lst_person_guest[index].imgFace[0].timeget
                                                        }

                                                        crr_person._id = crr_person.imgFace[0]._id
                                                        crr_person.imgRc = crr_person.imgFace[0].imgRc
                                                        crr_person.timeget = crr_person.imgFace[0].timeget

                                                        swal("Thành công!", "Xóa thành công!", "success", {
                                                            buttons: false,
                                                            timer: 1500,
                                                        });

                                                        lst_person_guest = lst_person_guest.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)

                                                        this.setState({
                                                            lst_person_guest: lst_person_guest,
                                                            crr_person: crr_person,
                                                        });
                                                    } else {

                                                        lst_person_guest = lst_person_guest.filter((item, index) => {
                                                            return item['person'] !== e.person
                                                        });

                                                        this.setState({
                                                            lst_person_guest: lst_person_guest,
                                                            crr_person: null,
                                                            info: null,
                                                            dataHistory: [],
                                                            crrDataInfo: {
                                                                '_id': "",
                                                                'name': '',
                                                                'phone': '',
                                                                'age': '',
                                                                'gender': '',
                                                                'type': '',
                                                                'last_type': ''
                                                            },
                                                        });
                                                        swal("Thành công!", "Xóa thành công!", "success", {
                                                            buttons: false,
                                                            timer: 1500,
                                                        });
                                                    }
                                                }
                                                else {
                                                    swal("Thất bại!", "Xóa thất bại!", "error");
                                                }
                                                // this.getDataByType(4, 'lst_person_guest', 'loading_guest', length_guest);
                                            }).catch(err => {
                                                if (err) {
                                                    swal("Thất bại!", "Không thể kết nối đến sever!", "error");
                                                }
                                            });
                                    }}
                                    className="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only  m-btn--pill m-btn--air btn-remove-customer" style={{ zIndex: 9, left: -5, top: -5 }}>
                                    <i className="la la-close"></i>
                                </button>
                                <button
                                    onClick={(event) => {
                                        this.moveImgToTrain(event, e);
                                    }}
                                    className={"btn btn-success m-btn m-btn--icon btn-sm m-btn--icon-only m-btn--pill m-btn--air btn-remove-customer" + (e.idStaff === 'unknown' ? 'd-none' : '')} data-tip data-for='MoveToTrain'
                                    style={{ zIndex: 9, left: 15, top: -5 }}
                                >
                                    <ReactTooltip id='MoveToTrain' type='dark' effect='solid'>
                                        <span>Chuyển thành ảnh mẫu</span>
                                    </ReactTooltip>
                                    <i className="la la-share"></i>
                                </button>
                            </>
                        }
                    </div>
                )
            })

        const bullet_img_vip = this.state.lst_person_vip.slice(0, this.state.fullScreen ? length_vip : 6).map((e, i) => {
            return (
                <div className="item p-1 col-xl-4 col-lg-4 col-md-6 col_xxl-6" key={i}>
                    <div id={`formVip` + `${e.information.id}`} className={this.state.timeSocketVip && e.timemomentcheckin && Math.abs(this.state.timeSocketVip - e.timemomentcheckin) < 30000 ? "row m-0 p-0 border-vip" : "row m-0 p-0 border-nomal"} onClick={() => { this.setState({ showUpdatePerson: true, crrDataInfo: e }) }}>
                        <div className='col-4 p-0 img-cover' style={{ 'height': '80px', 'backgroundImage': `url(${e.imgFace_Original_Size[0].imgFace == undefined ? "/img/photo-placeholder.png" : e.imgFace_Original_Size[0].imgFace.indexOf('http') >= 0 ? e.imgFace_Original_Size[0].imgFace : `${this.state.box_engine_cf.api2_url}/view${e.imgFace_Original_Size[0].imgFace}`})` }}>
                            {/* <img  src={`${this.state.box_engine_cf.api}/view/static/${e.imgFace[0] ? e.imgFace[0].imgFace : ''}`} style={{'maxHeight': '60px'}} /> */}
                        </div>
                        <div className="col-8 pl-3" id="staff_img_5facc2804a92151b011880ad">
                            <div>{e.information.name}</div>
                            <div>{e.timeget ? moment(e.timeget).format('HH:mm:ss') : ''}</div>
                            <div>{e.timeget ? moment(e.timeget).format('DD-MM-YYYY') : ''}</div>
                            <div><b>{aliasCamera(e.camera_id)}</b></div>
                        </div>
                    </div>
                    {
                        this.state.rule !== '5' &&
                        <>
                            <button
                                onClick={(event) => {
                                    event.preventDefault();
                                    swal({
                                        title: "Bạn có chắc?",
                                        text: "Bạn có chắc muốn xóa hay không?",
                                        icon: "warning",
                                        buttons: {
                                            confirm: 'Xóa',
                                            cancel: 'Hủy',
                                        },
                                    })
                                        .then(name => {
                                            if (!name) throw null;
                                            return fetch(`${this.state.box_engine_cf.api}/api/person/deleteTrack`, {
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json',
                                                    'Content-Type': 'application/json',
                                                    'Authorization': Store.getState().isLogin.access_token
                                                },
                                                body: JSON.stringify({
                                                    'idCamera': e.camera_id,
                                                    'person': e.person,
                                                    'track': e.id,
                                                    'type': e.type
                                                })
                                            })
                                        })
                                        .then(response => {
                                            return response.json()
                                        }).then(data => {
                                            if (data.status === 10000) {
                                                var lst_person_vip = this.state.lst_person_vip;
                                                var crr_person = this.state.crr_person;

                                                // for (let index = 0; index < lst_person_vip.length; index++) {
                                                //     for (let i = 0; i < lst_person_vip[index].imgFace.length; i++) {
                                                //         if (lst_person_vip[index].imgFace[i].imgRc == e.imgRc) {
                                                //             lst_person_vip[index].imgFace.splice(i, 1)
                                                //             lst_person_vip[index].imgFace_Original_Size.splice(i, 1)
                                                //             crr_person.imgFace.splice(i, 1)
                                                //             crr_person.imgFace_Original_Size.splice(i, 1)
                                                //             i--
                                                //         }
                                                //     }
                                                // }

                                                if (crr_person.imgFace.length > 0) {
                                                    for (let index = 0; index < lst_person_vip.length; index++) {
                                                        lst_person_vip[index]['_id'] = lst_person_vip[index].imgFace[0]._id
                                                        lst_person_vip[index]['imgRc'] = lst_person_vip[index].imgFace[0].imgRc
                                                        lst_person_vip[index]['timeget'] = lst_person_vip[index].imgFace[0].timeget
                                                    }

                                                    crr_person._id = crr_person.imgFace[0]._id
                                                    crr_person.imgRc = crr_person.imgFace[0].imgRc
                                                    crr_person.timeget = crr_person.imgFace[0].timeget

                                                    swal("Thành công!", "Xóa thành công!", "success", {
                                                        buttons: false,
                                                        timer: 1500,
                                                    });

                                                    lst_person_vip = lst_person_vip.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)

                                                    this.setState({
                                                        lst_person_vip: lst_person_vip,
                                                        crr_person: crr_person,
                                                    });
                                                } else {

                                                    lst_person_vip = lst_person_vip.filter((item, index) => {
                                                        return item['person'] !== e.person
                                                    });

                                                    this.setState({
                                                        lst_person_vip: lst_person_vip,
                                                        crr_person: null,
                                                        info: null,
                                                        dataHistory: [],
                                                        crrDataInfo: {
                                                            '_id': "",
                                                            'name': '',
                                                            'phone': '',
                                                            'age': '',
                                                            'gender': '',
                                                            'type': '',
                                                            'last_type': ''
                                                        },
                                                    });
                                                    swal("Thành công!", "Xóa thành công!", "success", {
                                                        buttons: false,
                                                        timer: 1500,
                                                    });
                                                }
                                            }
                                            else {
                                                swal("Thất bại!", "Xóa thất bại!", "error");
                                            }
                                        }).catch(err => {
                                            if (err) {
                                                swal("Thất bại!", "Không thể kết nối đến sever!", "error");
                                            }
                                        });
                                }}
                                className="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only  m-btn--pill m-btn--air btn-remove-customer" style={{ zIndex: 9, left: -5, top: -5 }}>
                                <i className="la la-close"></i>
                            </button>
                            <button
                                onClick={(event) => this.moveImgToTrain(event, e)}
                                className={"btn btn-success m-btn m-btn--icon btn-sm m-btn--icon-only m-btn--pill m-btn--air btn-remove-customer" + (e.idStaff === 'unknown' ? 'd-none' : '')} data-tip data-for='MoveToTrain'
                                style={{ zIndex: 9, left: 15, top: -5 }}
                            >
                                <ReactTooltip id='MoveToTrain' type='dark' effect='solid'>
                                    <span>Chuyển thành ảnh mẫu</span>
                                </ReactTooltip>
                                <i className="la la-share"></i>
                            </button>
                        </>
                    }
                </div>
            )
        })
        console.log(this.state.lst_person_blacklist);
        const bullet_img_blacklist = this.state.lst_person_blacklist.slice(0, this.state.fullScreen ? length_vip : 6).map((e, i) => {
            return (
                <div className="item p-1 col-xl-4 col-lg-4 col-md-6 col_xxl-6" key={i}>
                    <div id={'formBlack' + `${e.information.id}`} className={this.state.timeSocketBlack && e.timemomentcheckin && Math.abs(this.state.timeSocketBlack - e.timemomentcheckin) < 30000 ? "row m-0 p-0 border-black" : "row m-0 p-0 border-nomal"} onClick={() => { this.setState({ showUpdatePerson: true, crrDataInfo: e }) }}>
                        <div className='col-4 p-0 img-cover' style={{ 'height': '80px', 'backgroundImage': `url(${e.imgFace_Original_Size[0].imgFace == undefined ? "/img/photo-placeholder.png" : e.imgFace_Original_Size[0].imgFace.indexOf('http') >= 0 ? e.imgFace_Original_Size[0].imgFace : `${this.state.box_engine_cf.api2_url}/view${e.imgFace_Original_Size[0].imgFace}`})` }}>
                            {/* <img  src={`${this.state.box_engine_cf.api}/view/static/${e.imgFace[0] ? e.imgFace[0].imgFace : ''}`} style={{'maxHeight': '60px'}} /> */}
                        </div>
                        <div className="col-8 pl-3" id="staff_img_5facc2804a92151b011880ad">
                            <div>{e.information.name}</div>
                            <div>{e.timeget ? moment(e.timeget).format('HH:mm:ss') : ''}</div>
                            <div>{e.timeget ? moment(e.timeget).format('DD-MM-YYYY') : ''}</div>
                            <div><b>{aliasCamera(e.camera_id)}</b></div>
                        </div>
                    </div>
                    {
                        this.state.rule !== '5' &&
                        <>
                            <button
                                onClick={(event) => {
                                    event.preventDefault();
                                    swal({
                                        title: "Bạn có chắc?",
                                        text: "Bạn có chắc muốn xóa hay không?",
                                        icon: "warning",
                                        buttons: {
                                            confirm: 'Xóa',
                                            cancel: 'Hủy',
                                        },
                                    })
                                        .then(name => {
                                            if (!name) throw null;
                                            return fetch(`${this.state.box_engine_cf.api}/api/person/deleteTrack`, {
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json',
                                                    'Content-Type': 'application/json',
                                                    'Authorization': Store.getState().isLogin.access_token
                                                },
                                                body: JSON.stringify({
                                                    'idCamera': e.camera_id,
                                                    'person': e.person,
                                                    'track': e.id,
                                                    'type': e.type
                                                })
                                            })
                                        })
                                        .then(response => {
                                            return response.json()
                                        }).then(data => {
                                            if (data.status === 10000) {
                                                var lst_person_blacklist = this.state.lst_person_blacklist;
                                                var crr_person = this.state.crr_person;

                                                // for (let index = 0; index < lst_person_blacklist.length; index++) {
                                                //     for (let i = 0; i < lst_person_blacklist[index].imgFace.length; i++) {
                                                //         if (lst_person_blacklist[index].imgFace[i].imgRc == e.imgRc) {
                                                //             lst_person_blacklist[index].imgFace.splice(i, 1)
                                                //             lst_person_blacklist[index].imgFace_Original_Size.splice(i, 1)
                                                //             crr_person.imgFace.splice(i, 1)
                                                //             crr_person.imgFace_Original_Size.splice(i, 1)
                                                //             i--
                                                //         }
                                                //     }
                                                // }

                                                if (crr_person.imgFace.length > 0) {
                                                    for (let index = 0; index < lst_person_blacklist.length; index++) {
                                                        lst_person_blacklist[index]['_id'] = lst_person_blacklist[index].imgFace[0]._id
                                                        lst_person_blacklist[index]['imgRc'] = lst_person_blacklist[index].imgFace[0].imgRc
                                                        lst_person_blacklist[index]['timeget'] = lst_person_blacklist[index].imgFace[0].timeget
                                                    }

                                                    crr_person._id = crr_person.imgFace[0]._id
                                                    crr_person.imgRc = crr_person.imgFace[0].imgRc
                                                    crr_person.timeget = crr_person.imgFace[0].timeget

                                                    swal("Thành công!", "Xóa thành công!", "success", {
                                                        buttons: false,
                                                        timer: 1500,
                                                    });

                                                    lst_person_blacklist = lst_person_blacklist.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)

                                                    this.setState({
                                                        lst_person_blacklist: lst_person_blacklist,
                                                        crr_person: crr_person,
                                                    });
                                                } else {

                                                    lst_person_blacklist = lst_person_blacklist.filter((item, index) => {
                                                        return item['person'] !== e.person
                                                    });

                                                    this.setState({
                                                        lst_person_blacklist: lst_person_blacklist,
                                                        crr_person: null,
                                                        info: null,
                                                        dataHistory: [],
                                                        crrDataInfo: {
                                                            '_id': "",
                                                            'name': '',
                                                            'phone': '',
                                                            'age': '',
                                                            'gender': '',
                                                            'type': '',
                                                            'last_type': ''
                                                        },
                                                    });
                                                    swal("Thành công!", "Xóa thành công!", "success", {
                                                        buttons: false,
                                                        timer: 1500,
                                                    });
                                                }
                                            }
                                            else {
                                                swal("Thất bại!", "Xóa thất bại!", "error");
                                            }
                                        }).catch(err => {
                                            if (err) {
                                                swal("Thất bại!", "Không thể kết nối đến sever!", "error");
                                            }
                                        });
                                }}
                                className="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only  m-btn--pill m-btn--air btn-remove-customer" style={{ zIndex: 9, left: -5, top: -5 }}>
                                <i className="la la-close"></i>
                            </button>
                            <button
                                onClick={(event) => this.moveImgToTrain(event, e)}
                                className={"btn btn-success m-btn m-btn--icon btn-sm m-btn--icon-only m-btn--pill m-btn--air btn-remove-customer" + (e.idStaff === 'unknown' ? 'd-none' : '')} data-tip data-for='MoveToTrain'
                                style={{ zIndex: 9, left: 15, top: -5 }}
                            >
                                <ReactTooltip id='MoveToTrain' type='dark' effect='solid'>
                                    <span>Chuyển thành ảnh mẫu</span>
                                </ReactTooltip>
                                <i className="la la-share"></i>
                            </button>
                        </>
                    }
                </div>
            )
        })

        let bullet_img_customer = this.state.lst_person_customer.slice(0, this.state.lst_person_staff.length === 0 ?
            length_staff : this.state.lst_person_staff < 5 ?
                length_staff - 4 : this.state.lst_person_staff.length < 9 ?
                    length_staff - 8 : this.state.fullScreen ?
                        length_staff - 8 : length_staff - 12
        ).map((e, i) => (
            <div className="item p-1 pb-2 col-xl-3 col-lg-4 col-md-6 col_xxl-4" key={i}>
                <div className="row m-0 p-0" onClick={() => { this.setState({ showUpdatePerson: true, crrDataInfo: e }) }}>
                    <div className='col-4 p-0 img-cover' style={{ 'height': '80px', 'backgroundImage': `url(${e.imgFace_Original_Size[0].imgFace == undefined ? "/img/photo-placeholder.png" : e.imgFace_Original_Size[0].imgFace.indexOf('http') >= 0 ? e.imgFace_Original_Size[0].imgFace : `${this.state.box_engine_cf.api2_url}/view${e.imgFace_Original_Size[0].imgFace}`})` }}>
                        {/* <img width="100%" src={`${this.state.box_engine_cf.api}/view/static/${e.imgFace[0] ? e.imgFace[0].imgFace : ''}`} style={{'maxHeight': '100px'}} /> */}
                    </div>
                    <div className="col-8 pl-3" id="staff_img_5facc2804a92151b011880ad">
                        <div>{e.information.name}</div>
                        <div>{e.information.department}</div>
                        <div>{e.timeget ? moment(e.timeget).format('HH:mm:ss') : ''}</div>
                        <div>{e.timeget ? moment(e.timeget).format('DD-MM-YYYY') : ''}</div>
                        <div><b>{aliasCamera(e.camera_id)}</b></div>
                    </div>
                </div>
                {
                    this.state.rule !== '5' &&
                    <>
                        <button
                            onClick={(event) => {
                                event.preventDefault();
                                swal({
                                    title: "Bạn có chắc?",
                                    text: "Bạn có chắc muốn xóa hay không?",
                                    icon: "warning",
                                    buttons: {
                                        confirm: 'Xóa',
                                        cancel: 'Hủy',
                                    },
                                })
                                    .then(name => {
                                        if (!name) throw null;
                                        return fetch(`${this.state.box_engine_cf.api}/api/person/deleteTrack`, {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json',
                                                'Authorization': Store.getState().isLogin.access_token
                                            },
                                            body: JSON.stringify({
                                                'idCamera': e.camera_id,
                                                'person': e.person,
                                                'track': e.id,
                                                'type': e.type
                                            })
                                        })
                                    })
                                    .then(response => {
                                        return response.json()
                                    }).then(data => {
                                        if (data.status === 10000) {
                                            var lst_person_customer = this.state.lst_person_customer;
                                            var crr_person = this.state.crr_person;

                                            // for (let index = 0; index < lst_person_customer.length; index++) {
                                            //     for (let i = 0; i < lst_person_customer[index].imgFace.length; i++) {
                                            //         if (lst_person_customer[index].imgFace[i].imgRc == e.imgRc) {
                                            //             lst_person_customer[index].imgFace.splice(i, 1)
                                            //             lst_person_customer[index].imgFace_Original_Size.splice(i, 1)
                                            //             crr_person.imgFace.splice(i, 1)
                                            //             crr_person.imgFace_Original_Size.splice(i, 1)
                                            //             i--
                                            //         }
                                            //     }
                                            // }

                                            if (crr_person.imgFace.length > 0) {
                                                for (let index = 0; index < lst_person_customer.length; index++) {
                                                    lst_person_customer[index]['_id'] = lst_person_customer[index].imgFace[0]._id
                                                    lst_person_customer[index]['id'] = lst_person_customer[index].imgFace[0]._id
                                                    lst_person_customer[index]['imgRc'] = lst_person_customer[index].imgFace[0].imgRc
                                                    lst_person_customer[index]['timeget'] = lst_person_customer[index].imgFace[0].timeget
                                                }

                                                crr_person._id = crr_person.imgFace[0]._id
                                                crr_person.imgRc = crr_person.imgFace[0].imgRc
                                                crr_person.timeget = crr_person.imgFace[0].timeget

                                                swal("Thành công!", "Xóa thành công!", "success", {
                                                    buttons: false,
                                                    timer: 1500,
                                                });

                                                lst_person_customer = lst_person_customer.sort((a, b) => new moment(a['timeget']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)

                                                this.setState({
                                                    lst_person_customer: lst_person_customer,
                                                    crr_person: crr_person,
                                                });
                                            } else {

                                                lst_person_customer = lst_person_customer.filter((item, index) => {
                                                    return item['person'] !== e.person
                                                });

                                                this.setState({
                                                    lst_person_customer: lst_person_customer,
                                                    crr_person: null,
                                                    info: null,
                                                    dataHistory: [],
                                                    crrDataInfo: {
                                                        '_id': "",
                                                        'name': '',
                                                        'phone': '',
                                                        'age': '',
                                                        'gender': '',
                                                        'type': '',
                                                        'last_type': ''
                                                    },
                                                });
                                                swal("Thành công!", "Xóa thành công!", "success", {
                                                    buttons: false,
                                                    timer: 1500,
                                                });
                                            }
                                        }
                                        else {
                                            swal("Thất bại!", "Xóa thất bại!", "error");
                                        }
                                    }).catch(err => {
                                        if (err) {
                                            swal("Thất bại!", "Không thể kết nối đến sever!", "error");
                                        }
                                    });
                            }}
                            className="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only  m-btn--pill m-btn--air btn-remove-customer" style={{ zIndex: 9, left: -5, top: -5 }}>
                            <i className="la la-close"></i>
                        </button>
                        <button
                            onClick={(event) => this.moveImgToTrain(event, e)}
                            className={"btn btn-success m-btn m-btn--icon btn-sm m-btn--icon-only m-btn--pill m-btn--air btn-remove-customer" + (e.idStaff === 'unknown' ? 'd-none' : '')} data-tip data-for='MoveToTrain'
                            style={{ zIndex: 9, left: 15, top: -5 }}
                        >
                            <ReactTooltip id='MoveToTrain' type='dark' effect='solid'>
                                <span>Chuyển thành ảnh mẫu</span>
                            </ReactTooltip>
                            <i className="la la-share"></i>
                        </button>
                    </>
                }
            </div>
        ))

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper mb-0" style={{ 'fontFamily': 'arial,verdana,sans-serif' }}>
                <div className="m-content mt-3-phone pd_phone_0 p-3" style={this.state.fullScreen ? { position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 9999, backgroundColor: '#fff' } : {}}>
                    <div className="m-portlet mb-0" id="form_reva">
                        <div className="m-portlet__body  m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-5 col-md-5 col-xs-12 row m-0" style={this.state.fullScreen ? { height: '100vh' } : {}}>
                                    <div className="col-md-12 p-4" style={{ 'borderBottom': '1px solid #ebedf2' }}>
                                        {/*begin::Total Profit*/}
                                        <div className="card-box">
                                            <div className='row'>
                                                <div className="col-12">
                                                    {this.state.crr_person != null && this.state.crr_person.type === 4 ? (
                                                        <div className='col-md-5 col-xl-4 mb-3' style={{ 'position': 'relative', 'margin': 'auto' }}>
                                                            <Image width='100%'
                                                                src={
                                                                    this.state.crr_person == null ? '/img/photo-placeholder.png' :
                                                                        this.state.crr_person.imgFace_Original_Size[0].imgFace.indexOf('http') >= 0 ? this.state.crr_person.imgFace_Original_Size[0].imgFace :
                                                                            `${this.state.box_engine_cf.api2_url}/view${this.state.crr_person.imgFace_Original_Size[0].imgFace}`
                                                                }
                                                                style={{ height: 177, width: 177 }}
                                                                className='rounded-circle'></Image>
                                                        </div>
                                                    ) : (
                                                        <div className='col-md-5 col-xl-4 mb-3' style={{ 'position': 'relative', 'margin': 'auto' }}>
                                                            <Image width='100%'
                                                                src={
                                                                    this.state.crr_person == null ? '/img/photo-placeholder.png' :
                                                                        this.state.crr_person.imgFace_Original_Size[0].imgFace.indexOf('http') >= 0 ? this.state.crr_person.imgFace_Original_Size[0].imgFace :
                                                                            `${this.state.box_engine_cf.api2_url}/view${this.state.crr_person.imgFace_Original_Size[0].imgFace}`
                                                                }
                                                                style={{ height: 177, width: 177 }}
                                                                className='rounded-circle'></Image>
                                                            <Image style={{ 'position': 'absolute', 'bottom': '-20px', 'right': '-10px', 'border': 'solid 5px white', height: 82, width: 82 }} width='45%'
                                                                // src = {'/img/photo-placeholder.png'}
                                                                src={this.state.crr_person == null ? '/img/photo-placeholder.png' :
                                                                    this.state.crr_person.information.accept_face.indexOf('http') >= 0 ? this.state.crr_person.information.accept_face :
                                                                        `${this.state.box_engine_cf.api2_url}/view${this.state.crr_person.information.accept_face}`}
                                                                className='rounded-circle'></Image>
                                                        </div>
                                                    )}
                                                    <div className='col-md-10 col-xl-8' style={{ 'margin': 'auto' }}>
                                                        <table className='tb--Info'>
                                                            <tbody>
                                                                <tr>
                                                                    <td><i className='fa fa-credit-card'></i> Mã nhân viên</td>
                                                                    <td>
                                                                        <b>
                                                                            {this.state.crr_person == null ? "" : this.state.crr_person.information.code}
                                                                        </b>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-user'></i> Họ và Tên</td>
                                                                    <td>
                                                                        <b>
                                                                            {this.state.crr_person == null ? "" : this.state.crr_person.information.name}
                                                                        </b>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-building'></i> Bộ phận</td>
                                                                    <td>
                                                                        <b>
                                                                            {this.state.crr_person && this.state.crr_person.information.department}
                                                                        </b>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-clock'></i> Checkin</td>
                                                                    <td><b>{this.state.crr_person !== null ? this.state.crr_person.timeget : ''}</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-calendar-plus'></i> Lần có mặt trước</td>
                                                                    <td><b className='m--font-info'>{this.state.crr_person !== null ? this.state.crr_person.information.last_visited : ''}</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-user-cog'></i> Nhóm</td>
                                                                    <td className="select_camera">
                                                                        {<b className='m--font-info'> {this.state.crr_person === null ? "" : select_option.filter(x => x.value === this.state.crr_person.type).length > 0 ? select_option.filter(x => x.value === this.state.crr_person.type)[0].title : ''} </b>}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end::Total Profit*/}
                                    </div>
                                    {
                                        LIST_PERSON.indexOf('2') !== -1 &&
                                        <div className="col-md-12 p-2 position-relative">
                                            <div className="card-box scroll" >
                                                <h5 className="mt-0 mb-1 ml-1">VIP ({this.state.lst_person_vip.length})</h5>
                                                <Row className='m-row--no-padding m-scrollable scroll p-2'>
                                                    <PulseLoader
                                                        css={override}
                                                        sizeUnit={"px"}
                                                        size={12}
                                                        margin={'2px'}
                                                        color={'#36D7B7'}
                                                        loading={this.state.loading_vip}
                                                    />
                                                    {bullet_img_vip}
                                                </Row>
                                            </div>
                                        </div>
                                    }
                                    {
                                        LIST_PERSON.indexOf('3') !== -1 &&
                                        <div className="col-md-12 p-2 position-relative" style={{ 'borderTop': '1px solid #ebedf2' }}>
                                            <div className="card-box scroll" >
                                                <h5 className="mt-0 mb-1 ml-1">Danh sách đen ({this.state.lst_person_blacklist.length})</h5>
                                                <Row className='m-row--no-padding m-scrollable scroll p-2' >
                                                    <PulseLoader
                                                        css={override}
                                                        sizeUnit={"px"}
                                                        size={12}
                                                        margin={'2px'}
                                                        color={'#36D7B7'}
                                                        loading={this.state.loading_blacklist}
                                                    />
                                                    {bullet_img_blacklist}
                                                </Row>
                                            </div>
                                        </div>
                                    }
                                    <div className='my-1 position-absolute'>
                                        <button className='btn btn-outline-dark' style={{ border: 'none' }} onClick={() => {
                                            this.setState({ fullScreen: !this.state.fullScreen }, () => {
                                                if (this.state.fullScreen) {
                                                    document.documentElement.requestFullscreen();
                                                }
                                                else {
                                                    if (document.fullscreenElement) {
                                                        document.exitFullscreen();
                                                    }
                                                }
                                            });

                                        }}>
                                            {this.state.fullScreen ?
                                                <i class="fas fa-compress" style={{ fontSize: 25 }}></i>
                                                :
                                                <i class="fas fa-expand" style={{ fontSize: 25 }}></i>
                                            }
                                        </button>
                                    </div>
                                    <div className='my-1 position-absolute' style={{ right: 0 }}>
                                        {/* <button className='btn btn-outline-dark' style={{ border: 'none' }} onClick={() => 
                                            {
                                                this.setState({ muteAudio: !this.state.muteAudio })
                                            }}>
                                            {this.state.muteAudio ? <i class="fas fa-volume-mute"></i> : <i class="fas fa-volume-up"></i>}
                         
                                        </button> */}
                                        <button className='btn btn-outline-dark' style={{ border: 'none' }}
                                            onClick={() => {
                                                this.clickToAudioPlaying()
                                            }}>
                                            <span>
                                                <i className={this.state.audioPlayAudio == 1 ? 'fas fa-volume-mute' : this.state.audioPlayAudio === 3 ? 'fas fa-volume-up transform-item' : 'fas fa-volume-up'} style={{ fontSize: 20 }}></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-xl-7 col-md-7 col-xs-12 scroll">
                                    {
                                        LIST_PERSON.indexOf('5') !== -1 &&
                                        <div className="col-md-12 p-2" style={{ 'borderBottom': '1px solid #ebedf2' }} >
                                            <h5 className="mt-0 mb-1 ml-1">Khách hàng ({this.state.lst_person_customer.length})</h5>
                                            <Row className='m-row--no-padding m-scrollable scroll p-2' >
                                                <PulseLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={12}
                                                    margin={'2px'}
                                                    color={'#36D7B7'}
                                                    loading={this.state.loading_customer}
                                                />
                                                {bullet_img_customer}
                                            </Row>
                                        </div>
                                    }
                                    {
                                        LIST_PERSON.indexOf('1') !== -1 &&
                                        <div className="col-md-12 p-2" style={{ 'borderBottom': '1px solid #ebedf2' }} >
                                            <div className="card-box scroll" >
                                                {/* onDrop={(event) => this.drop('showModalChangeType', 2)} onDragOver={(event) => event.preventDefault()}  */}

                                                <h5 className="mt-0 mb-1 ml-1">Nhân viên ({this.state.lst_person_staff.length})</h5>
                                                <Row className='m-row--no-padding m-scrollable scroll p-2' >
                                                    <PulseLoader
                                                        css={override}
                                                        sizeUnit={"px"}
                                                        size={12}
                                                        margin={'2px'}
                                                        color={'#36D7B7'}
                                                        loading={this.state.loading_staff}
                                                    />
                                                    {bullet_img_staffs}
                                                </Row>
                                            </div>
                                        </div>
                                    }

                                    {/*begin::New Feedbacks*/}
                                    {
                                        LIST_PERSON.indexOf('4') !== -1 &&
                                        <div className="card-box" >
                                            <div className="col-md-12 pb-4 pt-4 pl-3 pr-3" style={{ 'borderBottom': '1px solid #ebedf2' }} >
                                                <div className="card-box scroll" >
                                                    <h5 className="mt-0 mb-1 ml-1">Người lạ ({this.state.lst_person_guest.length})</h5>
                                                    <Row className='m-row--no-padding m-scrollable scroll p-2' >
                                                        <PulseLoader
                                                            css={override}
                                                            sizeUnit={"px"}
                                                            size={12}
                                                            margin={'2px'}
                                                            color={'#36D7B7'}
                                                            loading={this.state.loading_guest}
                                                        />
                                                        {bullet_img_guest}
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/*end::New Feedbacks*/}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <ModalUpdatePerson
                    show={this.state.showUpdatePerson}
                    onHide={this.closeModal}
                    onSplit={this.modalClose}
                    onTrack={this.modalCloseChangeTrack}
                    crrdatainfo={this.state.crrDataInfo}
                    box_engine_cf={this.state.box_engine_cf}
                    onReload={this.reloadPerson}
                    typeModal={this.typeModal}
                />
                <ModalMoveImg
                    datamove={this.state.dataMoveImage}
                    show={this.state.modalShowMoveImage}
                    onHide={this.modalCloseMoveImage}
                />
            </div>

        );
    }

}

export default RevaRC;
