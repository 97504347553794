import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import Store from '../../store';
import DatePicker from "react-datepicker";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import swals from '@sweetalert/with-react';


const dataAddInit = {
    dayOfWeek: [1, 2, 3, 4, 5, 6, 7],
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    startTime: moment(new Date()).set({second:0}).format("HH:mm:ss"),
    endTime: moment(new Date()).add(1, 'hours').set({second:0}).format("HH:mm:ss"),
    doorId: "",
    isThroughDayShift: 0,
    name: "",
}

class ModalShift extends Component {
    defaultPickdate = new Date();
    // defaultPickdate = new Date();
    constructor(props) {
        super(props);
        this.state = {
            dataEdit: [],
            dataAddShift: {
                ...dataAddInit
            },
            start_date_add: new Date(),
            end_date_add: new Date(),
            start_time_add: new Date(),
            end_time_add: new Date(new Date().setHours(new Date().getHours() + 1)),
            defaultTypeShift: {
                value: 0, label: "Ngày"
            },
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            shifts: [],
            stateAddShift: false,
            typeShift: [{
                value: 0, label: "Ngày"
            }, {
                value: 1, label: "Xuyên ngày"
            },],
            requiredName: false,
            requiredDoor: false,
            personId: "",

        }
        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                }, () => {
                });
            } else {
                this.setState({
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                });
            }
        });
    }


    UpdateShift = async (data) => {
        var dataPost = [];
        dataPost.push({
            "personId": this.state.personId,
            "shifts": data,
        })
        if (this.state.box_engine_cf === undefined) return;
        fetch(`${this.state.box_engine_cf.api2_url}/api/person/setShifts`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "shiftsOfPersonList": dataPost
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                swal("Thành công!", "Cập nhật ca thành công!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                this.props.onHide();
            } else if (data.status === 10002) {
                swals({
                    title: "Cảnh báo",
                    content: <div>
                        {(data.person_errors.length > 0 &&
                            <div>
                                Lỗi mã nhân viên: {data.person_errors.map((err, i) => {
                                    let id = err.personId !== "" ? err.personId : "(trống)";
                                    return <span>{(i ? ', ' : '') + id}</span>
                                })}
                            </div>
                        )}
                        {(data.door_errors.length > 0 &&
                            <div>
                                 Lỗi mã cửa: {data.door_errors.map((err, i) => {
                                    let id = err.personId !== "" ? err.personId : "(trống)";
                                    return <span>{(i ? ', ' : '') + id}</span>
                                })}
                            </div>
                        )}
                        {(data.datetime_errors.length > 0 &&
                            <div>
                                 Lỗi ca: {data.datetime_errors.map((err, i) => {
                                    let id = err.personId !== "" ? err.personId : "(trống)";
                                    return <span>{(i ? ', ' : '') + id}</span>
                                })}
                            </div>
                        )}
                    </div>,
                    icon: "warning",
                });
            } else {
                swal("Lỗi!", "Cập nhật ca thất bại!", "error");
            }
        }).catch((error) => {
            if (error) {
                console.log(error)
                swal("Lỗi!", "Cập nhật ca thất bại!", "error");
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            this.setState({
                stateAddShift: false,
                personId: nextProps.data.id,
                shifts: nextProps.dataShiftEdit,
                dataAddShift: { ...dataAddInit },
                requiredName: false,
                requiredDoor: false,
                start_date_add: new Date(),
                end_date_add: new Date(),
                start_time_add: new Date(),
                end_time_add: new Date(new Date().setHours(new Date().getHours() + 1)),
                defaultTypeShift: {
                    value: 0, label: "Ngày"
                },
            });
        }
    }


    handleChangeType(e, value) {
        var crrDataInfo = this.state.dataEdit;
        crrDataInfo['type'] = value.value;
        this.setState({
            typePerson: value,
            dataEdit: crrDataInfo,
        })
    }

    handleChangeStartDate = (value, i) => {

        let clonedData = [...this.state.shifts];
        if (value !== null) {
        clonedData[i]["startDate"] = moment(value).format("YYYY-MM-DD");

        this.setState({
            shifts: clonedData,
        });
    }
    }

    handleChangeStartDateAdd = (value) => {

        let clonedData = this.state.dataAddShift;;
        if (value !== null) {
        clonedData["startDate"] = moment(value).format("YYYY-MM-DD");
        this.setState({
            dataAddShift: clonedData,
            start_date_add: value
        });
    }
    }

    handleChangeEndDate = (value, i) => {
        let clonedData = [...this.state.shifts];
        
        if (value !== null) {
            clonedData[i]["endDate"] = moment(value).format("YYYY-MM-DD");

            this.setState({
                shifts: clonedData,
            });
        } 
    }

    handleChangeEndDateAdd = (value) => {

        let clonedData = this.state.dataAddShift;

        if (value !== null) {
        clonedData["endDate"] = moment(value).format("YYYY-MM-DD");

        this.setState({
            dataAddShift: clonedData,
            end_date_add: value
        });
        }

    }

    handleChangeStartTime = (value, i) => {

        let clonedData = [...this.state.shifts];

        if (value !== null) {

        clonedData[i]["startTime"] = moment(value).format("HH:mm:ss");

        this.setState({
            shifts: clonedData,
        });
    }


    }

    handleChangeEndTime = (value, i) => {

        let clonedData = [...this.state.shifts];

        if (value !== null) {
        clonedData[i]["endTime"] = moment(value).format("HH:mm:ss");
            this.setState({
                shifts: clonedData,
            });
        }
    }

    handleChangeStartTimeAdd = (value, i) => {

        let clonedData = this.state.dataAddShift;

        if (value !== null) {
        clonedData["startTime"] = moment(value).add("00", 'seconds').format("HH:mm:ss");

        this.setState({
            start_time_add: value,
            dataAddShift: clonedData,
        });
     }
    }

    handleChangeEndTimeAdd = (value, i) => {

        let clonedData = this.state.dataAddShift;

        if (value !== null) {
        clonedData["endTime"] = moment(value).add("00", 'seconds').format("HH:mm:ss");

        this.setState({
            end_time_add: value,
            dataAddShift: clonedData,
        });
    }
}

    handleChangeTypeShift = (value, i) => {
        let clonedData = [...this.state.shifts];

        clonedData[i]["isThroughDayShift"] = value.value;

        this.setState({
            shifts: clonedData,
        });
    }

    handleChangeTypeShiftAdd = (value) => {
        let clonedData = this.state.dataAddShift;

        clonedData["isThroughDayShift"] = value.value;

        this.setState({
            defaultTypeShift: value,
            dataAddShift: clonedData,
        });
    }


    handleChangeInput = (e, i) => {
        let clonedData = [...this.state.shifts];

        clonedData[i][e.target.name] = e.target.value;

        this.setState({
            shifts: clonedData
        });

    }

    handleChangeInputAdd = (e) => {
        let dataAdd = this.state.dataAddShift;

        dataAdd[e.target.name] = e.target.value;

        this.setState({
            dataAddShift: dataAdd,
            requiredName: false,
            requiredDoor: false,
        });

    }

    handleDelete = (value, indexShift) => {
        let newShift = this.state.shifts.filter((shift, index) => index !== indexShift);
        this.setState({
            shifts: newShift
        });
    }

    handleDeleteAddShift = () => {
        this.setState({
            dataAddShift: { ...dataAddInit },
            stateAddShift: false
        });
    }

    toggleBtnAdd = () => {
        this.setState({ 
            stateAddShift: !this.state.stateAddShift,
            dataAddShift: { ...dataAddInit },
            start_date_add: new Date(),
            end_date_add: new Date(),
            start_time_add: new Date(),
            end_time_add: new Date(new Date().setHours(new Date().getHours() + 1)),
            defaultTypeShift: {
                value: 0, label: "Ngày"
            },
         });
    }




    render() {
        let click_handle = () => {
            if (this.state.stateAddShift && (this.state.dataAddShift.name === "" || this.state.dataAddShift.doorId === "")) {
                if (this.state.dataAddShift.name === "") {
                    this.setState({
                        requiredName: true
                    });
                } 
                if (this.state.dataAddShift.doorId === "") {
                    this.setState({
                        requiredDoor: true
                    });
                }
                return
            }
            if (this.state.shifts !== undefined) {
                let err_name_shift = false;
                let err_door_shift = false;
                let err_type_shift = false;
                this.state.shifts.map((value) => {
                    console.log(value)
                    if (value.name === "" || value.name === undefined) {
                        err_name_shift = true
                    }
                    if (value.doorId === "" || value.doorId === undefined) {
                        err_door_shift = true
                    }
                    if (value.isThroughDayShift === undefined || value.isThroughDayShift === null) {
                        err_type_shift = true
                    }
                })
                if (err_name_shift || err_door_shift || err_type_shift) {
                    swals({
                        title: "Cảnh báo",
                        content: <div>
                            {(err_name_shift &&
                                <div>
                                    Vui lòng không để trống tên ca.
                                </div>
                            )}
                            {(err_door_shift &&
                                <div>
                                    Vui lòng không để trống vị trí.
                                </div>
                            )}
                            {(err_type_shift &&
                                <div>
                                    Vui lòng chọn loại ca.
                                </div>
                            )}
                        </div>,
                        icon: "warning",
                    });
                    return
                }
            }
            var totalData = []
            if (this.state.stateAddShift && this.state.dataAddShift.name !== "") {
                if ((this.state.shifts === undefined && this.state.dataAddShift.name !== "")) {
                    totalData.push(this.state.dataAddShift);
                } else {
                    totalData = this.state.shifts.concat(this.state.dataAddShift);
                }
            } else {
                totalData = this.state.shifts;
            }
            if (!this.state.stateAddShift && (this.state.shifts === undefined || (this.state.shifts !== undefined && this.state.shifts.length === 0) )) return
            if (!this.state.stateAddShift && this.state.shifts === undefined && this.state.dataAddShift.name === "") return
            this.UpdateShift(totalData);
        }



        var shift = []
        if (this.state.shifts && this.state.shifts.length > 0) {
            this.state.shifts.map((valueShift, indexShift) => {
                shift.push(valueShift.name)
            })
        }



        if (this.state.shifts && this.state.shifts.length > 0) {
            var renderShift = this.state.shifts.map((value, index) => {

                return (
                    <>
                        <div className="m-widget14 pb-2 pt-0 row">
                            {/* <h5><b>Thông tin ca {index + 1} :</b></h5> */}
                            <div className="row col-xl-12">
                                <div className="form-group m-form__group col-xl-2">
                                    <label style={{fontSize: "12px"}} htmlFor="name">Tên ca <span class="text-danger">* </span></label>
                                    <input className="form-control m-input" id="name" name='name' onChange={e => this.handleChangeInput(e, index)}
                                        value={this.state.dataEdit === undefined ? '' : value.name} required />
                                </div>
                                <div className="form-group m-form__group col-xl-1">
                                    <label style={{fontSize: "12px"}} htmlFor="doorId">Vị trí <span class="text-danger">* </span></label>
                                    <input className="form-control m-input"  id="doorId" name='doorId' onChange={e => this.handleChangeInput(e, index)}
                                        value={this.state.dataEdit === undefined ? '' : value.doorId} />
                                </div>
                                <div className="form-group m-form__group col-xl-1 col-custom-175">
                                    <label style={{fontSize: "12px"}} htmlFor="name">Ngày bắt đầu <span class="text-danger">* </span></label>
                                    <DatePicker
                                        className="form-control m-input "
                                        selected={this.state.dataEdit === undefined ? '' : new Date(value.startDate)}
                                        onChange={(e) => this.handleChangeStartDate(e, index)}
                                        selectsStart
                                        startDate={value.start_date}
                                        endDate={value.end_date}
                                        // name={"pickdate_" + index}
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </div>
                                <div className="form-group m-form__group col-xl-1 col-custom-175">
                                    <label style={{fontSize: "12px"}} htmlFor="age">Ngày kết thúc <span class="text-danger">* </span></label>
                                    <DatePicker
                                        className="form-control m-input "
                                        selected={this.state.dataEdit === undefined ? '' : new Date(value.endDate)}
                                        selectsEnd
                                        minDate={value.start_date}
                                        startDate={value.start_date}
                                        endDate={value.end_date}
                                        onChange={(e) => this.handleChangeEndDate(e, index)}
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </div>
                                <div className="form-group m-form__group col-xl-1 col-custom-10">
                                    <label style={{fontSize: "12px"}} htmlFor="name">Giờ vào <span class="text-danger">* </span></label>

                                    <DatePicker
                                        className="form-control m-input"
                                        selected={this.state.dataEdit === undefined ? '' : new Date().setHours(value.startTime.split(':')[0], value.startTime.split(':')[1])}
                                        onChange={(e) => this.handleChangeStartTime(e, index)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        timeCaption="Giờ vào"

                                    />
                                </div>
                                <div className="form-group m-form__group col-xl-1 col-custom-10">
                                    <label style={{fontSize: "12px"}} htmlFor="age">Giờ ra <span class="text-danger">* </span></label>
                                    <DatePicker
                                        className="form-control m-input"
                                        selected={this.state.dataEdit === undefined ? '' : new Date().setHours(value.endTime.split(':')[0], value.endTime.split(':')[1])}
                                        showTimeSelect
                                        onChange={(e) => this.handleChangeEndTime(e, index)}
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Giờ ra"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                    />
                                </div>
                                <div className="form-group m-form__group col-xl-2">
                                    <label style={{fontSize: "12px"}} htmlFor="gender">Loại <span class="text-danger">*</span> </label>
                                    <Select
                                        getOptionValue={(option) => option.label}
                                        value={
                                            this.state.typeShift.filter((option) => {
                                                return value.isThroughDayShift === option.value
                                            })
                                        }
                                        onChange={value => this.handleChangeTypeShift(value, index)}
                                        // styles={customStyle}
                                        options={this.state.typeShift}
                                    />
                                </div>
                                <div className="form-group m-form__group col-xl-1 ">
                                    <button
                                        className="btn btn-danger m-btn m-btn--icon m-btn--icon-only"
                                        style={{ marginTop: '25px' }}
                                        type="button"
                                        onClick={() => this.handleDelete(value, index)}
                                    >
                                        <i className="fa fa-trash-alt" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            })
        }

        return (

            <Modal
                {...this.props}
                size="lg"
                dialogClassName="cus-size-modal"
                aria-labelledby="contained-modal-title-vcenter "
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Danh sách ca
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formUpdateUser">
                        <div className="col-xl-12">
                            <div className="m-widget14 p-2 d-flex justify-content-end row">
                                <button
                                    className="btn btn-info m-btn m-btn--icon m-btn--icon-only btn-lg"
                                    type="button"
                                    onClick={this.toggleBtnAdd}
                                >
                                    <i className={`${this.state.stateAddShift ? 'fa fa-minus' : 'fa fa-plus'}`} />
                                </button>
                            </div>
                            {renderShift}

                            <div className={`m-widget14 pb-2 row ${this.state.stateAddShift ? "" : " d-none"}`}>
                                <h5><b className="text-info pl-3">Thêm ca:</b></h5>
                                <div className="row col-xl-12">
                                    <div className="form-group m-form__group col-xl-2 ">
                                        <label style={{fontSize: "12px"}} htmlFor="name">Tên ca <span class="text-danger">* </span></label>
                                        <input className={`form-control m-input  ${this.state.requiredName ? "border_red" : ""}`} id="name"
                                            name='name'
                                            onChange={e => this.handleChangeInputAdd(e)}
                                            placeholder="Tên ca"
                                            value={this.state.dataAddShift.name}
                                        />
                                    </div>
                                    <div className="form-group m-form__group col-xl-1 ">
                                        <label style={{fontSize: "12px"}} htmlFor="doorId">Vị trí <span class="text-danger">* </span></label>
                                        <input className={`form-control m-input  ${this.state.requiredDoor ? "border_red" : ""}`}  
                                            id="doorId"
                                            name='doorId'
                                            onChange={e => this.handleChangeInputAdd(e)}
                                            placeholder="Vị trí"
                                            value={this.state.dataAddShift.doorId} />
                                    </div>

                                    <div className="form-group m-form__group col-xl-1 col-custom-175">
                                        <label style={{fontSize: "12px"}} htmlFor="name">Ngày bắt đầu <span class="text-danger">* </span></label>
                                        <DatePicker
                                            className={`form-control m-input  `}
                                            selectsStart
                                            startDate={this.state.start_date_add}
                                            endDate={this.state.end_date_add}
                                            selected={this.state.start_date_add}
                                            onChange={(e) => this.handleChangeStartDateAdd(e)}
                                            dateFormat="dd-MM-yyyy"
                                        />
                                    </div>

                                    <div className="form-group m-form__group col-xl-1 col-custom-175">
                                        <label style={{fontSize: "12px"}} htmlFor="age">Ngày kết thúc <span class="text-danger">* </span></label>
                                        <DatePicker
                                            className="form-control m-input "
                                            selected={this.state.end_date_add}
                                            selectsEnd
                                            minDate={this.state.start_date_add}
                                            startDate={this.state.start_date_add}
                                            endDate={this.state.end_date_add}
                                            onChange={(e) => this.handleChangeEndDateAdd(e)}
                                            dateFormat="dd-MM-yyyy"
                                        />
                                    </div>

                                    <div className="form-group m-form__group col-xl-1 col-custom-10">
                                        <label style={{fontSize: "12px"}} htmlFor="name">Giờ vào <span class="text-danger">* </span></label>

                                        <DatePicker
                                            className="form-control m-input "
                                            selected={this.state.start_time_add}
                                            onChange={(e) => this.handleChangeStartTimeAdd(e)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            dateFormat="HH:mm"
                                            timeCaption="Giờ vào"
                                            timeFormat="HH:mm"

                                        />
                                    </div>
                                    <div className="form-group m-form__group col-xl-1 col-custom-10">
                                        <label style={{fontSize: "12px"}} htmlFor="age">Giờ ra <span class="text-danger">* </span></label>
                                        <DatePicker
                                            className="form-control m-input "
                                            selected={this.state.end_time_add}
                                            showTimeSelect
                                            onChange={(e) => this.handleChangeEndTimeAdd(e)}
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Giờ ra"
                                            dateFormat="HH:mm"
                                            timeFormat="HH:mm"
                                        />
                                    </div>

                                    <div className="form-group m-form__group col-xl-2 ">
                                        <label style={{fontSize: "12px"}} htmlFor="gender">Loại <span class="text-danger">*</span> </label>
                                        <Select
                                            getOptionValue={(option) => option.label}
                                            value={this.state.defaultTypeShift}
                                            onChange={value => this.handleChangeTypeShiftAdd(value)}
                                            // styles={customStyle}
                                            options={this.state.typeShift}
                                        />
                                    </div>

                                    <div className="form-group m-form__group col-xl-1  ">
                                        <button
                                            className="btn btn-danger m-btn m-btn--icon m-btn--icon-only"
                                            style={{ marginTop: '25px' }}
                                            type="button"
                                            onClick={() => this.handleDeleteAddShift()}
                                        >
                                            <i className="fa fa-trash-alt" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" type="button" onClick={click_handle}>Lưu</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>Huỷ</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalShift.propTypes = {
    data: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}


export default ModalShift;