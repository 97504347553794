import React, { Component } from 'react';
import { logout } from '../../actions/isLogin';
import Store from '../../store';
import { ENGINE_NAME, ENGINE_NAME_BIFACE, HOST_CLOUD, LOGO } from '../../config'
import { withRouter } from 'react-router';
// import Select from "react-select";
import { setbox } from '../../actions/boxai';
import { theme } from '../../actions/setTheme';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Redirect } from 'react-router-dom';
import { isRule } from '../../actions/rules';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            crrcompany: { title: '' },
            crrCamera: { title: '' },
            listCompany: [],
            dataCamera: [],
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            displayTheme: Store.getState().theme.setTheme
        };

        this.getData();
        Store.subscribe(() => {
            if (Store.getState().theme.setTheme === 'Dark') {
                this.setState({
                    displayTheme: Store.getState().theme.setTheme
                })
            }

            if (Store.getState().isLogin.isLogin && this.state.isLogin !== Store.getState().isLogin.isLogin) {
                this.setState({
                    isLogin: Store.getState().isLogin.isLogin
                }, () => {
                    this.getData();
                })
            }
        });
    }
    getData = () => {
        this.getUser();
    }

    getUser = () => {
        if (Store.getState().isLogin.isLogin === true) {
            fetch(`${HOST_CLOUD}/api/user/getbytoken`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                }
            }).then((response) => {
                if (response.status === 401) {
                    this.setState({
                        isLogin: false
                    }, () => {
                        localStorage.clear();
                        Store.dispatch(logout());
                    })
                    // return null;
                }
                return response.json();
            }).then((result) => {
                if (result.status === 10000) {
                    this.setState({
                        user: result.data,
                        rule: result.data.rule,
                    }, () => {
                        this.getCompany();
                        if(result.data.rule === '5'){
                            this.props.history.push('/reva');
                        }
                        Store.dispatch(isRule(result.data.rule));
                    })
                }
                else {
                    this.setState({
                        isLogin: false
                    }, () => {
                        localStorage.clear();
                        Store.dispatch(logout());
                    })
                }
            }).catch((error) => {
                if (error) {
                    this.setState({
                        isLogin: false
                    }, () => {
                        localStorage.clear();
                        Store.dispatch(logout());
                    })
                }
            });
        }
    }

    getCompany = () => {
        fetch(`${HOST_CLOUD}/api/company/getbyuser1`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token
            }
        }).then((response) => {
            return response.json()
        }).then(async (data) => {
            if (data.status === 10000) {
                for (let index = 0; index < data.companies.length; index++) {
                    for (let i = 0; i < data.companies[index].boxes_engine.length; i++) {
                        if (ENGINE_NAME.split(',').every(every => every != data.companies[index].boxes_engine[i].name_engine) &&
                            ENGINE_NAME_BIFACE.split(',').every(every => every != data.companies[index].boxes_engine[i].name_engine)
                            ) {
                            data.companies[index].boxes_engine.splice(i, 1);
                            i--;
                        }
                        if (data.companies[index].boxes_engine[i] !== undefined) {
                            if (data.companies[index].boxes_engine[i].api_url !== null) {
                                var dataApiSplit = data.companies[index].boxes_engine[i].api_url.split(';')
                                if (dataApiSplit.length === 4) {
                                    data.companies[index].boxes_engine[i].api_url = dataApiSplit[0];
                                    data.companies[index].boxes_engine[i].api2_url = dataApiSplit[3];
                                    data.companies[index].boxes_engine[i].api3_url = dataApiSplit[2];
                                }else if (dataApiSplit.length > 1) {
                                    data.companies[index].boxes_engine[i].api_url = dataApiSplit[0];
                                    data.companies[index].boxes_engine[i].api2_url = dataApiSplit[1];
                                    data.companies[index].boxes_engine[i].api3_url = dataApiSplit[2];
                                }
                            }
                        }
                    }
                }
                var crrCamera = ""

                const dataCamera = data.companies[0].boxes_engine;
                let ids_Cam = [];
                for (let index = 0; index < dataCamera.length; index++) {
                    ids_Cam.push(dataCamera[index].id_box_engine.toString());
                }
                crrCamera = {
                    title: 'Tất cả các camera',
                    value: -1,
                    api: dataCamera[0] ? dataCamera[0].api_url : '',
                    api2: dataCamera[0] ? dataCamera[0].api2_url : '',
                    url: dataCamera[0] ? dataCamera[0].view_url_local : '',
                    dataCam: dataCamera[0] ? dataCamera[0] : null,
                    ids_Cam,
                };

                this.setState({
                    listCompany: data.companies,
                    crrcompany: { title: data.companies[0].name, value: data.companies[0].id_company, dataBox: data.companies[0].boxes_engine },
                    dataCamera: data.companies[0].boxes_engine,
                    crrCamera: crrCamera
                }, () => {
                    let crrboxai_cf = [], crrboxai_hm = [];
                    if (this.state.dataCamera[0] !== undefined) {
                        if (this.state.dataCamera[0].sub_engine_id === 6) //Heatmap
                        {
                            crrboxai_hm.push({ 'idCompany': this.state.crrcompany.value, 'value': this.state.dataCamera[0].id_box, 'api': this.state.dataCamera[0].api_url, 'api2_url': this.state.dataCamera[0].api2_url, 'dataCam': this.state.dataCamera[0] })
                            Store.dispatch(setbox("", crrboxai_hm));
                        }
                        else {
                            const dataCamera = this.state.dataCamera.filter(filter => [14, 18, 19].findIndex(e => e === filter.sub_engine_id) >= 0 || ENGINE_NAME_BIFACE.split(',').findIndex(e => e === filter.name_engine) >= 0);
                            let ids_Cam = [];
                            for (let index = 0; index < dataCamera.length; index++) {
                                ids_Cam.push(dataCamera[index].id_box_engine.toString());
                            }

                            crrboxai_cf.push({
                                'idCompany': this.state.crrcompany.value,
                                'value': -1,
                                'api': dataCamera[0].api_url,
                                'api2_url': dataCamera[0].api2_url,
                                'apiSocket': dataCamera[0].api3_url,
                                'dataCam': dataCamera[0],
                                'ids_Cam': ids_Cam,
                                'listCamera': dataCamera,
                            })
                            Store.dispatch(setbox(crrboxai_cf, ""));
                        }
                    }
                    
                })
            }
        })
    }

    changeHandleCompany = (value, e) => {
        let crrboxai_cf = [], crrboxai_hm = [];
        if (e.dataBox.length > 0) {
            if (e.dataBox[0].sub_engine_id === 6) //Heatmap
            {
                crrboxai_hm.push({ 'idCompany': e.value, 'value': e.dataBox[0].id_box, 'api': e.dataBox[0].api_url, 'api2_url': e.dataBox[0].api2_url, dataCam: e.dataBox[0] })
                Store.dispatch(setbox("", crrboxai_hm));
            }
            else {
                const dataCamera = e.dataBox.filter(filter => [14, 18, 19].findIndex(e => e === filter.sub_engine_id) >= 0 || ENGINE_NAME_BIFACE.split(',').findIndex(e => e === filter.name_engine) >= 0);
                let ids_Cam = [];
                for (let index = 0; index < dataCamera.length; index++) {
                    ids_Cam.push(dataCamera[index].id_box_engine.toString());
                }

                crrboxai_cf.push({
                    'idCompany': e.value,
                    'value': -1,
                    'api': dataCamera[0].api_url,
                    'api2_url': dataCamera[0].api2_url,
                    'apiSocket': dataCamera[0].api3_url,
                    'dataCam': dataCamera[0],
                    'ids_Cam': ids_Cam,
                    'listCamera': dataCamera,
                })
                Store.dispatch(setbox(crrboxai_cf, ""));
            }
            this.setState({
                crrcompany: e,
                dataCamera: e.dataBox,
                crrCamera: {
                    title: 'Tất cả camera',
                    value: e.dataBox[0].id_box_engine,
                    api: e.dataBox[0].api_url,
                    api2: e.dataBox[0].api2_url,
                    url: e.dataBox[0].view_url_local,
                    dataCam: e.dataBox[0],
                }
            })

        } else {
            Store.dispatch(setbox("", ""));
            this.setState({
                crrcompany: e,
                dataCamera: e.dataBox,
                crrCamera: { title: 'Tất cả các camera', value: -1 }
            })
        }
    }

    changeHandleCamera = (valueCamera, e, listCam) => {
        let crrboxai_cf = [], crrboxai_hm = [];
        listCam.shift();
        //Tat ca ca camera
        const dataCamera = listCam.filter(filter => [14, 18, 19].findIndex(e => e === filter.dataCam.sub_engine_id) >= 0 || ENGINE_NAME_BIFACE.split(',').findIndex(e => e === filter.dataCam.name_engine) >= 0);
        let data_ = [];
        dataCamera.forEach(e => {
            data_.push(e.dataCam)
        })
        if (e.value === -1) {
            let ids_Cam = [];
            for (let index = 0; index < dataCamera.length; index++) {
                ids_Cam.push(dataCamera[index].dataCam.id_box_engine.toString());
            }
            crrboxai_cf.push({
                'idCompany': this.state.crrcompany.value,
                'value': -1,
                'api': dataCamera[0].dataCam.api_url,
                'api2_url': dataCamera[0].dataCam.api2_url,
                'apiSocket': dataCamera[0].dataCam.api3_url,
                'dataCam': dataCamera[0].dataCam,
                'ids_Cam': [...new Set(ids_Cam)],
                'listCamera': data_,
            })
            Store.dispatch(setbox(crrboxai_cf, ""));
        } else {
            if (e.dataCam.sub_engine_id === 6) //Heatmap
            {
                crrboxai_hm.push({ 'idCompany': this.state.crrcompany.value, 'value': e.value, 'api': e.api, 'api2_url': e.api2, 'dataCam': e.dataCam })
                Store.dispatch(setbox("", crrboxai_hm));
            }
            else {
                console.log(e)
                if (e.dataCam.sub_engine_id === 14 || e.dataCam.sub_engine_id === 18 || e.dataCam.sub_engine_id === 19 || ENGINE_NAME_BIFACE.split(',').findIndex(name => name === e.dataCam.name_engine) >= 0) //Count_Face
                {
                    crrboxai_cf.push({
                        'idCompany': this.state.crrcompany.value,
                        'value': e.value,
                        'api': e.api,
                        'api2_url': e.api2,
                        'apiSocket': e.api3,
                        'dataCam': e.dataCam,
                        'ids_Cam': [e.dataCam.id_box_engine.toString()],
                        'listCamera': data_,
                    })
                    Store.dispatch(setbox(crrboxai_cf, ""));
                }
            }
        }

        this.setState({ crrCamera: e })
    }


    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        var displayThemeDark;
        var classThemeDark;
        var displayThemeLight;
        if (this.state.displayTheme === 'Dark') {
            displayThemeDark = 'd-none';
            classThemeDark = 'themeHeader'
            displayThemeLight = '';
        } else {
            displayThemeDark = '';
            displayThemeLight = 'd-none';
        }

        var options = [];
        for (var i = 0; i < this.state.listCompany.length; i++) {
            options.push(
                { title: this.state.listCompany[i].name, value: this.state.listCompany[i].id_company, dataBox: this.state.listCompany[i].boxes_engine })
        }
        var optionsCamera = [];
        var ids_Cam = [];
        for (let index = 0; index < this.state.dataCamera.length; index++) {
            ids_Cam.push(this.state.dataCamera[index].id_box_engine.toString());
            optionsCamera.push({
                title: this.state.dataCamera[index].alias_box_engine ?
                    this.state.dataCamera[index].alias_box_engine + `(${this.state.dataCamera[index].id_box_engine})`
                    :
                    this.state.dataCamera[index].name_engine + '_' + this.state.dataCamera[index].id_box + '_' + this.state.dataCamera[index].id_box_engine, 
                    value: this.state.dataCamera[index].id_box_engine, 
                    api: this.state.dataCamera[index].api_url, 
                    api2: this.state.dataCamera[index].api2_url,
                    api3: this.state.dataCamera[index].api3_url,
                    url: this.state.dataCamera[index].view_url_local, 
                    dataCam: this.state.dataCamera[index],
                    ids_Cam: this.state.dataCamera[index].id_box_engine.toString(),
            })
        }

        optionsCamera.unshift({
            title: 'Tất cả các camera',
            value: -1,
            api: this.state.dataCamera[0] ? this.state.dataCamera[0].api_url : '',
            api2: this.state.dataCamera[0] ? this.state.dataCamera[0].api2_url : '',
            api3: this.state.dataCamera[0] ? this.state.dataCamera[0].api3_url : '',
            url: this.state.dataCamera[0] ? this.state.dataCamera[0].view_url_local : '',
            dataCam: this.state.dataCamera[0] ? this.state.dataCamera[0] : null,
            ids_Cam,
        })

        return (
            <header id="m_header" className={classThemeDark + " m-grid__item m-header "} m-minimize-offset={200} m-minimize-mobile-offset={200}>
                <div className="m-container m-container--fluid m-container--full-height">
                    <div className="m-stack m-stack--ver m-stack--desktop">
                        {/* BEGIN: Brand */}
                        {
                            this.state.rule !== '5' &&
                            <div className="m-stack__item m-brand  m-brand--skin-dark">
                                <div className="m-stack m-stack--ver m-stack--general" style={{margin: 2}}>
                                    <div className="m-stack__item m-stack__item--middle m-brand__logo">

                                        {/* <a href="dashboard" className="m-brand__logo-wrapper" style={{padding: 30}}> */}

                                        <a href="reva" className="m-brand__logo-wrapper">

                                            {/* <img src="img/logoai.png" alt="imglogo" className="col-xl-9 pl-0" /> */}
                                            {LOGO === '1' && <img src="img/logoreva.png" alt="imglogo" className="col-xl-12 pl-0" />}
                                        </a>
                                    </div>
                                    <div className="m-stack__item m-stack__item--middle m-brand__tools">
                                        {/* BEGIN: Responsive Aside Left Menu Toggler */}
                                        <a href="#/" id="m_aside_left_offcanvas_toggle" className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
                                            <span />
                                        </a>
                                        {/* END */}
                                        {/* BEGIN: Left Aside Minimize Toggle */}
                                        <a href="#/"
                                            onClick={() => {
                                                if (($(".m-aside-left--minimize").length < 1)) {
                                                    $('#logoreva').hide()
                                                } else {
                                                    $('#logoreva').show()
                                                }
                                            }}
                                            id="m_aside_left_minimize_toggle" className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block  ">
                                            <span />
                                        </a>
                                        {/* END */}
                                        {/* BEGIN: Topbar Toggler */}
                                        <a id="m_aside_header_topbar_mobile_toggle" href="reva" className="m-brand__icon m--visible-tablet-and-mobile-inline-block">
                                            <i className="flaticon-more" />
                                        </a>
                                        {/* BEGIN: Topbar Toggler */}
                                    </div>
                                </div>
                            </div>
                        }
                        {/* END: Brand */}
                        <div className="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                            <div id="m_header_menu" className="ml-2 m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark">
                                <ul className="m-menu__nav  m-menu__nav--submenu-arrow ">
                                    <li className="m-menu__item  m-menu__item--submenu m-menu__item--rel pl-0" m-menu-submenu-toggle="click" m-menu-link-redirect="1" aria-haspopup="true">
                                        <a href="reva" className="m-brand__logo-wrapper">
                                            {LOGO === '1' && <img src="img/logoreva.png" alt="imglogo" id="logoreva" className="pl-2" />}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {/* BEGIN: Topbar */}
                            <div id="m_header_topbar" className="m-topbar  m-stack m-stack--ver m-stack--general m-stack--fluid">
                                <div className="m-stack__item m-topbar__nav-wrapper">
                                    <ul className="m-topbar__nav m-nav m-nav--inline header_mobie ">
                                        <li className="m-nav__item m-topbar__user-profile m-topbar__user-profile--img">
                                            <div className="m-nav__link m-dropdown__toggle">
                                                <div className="row">
                                                    {/* <div className="m-topbar__welcome" style={{ 'lineHeight': '50px','color':'#b1b1b5' }}>Company: </div> */}
                                                    <div className="m-topbar__welcome p-2 select_camera">
                                                        <Autocomplete
                                                            options={options}
                                                            getOptionLabel={option => option.title}
                                                            value={this.state.crrcompany}
                                                            onChange={this.changeHandleCompany}
                                                            disableClearable
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    placeholder="Company"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="m-topbar__welcome p-2 select_camera">
                                                        <Autocomplete
                                                            options={optionsCamera}
                                                            getOptionLabel={option => option.title}
                                                            value={this.state.crrCamera}
                                                            onChange={(e, value) => this.changeHandleCamera(e, value, optionsCamera)}
                                                            disableClearable
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Camera"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light pr-0" m-dropdown-toggle="click" m-dropdown-persistent="1">
                                            <a href="#/" className="m-nav__link m-dropdown__toggle ">
                                                <span className="m-topbar__userpic">
                                                    <img src="/img/photo-placeholder.png" alt="none" className="m--img-rounded m--marginless m--img-centered" />
                                                </span>
                                            </a>
                                            <div className="m-dropdown__wrapper">
                                                <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" />
                                                <div className="m-dropdown__inner">
                                                    <div className="m-dropdown__header m--align-center" style={{ background: 'url(assets/app/media/img/misc/user_profile_bg.jpg)', backgroundSize: 'cover' }}>
                                                        <div className="m-card-user m-card-user--skin-dark">
                                                            <div className="m-card-user__pic">
                                                                <img src="/img/photo-placeholder.png" alt="none" className="m--img-rounded m--marginless" />
                                                            </div>
                                                            <div className="m-card-user__details">
                                                                <span className="m-card-user__name m--font-weight-500">{this.state.user == null ? "Guest" : this.state.user.first_name + " " + this.state.user.last_name}</span>
                                                                <a href="#/" className="m-card-user__email m--font-weight-300 m-link">{this.state.user == null ? "" : this.state.user.email}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="m-dropdown__body">
                                                        <div className="m-dropdown__content">
                                                            <ul className="m-nav m-nav--skin-light">
                                                                <li className="m-nav__item pull-left">
                                                                    <a href="#/" className={displayThemeDark}
                                                                        onClick={() => {
                                                                            localStorage.setItem('setTheme', 'Dark');
                                                                            var getStorage = localStorage.getItem('setTheme');
                                                                            Store.dispatch(theme("Dark"));
                                                                            this.setState({
                                                                                displayTheme: getStorage
                                                                            })
                                                                        }} ><img src="img/sunny.png" alt="none" id="sunny" /></a>
                                                                </li>
                                                                <li className="m-nav__item pull-left">
                                                                    <a href="#/" className={displayThemeLight}
                                                                        onClick={() => {
                                                                            localStorage.setItem('setTheme', 'Light');
                                                                            var getStorage = localStorage.getItem('setTheme');
                                                                            Store.dispatch(theme("Light"));
                                                                            this.setState({
                                                                                displayTheme: getStorage
                                                                            })
                                                                        }}><img src="img/moon.png" alt="none" id="moon" /></a>
                                                                </li>
                                                                <li className="m-nav__item text-right">
                                                                    <a href="#/" className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder" onClick={() => {
                                                                        this.setState({
                                                                            isLogin: false
                                                                        }, () => {
                                                                            localStorage.clear();
                                                                            window.location.href = "/login";
                                                                            Store.dispatch(logout());

                                                                        })
                                                                    }}>Đăng xuất</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* END: Topbar */}
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(Header);