import React from "react";
import swal from "sweetalert";
import swals from '@sweetalert/with-react';
import Store from "../../../store";

var filesLength = 0
var maxUploadImages = 0


const uploadFace = async (base64, crrDataInfo, box_engine_cf) => {
    if (box_engine_cf.api == undefined) return;
        let error = false;

        await fetch(`${box_engine_cf.api}/api/upload_face_import`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                "image": base64.split(',').slice(1).toString(),
                "id_staff": crrDataInfo.code,
                "idCamera": box_engine_cf.value === -1 ? box_engine_cf.ids_Cam.toString() : box_engine_cf.id_box_engine,
                "type": crrDataInfo.type
            })
        }).then((responseData) => {
            return responseData.json()
        }).then((data) => {
            if (data.status == 10000) {
                if (data.face_images.length > 0) 
                    error = false;
                else {
                    error = true;
                }
            } else {
                error = true;
            }
        }).catch((err) => {
            error = true;
        })
    return error;
}

const actionUpload = async (files, base64, crrDataInfo, box_engine_cf) => {
    let error = false;
    if (files.base64.length > 0) {
        error = await uploadFace(base64, crrDataInfo, box_engine_cf)
        filesLength = files.base64.length
        maxUploadImages = files.length + maxUploadImages
    }
    return error;
}

export const onFilesChange = async (files, crrDataInfo, box_engine_cf) => {
    var readerxx = new FileReader();
    readerxx.onload = function (e) {
        document.getElementById('preview').src = e.target.result;
    };
    console.log('files',files);
    readerxx.readAsDataURL(files.fileList[0]);
    var _URL = window.URL;
    var fileSet, imgSet;
    var size = [];
    var arrError = [];
    for (let index = 0; index < files.fileList.length; index++) {
        if ((fileSet = files.fileList[index])) {
            var dataurl = files.base64[index];
            imgSet = new Image();
            imgSet.onload = async function () {
                var heightSet = this.height;
                var widthSet = this.width;

                if (widthSet >= 1281 || heightSet >= 1281) {
                    if (window.File && window.FileReader && window.FileList && window.Blob) {
                        var file = files.fileList[index];
                        if (file) {
                            var reader = new FileReader();
                            // Set the image once loaded into file reader
                            reader.onload = async function (e) {
                                var img = document.createElement("img");
                                img.src = e.target.result;
                                var canvas = document.createElement("canvas");
                                var ctx = canvas.getContext("2d");
                                ctx.drawImage(img, 0, 0);

                                var MAX_WIDTH = 1280;
                                var MAX_HEIGHT = 1280;
                                var width = widthSet;
                                var height = heightSet;
                                if (width > height) {
                                    if (width > MAX_WIDTH) {
                                        height *= MAX_WIDTH / width;
                                        width = MAX_WIDTH;
                                    }
                                } else {
                                    if (height > MAX_HEIGHT) {
                                        width *= MAX_HEIGHT / height;
                                        height = MAX_HEIGHT;
                                    }
                                }
                                canvas.width = width;
                                canvas.height = height;
                                var ctx = canvas.getContext("2d");
                                ctx.drawImage(img, 0, 0, width, height);
                                dataurl = canvas.toDataURL(file.type);
                            }
                            await reader.readAsDataURL(file);
                        }
                    }
                } 
            };
            const dataResult = await actionUpload(files, dataurl, crrDataInfo, box_engine_cf);
            size.push(dataResult);
            if(dataResult){
                arrError.push(files.fileList[index].name);
            }
            imgSet.src = await _URL.createObjectURL(fileSet);
        } else {
            const dataResult = await actionUpload(files, files.base64[index], crrDataInfo, box_engine_cf);
            size.push(dataResult);
            if(dataResult){
                arrError.push(files.fileList[index].name);
            }
        }
    }
    validateImg(arrError, size, files.fileList)
}



const validateImg = (arrError,size, files) => {
    if(size.length === files.length) {
        if(size.findIndex(e => e === true) === -1){
            swal({
                title: "Upload ảnh thành công",
                text: filesLength + '/' + filesLength + '!',
                icon: "success",
                button: "Oke!",
            });
        } else {
            if (arrError.length === files.length) {
                swals({
                    title: "Upload ảnh không thành công",
                    content: <div>
                        <div>
                            Hình ảnh không phát hiện được khuôn mặt: 
                        </div>
                        <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
                            {
                                arrError.map((value) => {
                                    return (
                                        <div>
                                            {value}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>,
                    icon: "error",
                    button: "Oke!",
                });
            } else {
                swals({
                    title: "Upload ảnh thành công",
                    text: `${files.fileList.length - arrError.length}` + '/' + filesLength + '!',
                    content: <div>
                        <div>
                            Hình ảnh không phát hiện được khuôn mặt: 
                        </div>
                        <div className="scrollDashBoard listCheckin" data-scrollable="true" data-height="400" style={{ 'height': '400px', 'overflow': 'hidden' }}>
                            {
                                arrError.map((value) => {
                                    return (
                                        <div>
                                            {value}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>,
                    icon: "success",
                    button: "Oke!",
                });
            }
        }
        
    }
}
