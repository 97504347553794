export const chartHourLight = {
    chart: {
        type: 'column',
        height: 380,
        backgroundColor: {
            linearGradient: { x1: 1, y1: 1, x2: 1, y2: 1 },
            stops: [
                [0, '#ffff']
            ]
        },
        style: {
            fontFamily: '\'Unica One\', sans-serif'
        },
        plotBorderColor: '#e6e6e6'
    },
    colors: ['#008ffb', '#00e396', '#feb019', '#ff4560'],
    title: {
        text: 'Tổng: ' + 0,
        style: {
            fontSize: '15px',
            color: '#666666',
            textTransform: 'uppercase',
        }
    },
    xAxis: {
        type: 'datetime',
        labels: {
            format: '{value: %H:%S}',
            style: {
                color: '#666666'
            }
        },
        gridLineColor: '#e6e6e6',
        lineColor: '#e6e6e6',
        minorGridLineColor: '#505053',
        tickColor: '#e6e6e6',
        title: {
            style: {
                color: '#666666'

            }
        }
    },
    yAxis: {
        min: 0,
        gridLineColor: '#e6e6e6',
        labels: {
            style: {
                color: '#666666'
            }
        },
        lineColor: '#e6e6e6',
        minorGridLineColor: '#505053',
        tickColor: '#e6e6e6',
        tickWidth: 1,
        title: {
            style: {
                color: '#666666'
            },
            text: ''
        }
    },
    legend: {
        enabled: false
    },
    tooltip: {
        xDateFormat: '%Y-%m-%d %H:%S',
        shared: true,
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
            color: '#b1b1b5'
        }
    },
    plotOptions: {
        series: {
            pointPadding: 0.15,
            groupPadding: 0.05,
            borderWidth: 0.1,
        }
    },

    series: [{
        name: 'Lưu lượng qua lại',
        data: [0]
    }],
    navigation: {
        buttonOptions: {
            enabled: true
        }
    },
    responsive: {
        rules: [{
            condition: {
                maxWidth: 450
            },
            chartOptions: {
                chart: {
                    height: 280
                }
            }
        }]
    }
}

export const chartHourDark = {
    chart: {
        type: 'column',
        height: 380,
        backgroundColor: {
            linearGradient: { x1: 1, y1: 1, x2: 1, y2: 1 },
            stops: [
                [0, '#222437']
            ]
        },
        style: {
            fontFamily: '\'Unica One\', sans-serif'
        },
        plotBorderColor: '#606063'
    },
    colors: ['#008ffb', '#00e396', '#feb019', '#ff4560'],
    title: {
        text: 'Tổng: ' + 0,
        style: {
            fontSize: '15px',
            color: '#b1b1b5',
            textTransform: 'uppercase',
        }
    },
    xAxis: {
        type: 'datetime',
        labels: {
            format: '{value: %H:%S}',
            style: {
                color: '#b1b1b5'
            }
        },
        gridLineColor: '#707073',
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        title: {
            style: {
                color: '#b1b1b5'

            }
        }
    },
    yAxis: {
        min: 0,
        gridLineColor: '#707073',
        labels: {
            style: {
                color: '#b1b1b5'
            }
        },
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        tickWidth: 1,
        title: {
            style: {
                color: '#b1b1b5'
            },
            text: ''
        }
    },
    legend: {
        enabled: false
    },
    tooltip: {
        xDateFormat: '%Y-%m-%d %H:%S',
        shared: true,
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
            color: '#b1b1b5'
        }
    },
    plotOptions: {
        series: {
            pointPadding: 0.15,
            groupPadding: 0.05,
            borderWidth: 0.1,
        }
    },
    series: [{
        name: 'Lưu lượng qua lại',
        data: [0]
    }],
    navigation: {
        buttonOptions: {
            enabled: true
        }
    },
    responsive: {
        rules: [{
            condition: {
                maxWidth: 450
            },
            chartOptions: {
                chart: {
                    height: 280
                }
            }
        }]
    }
}