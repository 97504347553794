import { RULE } from '../../actions/rules';

const initialState = {
    rule: 5,
}

export default function rules(state = initialState, action){
    if(action.type === RULE){
        return {
            ...state,
            rule: action.payload.rule,
        }
    }
    return state;
}