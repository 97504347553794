import React, { Component } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, Row, Col, Image } from 'react-bootstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Dropdown } from 'semantic-ui-react'
import { Tab, Tabs } from 'react-bootstrap';
import $ from 'jquery';
import 'toasted-notes/src/styles.css';
import toastr from 'reactjs-toastr';
import Pagination from "react-js-pagination";
import Store from '../../store';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LIST_PERSON, SELECT_GROUP } from '../../config';
import { onFilesChange } from '../action/UploadFaceImport/UploadFaceImport';
import ReactFileReader from 'react-file-reader';
import moment from 'moment'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

let select_option = [];

if (LIST_PERSON.indexOf('1') !== -1 && SELECT_GROUP != 0) {
    select_option.push({ title: 'Nhân viên', value: 1 })
}
if (LIST_PERSON.indexOf('2') !== -1) {
    select_option.push({ title: 'Vip', value: 2 })
}
if (LIST_PERSON.indexOf('3') !== -1) {
    select_option.push({ title: 'Danh sách đen', value: 3 })
}
if (LIST_PERSON.indexOf('4') !== -1) {
    select_option.push({ title: 'Người lạ', value: 4 })
}
if (LIST_PERSON.indexOf('5') !== -1) {
    select_option.push({ title: 'Khách hàng', value: 5 })
}
const select_option_1 = [
    // { title: 'Người lạ', value: 4 },
    // { title: 'Customer', value: 5 },
    { title: 'Vip', value: 2 },
    { title: 'Nhân viên', value: 1 },
    // { title: 'Black list', value: 3 },
];

class ModalUpdatePerson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            activePageTraining: 1,
            listImage: [],
            old_type: null,
            old_id: "",
            box_engine_cf: "",
            crrDataInfo: null,
            show: false,
            checkedMale: true,
            checkedFemale: false,
            crr_type: select_option[0],
            optionPerson: [],
            selected_type: null,
            valuePerson: null,
            checkDeleteMulti: false,
            typeModal: 0,
            listChecked: {
                valueImg: [],
                _id: [],
                dataPost: [],
            },
            listImageTraining: [],
            crrImagesTraining: [],
            keyTab: 'update',
            loadingHistory: false,
            dataHistory: [],
            activePageHistory: 1,
            offsetY: 0,
            hasMoreHistory: true,
            crrPersonGuide: null,
            isEditable: false
        }
        this.itemsPerPage = 8;
        this.itemsPerPageTraining = 12;
        Store.subscribe(() => {
            this.setState({
                rule: Store.getState().rules.rule,
            })
        })
    }

    MoveImage = (newPerson) => {
        fetch(`${this.state.box_engine_cf.api}/api/person/acceptFace`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                person: "",
                images: [{ track_id: this.state.crrPersonGuide.id, file_name: this.state.crrPersonGuide.images[0].file_name }],
                type: this.state.old_type,
                new_person: newPerson,
                new_type: this.state.crrDataInfo.type,
                track: this.state.crrPersonGuide.id,
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                this.props.onHide(this.state.crrDataInfo, this.state.old_id, this.state.old_type)
                swal("Thành công!", "Chuyển ảnh thành công!", "success", {
                    buttons: false,
                    timer: 1500,
                });
            } else {
                swal("Lỗi!", "Chuyển ảnh thất bại!", "error");
            }
        }).catch((error) => {
            if (error) {
                swal("Lỗi!", "Chuyển ảnh thất bại!", "error");
            }
        })
    }

    FilterImg = (activePage) => {
        // const { listImage } = this.state;
        if (this.state.crrDataInfo === null) return;

        const offset = (activePage - 1) * this.itemsPerPage;
        const listImage = this.state.crrDataInfo.imgFace_Original_Size.slice(offset, offset + this.itemsPerPage);
        this.setState({
            listImage
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber,
            listImage: [],
            loadingImage: true
        }, () => {
            this.FilterImg(pageNumber);
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            let crr_person = { ...nextProps.crrdatainfo };
            let box_engine_cf = nextProps.box_engine_cf;
            let typeModal = nextProps.typeModal;
            let crr_person_guide = { ...nextProps.crrdatainfo }
            if (crr_person != null && box_engine_cf != null) {
                this.setState({
                    typeModal: typeModal
                }, () => {
                    this.getDataPerson(box_engine_cf, crr_person);
                })
                this.setState({
                    typeModal: typeModal,
                }, () => {
                    this.getImageTraining(box_engine_cf, crr_person)
                })
                this.setState({
                    show: nextProps.show,
                    box_engine_cf: box_engine_cf,
                    activePageHistory: 1,
                    crrPersonGuide: crr_person_guide,
                    typeModal: typeModal
                }, () => {
                    this.getHistory(box_engine_cf, crr_person);
                })
            }
        } else {
            this.setState({
                selected_type: null,
                valuePerson: null,
                checkDeleteMulti: false,
                listChecked: {
                    valueImg: [],
                    _id: [],
                    dataPost: [],
                },
                crrDataInfo: null,
                dataHistory: [],
                keyTab: 'update',
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.keyTab !== this.state.keyTab) {
            this.setState({
                checkDeleteMulti: false,
                listChecked: {
                    valueImg: [],
                    _id: [],
                    dataPost: [],
                },
            })
        }
    }

    getDataPerson = (box_engine_cf, crr_person, refresh) => {
        fetch(`${box_engine_cf.api}/api/person/getById/${this.state.typeModal === 2 ? crr_person.id : crr_person.person}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        })
            .then((response) => {
                return response.json()
            }).then((rs) => {
                if (rs.status === 10000) {
                    if (refresh) {
                        let data = this.state.crrDataInfo;
                        data.information = rs.data;
                        data.type = rs.data.person_type;
                        var crr_type = "";
                        if (data.type === 1) {
                            crr_type = { title: 'Nhân viên', value: 1 }
                        } else if (data.type === 2) {
                            crr_type = { title: 'Vip', value: 2 }
                        } else if (data.type === 3) {
                            crr_type = { title: 'Danh sách đen', value: 3 }
                        } else if (data.type === 4) {
                            crr_type = { title: 'Người lạ', value: 4 }
                        } else {
                            crr_type = { title: 'Khách hàng', value: 5 }
                        }
                        this.setState({
                            old_type: crr_person.person_type,
                            old_id: crr_person.person,
                            crrDataInfo: data,
                            crr_type: crr_type,
                            box_engine_cf: box_engine_cf,
                            activePage: 1,
                        }, () => {
                            // this.FilterImg(this.state.activePage);
                        });
                    } else {
                        crr_person.information = rs.data;
                        var crr_type = "";
                        if (crr_person.person_type === 1) {
                            crr_type = { title: 'Nhân viên', value: 1 }
                        } else if (crr_person.person_type === 2) {
                            crr_type = { title: 'Vip', value: 2 }
                        } else if (crr_person.person_type === 3) {
                            crr_type = { title: 'Danh sách đen', value: 3 }
                        } else if (crr_person.person_type === 4) {
                            crr_type = { title: 'Người lạ', value: 4 }
                        } else {
                            crr_type = { title: 'Khách hàng', value: 5 }
                        }
                        this.setState({
                            old_type: crr_person.person_type,
                            old_id: crr_person.person,
                            crrDataInfo: crr_person,
                            crr_type: crr_type,
                            box_engine_cf: box_engine_cf,
                            activePage: 1,
                        }, () => {
                            // this.FilterImg(this.state.activePage);
                        });
                    }
                } else {
                    const crrPersonGuide = this.state.crrPersonGuide;

                    var crr_type = "";
                    if (crrPersonGuide.type === 1) {
                        crr_type = { title: 'Nhân viên', value: 1 }
                    } else if (crrPersonGuide.type === 2) {
                        crr_type = { title: 'Vip', value: 2 }
                    } else if (crrPersonGuide.type === 3) {
                        crr_type = { title: 'Danh sách đen', value: 3 }
                    } else if (crrPersonGuide.type === 4) {
                        crr_type = { title: 'Người lạ', value: 4 }
                    } else {
                        crr_type = { title: 'Khách hàng', value: 5 }
                    }
                    this.setState({
                        crrDataInfo: crrPersonGuide,
                        crr_type: crr_type,
                        old_type: crr_person.type,
                    })
                }
            });
    }

    SplitTrack = (ids) => {
        $('#button_split_track').addClass('m-loader');
        $('#button_split_track').attr('disabled', true);
        console.log("split traclk");
        fetch(`${this.state.box_engine_cf.api}/api/person/new_track`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                'ids': ids,
                'old_id': this.state.old_id,
                'idCamera': this.state.box_engine_cf.dataCam.id_box_engine.toString(),
                'type': this.state.crrDataInfo.type
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                console.log(rs);
                var { crrDataInfo, listImage } = this.state;
                let listImageOld = { ...crrDataInfo }
                let listImageNew = { ...crrDataInfo }
                listImageNew.imgFace = [];
                listImageNew.imgFace_Original_Size = [];
                listImageNew._id = rs._id_track;
                listImageNew.information._id = rs._id_person;
                listImageNew.information.name = "";
                listImageNew.information.age = "";
                listImageNew.information.gender = "";
                listImageNew.information.department = "";
                listImageNew.information.id = "";

                for (let index = 0; index < ids.length; index++) {
                    for (let i = 0; i < crrDataInfo.imgFace_Original_Size.length; i++) {
                        if (crrDataInfo.imgFace_Original_Size[i]._id === ids[index]) {
                            let item = crrDataInfo.imgFace.splice(i, 1);
                            item._id = rs._id_track;
                            listImageNew.imgFace.push(item);
                            let item_og = crrDataInfo.imgFace_Original_Size.splice(i, 1);
                            item_og._id = rs._id_track;
                            listImageNew.imgFace_Original_Size.push(item_og)
                        }
                    }
                }

                $('#button_split_track').removeClass('m-loader');
                $('#button_split_track').removeAttr('disabled', true);
                swal("Thành công!", "Tách thành công!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                return this.props.onSplit(listImageOld, listImageNew, 'split');
            }
            else {
                $('#button_split_track').removeClass('m-loader');
                $('#button_split_track').removeAttr('disabled', true);
                swal("Lỗi!", "Tách thất bại!", "error");
            }
        }).catch((error) => {
            if (error) {
                $('#button_split_track').removeClass('m-loader');
                $('#button_split_track').removeAttr('disabled', true);
            }
        })
    }


    SaveInfo = () => {
        if (this.state.box_engine_cf === undefined) return;

        var age = this.state.crrDataInfo.information.age;
        if (!moment(age, 'DD/MM/YYYY', true).isValid()) {
            var crrDataInfo = this.state.crrDataInfo;
            crrDataInfo.information.age = ""
            this.setState({
                crrDataInfo: crrDataInfo
            });
            alert("Định dạng ngày sinh không đúng");
            return;
        }

        $('#button_update_info').addClass('m-loader');
        $('#button_update_info').attr('disabled', true);
        let dataPost = { ...this.state.crrDataInfo.information }
        // let dataPost2 = JSON.stringify({...dataPost, id: this.state.crrDataInfo.information.code})
        // console.log(dataPost2);
        delete dataPost.accept_face;
        delete dataPost.trainning_set;
        fetch(`${this.state.box_engine_cf.api}/api/person/update`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                ...dataPost,
                new_type: this.state.crrDataInfo.information?.type,
                type: this.state.old_type,
                company_id: this.state.box_engine_cf.idCompany,
                id: this.state.crrDataInfo.information.id
            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                if (this.state.old_type === 4) {
                    var crrDataInfo = this.state.crrDataInfo;

                    crrDataInfo.information._id = data.data
                    crrDataInfo.person = data.data;

                    $('#button_update_info').removeClass('m-loader');
                    $('#button_update_info').removeAttr('disabled', true);

                    const data_person = {
                        person: crrDataInfo.person,
                        type: this.state.crr_type.value,
                    }

                    this.getImageTraining(this.state.box_engine_cf, data_person);
                    if (this.state.crrDataInfo.type === this.state.old_type) {
                        this.props.onReload(this.state.crrDataInfo)
                    }

                    this.setState({
                        crrDataInfo: crrDataInfo,
                        isEditable: false
                    })
                    swal("Thành công!", "Cập nhật thành công!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    this.props.onHide(this.state.crrDataInfo, this.state.old_id, this.state.old_type)
                } else {

                    var crrDataInfo = this.state.crrDataInfo;

                    crrDataInfo.information._id = data.data
                    crrDataInfo.person = data.data;

                    $('#button_update_info').removeClass('m-loader');
                    $('#button_update_info').removeAttr('disabled', true);

                    const data_person = {
                        person: crrDataInfo.person,
                        type: this.state.crr_type.value,
                    }

                    this.getImageTraining(this.state.box_engine_cf, data_person);
                    if (this.state.crrDataInfo.type === this.state.old_type) {
                        this.props.onReload(this.state.crrDataInfo)
                    }

                    this.setState({
                        crrDataInfo: crrDataInfo,
                        isEditable: false
                    })
                    swal("Thành công!", "Cập nhật thành công!", "success", {
                        buttons: false,
                        timer: 1500,
                    });
                    this.props.onHide(this.state.crrDataInfo, this.state.old_id, this.state.old_type)
                }

            } else {
                console.log("cập nhật thát bại");
                swal("Lỗi!", "Cập nhật thất bại!", "error");
                $('#button_update_info').removeClass('m-loader');
                $('#button_update_info').removeAttr('disabled', true);
            }
            this.props.onHide()
        })
            .catch((error) => {
                console.log(error)
                if (error) {
                    console.log("cập nhật thất bại");
                    swal("Lỗi!", "Cập nhật thất bại!", "error");
                    $('#button_update_info').removeClass('m-loader');
                    $('#button_update_info').removeAttr('disabled', true);
                }
                this.props.onHide()
            })
    }

    handleChange(e) {
        var crrDataInfo = this.state.crrDataInfo;
        crrDataInfo.information[e.target.name] = e.target.value
        this.setState({
            crrDataInfo: crrDataInfo
        });
    }

    handleEnter = (event) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }
    getHistory = (box_engine_cf, crr_person) => {
        this.setState({
            loadingHistory: true,
        })
        fetch(`${box_engine_cf.api2_url}/api/person/history`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                id_person: this.state.typeModal == 2 ? crr_person.id : crr_person.person,
                type: crr_person.type,
                index: this.state.activePageHistory,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 10000) {
                    var { dataHistory } = this.state;
                    dataHistory = dataHistory.concat(data.data)
                    var totalPageHistory = parseInt(dataHistory.length / 10);
                    if (totalPageHistory % 10 != 0)
                        totalPageHistory++;
                    this.setState({
                        dataHistory: dataHistory,
                        loadingHistory: this.state.activePageHistory > totalPageHistory ? false : true,
                    });
                } else if (data.status === 10001) {
                    this.setState({
                        hasMoreHistory: false,
                        loadingHistory: false,
                    });
                } else {
                    this.setState({
                        dataHistory: [],
                        loadingHistory: false,
                    });
                }
            })
            .catch((error) => {
                if (error) {
                    this.setState({
                        dataHistory: [],
                        loadingHistory: false,
                    });
                }
            });
    };

    handleReloadHistory = () => {
        this.setState({
            dataHistory: [],
            activePageHistory: 1,
            loadingHistory: true
        }, () => {
            this.getHistory(this.state.box_engine_cf, this.state.crrDataInfo);
        })
    }

    fetchMoreDataHistory = () => {
        this.setState(
            {
                activePageHistory: this.state.activePageHistory + 1,
                loadingHistory: true,
            },
            () => {
                if (this.state.crrDataInfo != null) {
                    setTimeout(() => {
                        this.getHistory(
                            this.state.box_engine_cf,
                            this.state.crrDataInfo,
                        );
                    }, 1000);
                } else {
                    this.setState({
                        loadingHistory: false,
                    });
                }
            });
    };

    personGetByType = () => {
        if (this.state.crrDataInfo === null) return;
        fetch(`${this.state.box_engine_cf.api}/api/person/getByType`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                'type': this.state.selected_type.value,
                'day': new Date().toISOString().split('T')[0],
                'idCamera': this.state.box_engine_cf.ids_Cam.join(','),
                id_company: `${this.state.box_engine_cf.idCompany}`,
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                var optionPerson = []
                for (let index = 0; index < rs.data.length; index++) {
                    var name = "No Name"
                    const id = rs.data[index].id ? ` - ${rs.data[index].id}` : '';
                    if (rs.data[index].name !== "") {
                        name = rs.data[index].name
                    }
                    optionPerson.push(
                        {
                            key: rs.data[index]._id,
                            text: `${name}${id}`,
                            value: rs.data[index],
                            image: {
                                avatar: true,
                                src: rs.data[index].accept_face.indexOf('http') >= 0 ? rs.data[index].accept_face : this.state.box_engine_cf.api + '/view/image/' + rs.data[index].accept_face
                            },
                        }
                    )
                }
                this.setState({
                    optionPerson: optionPerson
                });
            }
        })
    }

    handleChangePerson = (index, option) => {
        this.setState({
            valuePerson: option.value,
        });
    }

    handleChangeOption = (index, option) => {
        this.setState({
            selected_type: option,
            valuePerson: null
        }, () => {
            this.personGetByType();
        });
    }

    handleChangeType = (index, option) => {
        let crrDataInfo = this.state.crrDataInfo;
        crrDataInfo.information.type = option.value;
        crrDataInfo.type = option.value;
        crrDataInfo.person_type = option.value;
        this.setState({
            crr_type: option,
            crrDataInfo: crrDataInfo
        }, () => {
            console.log(crrDataInfo);
        })
    }
    handleCheckedImg = (event, value) => {
        let listChecked = this.state.listChecked;
        let isChecked = event.target.checked;
        if (listChecked.valueImg.indexOf(event.target.value) === -1) {
            listChecked.valueImg.push(event.target.value)
            listChecked._id.push(value.id)
            listChecked.dataPost.push({ track_id: value.id, file_name: event.target.value })
        } else {
            var i = listChecked.valueImg.indexOf(event.target.value);
            if (i != -1) {
                listChecked.valueImg.splice(i, 1);
                listChecked._id.splice(i, 1);
            }
        }
        this.setState({
            listChecked: listChecked
        })
    }

    change_track = () => {
        let old_id_person = this.state.crrDataInfo.person;
        let old_id_track = "";
        let old_type = this.state.crrDataInfo.person_type;
        let new_id_person = this.state.valuePerson._id;
        let new_id_track = "";
        let new_type = this.state.valuePerson.type;
        fetch(`${this.state.box_engine_cf.api}/api/img/change_track`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                'old_id': old_id_person,
                'old_track': old_id_track,
                'new_id': new_id_person,
                'new_track': new_id_track,
                'old_type': old_type,
                'new_type': new_type
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                swal("Thành công!", "Cập nhật thành công!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                const valuePerson = {
                    type: new_type,
                    person: new_id_person,
                }
                this.getImageTraining(this.state.box_engine_cf, valuePerson);
                this.getDataPerson(this.state.box_engine_cf, valuePerson, true);
                this.setState({
                    keyTab: "change"
                })
                return this.props.onTrack(old_id_person, new_id_person, old_id_track, new_type);
            }
            else {
                swal("Lỗi!", "Cập nhật thất bại!", "error");
            }
        })
    }
    getImageTraining = (box_engine_cf, valuePerson) => {
        if (box_engine_cf === undefined) return;
        fetch(`${box_engine_cf.api}/api/imgFace/getById/${this.state.typeModal === 2 ? valuePerson.id : valuePerson.person}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                this.setState({
                    listImageTraining: rs.data,
                    activePageTraining: 1,
                    loading: false
                }, () => {
                    this.FilterImgTraining(this.state.activePageTraining);
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    loading: false
                });
            }
        })
    }
    FilterImgTraining = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPageTraining;
        const crrImagesTraining = this.state.listImageTraining.slice(offset, offset + this.itemsPerPageTraining);
        this.setState({ crrImagesTraining })
    }
    handlePageChangeTraining = (activePage) => {
        this.setState({
            ...this.state,
            activePageTraining: activePage,
            crrImagesTraining: [],
        }, () => {
            this.FilterImgTraining(activePage);
        })
    }
    deleteFaceMulti = async (dataImg) => {
        if (this.state.box_engine_cf === undefined) return;
        $('#btn_deleteMulti').addClass('m-loader');
        $('#btn_deleteMulti').attr('disabled', true);
        let data = await fetch(`${this.state.box_engine_cf.api}/api/person/ignoreFace`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                person: this.state.listImageTraining[0].person,
                images: dataImg.dataPost,
                type: this.state.listImageTraining[0].person_type
            })
        }).then((response) => {
            return response.json()
        });
        if (data.status === 10000) {
            let listImageTraining = this.state.listImageTraining;
            for (let index = 0; index < dataImg.valueImg.length; index++) {
                for (let i = 0; i < listImageTraining.length; i++) {
                    if (dataImg.valueImg[index] == listImageTraining[i].images[0].file_name) {
                        listImageTraining.splice(i, 1)
                    }
                }
            }
            var activePageTraining = this.state.activePageTraining;
            var totalPage = parseInt(this.state.listImageTraining.length / this.itemsPerPageTraining) + 1;
            if (this.state.listImageTraining.length % this.itemsPerPageTraining == 0 && activePageTraining == totalPage)
                activePageTraining--;

            this.setState({
                ...this.state,
                listChecked: {
                    valueImg: [],
                    _id: [],
                    dataPost: [],
                },
                listImageTraining: listImageTraining,
                activePageTraining: activePageTraining,
                checkDeleteMulti: false,
            });
            swal("Thành công!", "Xóa ảnh thành công", "success", {
                buttons: false,
                timer: 1500,
            });
            $('#btn_deleteMulti').removeClass('m-loader');
            $('#btn_deleteMulti').removeAttr('disabled', true);
            this.FilterImgTraining(this.state.activePageTraining);
            this.getDataPerson(this.state.box_engine_cf, this.state.crrDataInfo)
        } else {
            swal("Lỗi!", "Xóa ảnh lỗi", "error", {
                buttons: false,
                timer: 1500,
            });
            $('#btn_deleteMulti').removeClass('m-loader');
            $('#btn_deleteMulti').removeAttr('disabled', true);
        }

    }

    getUserType = (type, fullData) => {
        for (var i = 0; i < select_option.length; i++) {
            if (select_option[i].value == type)
                return fullData ? select_option[i] : select_option[i].title;
        }
    }

    handleChangeEditable = (status) => {
        this.setState({
            isEditable: status
        });
    }

    renderButton = () => {
        if (this.state.rule !== '5') {
            if (this.state.isEditable) {
                return (
                    <div>
                        <button id="button_update_info" className="btn btn-accent m-loader--light m-loader--right"
                            onClick={() => {
                                this.SaveInfo();
                            }}>
                            <span>
                                <i className='la la-floppy-o'></i> Lưu lại
                            </span>
                        </button>
                        &nbsp;
                        <button id="button_cancel_update_info" className="btn btn-danger m-loader--light m-loader--right"
                            onClick={() => {
                                this.handleChangeEditable(false);
                            }}>
                            <span>
                                <i className='la la-remove'></i> Hủy bỏ
                            </span>
                        </button>
                    </div>
                );
            } else
                return (
                    <button id="button_update_info" className="btn btn-success m-loader--light m-loader--right"
                        onClick={() => {
                            this.handleChangeEditable(true);
                        }}>
                        <span>
                            <i className='la la-edit'></i> Chỉnh sửa
                        </span>
                    </button>
                );
        } else
            return "";
    }

    handleCloseModal = () => {
        this.setState({
            isEditable: false
        });
        this.props.onHide();
    }

    tabTitleFormat = (icon, name) => {
        return (
            <span><i className={icon}></i> {name}</span>
        );
    }

    render() {
        var pageRangeDisplayed = $(window).width() < 768 ? 2 : 5;
        var TextGender = "";
        if (this.state.valuePerson !== null) {
            if (this.state.valuePerson.gender === '1') {
                TextGender = "Nam"
            } else if (this.state.valuePerson.gender === '2') {
                TextGender = "Nữ"
            }
        }
        let bulletedImg = this.state.crrImagesTraining.map((value, index) => {
            var urlImg = ""
            if (value.images.length > 0) {
                const date = value.images[0].file_name.split("_");
                urlImg = value.mount_folder + "/" + date[1].slice(0, 6) + "/" + value.camera_id;
            }
            return (
                <Col sm={3} md={2} xs={4} className="pl-0 pr-2 mb-2" key={index + 1}>
                    <div>
                        <label className="m-checkbox img-checkbox m-checkbox-day pl-0 mb-0" style={{ 'width': '100%' }}>
                            <input type="checkbox" defaultValue={value.images[0].file_name} name="img_checked" checked={this.state.listChecked.valueImg.indexOf(value.images[0].file_name) == -1 ? false : true} onClick={e => this.handleCheckedImg(e, value)} readOnly />
                            <img src={`${`${this.state.box_engine_cf.api2_url}/view/image/${urlImg}/${value.images[0].file_name}`}`} className="opacity_img img_check_training" />
                            <span />
                        </label>
                    </div>
                </Col>
            )
        });
        const aliasCamera = (id) => {
            if (this.state.box_engine_cf) {
                const listCamera = this.state.box_engine_cf.listCamera;

                const dataCamera = listCamera.find(e => e.id_box_engine == id);

                if (dataCamera) {
                    if (dataCamera.alias_box_engine) {
                        return `${dataCamera.alias_box_engine} (${id})`;
                    } else {
                        return id;
                    }
                } else {
                    return id;
                }
            }
        }
        var bullet_data_history = this.state.dataHistory.map((e, i) => {
            return (
                <tr key={i} style={{ fontSize: 16 }}>
                    <td style={{ width: 50 }}>{i + 1}</td>
                    <td style={{ width: 150 }}>
                        <Image
                            width='70'
                            height='70'
                            src={
                                e.imgFace.indexOf('http') >= 0 ? e.imgFace :
                                    `${this.state.box_engine_cf.api2_url}/view${e.imgFace}`
                            }
                        ></Image>

                    </td>
                    <td>
                        <span style={{ display: 'inline-block', padding: '5px 10px', backgroundColor: '#f59345', color: 'white', borderRadius: 5 }}>{e.created_time}</span>
                    </td>
                    <td style={{ width: 180 }}>
                        {aliasCamera(e.camera_id)}
                    </td>
                </tr>
            );
        });
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                style={{ zIndex: 9999 }}
            >
                <Modal.Body>
                    <i
                        className='la la-close closeModalUpdatePerson'
                        onClick={this.handleCloseModal}
                    ></i>
                    <Tabs defaultActiveKey='update' activeKey={this.state.keyTab} onSelect={(k) => this.setState({ keyTab: k })} transition={false} id="uncontrolled-tab-example">
                        <Tab eventKey="update" title={this.tabTitleFormat("la la-info-circle", "Cập nhật thông tin")}>

                            <div className="col-xl-12 pull-left">
                                <Row className='mb-4'>
                                    <div className="col-md-5">
                                        <div style={{ 'position': 'relative' }}>
                                            <Image onClick={() => {
                                                if (this.state.info !== null) {
                                                    this.setState({
                                                        modalShow: true
                                                    })
                                                } else {
                                                    swal("Cảnh báo!", "Bạn chưa lựa chọn đối tượng!", "warning");
                                                }
                                            }} style={{ 'cursor': 'pointer', height: 300, width: 300 }} width='100%'
                                                src={
                                                    this.state.crrDataInfo === null ? '/img/photo-placeholder.png' : this.state.typeModal === 2 ? `${this.state.box_engine_cf.api2_url}/view${this.state.crrDataInfo.information.accept_face}` : `${this.state.box_engine_cf.api2_url}/view${this.state.crrDataInfo.imgFace_Original_Size[0].imgFace}`
                                                }
                                                className='rounded-circle'></Image>
                                            <Image style={{ 'cursor': 'pointer', 'position': 'absolute', 'bottom': '-20px', 'right': '-10px', 'border': 'solid 5px white', height: 120, width: 120 }} width='45%'
                                                src={
                                                    this.state.crrDataInfo === null ? '/img/photo-placeholder.png' : this.state.old_type === 4 ? `${this.state.box_engine_cf.api2_url}/view${this.state.crrDataInfo.imgFace_Original_Size[0].imgFace}` : `${this.state.box_engine_cf.api2_url}/view${this.state.crrDataInfo.information.accept_face}`
                                                }

                                                className='rounded-circle'></Image>
                                        </div>
                                    </div>
                                    <div className="col-md-7 pl-0 table-responsive">
                                        <form id="formUpdate">
                                            <table className='tb--Info'>
                                                <tbody>
                                                    <tr>
                                                        <td><i className='fa fa-credit-card'></i> Mã nhân viên</td>
                                                        <td>
                                                            <b>
                                                                {
                                                                    this.state.isEditable ?
                                                                        <input
                                                                            onKeyDown={(event) => this.handleEnter(event)}
                                                                            value={this.state.crrDataInfo === null ? "" : this.state.crrDataInfo.information.code}
                                                                            onChange={this.handleChange.bind(this)}
                                                                            name="code"
                                                                            className='input--text m--font-success col-md-10 p-0'
                                                                            type='text'>
                                                                        </input>
                                                                        : this.state.crrDataInfo === null ? "" : this.state.crrDataInfo.information.code
                                                                }
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className='fa fa-user'></i> Họ và tên</td>
                                                        <td>
                                                            <b>
                                                                {
                                                                    this.state.isEditable ?
                                                                        <input
                                                                            onKeyDown={(event) => this.handleEnter(event)}
                                                                            value={this.state.crrDataInfo === null ? "" : this.state.crrDataInfo.information.name}
                                                                            onChange={this.handleChange.bind(this)}
                                                                            name="name"
                                                                            className='input--text m--font-success col-md-10 p-0'
                                                                            type='text'>
                                                                        </input>
                                                                        : this.state.crrDataInfo === null ? "" : this.state.crrDataInfo.information.name
                                                                }
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className='fa fa-phone'></i> Số điện thoại</td>
                                                        <td>
                                                            <b>
                                                                {
                                                                    this.state.isEditable ?
                                                                        <input
                                                                            onKeyDown={(event) => this.handleEnter(event)}
                                                                            value={this.state.crrDataInfo === null ? "" : this.state.crrDataInfo.information.phone}
                                                                            onChange={this.handleChange.bind(this)}
                                                                            name="phone"
                                                                            className='input--text m--font-success col-md-10 p-0'
                                                                            type='text'>
                                                                        </input>
                                                                        : this.state.crrDataInfo === null ? "" : this.state.crrDataInfo.information.phone
                                                                }
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className='fa fa-birthday-cake'></i> Năm sinh</td>
                                                        <td>
                                                            <b>
                                                                {
                                                                    this.state.isEditable ?
                                                                        <input
                                                                            onKeyDown={(event) => this.handleEnter(event)}
                                                                            value={this.state.crrDataInfo === null ? "" : this.state.crrDataInfo.information.age}
                                                                            onChange={this.handleChange.bind(this)}
                                                                            name="age"
                                                                            className='input--text m--font-success col-md-10 p-0'
                                                                            type='text'
                                                                            placeholder='dd/mm/yyyy'>
                                                                        </input>
                                                                        : this.state.crrDataInfo === null ? "" : this.state.crrDataInfo.information.age
                                                                }
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className='fa fa-venus-mars'></i> Giới tính</td>
                                                        <td>
                                                            <b>
                                                                {
                                                                    this.state.isEditable ?
                                                                        <div className="m-radio-inline">
                                                                            <label className="m-radio mb-0">
                                                                                <input type="radio" name="gender" checked={this.state.crrDataInfo === null ? false : this.state.crrDataInfo.information.gender === "1" ? true : false} defaultValue={1} onChange={this.handleChange.bind(this)} /> Nam
                                                                                <span />
                                                                            </label>
                                                                            <label className="m-radio mb-0">
                                                                                <input type="radio" name="gender" checked={this.state.crrDataInfo === null ? false : this.state.crrDataInfo.information.gender === "2" ? true : false} defaultValue={2} onChange={this.handleChange.bind(this)} /> Nữ
                                                                                <span />
                                                                            </label>
                                                                        </div>
                                                                        : this.state.crrDataInfo === null ? "" : this.state.crrDataInfo.information.gender === "1" ? "Nam" : "Nữ"
                                                                }
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className='fa fa-building'></i> Bộ phận</td>
                                                        <td>
                                                            <b>
                                                                {
                                                                    this.state.isEditable ?
                                                                        <input
                                                                            onKeyDown={(event) => this.handleEnter(event)}
                                                                            value={this.state.crrDataInfo === null ? "" : this.state.crrDataInfo.information.department}
                                                                            onChange={this.handleChange.bind(this)}
                                                                            name="department"
                                                                            className='input--text m--font-success col-md-10 p-0'
                                                                            type='text'>
                                                                        </input>
                                                                        : this.state.crrDataInfo === null ? "" : this.state.crrDataInfo.information.department
                                                                }
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    {
                                                        this.state.typeModal === 2 ? (
                                                            null
                                                        )
                                                            : (
                                                                <tr>
                                                                    <td><i className='fa fa-clock'></i> Checkin</td>
                                                                    <td><b>{this.state.crrDataInfo !== null ? this.state.crrDataInfo.timeget : ''}</b></td>
                                                                </tr>
                                                            )
                                                    }
                                                    {
                                                        this.state.typeModal === 2 ? (
                                                            null
                                                        )
                                                            : (
                                                                <tr>
                                                                    <td><i className='fa fa-calendar-plus'></i> Lần có mặt trước</td>
                                                                    <td><b className='m--font-info'>{this.state.crrDataInfo !== null ? this.state.crrDataInfo.person_type === 4 ? this.state.crrDataInfo.last_visited : this.state.crrDataInfo.information.last_visited : ''}</b></td>
                                                                </tr>
                                                            )
                                                    }
                                                    <tr>
                                                        <td><i className='fa fa-user-cog'></i> Nhóm</td>
                                                        <td className="select_camera">
                                                            {
                                                                this.state.isEditable ?
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        // style={{zIndex: 1000000000}}
                                                                        options={select_option}
                                                                        disabled={SELECT_GROUP == 0 && this.state.old_type === 1 ? true : false}
                                                                        getOptionLabel={option => option.title}
                                                                        value={this.state.crrDataInfo === null ? this.state.crr_type : this.getUserType(this.state.crrDataInfo.type, true)}
                                                                        onChange={this.handleChangeType}
                                                                        disableClearable
                                                                        renderInput={params => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                placeholder="Select Type"
                                                                                fullWidth
                                                                            />
                                                                        )}
                                                                    />
                                                                    : this.state.crrDataInfo === null ? "" : this.getUserType(this.state.crrDataInfo.type, false)
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </Row>
                                <div className="pull-right">
                                    {this.renderButton()}
                                </div>
                            </div>
                        </Tab>
                        {
                            this.state.rule !== '5' &&
                            <Tab eventKey="change" title={this.tabTitleFormat("la la-user", "Ảnh mẫu")}>
                                <div className="m-form m-form--fit m-form--label-align-right">
                                    <div className="m-portlet__body">
                                        <div className="list_col_image">
                                            {
                                                <div className="mt-2 mb-2">
                                                    {
                                                        this.state.listImageTraining.length > 0
                                                            ?
                                                            <label className="m-checkbox m-checkbox--success mt-3 ml-1">
                                                                <input type="checkbox" onClick={(e) => {
                                                                    this.setState({
                                                                        checkDeleteMulti: !this.state.checkDeleteMulti,
                                                                        listChecked: {
                                                                            valueImg: [],
                                                                            _id: [],
                                                                            dataPost: [],
                                                                        }
                                                                    }, () => {
                                                                        let valueImg = [];
                                                                        let _id = [];
                                                                        let dataPost = [];
                                                                        if (this.state.checkDeleteMulti) {
                                                                            this.state.listImageTraining.forEach(value => {
                                                                                valueImg.push(value.images[0].file_name);
                                                                                _id.push(value.id);
                                                                                dataPost.push({ track_id: value.id, file_name: value.images[0].file_name });
                                                                            })
                                                                        }
                                                                        this.setState({
                                                                            listChecked: {
                                                                                valueImg: valueImg,
                                                                                _id: _id,
                                                                                dataPost: dataPost,
                                                                            }
                                                                        })
                                                                    })
                                                                }} checked={this.state.checkDeleteMulti} /> <div style={{ 'paddingTop': '2px' }}>{this.state.checkDeleteMulti ? 'Bỏ chọn tất cả' : 'Chọn tất cả'}</div>
                                                                <span />
                                                            </label>
                                                            : ""
                                                    }
                                                    {
                                                        this.state.listChecked.valueImg.length > 0
                                                            ?
                                                            <div style={{ 'display': 'inline-flex' }} className="pull-right">
                                                                <button
                                                                    onClick={(e) => {
                                                                        if (this.state.listChecked.valueImg.length > 0) {
                                                                            e.preventDefault();
                                                                            if (!window.confirm("Bạn chắc chắn muốn xóa ảnh này?")) {
                                                                                return;
                                                                            }
                                                                            this.deleteFaceMulti(
                                                                                this.state.listChecked
                                                                            );
                                                                        } else {
                                                                            swal("Cảnh báo!", "Bạn chưa chọn ảnh để xóa", "warning");
                                                                        }
                                                                    }}
                                                                    className="btn btn-danger width-mobie--100 m-loader--light m-loader--right" id="btn_deleteMulti">
                                                                    Xóa ảnh đã chọn
                                                                </button>
                                                            </div>
                                                            :
                                                            (
                                                                <div style={{ 'display': 'inline-block' }} className="ml-2 pull-right">
                                                                    <ReactFileReader maxFileSize={1000} minFileSize={0} fileTypes={['image/png', 'image/jpg', 'image/jpeg']} base64={true} multipleFiles={true} handleFiles={async (file) => {
                                                                        this.setState({
                                                                            loading: true
                                                                        })
                                                                        await onFilesChange(file, this.state.crrDataInfo, this.state.box_engine_cf);
                                                                        const data_person = {
                                                                            id: this.state.typeModal === 2 ? this.state.crrDataInfo.id : this.state.crrDataInfo.person,
                                                                            person: this.state.typeModal === 2 ? this.state.crrDataInfo.id : this.state.crrDataInfo.person,
                                                                            type: this.state.crrDataInfo.type,
                                                                        }
                                                                        this.getImageTraining(this.state.box_engine_cf, data_person);
                                                                        this.getDataPerson(this.state.box_engine_cf, this.state.crrDataInfo);
                                                                        this.setState({
                                                                            loading: false
                                                                        })
                                                                    }}>
                                                                        <button className='btn m-btn--icon m-btn button_uploadfaces btn-default m-loader--success m-loader--right'>
                                                                            {
                                                                                this.state.loading ? null :
                                                                                    (
                                                                                        <span>
                                                                                            <i className="la la-cloud-upload"></i>
                                                                                            <span>Upload ảnh mẫu</span>

                                                                                        </span>
                                                                                    )
                                                                            }
                                                                            <PulseLoader
                                                                                css={override}
                                                                                sizeUnit={"px"}
                                                                                size={12}
                                                                                margin={'2px'}
                                                                                color={'#36D7B7'}
                                                                                loading={this.state.loading}
                                                                            />
                                                                        </button>
                                                                    </ReactFileReader>
                                                                    <img src="" id="preview" style={{ 'display': 'none' }}></img>
                                                                </div>
                                                            )
                                                    }

                                                </div>
                                            }

                                            <div className="row image_capture_small pull-left w-100">
                                                {
                                                    bulletedImg
                                                }
                                            </div>
                                            {
                                                this.state.listImageTraining.length > 0
                                                    ?
                                                    <div className="col-xl-12  pl-0 pr-4 pull-left">
                                                        <div className="pull-left">
                                                            <Pagination
                                                                prevPageText={<i className='la la-angle-left'></i>}
                                                                nextPageText={<i className='la la-angle-right'></i>}
                                                                firstPageText={<i className='la la-angle-double-left'></i>}
                                                                lastPageText={<i className='la la-angle-double-right'></i>}
                                                                activePage={this.state.activePageTraining}
                                                                itemsCountPerPage={this.itemsPerPageTraining}
                                                                totalItemsCount={this.state.listImageTraining.length}
                                                                pageRangeDisplayed={pageRangeDisplayed}
                                                                onChange={this.handlePageChangeTraining}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    ""
                                            }

                                            {/* <div className="text-center">
                                                <PulseLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={12}
                                                    margin={'2px'}
                                                    color={'#36D7B7'}
                                                    loading={this.state.loading}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        }
                        {
                            this.state.rule !== '5' &&
                            <Tab eventKey="history" title={this.tabTitleFormat("la la-list-alt", "Lịch sử")}>
                                <table id='tbViolation' className="table text-center table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed" style={{ "maxHeight": "800px", "overflow": "hidden scroll" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>STT</th>
                                            <th style={{ width: 150 }}>Ảnh</th>
                                            <th>Thời gian</th>
                                            <th style={{ width: 180 }}>Camera</th>
                                        </tr>
                                    </thead>
                                    <tbody className='scroll' id="scrollableHistory">
                                        {/* {
                                                                        bullet_data_history
                                                                    } */}
                                        <InfiniteScroll
                                            dataLength={this.state.dataHistory.length}
                                            next={this.fetchMoreDataHistory}
                                            hasMore={this.state.hasMoreHistory}
                                            loader={
                                                <div className="pt-3 text-center m-auto">
                                                    <PulseLoader
                                                        css={override}
                                                        sizeUnit={"px"}
                                                        size={12}
                                                        margin={'2px'}
                                                        color={'#36D7B7'}
                                                        loading={this.state.loadingHistory}
                                                    />
                                                </div>
                                            }
                                            scrollableTarget="scrollableHistory"
                                        >
                                            {bullet_data_history}
                                        </InfiniteScroll>
                                    </tbody>
                                </table>
                                <div className='pull-right'>
                                    <button
                                        onClick={() => {
                                            this.handleReloadHistory();
                                        }}
                                        className="btn btn-secondary m-btn m-btn--icon">
                                        <span>
                                            <i className="la la-rotate-left" />
                                            <span>Tải lại</span>
                                        </span>
                                    </button>
                                </div>
                            </Tab>
                        }
                        {/* {
                            this.state.rule !== '5' &&
                            <Tab eventKey="merge" title="Gộp đối tượng">
                                <div className="row">
                                    <div className="col-md-5 pr-0">
                                        <Row className='mb-4'>
                                            <div style={{ 'width': '60%', 'margin': 'auto' }}>
                                                <div style={{ 'position': 'relative' }}>
                                                    <Image
                                                        style={{ 'cursor': 'pointer' }}
                                                        width='100%'
                                                        src={
                                                            this.state.crrDataInfo == null ?
                                                                '/img/photo-placeholder.png' :
                                                                this.state.crrDataInfo.imgFace_Original_Size[0] !== undefined && this.state.crrDataInfo.imgFace_Original_Size[0].imgFace.indexOf('http') >= 0 ?
                                                                    this.state.crrDataInfo.imgFace_Original_Size[0].imgFace :
                                                                    `${this.state.box_engine_cf.api}/view/image/${this.state.crrDataInfo.imgFace_Original_Size[0] !== undefined && this.state.crrDataInfo.imgFace_Original_Size[0].imgFace}`}
                                                        className='rounded-circle'
                                                    ></Image>
                                                </div>
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} className="pt-4">
                                                <table className='tb--Info'>
                                                    <tbody>
                                                        <tr>
                                                            <td><i className='fa fa-clock'></i> Checkin</td>
                                                            <td><b>{this.state.crrDataInfo != null ? this.state.crrDataInfo.timecreated : ''}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td><i className='fa fa-calendar-plus'></i> Lần có mặt trước</td>
                                                            <td><b className='m--font-info'>{this.state.crrDataInfo != null ? this.state.crrDataInfo.information.last_visited : ''}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="col-md-2 m-auto text-center">
                                        <button disabled={this.state.valuePerson === null ? true : false} onClick={this.change_track} className="btn btn-outline-accent m-btn m-btn--icon">
                                            <i className="la la-arrow-right"></i>
                                        </button>
                                    </div>
                                    <div className="col-md-5 pl-0">
                                        <Row className='mb-4'>
                                            <div style={{ 'width': '60%', 'margin': 'auto' }}>
                                                <div style={{ 'position': 'relative' }}>
                                                    <Image
                                                        style={{ 'cursor': 'pointer' }}
                                                        width='100%'
                                                        src={
                                                            this.state.valuePerson == null ?
                                                                '/img/photo-placeholder.png' :
                                                                this.state.valuePerson.accept_face.indexOf('http') >= 0 ?
                                                                    this.state.valuePerson.accept_face :
                                                                    `${this.state.box_engine_cf.api}/view/image/${this.state.valuePerson.accept_face}`
                                                        }
                                                        className='rounded-circle'
                                                    ></Image>
                                                </div>

                                            </div>

                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} className="pt-4">
                                                <div>
                                                    <table className='tb--Info'>
                                                        <tbody>
                                                            <tr>
                                                                <td><i className='fa fa-user'></i> Họ và tên</td>
                                                                <td><b>{this.state.valuePerson != null ? this.state.valuePerson.name : ''}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td><i className='fa fa-phone'></i> Số điện thoại</td>
                                                                <td><b>{this.state.valuePerson != null ? this.state.valuePerson.phone : ''}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td><i className='fa fa-birthday-cake'></i> Năm sinh</td>
                                                                <td><b>{this.state.valuePerson != null ? this.state.valuePerson.age : ''}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td><i className='fa fa-venus-mars'></i> Giới tính</td>
                                                                <td><b>{TextGender}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td><i className='fa fa-user-cog'></i> Nhóm</td>
                                                                <td className="select_camera">
                                                                    <Autocomplete
                                                                        options={select_option_1}
                                                                        getOptionLabel={option => option.title}
                                                                        value={this.state.selected_type}
                                                                        onChange={this.handleChangeOption}
                                                                        disableClearable
                                                                        renderInput={params => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                placeholder="Chọn nhóm"
                                                                                fullWidth
                                                                            />
                                                                        )}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="p-2">
                                                        <Dropdown
                                                            placeholder='Chọn người'
                                                            fluid
                                                            search
                                                            selection
                                                            value={this.state.valuePerson}
                                                            onChange={this.handleChangePerson}
                                                            options={this.state.optionPerson}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Tab>
                        } */}
                    </Tabs>
                </Modal.Body>
            </Modal>
        )
    }
}


ModalUpdatePerson.propTypes = {
    crrdatainfo: PropTypes.object,
    box_engine_cf: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    onSplit: PropTypes.func.isRequired,
    onTrack: PropTypes.func.isRequired,
    show: PropTypes.bool,
    onReLoad: PropTypes.func,
}

export default React.memo(ModalUpdatePerson);