import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Store from '../../store';
import { withRouter } from 'react-router';
import { EMOTION } from '../../config';

class MenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
        }
        Store.subscribe(() => {
            this.setState({
                isLogin: Store.getState().isLogin.isLogin
            })
        });
    }

    render() {
        var active = '';
        if (this.props.location.pathname === "/") {
            active = 'm-menu__item--active'
        }
        return (
            <div id="m_aside_left" className={"m-grid__item	m-aside-left  m-aside-left--skin-dark "}>
                {/* BEGIN: Aside Menu */}
                <div id="m_ver_menu" className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark " m-menu-vertical={1} m-menu-scrollable={1} m-menu-dropdown-timeout={500} style={{ position: 'relative' }}>
                    <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
                        <li className="m-menu__item" aria-haspopup="true">
                            {/* <NavLink to="/dashboard" className={"m-menu__link "+active} activeClassName="m-menu__item--active"> */}
                            <NavLink to="/reva" className={"m-menu__link "+active} activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon la la-video-camera" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Giám sát an ninh</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="m-menu__item" aria-haspopup="true">
                            {/* <NavLink to="/dashboard" className={"m-menu__link "+active} activeClassName="m-menu__item--active"> */}
                            <NavLink to="/checking" className={"m-menu__link "} activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon la la-calendar-check-o"/>
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Theo dõi điểm danh</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        {/* <li className="m-menu__item" aria-haspopup="true"> */}
                            {/* <NavLink to="/dashboard" className={"m-menu__link "+active} activeClassName="m-menu__item--active"> */}
                            {/* <NavLink to="/dashboard" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon flaticon-graph" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Báo cáo hệ thống</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li> */}
                        {/* <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <a href="#/" className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon la la-video-camera" />
                                <span className="m-menu__link-text">Quản lý vào/ra</span>
                                <i className="m-menu__ver-arrow la la-angle-right" />
                            </a>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav"> */}
                                    {/* <li className="m-menu__item " aria-haspopup="true"> */}
                                        {/* <NavLink to="/reva" className="m-menu__link " activeClassName="m-menu__item--active"> */}
                                        {/* <NavLink to="/dashboard" className={"m-menu__link "+active} activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text"> Màn hình theo dõi vào ra</span>
                                        </NavLink>
                                    </li> */}
                                    {/* <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/checking" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text"> Theo dõi điểm danh</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                        <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <a href="#/" className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon flaticon-search" />
                                <span className="m-menu__link-text">Lịch sử/Tìm kiếm</span>
                                <i className="m-menu__ver-arrow la la-angle-right" />
                            </a>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/history" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Lịch sử</span>
                                        </NavLink>
                                    </li>
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/searchimage" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Tìm kiếm đối tượng</span>
                                        </NavLink>
                                    </li>
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/trash" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Dữ liệu loại</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <a href="#/" className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon la la-info" />
                                <span className="m-menu__link-text">Thống kê/Báo cáo</span>
                                <i className="m-menu__ver-arrow la la-angle-right" />
                            </a>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/dashboard" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Thống kê lưu lượng</span>
                                        </NavLink>
                                    </li>
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/statistical" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Tần xuất xuất hiện</span>
                                        </NavLink>
                                    </li>
                                    {/* {
                                        EMOTION == 1 &&
                                        <>
                                            <li className="m-menu__item " aria-haspopup="true">
                                                <NavLink to="/age" className="m-menu__link " activeClassName="m-menu__item--active">
                                                    <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                        <span />
                                                    </i>
                                                    <span className="m-menu__link-text"> Tuổi</span>
                                                </NavLink>
                                            </li>
                                            <li className="m-menu__item " aria-haspopup="true">
                                                <NavLink to="/gender" className="m-menu__link " activeClassName="m-menu__item--active">
                                                    <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                        <span />
                                                    </i>
                                                    <span className="m-menu__link-text"> Giới tính</span>
                                                </NavLink>
                                            </li>
                                            <li className="m-menu__item " aria-haspopup="true">
                                                <NavLink to="/emotion" className="m-menu__link " activeClassName="m-menu__item--active">
                                                    <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                        <span />
                                                    </i>
                                                    <span className="m-menu__link-text"> Cảm xúc</span>
                                                </NavLink>
                                            </li>
                                        </>
                                    } */}
                                </ul>
                            </div>
                        </li>
                        <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <a href="#/" className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon flaticon-profile" />
                                <span className="m-menu__link-text">Quản lý User</span>
                                <i className="m-menu__ver-arrow la la-angle-right" />
                            </a>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/list-user" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Danh sách User</span>
                                        </NavLink>
                                    </li>
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/trainning" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Ảnh mẫu</span>
                                        </NavLink>
                                    </li>
                                    {/* <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/setting-door" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Thiết lập cửa</span>
                                        </NavLink>
                                    </li> */}
                                    
                                </ul>
                            </div>
                        </li>
                        
                        


                        {/* <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <a href="#/" className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon la la-list" />
                                <span className="m-menu__link-text">BiQueue</span>
                                <i className="m-menu__ver-arrow la la-angle-right" />
                            </a>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/history_emotion" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Emotion History</span>
                                        </NavLink>
                                    </li>
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/statistical_biqueue" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Emotion Statistical</span>
                                        </NavLink>
                                    </li>
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/statistical_staying" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Staying time Statistics</span>
                                        </NavLink>
                                    </li>
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/stayStatistic_person" className="m-menu__link " activeClassName="m-menu__item--active">
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Staying time Statistics / Persons</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                        {/* <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/traffic" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon fa flaticon-diagram" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Traffic</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li> */}
                        {/* <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/age" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon la la-birthday-cake" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Age</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li> */}
                        {/* <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/gender" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon la la-intersex" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Gender</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li> */}
                        {/* <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/emotion" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon la la-smile-o" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Emotion</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li> */}
                        {/* <li className="m-menu__item" aria-haspopup="true">
                            <NavLink to="/trash" className="m-menu__link " activeClassName="m-menu__item--active">
                                <i className="m-menu__link-icon fa flaticon-pin" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Ảnh nghiêng</span>
                                    </span>
                                </span>
                             </NavLink>
                        </li> */}
                    </ul>
                </div>
                {/* END: Aside Menu */}
            </div>
        );
    }
}

export default withRouter(MenuBar);