export const SETBOX = 'SETBOX';
export function setbox(box_engine_cf, box_engine_hm){
    return {
        type: SETBOX,
        payload:{
            box_engine_cf: box_engine_cf,
            box_engine_hm: box_engine_hm,
        }
    }
}
