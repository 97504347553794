import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Chart from 'react-apexcharts';

class ViewGender extends Component {
    render() {
        return (
            <Tabs defaultActiveKey="chart" transition={false} id="uncontrolled-tab-example">
                <Tab eventKey="chart" title="Biểu đồ">
                    <div className="col-md-12">
                        <div className="donut mb-4" id="chart_gender">
                            <Chart options={this.props.optionsGender} series={this.props.seriesGender} type="donut" width="380" />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="table" title="Bảng">
                    <div className="col-md-12">
                        <table className="table table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Giới tính</th>
                                    <th scope="col">Lưu lượng qua lại</th>
                                </tr>
                            </thead>
                            {
                                this.props.dataGender.map((e, i) => {
                                    var numMale = 0
                                    var numFemale = 0
                                    var numUnknow = 0
                                    for (let i = 0; i < e.data.length; i++) {
                                        numMale += e.data[i].gender.male;
                                        numFemale += e.data[i].gender.female;
                                        numUnknow += e.data[i].gender.unknown
                                    }
                                    return (
                                        <tbody id="show_data_gender" key={i}>
                                            <tr>
                                                <td>Nam</td>
                                                <td id="male_data">{numMale}</td>
                                            </tr>
                                            <tr>
                                                <td>Nữ</td>
                                                <td id="female_data">{numFemale}</td>
                                            </tr>
                                            <tr>
                                                <td>Không rõ</td>
                                                <td id="unknown_data">{numUnknow}</td>
                                            </tr>
                                        </tbody>
                                    );
                                })
                            }
                        </table>
                    </div>
                </Tab>
            </Tabs>
        );
    }
}

export default ViewGender;