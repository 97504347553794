import {THEME} from '../../actions/setTheme';

let setTheme = localStorage.getItem('setTheme');
const initialState = {
    setTheme:  setTheme
};
export default function theme(state = initialState, action) {
    switch(action.type){
        case THEME:
            return{
                setTheme: action.payload.setTheme
            };
        default:
            return state;
    }
}
