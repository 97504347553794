import {InboxOutlined} from "@ant-design/icons";
import {Upload} from "antd";
import axios from "axios";
import "moment/locale/vi";
import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import Dropzone from "react-dropzone";
import LoadingOverlay from "react-loading-overlay";
import {PulseLoader} from "react-spinners";

const {Dragger} = Upload;

const ModalUpload = (props) => {
  const {show, onHide, onUpload} = props;
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      {...props}
      animation={false}
      size="lg"
      backdrop={loading ? "static" : true}
      keyboard={false}
      dialogClassName={`${
        window.innerWidth >= 1920 ? "modal-size-res" : "modal-size"
      }`}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <LoadingOverlay
        active={loading}
        spinner={
          <PulseLoader
            sizeUnit={"px"}
            size={12}
            margin={"2px"}
            color={"#36D7B7"}
            loading={true}
          />
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(0, 0, 0, 0.58)",
          }),
        }}
        className="col-xl-12 p-0"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Upload thư mục
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="Container-modal">
            <Dropzone
              onDrop={onUpload}
              accept="image/*"
              // minSize={1024}
              // maxSize={300000}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject,
              }) => {
                const additionalClass = isDragAccept
                  ? "accept"
                  : isDragReject
                  ? "reject"
                  : "";

                return (
                  <div
                    {...getRootProps({
                      className: `dropzone ${additionalClass}`,
                    })}
                  >
                    <input {...getInputProps()} />
                    <InboxOutlined style={{fontSize: "50px"}} />
                    <p>Kéo thả thư mục hình ảnh, hoặc chọn ảnh để nhập</p>
                    <p style={{color: "red"}}>
                      * Quy tắc đặt tên ảnh: Tên ảnh bắt đầu bằng Mã nhân viên
                      (Ví dụ: 101.png). Nếu một người có nhiều ảnh thì thêm _số
                      thứ tự ảnh (Ví dụ: 101_1.png, 101_2.png, ...)
                    </p>
                  </div>
                );
              }}
            </Dropzone>
          </div>
        </Modal.Body>
      </LoadingOverlay>
    </Modal>
  );
};

export default ModalUpload;
