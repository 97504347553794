import { css } from '@emotion/core';
import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import Store from '../../../store';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;



class SettingDoor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            data: null,
            shifts: [],
        };
        var itemsPerPage = 10;
        this.itemsPerPage = itemsPerPage;
        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    ...this.state,
                    loading: true,
                    isLogin: Store.getState().isLogin.isLogin,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                }, () => {
                    this.getDoor();
                });
            } else {
                this.setState({
                    ...this.state,
                    isLogin: Store.getState().isLogin.isLogin,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                });
            }
        });
        this.getDoor();
    }

    getDoor() {
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        fetch(`${this.state.box_engine_cf.api2_url}/api/door/doors`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                var str = JSON.stringify(rs.data, undefined, 4)
                this.setState({
                    data: str,
                });
            }
           
        }).catch((error) => {
            if (error) {
                console.log(error)
            }
        })
    }

    setDoors() {
        if (this.state.box_engine_cf === undefined) return;
        if (this.state.box_engine_cf.api2_url === null) return;
        fetch(`${this.state.box_engine_cf.api2_url}/api/door/setDoors`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                doors: JSON.parse(this.state.data)
            })
            
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                swal("Thành công", "Sửa cài đặt cửa thành công", "success", {
                    button: "Đóng",
                });
                this.getDoor()
            } else {
                swal("Lỗi", "Sửa cài đặt thất bại", "error");
            }
           
        }).catch((error) => {
            if (error) {
                console.log(error)
            }
        })
    }



    handleChangeInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }





    render() {
        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper p-2" id="capture_form">
                <div className="m-content p-2">
                    <div className="m-portlet" id="form_reva">
                        <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                                <div className="m-portlet m-portlet--space bg-white border-white">
                                    <div className="m-portlet__head">
                                        <div className="m-portlet__head-caption">
                                            <div className="m-portlet__head-title">
                                                <h3 className="m-portlet__head-text">
                                                    Cài đặt cửa
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-portlet__body">
                                        <div className="row">
                                            {
                                                <>
                                                    <div className="row col-md-12">
                                                        <div className="col-md-1 col-xl-1">
                                                            Thông tin cài đặt
                                                        </div>
                                                        <div className="col-md-11 col-xl-11">
                                                            <div id="">
                                                                <div className="form-group m-form__group m-0">
                                                                    <div className="form-group m-form__group" >
                                                                        <textarea type="text" 
                                                                        rows="15" className="form-control m-input col-md-5" 
                                                                        value={this.state.data}
                                                                        name="data"
                                                                        onChange={(event) => this.handleChangeInput(event)} 
                                                                        />
                                                                    </div>
                                                                    <button className="btn btn-accent m-loader--light m-loader--right"
                                                                    onClick={() => this.setDoors()}
                                                                    >Xác nhận
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="m-portlet__head capture_form_select d-flex align-items-center justify-content-between">


                        </div>
                        <div className="m-portlet__body  m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-3">
                                    <div className="m-portlet--tab mb-0">

                                    </div>
                                </div>
                                <div className="col-xl-9" id="iframe_hide_mobie">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SettingDoor;