import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import swal from 'sweetalert';
import Store from '../../store';
import { LIST_PERSON, SELECT_GROUP } from './../../config';

let select_option = [];

if (LIST_PERSON.indexOf('1') !== -1 && SELECT_GROUP != 0) {
    select_option.push({ title: 'Nhân viên', value: 1 })
}
if (LIST_PERSON.indexOf('2') !== -1) {
    select_option.push({ title: 'Vip', value: 2 })
}
if (LIST_PERSON.indexOf('3') !== -1) {
    select_option.push({ title: 'Danh sách đen', value: 3 })
}
if(LIST_PERSON.indexOf('4') !== -1){
    select_option.push({ title: 'Người lạ', value: 4, key: 4 })
}
if (LIST_PERSON.indexOf('5') !== -1) {
    select_option.push({ title: 'Khách hàng', value: 5 })
}



class ModalUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEdit: [],
            select_option: select_option,
            typePerson: {
                text: 'Người lạ',
                value: 4,
            },
            type: "",
            checkedMale: true,
            checkedFemale: false,
            checkedBD: false,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            shifts: []
            
        }
        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                }, () => {
                });
            } else {
                this.setState({
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                });
            }
        });
    }


    UpdateUser = async () => {

        if (this.state.box_engine_cf === undefined) return;
        fetch(`${this.state.box_engine_cf.api}/api/person/update`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...this.state.dataEdit,type: this.state.type, new_type: this.state.typePerson.value})
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                swal("Thành công!", "Cập nhật thành công!", "success", {
                    buttons: false,
                    timer: 1500,
                });
                this.props.onHide();
            } else {
                swal("Lỗi!", "Cập nhật thất bại!", "error");
            }
        }).catch((error) => {
            if (error) {
                swal("Lỗi!", "Cập nhật thất bại!", "error");
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            const typePerson = nextProps.data.type === 1 ? { title: 'Nhân viên', value: 1 } : nextProps.data.type === 2 ? { title: 'Vip', value: 2 } :
                nextProps.data.type === 3 ? { title: 'Danh sách đen', value: 3 } : nextProps.data.type === 5 ? { title: 'Khách hàng', value: 5 } : null;
                this.setState({
                dataEdit: nextProps.data,
                shifts: nextProps.shifts,
                typePerson: typePerson,
                type: nextProps.data.type,
            }); 
        }
    }

    UserHandle(e) {
        var UserHan = this.state.dataEdit;
        UserHan[e.target.name] = e.target.value
        this.setState({ dataEdit: UserHan });
    }

    handleChangeType(e, value){
        var crrDataInfo = this.state.dataEdit;
        crrDataInfo['type'] = value.value;
       this.setState({
           typePerson: value,
           dataEdit: crrDataInfo,
       })
    }


  

    render() {
        let click_handle = (event) => {
            this.UpdateUser(this.state.dataEdit, event);
        }
        console.log(this.state.dataEdit);

        if (this.state.dataEdit === null ? 1 : this.state.dataEdit.gender == 1) {
            this.state.checkedMale = true
            this.state.checkedFemale = false
            this.state.checkedBD = false
        } else if (this.state.dataEdit.gender == 2) {
            this.state.checkedMale = false
            this.state.checkedFemale = true
            this.state.checkedBD = false
        } else if (this.state.dataEdit.gender == 3) {
            this.state.checkedMale = false
            this.state.checkedFemale = false
            this.state.checkedBD = true
        } else {
            this.state.checkedMale = false
            this.state.checkedFemale = false
            this.state.checkedBD = false
        }

        var shift = []
        if (this.state.shifts && this.state.shifts.length > 0) {
            this.state.shifts.map((valueShift, indexShift) => {
                shift.push(valueShift.name)
            })
        }

        return (


            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formUpdateUser">
                        <div className="col-xl-12">
                            <div className="m-widget14 row">

                                <div className="form-group m-form__group col-xl-12 p-0">
                                    <label htmlFor="id">Mã nhân viên <span class="text-danger">* </span></label>
                                    <input className="form-control m-input" id="code" name='code' onChange={e => this.UserHandle(e)} placeholder="ID"
                                        value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.code} required/>
                                </div>
                                <div className="form-group m-form__group col-xl-12 p-0">
                                    <label htmlFor="name">Họ và tên <span class="text-danger">* </span></label>
                                    <input className="form-control m-input" id="name" name='name' onChange={e => this.UserHandle(e)} placeholder="Họ và tên"
                                        value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.name} required />
                                </div>
                                <div className="form-group m-form__group col-xl-12 p-0">
                                    <label htmlFor="age">Tuổi <span class="text-danger">* </span></label>
                                    <input className="form-control m-input" id="age" name='age' onChange={e => this.UserHandle(e)} placeholder="Tuổi"
                                        value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.age} required />
                                </div>
                                <div className="form-group m-form__group col-xl-12 p-0">
                                    <label htmlFor="gender">Giới tính <span class="text-danger">*</span> </label>
                                    <div className="mt-2">
                                        <label className="m-radio mr-2">
                                            <input type="radio" name="gender" defaultChecked={this.state.checkedMale} defaultValue={1} onClick={e => this.UserHandle(e)} /> Nam
                                            <span />
                                        </label>
                                        <label className="m-radio mr-2">
                                            <input type="radio" name="gender" defaultChecked={this.state.checkedFemale} defaultValue={2} onClick={e => this.UserHandle(e)} /> Nữ
                                            <span />
                                        </label>
                                        <label className="m-radio mr-2">
                                            <input type="radio" name="gender" defaultChecked={this.state.checkedBD} defaultValue={3} onClick={e => this.UserHandle(e)} /> Giới tính khác
                                            <span />
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group col-xl-12 p-0">
                                    <label htmlFor="Phone">Số điện thoại </label>
                                    <input className="form-control m-input" type="tel" id="Phone" name='phone' onChange={e => this.UserHandle(e)} placeholder="Số điện thoại"
                                        value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.phone} />
                                </div>
                                <div className="form-group m-form__group col-xl-12 p-0">
                                    <label htmlFor="Address">Phòng ban </label>
                                    <input className="form-control m-input" id="department" name='department' onChange={e => this.UserHandle(e)} placeholder="Phòng ban"
                                        value={this.state.dataEdit === undefined ? '' : this.state.dataEdit.department} />
                                </div>
                                <div className="form-group m-form__group col-xl-12 p-0">
                                    <label htmlFor="Avatar">Nhóm </label>
                                    <div className="select_option_type">
                                        <Autocomplete
                                            options={this.state.select_option}
                                            disabled={SELECT_GROUP == 0 && this.state.type === 1 ? true : false}
                                            getOptionLabel={option => option.title}
                                            value={this.state.typePerson}
                                            onChange={this.handleChangeType.bind(this)}
                                            disableClearable
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder='Chọn...'
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                {/* <div className="form-group m-form__group col-xl-12 p-0">
                                    <label htmlFor="Address">Ca </label>
                                    <input className="form-control m-input" disabled  placeholder="Ca" value={shift.toString()} />
                                </div> */}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" onClick={click_handle}>Lưu</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>Huỷ</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalUser.propTypes = {
    data: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
}


export default ModalUser;