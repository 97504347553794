import React, { Component } from 'react';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import Store from '../../../store';
import $ from 'jquery';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { Row, Col, Image } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ReactTooltip from 'react-tooltip'
import ModalMoveImg from '../../modal/ModalMoveImg';
import { Input } from '@material-ui/core';
import ModalUpdatePerson from '../../modal/ModalUpdatePerson';
import { LIST_PERSON } from '../../../config';
import {
    ExcelExport,
    ExcelExportColumn,
    ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
let select_option = [];

if(LIST_PERSON.indexOf('1') !== -1){
    select_option.push({ title: 'CBNV', value: 1 })
}
if (LIST_PERSON.indexOf('2') !== -1) {
    select_option.push({ title: 'Vip', value: 2 })
}
if (LIST_PERSON.indexOf('3') !== -1) {
    select_option.push({ title: 'Danh sách đen', value: 3 })
}
if (LIST_PERSON.indexOf('4') !== -1) {
    select_option.push({ title: 'Người lạ', value: 4 })
}
if (LIST_PERSON.indexOf('5') !== -1) {
    select_option.push({ title: 'Khách hàng', value: 5 })
}

class History extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            access_token: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            loading: true,
            listHistory: [],
            crrHistory: [],
            dataImageMoveChange: null,
            crrFrom_Date: new Date(),
            crr_comp: select_option[0],
            modalShow: false,
            startDate: new Date(moment().format("YYYY-MM-DD") + ' 00:00:00'),
            endDate: new Date(moment().format("YYYY-MM-DD") + ' 23:59:59'),
            deparment: '',
            id_staff: '',
            page_num: 1,
            page_size: 16,
            total_count: 0,
            showUpdatePerson: false,
            crrDataInfo: null,
            loading_api_excel: false,
            listHistoryExport: []
        };
        var itemsPerPage = 1;
        if ($(window).width() < 768) {
            itemsPerPage = 8
        } else {
            itemsPerPage = 16
        }
        this.itemsPerPage = itemsPerPage;
        Store.subscribe(() => {
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    ...this.state,
                    loading: true,
                    listHistory: [],
                    crrHistory: [],
                    isLogin: Store.getState().isLogin.isLogin,
                    access_token: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    dataImageMoveChange: null,
                    modalShow: false
                }, () => {
                    this.getPerson();
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    listHistory: [],
                    crrHistory: [],
                    isLogin: Store.getState().isLogin.isLogin,
                    access_token: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                    dataImageMoveChange: null,
                    modalShow: false
                });
            }
        });
        this.getPerson();
    }
    getPerson() {
        if (this.state.box_engine_cf === undefined) return;
        fetch(`${this.state.box_engine_cf.api2_url}/api/person/getHistory`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                type: this.state.crr_comp.value,
                idCamera: this.state.box_engine_cf.ids_Cam.join(','),
                start_time: moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),
                end_time: moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'),
                department: this.state.deparment,
                id_staff: this.state.id_staff,
                page_num: this.state.page_num,
                page_size: this.state.page_size,
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                var data = rs.data;
                // const a = data.map(map => ({
                //     ...map,
                //     imgFace: [{ imgFace: map.imgFace }]
                // }))
                this.setState({
                    listHistory: data,
                    loading: false,
                    total_count: rs.count,
                });
                $('.imgHistory').height($('.imgHistory').width());
                $('.imgHistoryRoot').height($('.imgHistoryRoot').width());
            }
            else {
                this.setState({
                    listHistory: [],
                    loading: false,
                    total_count: 0,
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    listHistory: [],
                    loading: false,
                    total_count: 0,
                });
            }
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            page_num: pageNumber,
            crrImages: [],
        }, () => {
            this.getPerson();
        })
    }

    _exporter;
    export = () => {
        this._exporter.save();
    }

    getPersonExport() {
        if (this.state.box_engine_cf === undefined) return;
        this.setState({ loading_api_excel: true });
        fetch(`${this.state.box_engine_cf.api2_url}/api/person/getHistory`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: this.state.crr_comp.value,
                idCamera: this.state.box_engine_cf.ids_Cam.join(','),
                start_time: moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),
                end_time: moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'),
                department: this.state.deparment,
                id_staff: this.state.id_staff,
                page_num: -1,
                page_size: 1000,
            })
        }).then((response) => {
            return response.json()
        }).then((rs) => {
            if (rs.status === 10000) {
                var data = rs.data;
                console.log(rs)
                this.setState({
                    listHistoryExport: data,
                    loading_api_excel: false,
                }, () => {
                    this._exporter.save();
                });
            }
            else {
                this.setState({
                    loading_api_excel: false,
                    listHistoryExport: [],
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    loading_api_excel: false,
                    listHistoryExport: [],
                });
            }
        })
    }

    moveImgToTrain = (event, value) => {
        console.log(value)
        this.setState({
            dataImageMoveChange: value,
        }, () => {
            this.setState({
                modalShow: true
            });
        });
    }
    closeModal = (crrDataInfo, old_id, old_type) => {
        this.setState({ showUpdatePerson: false, crrDataInfo: null }, () => {
            this.getPerson();
        })
    }

    modalCloseUpdatePerson = (old_track, new_track, type) => {
        console.log("split success");
        var lst_person;
        var lst_person_guest = this.state.lst_person_guest;
        var crr_person = this.state.crr_person;

        if (crr_person.type === 4) {
            lst_person = this.state.items
        } else if (crr_person.type === 3) {
            lst_person = this.state.lst_person_blacklist
        } else if (crr_person.type === 2) {
            lst_person = this.state.lst_person_vip
        } else if (crr_person.type === 1) {
            lst_person = this.state.lst_person_staff
        }

        if (type === 'split') {
            if (crr_person.type === 4) {
                for (let ii = 0; ii < lst_person.length; ii++) {
                    if (lst_person[ii].person === crr_person.person) {
                        lst_person[ii]._id = old_track._id
                        lst_person[ii].imgRc = old_track.imgRc
                        lst_person[ii].timeget = old_track.timeget
                        lst_person[ii].timecreated = old_track.timecreated
                        //imgFace
                        lst_person[ii].imgFace[0].imgFace = old_track.imgFace
                        lst_person[ii].imgFace[0]._id = old_track._id
                        lst_person[ii].imgFace[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace[0].timeget = old_track.timeget
                        //imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0]._id = old_track._id
                        lst_person[ii].imgFace_Original_Size[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace_Original_Size[0].timeget = old_track.timeget
                    }
                }
                lst_person.unshift(new_track)
                lst_person_guest.unshift(new_track)
                lst_person = lst_person.sort((a, b) => new moment(a['timecreated']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timecreated']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                lst_person_guest = lst_person_guest.sort((a, b) => new moment(a['timecreated']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timecreated']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                this.setState({
                    items: lst_person,
                    lst_person_guest: lst_person_guest,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 3) {
                for (let ii = 0; ii < lst_person.length; ii++) {
                    if (lst_person[ii].person === crr_person.person) {
                        lst_person[ii]._id = old_track._id
                        lst_person[ii].imgRc = old_track.imgRc
                        lst_person[ii].timeget = old_track.timeget
                        lst_person[ii].timecreated = old_track.timecreated
                        //imgFace
                        lst_person[ii].imgFace[0].imgFace = old_track.imgFace
                        lst_person[ii].imgFace[0]._id = old_track._id
                        lst_person[ii].imgFace[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace[0].timeget = old_track.timeget
                        //imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0]._id = old_track._id
                        lst_person[ii].imgFace_Original_Size[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace_Original_Size[0].timeget = old_track.timeget
                    }
                }
                lst_person.unshift(new_track)
                lst_person = lst_person.sort((a, b) => new moment(a['timecreated']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timeget']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                this.setState({
                    lst_person_blacklist: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 2) {
                for (let ii = 0; ii < lst_person.length; ii++) {
                    if (lst_person[ii].person === crr_person.person) {
                        lst_person[ii]._id = old_track._id
                        lst_person[ii].imgRc = old_track.imgRc
                        lst_person[ii].timeget = old_track.timeget
                        lst_person[ii].timecreated = old_track.timecreated
                        //imgFace
                        lst_person[ii].imgFace[0].imgFace = old_track.imgFace
                        lst_person[ii].imgFace[0]._id = old_track._id
                        lst_person[ii].imgFace[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace[0].timeget = old_track.timeget
                        //imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0]._id = old_track._id
                        lst_person[ii].imgFace_Original_Size[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace_Original_Size[0].timeget = old_track.timeget
                    }
                }
                lst_person.unshift(new_track)
                lst_person = lst_person.sort((a, b) => new moment(a['timecreated']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timecreated']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                this.setState({
                    lst_person_vip: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 1) {
                for (let ii = 0; ii < lst_person.length; ii++) {
                    if (lst_person[ii].person === crr_person.person) {
                        lst_person[ii]._id = old_track._id
                        lst_person[ii].imgRc = old_track.imgRc
                        lst_person[ii].timeget = old_track.timeget
                        lst_person[ii].timecreated = old_track.timecreated
                        //imgFace
                        lst_person[ii].imgFace[0].imgFace = old_track.imgFace
                        lst_person[ii].imgFace[0]._id = old_track._id
                        lst_person[ii].imgFace[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace[0].timeget = old_track.timeget
                        //imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size
                        lst_person[ii].imgFace_Original_Size[0]._id = old_track._id
                        lst_person[ii].imgFace_Original_Size[0].imgRc = old_track.imgRc
                        lst_person[ii].imgFace_Original_Size[0].timeget = old_track.timeget
                    }
                }
                lst_person.unshift(new_track)
                lst_person = lst_person.sort((a, b) => new moment(a['timecreated']).format('YYYY-MM-DD HH:mm:ss') < new moment(b['timecreated']).format('YYYY-MM-DD HH:mm:ss') ? 1 : -1)
                this.setState({
                    lst_person_staff: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            }
        } else if (type === 'delete') {
            if (crr_person.type === 4) {
                for (let i = 0; i < lst_person.length; i++) {
                    if (lst_person[i].person === crr_person.person) {
                        lst_person[i].imgFace[0].imgFace = old_track.imgFace;
                        lst_person[i].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size;
                    }
                }
                this.setState({
                    items: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 3) {
                for (let i = 0; i < lst_person.length; i++) {
                    if (lst_person[i].person === crr_person.person) {
                        lst_person[i].imgFace[0].imgFace = old_track.imgFace;
                        lst_person[i].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size;
                    }
                }
                this.setState({
                    lst_person_blacklist: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 2) {
                for (let i = 0; i < lst_person.length; i++) {
                    if (lst_person[i].person === crr_person.person) {
                        lst_person[i].imgFace[0].imgFace = old_track.imgFace;
                        lst_person[i].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size;
                    }
                }
                this.setState({
                    lst_person_vip: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            } else if (crr_person.type === 1) {
                for (let i = 0; i < lst_person.length; i++) {
                    if (lst_person[i].person === crr_person.person) {
                        lst_person[i].imgFace[0].imgFace = old_track.imgFace;
                        lst_person[i].imgFace_Original_Size[0].imgFace = old_track.imgFace_Original_Size;
                    }
                }
                this.setState({
                    lst_person_staff: lst_person,
                    modalShow: false,
                }, () => {
                    this.getinfo(this.state.crr_person);
                })
            }
        } else {
            this.setState({
                modalShow: false,
            });
        }
    }

    modalCloseChangeTrack = (old_id, new_id, old_id_track, typeFormChange) => {
        //     console.log("Change track success");
        //     var items = this.state.items;
        //     var lst_person_guest = this.state.lst_person_guest;
        //     var crr_person = this.state.crr_person;
        //     if (typeFormChange !== 4) {
        //         if (old_id !== undefined) {
        //             // items = items.filter((item, index) => {
        //             //     return item['person'] !== old_id
        //             // });
        //             lst_person_guest = lst_person_guest.filter((item, index) => {
        //                 return item['person'] !== old_id
        //             });
        //         }

        //         this.setState({
        //             modalChangeTrackShow: false,
        //             lst_person_guest: lst_person_guest,
        //             // items: items
        //         });
        //     } else {

        //         if (crr_person !== null) {
        //             if (crr_person.imgFace.length > 0) {
        //                 this.getinfo(crr_person);
        //                 this.setState({
        //                     modalChangeTrackShow: false,
        //                 });
        //             } else {


        //                 this.setState({
        //                     modalChangeTrackShow: false,
        //                     crr_person: null,
        //                     info: null,
        //                     dataHistory: [],
        //                     crrDataInfo: {
        //                         '_id': "",
        //                         'name': '',
        //                         'phone': '',
        //                         'age': '',
        //                         'gender': '',
        //                         'department': '',
        //                         'type': '',
        //                         'last_type': ''
        //                     },
        //                 });
        //             }
        //         } else {
        //             this.setState({
        //                 modalChangeTrackShow: false,
        //             });
        //         }
        //     }
    }

    modalClose = () => {
        this.setState({
            modalShow: false,
        }, () => {
            this.getPerson();
        });
    }

    handleChangeStartDate = startDate => {
        this.setState({
            startDate: startDate
        })
    }

    handleChangeEndDate = endDate => {
        this.setState({
            endDate: endDate
        })
    }

    handleChangeOption = (index, option) => {
        this.setState({
            crr_comp: option,
            loading: true,
            listHistory: [],
            page_num: 1,
            crrHistory: []
        }, () => {
            this.getPerson()
        });
    }

    render() {
        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }
        const aliasCamera = (id) => {
            if (this.state.box_engine_cf) {
                const listCamera = this.state.box_engine_cf.listCamera;

                const dataCamera = listCamera.find(e => e.id_box_engine == id);

                if (dataCamera) {
                    if (dataCamera.alias_box_engine) {
                        return `${dataCamera.alias_box_engine}(${id})`;
                    } else {
                        return `${dataCamera.name_engine}_${id}`;
                    }
                } else {
                    return id;
                }
            }
        }
        let bulletedImg = this.state.listHistory.map((value, index) => {
            var gender = ""
            if (value.gender == 1) {
                gender = "Nam"
            } else if (value.gender == 2) {
                gender = "Nữ"
            } else {
                gender = "Giới tính khác"
            }

            var glasses = ""
            if (value.glasses === 0) {
                glasses = "Không đeo kính"
            } else if (value.glasses === 1) {
                glasses = "Đeo kính"
            }
            var urlImg = ""
            if (value.images.length > 0) {
                const date = value.images[0].file_name.split("_");
                urlImg = value.mount_folder + "/" + date[1].slice(0, 6) + "/" + value.camera_id;
            }
            return (
                <Col xs={12} md={6} lg={4} xl={3} key={index} className="p-1 position-relative col_xxl-4 ">
                    <div className={'box box-block form_info_history mb-0 '} style={{ 'borderRadius': '5px' }} onClick={() => { this.setState({ showUpdatePerson: true, crrDataInfo: { ...value, imgFace_Original_Size: [{ imgFace: value.images[0].file_name }] } }) }}>
                        <Row className='row-sm'>
                            <Col xs={4} md={4} style={{ 'textAlign': 'center' }} className="imgHover">
                                <Image width='100%' src={`${value.images.length === 0 ? '/img/photo-placeholder.png' : `${this.state.box_engine_cf.api2_url}/view${value.images[0].file_name}`}`} className='img-fluid imgHistory   border-3' />
                            </Col>
                            <Col xs={8} md={8} style={{ 'fontSize': '13px' }}>
                                <Row className="ml-3">
                                    <Col xs={12} md={12} className="pr-0 pl-0">
                                        <Row>
                                            <Col xs={8} md={8} className="pr-2 pl-1" style={{ 'display': 'inline-block' }}>
                                                <div className="col-md-12 p-0 table-responsive">
                                                    <div id="formUpdate">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td><i className='fa fa-credit-card'></i> </td>
                                                                    <td>
                                                                        <b>
                                                                            {
                                                                                value.code
                                                                            }
                                                                        </b>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-user'></i> </td>
                                                                    <td>
                                                                        <div className='textInfoHistory'>
                                                                            <b>
                                                                                {
                                                                                    value.name
                                                                                }
                                                                            </b>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td><i className='fa fa-birthday-cake'></i> </td>
                                                                    <td>
                                                                        {
                                                                            value.age
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-venus-mars'></i> </td>
                                                                    <td>
                                                                        {
                                                                            gender
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-camera'></i> </td>
                                                                    <td>
                                                                        {
                                                                            aliasCamera(value.camera_id)
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-eye'></i> </td>
                                                                    <td>
                                                                        {
                                                                            glasses
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-building'></i> </td>
                                                                    <td>
                                                                        <div className='textInfoHistory'>
                                                                            {
                                                                                value.department
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><i className='fa fa-clock'></i></td>
                                                                    <td>{value.created_time}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} className="pl-0 pr-0 d-flex flex-column justify-content-between align-items-center">
                                                {
                                                    value.idStaff == 'unknown'
                                                        ?
                                                        ''
                                                        :
                                                        <Image width='100%' src={`${this.state.box_engine_cf.api2_url}/view${value.thumbnail_sample_image}`} style={{ 'maxHeight': '100px' }} className={'img-fluid ' + (value.thumbnail_sample_image === "" ? "d-none" : "") + ' imgHistoryRoot border-3'} />
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <button
                        onClick={(event) => {
                            this.moveImgToTrain(event, value);
                            this.setState({
                                crrDataInfo: value
                            })
                        }}
                        className={"btn btn-success m-btn m-btn--icon btn-sm m-btn--icon-only btn-move-img m-btn--pill m-btn--air btn-removeimg-capture btn-remove-customer " + (value.idStaff === 'unknown' ? 'd-none' : '')}
                        data-tip data-for='MoveToTrain'
                        style={{ zIndex: 9, left: 15, top: 10 }}
                    >
                        <ReactTooltip id='MoveToTrain' type='dark' effect='solid'>
                            <span>Chuyển thành ảnh mẫu</span>
                        </ReactTooltip>
                        <i className="la la-share"></i>
                    </button>
                </Col>
            )
        });

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content mt-3-phone pd_phone_0 p-3">
                    <div className="m-portlet m-portlet--tab mb-0" id="form_reva">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption col-xs-12 col-md-12 col-lg-10 col-xl-10 ">
                                <div className="col-md-5 col-xs-12 pl-0">
                                    <div className="input-daterange input-group dateRanger">
                                        <DatePicker
                                            className="form-control m-input m_datetimepicker_6 w_140"
                                            showTimeSelect={true}
                                            selected={this.state.startDate}
                                            onChange={this.handleChangeStartDate}
                                            dateFormat="yyyy-MM-dd HH:mm"
                                            timeFormat="HH:mm"
                                            withPortal
                                            placeholderText="Start Date" />
                                        <div className="input-group-append" id="m_daterangepicker_6">
                                            <span className="input-group-text">
                                                <i className="la la-calendar glyphicon-th" />
                                            </span>
                                        </div>
                                        <DatePicker
                                            className="form-control m-input m_datetimepicker_6 w_140"
                                            showTimeSelect={true}
                                            selected={this.state.endDate}
                                            onChange={this.handleChangeEndDate}
                                            dateFormat="yyyy-MM-dd HH:mm"
                                            timeFormat="HH:mm"
                                            withPortal
                                            minDate={this.state.startDate}
                                            placeholderText="End Date" />
                                    </div>
                                </div>

                                <div className="col-md-2 col-xs-6 pr-0 pl-0 select_camera">
                                    <Autocomplete
                                        options={select_option}
                                        getOptionLabel={option => option.title}
                                        // defaultValue={{ 'value': -1, 'title': language[this.state.indexlanguage].placeholderSelect.all_department }}
                                        value={this.state.crr_comp}
                                        onChange={this.handleChangeOption}
                                        disableClearable
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Select Type"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </div>

                                <div className="ml-3 mr-3">
                                    <input min="0" className="form-control m-input" placeholder="Nhập tên bộ phận" name="deparment" value={this.state.deparment} onKeyDown={this.onEnterPress} onChange={(event) => this.setState({ deparment: event.target.value })} />
                                </div>
                                <div className="mr-3">
                                    <input min="0" className="form-control m-input" placeholder="Nhập Mã/Tên nhân viên" name="id_staff" value={this.state.id_staff} onKeyDown={this.onEnterPress} onChange={(event) => this.setState({ id_staff: event.target.value })} />
                                </div>

                                <div className="ml-2 mb-mobile-10">
                                    <button
                                        onClick={() => {
                                            this.setState({
                                                page_num: 1,
                                                loading: true,
                                                listHistory: [],
                                                crrHistory: []
                                            }, () => {
                                                this.getPerson();
                                            })
                                        }}
                                        className="btn btn-accent m-btn m-btn--icon">
                                        <span>
                                            <i className="la la-search" />
                                            <span>Tìm kiếm</span>
                                        </span>
                                    </button>
                                </div>

                            </div>
                            <div className="ml-2 mb-mobile-10 pull-right">
                                <button className="btn btn-primary m-btn m-btn--icon"
                                    onClick={() => this.getPersonExport()}
                                >
                                    {
                                        this.state.loading_api_excel ?
                                            <PulseLoader
                                                css={override}
                                                sizeUnit={"px"}
                                                size={12}
                                                margin={'2px'}
                                                color={'#36D7B7'}
                                                loading={this.state.loading_api_excel}
                                            />
                                            :
                                            <span>
                                                <i className="la la-download" />
                                                <span>Xuất Excel</span>
                                            </span>
                                    }
                                </button>
                            </div>
                        </div>
                        {/*begin::Form*/}
                        <div className="m-form m-form--fit m-form--label-align-right">
                            <div className="m-portlet__body pt-3">
                                <div className="col-md-12">
                                    {/* <span>
                                        Showing {this.state.showFirst} to {this.state.showLast} of {this.state.totalLength} entries
                                    </span> */}
                                    <div className="pl-2">
                                        <Pagination
                                            prevPageText={<i className='la la-angle-left'></i>}
                                            nextPageText={<i className='la la-angle-right'></i>}
                                            firstPageText={<i className='la la-angle-double-left'></i>}
                                            lastPageText={<i className='la la-angle-double-right'></i>}
                                            activePage={this.state.page_num}
                                            itemsCountPerPage={this.state.page_size}
                                            totalItemsCount={this.state.total_count}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                    <div className='row-sm row pl-3 pr-3 pb-3'>
                                        {bulletedImg}
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={12}
                                            margin={'2px'}
                                            color={'#36D7B7'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <ModalMoveImg
                                        datamove={this.state.dataImageMoveChange}
                                        show={this.state.modalShow}
                                        onHide={this.modalClose}
                                    />
                                    <div className="hide_desktop pl-2" style={{ 'paddingBottom': '50px' }}>
                                        <Pagination
                                            prevPageText={<i className='la la-angle-left'></i>}
                                            nextPageText={<i className='la la-angle-right'></i>}
                                            firstPageText={<i className='la la-angle-double-left'></i>}
                                            lastPageText={<i className='la la-angle-double-right'></i>}
                                            activePage={this.state.page_num}
                                            itemsCountPerPage={this.state.page_size}
                                            totalItemsCount={this.state.total_count}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end::Form*/}
                    </div>

                </div>
                <ModalUpdatePerson
                    show={this.state.showUpdatePerson}
                    onHide={this.closeModal}
                    onSplit={this.modalCloseUpdatePerson}
                    onTrack={this.modalCloseChangeTrack}
                    crrdatainfo={this.state.crrDataInfo}
                    box_engine_cf={this.state.box_engine_cf}
                />
                <ExcelExport
                    data={this.state.listHistoryExport}
                    ref={(exporter) => { this._exporter = exporter; }}
                    fileName={`Danh-Sach-Nhan-Vien-Checkin-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`}
                >
                    <ExcelExportColumn field="id" title="ID" width={80} />
                    <ExcelExportColumn field="name" title="Họ và Tên" width={180} />
                    <ExcelExportColumn field="department" title="Phòng ban" width={150} />
                    <ExcelExportColumn field="timeget" title="Thời gian checkin" width={150} />
                </ExcelExport>
            </div>
        );
    }
}

export default History;