import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import HighchartsReact from 'highcharts-react-official';

class ViewAge extends Component {
    render() {
        return (
            <Tabs defaultActiveKey="chart" transition={false} id="uncontrolled-tab-example">
                <Tab eventKey="chart" title="Biểu đồ">
                    <div className="col-md-12  pl_phone_0 pr_phone_0">
                        <HighchartsReact
                            highcharts={this.props.highCharts}
                            options={this.props.chartOptionsAge}
                        />
                    </div>
                </Tab>
                <Tab eventKey="table" title="Bảng">
                    <div className="col-md-12">
                        <table className="table table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Tuổi</th>
                                    <th scope="col">Dữ liệu qua lại</th>
                                </tr>
                            </thead>
                            {
                                this.props.dataAge.map((e, i) => {
                                    var age_10_15 = 0;
                                    var age_16_25 = 0;
                                    var age_26_30 = 0;
                                    var age_31_40 = 0;
                                    var age_41_50 = 0;
                                    var age_51_60 = 0;
                                    var age_61 = 0;
                                    var unknown = 0;
                                    for (let i = 0; i < e.data.length; i++) {
                                        age_10_15 += e.data[i].age.age_10_15;
                                        age_16_25 += e.data[i].age.age_16_25;
                                        age_26_30 += e.data[i].age.age_26_30;
                                        age_31_40 += e.data[i].age.age_31_40;
                                        age_41_50 += e.data[i].age.age_41_50;
                                        age_51_60 += e.data[i].age.age_51_60;
                                        age_61 += e.data[i].age.age_61;
                                        unknown += e.data[i].age.age_unknown;
                                    }
                        
                                    return (
                                        <tbody id="show_data_age" key={i}>
                                            <tr>
                                                <td>10-15</td>
                                                <td id="age_10_15_data">{age_10_15}</td>
                                            </tr>
                                            <tr>
                                                <td>16-25</td>
                                                <td id="age_16_25_data">{age_16_25}</td>
                                            </tr>
                                            <tr>
                                                <td>26-30</td>
                                                <td id="age_26_30_data">{age_26_30}</td>
                                            </tr>
                                            <tr>
                                                <td>31-40</td>
                                                <td id="age_31_40_data">{age_31_40}</td>
                                            </tr>
                                            <tr>
                                                <td>41-50</td>
                                                <td id="age_41_50_data">{age_41_50}</td>
                                            </tr>
                                            <tr>
                                                <td>51-60</td>
                                                <td id="age_51_60_data">{age_51_60}</td>
                                            </tr>
                                            <tr>
                                                <td>61+</td>
                                                <td id="age_61_data">{age_61}</td>
                                            </tr>
                                            <tr>
                                                <td>Không rõ</td>
                                                <td id="age_unknown_data">{unknown}</td>
                                            </tr>
                                        </tbody>
                                    );
                                })
                            }
                        </table>
                    </div>
                </Tab>
            </Tabs>
        );
    }
}

export default ViewAge;