

var _HOST_CLOUD = ""
var _HOST = ""
var _HOST_1 = ""
var _HOST_REVA = ""
var _HOST_SOCKET_COUTFACE = ""
var _HOST_COUNTHEAD = ""
var _ENGINE_NAME = ''
var _LIST_PERSON = ''
var _AUDIO_TIME = ''
var _LOGO = ''

_HOST_CLOUD = process.env.REACT_APP_HOST_CLOUD
_HOST = process.env.REACT_APP_HOST
_HOST_1 = process.env.REACT_APP_HOST_1
_HOST_REVA = process.env.REACT_APP_HOST_REVA
_HOST_SOCKET_COUTFACE = process.env.REACT_APP_HOST_SOCKET_COUTFACE
_HOST_COUNTHEAD = process.env.REACT_APP_HOST_HOST_COUNTHEAD
_ENGINE_NAME = process.env.REACT_APP_BOX_ENGINE_NAME
_LIST_PERSON = process.env.REACT_APP_LIST_PERSON
_AUDIO_TIME = process.env.REACT_APP_AUDIO_TIME
_LOGO = process.env.REACT_APP_LOGO

export const ENGINE_NAME_BIFACE = process.env.REACT_APP_BOX_ENGINE_NAME_BIFACE

export const HOST_CLOUD = _HOST_CLOUD
export const HOST = _HOST
export const HOST_1 = _HOST_1
export const HOST_REVA = _HOST_REVA
export const HOST_SOCKET_COUTFACE = _HOST_SOCKET_COUTFACE
export const HOST_COUNTHEAD = _HOST_COUNTHEAD
export const ENGINE_NAME = _ENGINE_NAME
export const LIST_PERSON = _LIST_PERSON
export const AUDIO_TIME = _AUDIO_TIME
export const LOGO = _LOGO
export const EMOTION = process.env.REACT_APP_EMOTION
export const SELECT_GROUP = process.env.REACT_APP_SELECT_GROUP
export const SEARCH_MONTHS = process.env.REACT_APP_SEARCH_MONTHS