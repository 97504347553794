import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { HOST_CLOUD } from '../config'
import './css/login.css';
import Store from '../store';
import { login } from '../actions/isLogin';
import { theme } from '../actions/setTheme';
import swal from 'sweetalert';
import $ from 'jquery';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: 0
        }
        this.email = '';
        this.password = '';
        localStorage.clear();
    }
    CheckLogin = async (event) => {
        var form = $("#formLoginCheck")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            event.preventDefault()
            event.stopPropagation()
            $('#btnLogin').addClass('m-loader');
            $('#btnLogin').attr('disabled', true);
            await fetch(`${HOST_CLOUD}/api/login`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: this.email.trim(),
                    password: this.password.trim()
                })
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status === 10000) {
                    this.setState({ error: 0 })
                    var now = new Date().getTime();
                    localStorage.setItem('setupTime', now)
                    localStorage.setItem("access_token", "Bearer " + data.access_token);
                    localStorage.setItem("setTheme", "Dark");
                    Store.dispatch(theme("Dark"));
                    Store.dispatch(login("Bearer " + data.access_token));
                    window.location.href = "/reva";
                } else if (data.status === 10005) {
                    $('#btnLogin').removeClass('m-loader');
                    $('#btnLogin').removeAttr('disabled', true);
                    swal("Cảnh báo", "Tài khoản của bạn đã bị khóa, vui lòng chờ 30 phút rồi đăng nhập lại hoặc liên hệ admin", "warning");
                } else {
                    this.setState({ error: 1 })
                    $('#btnLogin').removeClass('m-loader');
                    $('#btnLogin').removeAttr('disabled', true);
                    // return;
                }
            }).catch(function (error) {
                swal("Warning!", "Hiện tại hệ thống của chúng tôi đang nâng cấp, vui lòng đăng nhập lại sau", "info");
                $('#btnLogin').removeClass('m-loader');
                $('#btnLogin').removeAttr('disabled', true);
            })

            // let result = await fetch(`${HOST_CLOUD}/api/login`, {
            //     method: 'POST',
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({
            //         email: this.email,
            //         password: this.password
            //     })
            // });
            // let data = await result.json();
            // console.log(data);
            // if (data.status !== 10000) {
            //     this.setState({ error: 1 })
            //     // return;
            // }
            // else {
            //     this.setState({ error: 0 })
            //     var now = new Date().getTime();
            //     localStorage.setItem('setupTime', now)
            //     // console.log("Bearer " + data.access_token)
            //     localStorage.setItem("access_token", "Bearer " + data.access_token);
            //     localStorage.setItem("setTheme", "Dark");
            //     Store.dispatch(theme("Dark"));
            //     Store.dispatch(login("Bearer " + data.access_token));
            //     window.location.href = "/dashboard";
            // }
        }
    }

    onEnterPress = (e) => {

        if (e.keyCode === 13 && e.shiftKey === false) {
            if (e.target.name === 'email') {
                this.refs.password.focus();
            }
            else {
                e.preventDefault();
                this.CheckLogin(e);
            }
        }

    }

    render() {
        return (
            <section className="login-block" style={{ 'width': '100%','height':'100%' }}>
                <div className="container">
                    <Row>
                        <Col md={4} className="login-sec">
                            <form id="formLoginCheck">
                                <h2 className="text-center">Đăng Nhập</h2>
                                <div className="login-form" id="formLogin">
                                    <label id="label_check" className={"form-check-label" + (this.state.error === 1 ? "" : " d-none")}>
                                        <small className="text-danger">* bạn nhập sai email hoặc mật khẩu</small>
                                    </label>
                                    <div className="form-group mt-2">
                                        <label htmlFor="exampleInputEmail1" className="text-uppercase">Email</label>
                                        <input type="text" name="email" className="form-control" placeholder='Email' required onKeyDown={this.onEnterPress} onChange={(e) => {
                                            this.email = e.target.value;
                                        }} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1" className="text-uppercase">Mật khẩu</label>
                                        <input type="password" ref='password' name="password" className="form-control" placeholder='Mật khẩu' required onKeyDown={this.onEnterPress} onChange={(e) => {
                                            this.password = e.target.value;
                                        }} />
                                    </div>
                                    <div className="form-check text-center pl-0">
                                        <button type="submit" className="btn btn-login mt-2 m-loader--light m-loader--right" id="btnLogin" onClick={(event) => {
                                            this.CheckLogin(event);
                                        }}>Đăng nhập</button>
                                    </div>
                                </div>
                                <div className="copy-text">Created with <i className="fa fa-heart" /> by BeetMember</div>
                            </form>
                        </Col>
                        <Col md={8} className="banner-sec">
                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
                                    <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                                </ol>
                                <div className="carousel-inner" role="listbox">
                                    <div className="carousel-item active">
                                        <img className="d-block img-fluid" src="img/pexels-photo.jpg" alt="First slide" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text b-0">
                                                <h2>This is Reva</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block img-fluid" src="img/people-coffee-tea-meeting.jpg" alt="First slide" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text">
                                                <h2>This is Heaven</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        )
    }
}
export default Login;
