import React, { Component } from 'react';
// First way to import
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import DatePicker from "react-datepicker";
import { convertDate } from '../utils/Date'
import { Redirect } from 'react-router-dom';
import Store from '../../store';
import $ from 'jquery';
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/core';
import { chartAgeLight, chartAgeDark } from './Dashboard/ConfigChart/ChartAge';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Age extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogin: Store.getState().isLogin.isLogin,
            accessToken: Store.getState().isLogin.access_token,
            box_engine_cf: Store.getState().boxai.box_engine_cf[0],
            dataAge: [],
            optionsTrafic: null,
            startDate: new Date(),
            endDate: new Date(),
            loading: true,
            chartOptions: chartAgeDark,
        };

        Store.subscribe(() => {
            //CharAGE
            if (Store.getState().theme.setTheme === 'Dark') {
                this.setState({
                    chartOptions: chartAgeDark
                })
            } else {
                this.setState({
                    chartOptions: chartAgeLight,
                })
            }
            if (Store.getState().boxai.box_engine_cf.length !== 0) {
                this.setState({
                    loading: true,
                    isLogin: Store.getState().isLogin.isLogin,
                    accessToken: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                }, () => {
                    this.getData();
                });
            } else {
                this.setState({
                    loading: false,
                    isLogin: Store.getState().isLogin.isLogin,
                    accessToken: Store.getState().isLogin.access_token,
                    box_engine_cf: Store.getState().boxai.box_engine_cf[0],
                });
            }

        });
    }

    componentDidMount() {
        this.getData();
        $('text.highcharts-credits').html('');
    }

    getData = () => {
        this.getAge()
    }

    getAge = async () => {
        if (this.state.box_engine_cf === undefined) return;
        await fetch(`${this.state.box_engine_cf.api2_url}/api/reports/age_perday`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Store.getState().isLogin.access_token
            },
            dataType: 'json',
            body: JSON.stringify({
                'idCamera': this.state.box_engine_cf.ids_Cam.join(','),
                'start_date': convertDate(this.state.startDate),
                'end_date': convertDate(this.state.endDate)

            })
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.status === 10000) {
                var age_10_15 = 0;
                var age_16_25 = 0;
                var age_26_30 = 0;
                var age_31_40 = 0;
                var age_41_50 = 0;
                var age_51_60 = 0;
                var age_61 = 0;
                for (let i = 0; i < data.data.length; i++) {
                    age_10_15 += data.data[i].age_10_15;
                    age_16_25 += data.data[i].age_16_25;
                    age_26_30 += data.data[i].age_26_30;
                    age_31_40 += data.data[i].age_31_40;
                    age_41_50 += data.data[i].age_41_50;
                    age_51_60 += data.data[i].age_51_60;
                    age_61 += data.data[i].age_61;
                }
                var data_age =
                    [
                        age_10_15,
                        age_16_25,
                        age_26_30,
                        age_31_40,
                        age_41_50,
                        age_51_60,
                        age_61
                    ];
                var totalAll = age_10_15 + age_16_25 + age_26_30 + age_31_40 + age_41_50 + age_51_60 + age_61;
                this.setState({
                    dataAge: [data],
                    loading: false,
                    chartOptions: {
                        title: {
                            text: 'Total: ' + totalAll,
                            style: {
                                fontSize: '15px'
                            }
                        },
                        series: [{
                            name: 'Age',
                            data: data_age,
                            color: '#ffc241'
                        }],
                    }
                });
            } else {
                this.setState({
                    dataAge: [],
                    loading: false,
                    chartOptions: {
                        title: {
                            text: 'Total: 0',
                            style: {
                                fontSize: '15px'
                            }
                        },
                        series: [{
                            name: 'Age',
                            data: [],
                            color: '#ffc241'
                        }],
                    }
                });
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    dataAge: [],
                    loading: false,
                    chartOptions: {
                        title: {
                            text: 'Total: 0',
                            style: {
                                fontSize: '15px'
                            }
                        },
                        series: [{
                            name: 'Age',
                            data: [],
                            color: '#ffc241'
                        }],
                    }
                });
            }
        });

    }

    handleChangeStartDate = startDate => {
        this.setState({
            startDate: startDate
        });
    }

    handleChangeEndDate = endDate => {
        this.setState({
            endDate: endDate
        })
    }

    handleClickSearch = () => {
        this.setState({
            loading: true
        }, () => {
            this.getAge()
        });
    }

    render() {

        if (this.state.isLogin === false) {
            return (
                <Redirect to={'/login'} />
            )
        }

        const { chartOptions } = this.state;
        let bulletedAge = this.state.dataAge.map((e, i) => {
            var age_10_15 = 0;
            var age_16_25 = 0;
            var age_26_30 = 0;
            var age_31_40 = 0;
            var age_41_50 = 0;
            var age_51_60 = 0;
            var age_61 = 0;
            var unknown = 0;
            for (let i = 0; i < e.data.length; i++) {
                age_10_15 += e.data[i].age_10_15;
                age_16_25 += e.data[i].age_16_25;
                age_26_30 += e.data[i].age_26_30;
                age_31_40 += e.data[i].age_31_40;
                age_41_50 += e.data[i].age_41_50;
                age_51_60 += e.data[i].age_51_60;
                age_61 += e.data[i].age_61;
                unknown += e.data[i].unknown;
            }
            return (
                <tbody id="show_data_age" key={i}>
                    <tr>
                        <td>10-15</td>
                        <td>{age_10_15}</td>
                    </tr>
                    <tr>
                        <td>16-25</td>
                        <td>{age_16_25}</td>
                    </tr>
                    <tr>
                        <td>26-30</td>
                        <td>{age_26_30}</td>
                    </tr>
                    <tr>
                        <td>31-40</td>
                        <td>{age_31_40}</td>
                    </tr>
                    <tr>
                        <td>41-50</td>
                        <td>{age_41_50}</td>
                    </tr>
                    <tr>
                        <td>51-60</td>
                        <td>{age_51_60}</td>
                    </tr>
                    <tr>
                        <td>61+</td>
                        <td>{age_61}</td>
                    </tr>
                    <tr>
                        <td>Unknown</td>
                        <td>{unknown}</td>
                    </tr>
                </tbody>
            );
        });
        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content mt-3-phone pd_phone_0 pt-3 pl-0 pr-0">
                    <div className="row m-0 m_phone_0">
                        <div className="col-xl-12 pd_phone_0">
                            <div className="m-portlet m-portlet--full-height ">
                                <LoadingOverlay
                                    active={this.state.loading}
                                    spinner={<FadeLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={150}
                                        color={'rgb(54, 215, 183)'}
                                        loading={true}
                                    />}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(17, 17, 17, 0.24)'
                                        })
                                    }}
                                >
                                    <div className="m-portlet__head">
                                        <div className="m-portlet__head-caption">
                                            <div className="m-portlet__head-title">
                                                <h3 className="m-portlet__head-text">
                                                    Age
                                            </h3>
                                            </div>
                                        </div>
                                        <div className="m-portlet__head-tools">
                                            <div className="m-form__group row">
                                                <div className="col-md-10 mt-1 pr-0 d-none d-md-block">
                                                    <div className="input-daterange input-group dateRanger">
                                                        <DatePicker
                                                            className="form-control m-input m_datetimepicker_6"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChangeStartDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            withPortal
                                                            placeholderText="Start Date" />
                                                        <div className="input-group-append" id="m_daterangepicker_6">
                                                            <span className="input-group-text">
                                                                <i className="la la-calendar glyphicon-th" />
                                                            </span>
                                                        </div>
                                                        <DatePicker
                                                            className="form-control m-input m_datetimepicker_6"
                                                            selected={this.state.endDate}
                                                            onChange={this.handleChangeEndDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            withPortal
                                                            minDate={this.state.startDate}
                                                            placeholderText="End Date" />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 pl-0 d-none d-md-block">
                                                    <button onClick={this.handleClickSearch} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air" id="searchAge">Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-portlet__body pl_phone_0 pr_phone_0">
                                        <div className="text-center">
                                            <div className="col-md-10 mt-1 pr-0 d-block d-md-none">
                                                <div className="input-daterange input-group dateRanger">
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.startDate}
                                                        onChange={this.handleChangeStartDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        disabledKeyboardNavigation
                                                        placeholderText="Start Date" />
                                                    <div className="input-group-append" id="m_daterangepicker_6">
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar glyphicon-th" />
                                                        </span>
                                                    </div>
                                                    <DatePicker
                                                        className="form-control m-input m_datetimepicker_6"
                                                        selected={this.state.endDate}
                                                        onChange={this.handleChangeEndDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        withPortal
                                                        disabledKeyboardNavigation
                                                        minDate={this.state.startDate}
                                                        placeholderText="End Date" />
                                                </div>
                                            </div>
                                            <div className="col-md-2 pl-0 d-block btn_pd_mobile d-md-none">
                                                <button onClick={this.handleClickSearch} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air" id="searchAge">Search</button>
                                            </div>
                                        </div>
                                        <div className="row m_phone_0" id="contai_showAge">
                                            <div className="col-md-6 pl_phone_0 pr_phone_0">
                                                <div id="container_Age">
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={chartOptions}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 pt-3">
                                                <table className="table table-bordered">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">Age</th>
                                                            <th scope="col">Traffic</th>
                                                        </tr>
                                                    </thead>
                                                    {bulletedAge}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Age;